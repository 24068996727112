import React from "react";
import styled from "styled-components";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { TextElementDisplay_TextElementV2Fragment$key } from "@generated/TextElementDisplay_TextElementV2Fragment.graphql";

const TEXT_ELEMENT_FRAGMENT = graphql`
	fragment TextElementDisplay_TextElementV2Fragment on TextLearnElement {
		text
		title
	}
`;

interface OwnProps {
	textElementFragmentRef: TextElementDisplay_TextElementV2Fragment$key;
}

const Textbox = styled.div`
	max-width: 768px;
`;

export function TextElementDisplay({ textElementFragmentRef }: OwnProps) {
	const textElement = useFragment<TextElementDisplay_TextElementV2Fragment$key>(
		TEXT_ELEMENT_FRAGMENT,
		textElementFragmentRef,
	);

	return (
		<div className="p-6">
			<h1 dangerouslySetInnerHTML={{ __html: textElement.title }} />
			<Textbox dangerouslySetInnerHTML={{ __html: textElement.text || "" }} />
		</div>
	);
}
