/**
 * @generated SignedSource<<3b055ff72941daceb96ef502148f5a65>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MainTopBar_UserDataFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ProfileLink_UserDataFragment">;
  readonly " $fragmentType": "MainTopBar_UserDataFragment";
};
export type MainTopBar_UserDataFragment$key = {
  readonly " $data"?: MainTopBar_UserDataFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"MainTopBar_UserDataFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MainTopBar_UserDataFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProfileLink_UserDataFragment"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "66b7fd155ddcc69fb742f20da271d526";

export default node;
