/**
 * @generated SignedSource<<2902bf5b0c9dc5af6bd679182bb5c544>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TextElementPart_ContentSubmissionFragment$data = {
  readonly definition: {
    readonly currentElementState?: {
      readonly element: {
        readonly " $fragmentSpreads": FragmentRefs<"TextElementDisplay_TextElementV2Fragment">;
      };
    } | null;
  };
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"ContentElementContainer_ContentSubmissionFragment" | "ProjectContentElementContainer_ContentSubmissionFragment" | "SubmitDisplayElementButton_ContentSubmissionFragment">;
  readonly " $fragmentType": "TextElementPart_ContentSubmissionFragment";
};
export type TextElementPart_ContentSubmissionFragment$key = {
  readonly " $data"?: TextElementPart_ContentSubmissionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"TextElementPart_ContentSubmissionFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TextElementPart_ContentSubmissionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "definition",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "currentElementState",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "element",
                  "plural": false,
                  "selections": [
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "TextElementDisplay_TextElementV2Fragment"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "ActiveELearningContentSubmissionDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContentElementContainer_ContentSubmissionFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SubmitDisplayElementButton_ContentSubmissionFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectContentElementContainer_ContentSubmissionFragment"
    }
  ],
  "type": "ContentSubmission",
  "abstractKey": null
};

(node as any).hash = "747451754f32c4f882fb117d1481ab01";

export default node;
