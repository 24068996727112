import styled from "styled-components";

export const MainContainerWithTopBar = styled.div`
	padding: 2rem;
	width: 85%;
	height: 100%;
	max-height: calc(100vh - 75px);
	overflow: auto;
	@media only screen and (max-width: 1024px) {
		max-height: calc(100vh - 90px);
	}
`;
