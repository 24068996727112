/**
 * @generated SignedSource<<8c6459792a36f0109ce8f4c89109dcf8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ContentNodeAdvancementStatus = "BlockedByTree" | "CanBeRestartedAfterFailed" | "CanBeRestartedAfterPassed" | "CanBeStarted" | "CanNotBeRestartedAfterFailed" | "CanNotBeRestartedAfterPassed" | "CanNotBeStarted" | "Continue" | "NotContentNode" | "ViolatesTreeFlow" | "%future added value";
export type ElementTypeV2 = "clozeText" | "file" | "multipleChoice" | "order" | "podcast" | "randomMultipleChoice" | "text" | "video" | "%future added value";
export type RestartIfFailedContentConfigType = "RestartIfFailedContent_BlockPermanently" | "RestartIfFailedContent_BlockPermanently_WhenRewarded" | "RestartIfFailedContent_BlockTemporarily" | "RestartIfFailedContent_BlockTemporarily_WhenRewarded" | "RestartIfFailedContent_CanNot" | "%future added value";
export type StartContentConfigType = "StartContent_CanIfInTreeFlow" | "StartContent_CanNot" | "StartContent_SpecificUserStatusesCan" | "%future added value";
export type TypeDefinitionType = "branch" | "content" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type NavigateDirectToQuestionsAdvanceContentButton_LearnOpportunityFragment$data = {
  readonly id: string;
  readonly root: {
    readonly structureDefinition: {
      readonly extension?: {
        readonly wordpressCertificates?: ReadonlyArray<{
          readonly id: string;
        } | null>;
      };
    };
  } | null;
  readonly shortDescription: string | null;
  readonly structureDefinition: {
    readonly title: string;
  };
  readonly typeDefinition: {
    readonly containedElementTypes?: ReadonlyArray<ElementTypeV2>;
    readonly contentNodeAdvancementResult?: {
      readonly activeContentSubmissionId?: string;
      readonly configResults?: ReadonlyArray<{
        readonly blockedUntil?: string;
        readonly canBeRestarted: boolean;
        readonly configType: RestartIfFailedContentConfigType;
      }>;
      readonly reasonConfigTypes?: ReadonlyArray<StartContentConfigType>;
      readonly status: ContentNodeAdvancementStatus;
    };
    readonly definitionType?: TypeDefinitionType;
  };
  readonly " $fragmentType": "NavigateDirectToQuestionsAdvanceContentButton_LearnOpportunityFragment";
};
export type NavigateDirectToQuestionsAdvanceContentButton_LearnOpportunityFragment$key = {
  readonly " $data"?: NavigateDirectToQuestionsAdvanceContentButton_LearnOpportunityFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NavigateDirectToQuestionsAdvanceContentButton_LearnOpportunityFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NavigateDirectToQuestionsAdvanceContentButton_LearnOpportunityFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "LearnOpportunityV2",
      "kind": "LinkedField",
      "name": "root",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "structureDefinition",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "extension",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "WordpressCertificateAndLearnState",
                          "kind": "LinkedField",
                          "name": "wordpressCertificates",
                          "plural": true,
                          "selections": [
                            (v0/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "type": "LearnOpportunityRootExtensionImpl",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "LearnOpportunityRootStructureDefinition",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shortDescription",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "structureDefinition",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "typeDefinition",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "definitionType",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "contentNodeAdvancementResult",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "status",
                  "storageKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "reasonConfigTypes",
                      "storageKey": null
                    }
                  ],
                  "type": "CanNotBeStartedContentNodeAdvancementResult",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "activeContentSubmissionId",
                      "storageKey": null
                    }
                  ],
                  "type": "ContinueContentNodeAdvancementResult",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "configResults",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "configType",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "canBeRestarted",
                          "storageKey": null
                        },
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "blockedUntil",
                              "storageKey": null
                            }
                          ],
                          "type": "NegativeBlockTemporarilyRestartIfFailedContentConfigResult",
                          "abstractKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "CanNotBeRestartedAfterFailedContentNodeAdvancementResult",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "containedElementTypes",
              "storageKey": null
            }
          ],
          "type": "LearnOpportunityELearningContentTypeDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "LearnOpportunityV2",
  "abstractKey": null
};
})();

(node as any).hash = "00cdbc9ec62dab4c44439cb9b9671fa1";

export default node;
