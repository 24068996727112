/**
 * @generated SignedSource<<71641c9c2b75c5c3fccf62e76a0f5d16>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DebugScreen_Query$variables = {};
export type DebugScreen_Query$data = {
  readonly Viewer: {
    readonly Auth: {
      readonly currentUser: {
        readonly user: {
          readonly " $fragmentSpreads": FragmentRefs<"DefaultContainer_UserDataFragment">;
        };
      } | null;
    };
  };
};
export type DebugScreen_Query = {
  response: DebugScreen_Query$data;
  variables: DebugScreen_Query$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DebugScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthViewerSchema",
            "kind": "LinkedField",
            "name": "Auth",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CurrentUser",
                "kind": "LinkedField",
                "name": "currentUser",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "DefaultContainer_UserDataFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DebugScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthViewerSchema",
            "kind": "LinkedField",
            "name": "Auth",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CurrentUser",
                "kind": "LinkedField",
                "name": "currentUser",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "extension",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "salutation",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "title",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "lastName",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "firstName",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "efnNumber",
                                "storageKey": null
                              }
                            ],
                            "type": "InfectopharmUserExtensionImpl",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7e639942a09ff354bf2543cf0b9059db",
    "id": null,
    "metadata": {},
    "name": "DebugScreen_Query",
    "operationKind": "query",
    "text": "query DebugScreen_Query {\n  Viewer {\n    Auth {\n      currentUser {\n        user {\n          ...DefaultContainer_UserDataFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment DefaultContainer_UserDataFragment on User {\n  ...MainTopBar_UserDataFragment\n}\n\nfragment MainTopBar_UserDataFragment on User {\n  ...ProfileLink_UserDataFragment\n}\n\nfragment ProfileLink_UserDataFragment on User {\n  extension {\n    __typename\n    ... on InfectopharmUserExtensionImpl {\n      salutation\n      title\n      lastName\n      firstName\n      efnNumber\n    }\n  }\n}\n"
  }
};

(node as any).hash = "3555c156c7beb679c4523a59d30a52cc";

export default node;
