/**
 * @generated SignedSource<<26acc2be5829e372eab285103de653b0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DataPrivacyScreen_Query$variables = {
  brandId: string;
};
export type DataPrivacyScreen_Query$data = {
  readonly Viewer: {
    readonly Auth: {
      readonly currentUser: {
        readonly user: {
          readonly " $fragmentSpreads": FragmentRefs<"DefaultContainer_UserDataFragment">;
        };
      } | null;
    };
    readonly Privacy: {
      readonly LatestPrivacy: {
        readonly content: string;
        readonly id: string;
      } | null;
    };
  };
};
export type DataPrivacyScreen_Query = {
  response: DataPrivacyScreen_Query$data;
  variables: DataPrivacyScreen_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "brandId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "PrivacyViewerSchema",
  "kind": "LinkedField",
  "name": "Privacy",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "brandId",
          "variableName": "brandId"
        }
      ],
      "concreteType": "Privacy",
      "kind": "LinkedField",
      "name": "LatestPrivacy",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "content",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DataPrivacyScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthViewerSchema",
            "kind": "LinkedField",
            "name": "Auth",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CurrentUser",
                "kind": "LinkedField",
                "name": "currentUser",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "DefaultContainer_UserDataFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DataPrivacyScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthViewerSchema",
            "kind": "LinkedField",
            "name": "Auth",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CurrentUser",
                "kind": "LinkedField",
                "name": "currentUser",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "extension",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "salutation",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "title",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "lastName",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "firstName",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "efnNumber",
                                "storageKey": null
                              }
                            ],
                            "type": "InfectopharmUserExtensionImpl",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "50eb638d3e548f519f8bd0f61d4cdc55",
    "id": null,
    "metadata": {},
    "name": "DataPrivacyScreen_Query",
    "operationKind": "query",
    "text": "query DataPrivacyScreen_Query(\n  $brandId: ID!\n) {\n  Viewer {\n    Auth {\n      currentUser {\n        user {\n          ...DefaultContainer_UserDataFragment\n          id\n        }\n      }\n    }\n    Privacy {\n      LatestPrivacy(brandId: $brandId) {\n        id\n        content\n      }\n    }\n  }\n}\n\nfragment DefaultContainer_UserDataFragment on User {\n  ...MainTopBar_UserDataFragment\n}\n\nfragment MainTopBar_UserDataFragment on User {\n  ...ProfileLink_UserDataFragment\n}\n\nfragment ProfileLink_UserDataFragment on User {\n  extension {\n    __typename\n    ... on InfectopharmUserExtensionImpl {\n      salutation\n      title\n      lastName\n      firstName\n      efnNumber\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "63816c8fde219c16a5e0c12577861048";

export default node;
