import React from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import styled from "styled-components";

export function Loader() {
	return (
		<div className="d-flex flex-grow-1">
			<div className="flex justify-content-center align-items-center w-full h-full flex-grow-1">
				<ProgressSpinnerCustom color="var(--primary-color)" style={{ width: 100, height: 100 }} />
			</div>
		</div>
	);
}

const ProgressSpinnerCustom = styled(ProgressSpinner)`
	@keyframes p-progress-spinner-color {
		100%,
		0% {
			stroke: var(--primary-color);
		}
		40% {
			stroke: var(--primary-color);
		}
		66% {
			stroke: var(--primary-color);
		}
		80%,
		90% {
			stroke: var(--primary-color);
		}
	}
`;
