import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { FileElementPart_ContentSubmissionFragment$key } from "@generated/FileElementPart_ContentSubmissionFragment.graphql";
import { FileElementDisplay } from "./FileElementDisplay.component";
import { SubmitDisplayElementButton } from "../SubmitDisplayElementButton.component";
import { ProjectContentElementContainer } from "../../../../infecto-lms-webapp-impl/containers/ProjectContentElement.container";

const CONTENT_SUBMISSION_FRAGMENT = graphql`
	fragment FileElementPart_ContentSubmissionFragment on ContentSubmission {
		id
		definition {
			... on ActiveELearningContentSubmissionDefinition {
				currentElementState {
					element {
						...FileElementDisplay_FileElementV2Fragment
					}
				}
			}
		}
		...ProjectContentElementContainer_ContentSubmissionFragment
		...SubmitDisplayElementButton_ContentSubmissionFragment
	}
`;

interface OwnProps {
	contentSubmissionFragmentRef: FileElementPart_ContentSubmissionFragment$key;
}

export function FileElementPart({ contentSubmissionFragmentRef }: OwnProps) {
	const contentSubmission = useFragment<FileElementPart_ContentSubmissionFragment$key>(
		CONTENT_SUBMISSION_FRAGMENT,
		contentSubmissionFragmentRef,
	);

	if (!contentSubmission.definition.currentElementState) return null;

	return (
		<ProjectContentElementContainer
			contentSubmissionFragmentRef={contentSubmission}
			nextButton={<SubmitDisplayElementButton contentSubmissionFragmentRef={contentSubmission} />}
		>
			<FileElementDisplay
				fileElementFragmentRef={contentSubmission.definition.currentElementState.element}
			/>
		</ProjectContentElementContainer>
	);
}
