/**
 * @generated SignedSource<<f3200da3e8569c080d2d69b32275a187>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type VideoElementDisplayWithAnswers_VideoElementV2Fragment$data = {
  readonly title: string;
  readonly " $fragmentType": "VideoElementDisplayWithAnswers_VideoElementV2Fragment";
};
export type VideoElementDisplayWithAnswers_VideoElementV2Fragment$key = {
  readonly " $data"?: VideoElementDisplayWithAnswers_VideoElementV2Fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"VideoElementDisplayWithAnswers_VideoElementV2Fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "VideoElementDisplayWithAnswers_VideoElementV2Fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    }
  ],
  "type": "VideoElement",
  "abstractKey": null
};

(node as any).hash = "49250b243cf152eaf66a968128cbf8e4";

export default node;
