/**
 * @generated SignedSource<<10a014164ad8db2d605f066ae7fffd59>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LinkButton_MessageDefinitionLinkButtonFragment$data = {
  readonly link: string;
  readonly title: string;
  readonly " $fragmentType": "LinkButton_MessageDefinitionLinkButtonFragment";
};
export type LinkButton_MessageDefinitionLinkButtonFragment$key = {
  readonly " $data"?: LinkButton_MessageDefinitionLinkButtonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"LinkButton_MessageDefinitionLinkButtonFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LinkButton_MessageDefinitionLinkButtonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "link",
      "storageKey": null
    }
  ],
  "type": "MessageDefinitionLinkButton",
  "abstractKey": null
};

(node as any).hash = "222322c7ccf07aed0040352054b54e16";

export default node;
