import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Loader } from "../../../components/loading.component";
import { ImplAuthRoutes } from "../../../../infecto-lms-webapp-impl/router/routes/auth/impl-auth.routes";

export const AuthRoutes = () => {
	return (
		<BrowserRouter>
			<Suspense fallback={<Loader />}>
				<Routes>
					{[...ImplAuthRoutes].map((prd) => {
						return <Route key={prd.path} path={prd.path} element={prd.component} />;
					})}
				</Routes>
			</Suspense>
		</BrowserRouter>
	);
};
