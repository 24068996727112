import React, { useEffect, useRef } from "react";
import { RadioButtonProps } from "./radio-button.interface";
import { RadioButton as RadioButtonType } from "primereact/radiobutton";
import { iconConverter } from "../../utils/icon-converter";
import { StyledRadioButton } from "./radio-button.styles";

export const RadioButton = ({ status, extra, ...props }: RadioButtonProps) => {
	const ref = useRef<RadioButtonType>(null);
	const className = status === "error" ? "p-invalid" : "";
	const disabled = status === "disabled";
	const extras = {
		...(extra && extra),
		...(extra?.icon && { icon: iconConverter.for(extra.icon) }),
	};
	useEffect(() => {
		const newIcon = extras?.icon;
		if (newIcon) {
			const item = ref.current
				?.getElement()
				.getElementsByClassName("p-radiobutton-icon")
				.item(0) as HTMLElement | undefined;
			const classList = item?.classList;
			newIcon.split(" ").forEach((className: string) => classList?.add(className));
			if (item) item.style.fontSize = "0.75rem";
		}
	}, [extra?.icon]);
	return (
		<StyledRadioButton className={className} disabled={disabled} {...extras} {...props} ref={ref} />
	);
};
