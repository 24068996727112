/**
 * @generated SignedSource<<e598fd2903ee32c5c0aec2718b5d38d0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type FileContentTypeV2 = "jpg" | "pdf" | "png" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type FileElementDisplay_FileElementV2Fragment$data = {
  readonly file: {
    readonly url: string | null;
  } | null;
  readonly fileContentType: FileContentTypeV2;
  readonly title: string;
  readonly " $fragmentType": "FileElementDisplay_FileElementV2Fragment";
};
export type FileElementDisplay_FileElementV2Fragment$key = {
  readonly " $data"?: FileElementDisplay_FileElementV2Fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"FileElementDisplay_FileElementV2Fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FileElementDisplay_FileElementV2Fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "file",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fileContentType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    }
  ],
  "type": "FileLearnElement",
  "abstractKey": null
};

(node as any).hash = "dd741a92fc4c2a9200f5831de3368773";

export default node;
