import React, { useEffect, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import styled from "styled-components";
import { PrimaryButton } from "./buttons/PrimaryButton";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

interface OwnProps {
	url: string;
}

export function PdfViewer({ url }: OwnProps) {
	const [numPages, setNumPages] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [manualChange, setManualChange] = useState(false);
	const [zoom, setZoom] = useState(1);
	const pageRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const handleScroll = () => {
			if (manualChange) {
				setManualChange(false);
				return;
			}

			if (pageRef.current) {
				const { scrollTop, clientHeight, scrollHeight } = pageRef.current;
				const scrolledPage = Math.ceil((scrollTop / (scrollHeight - clientHeight)) * numPages);
				if (scrolledPage !== currentPage) {
					setCurrentPage(scrolledPage);
				}
			}
		};

		const viewer = pageRef.current;
		viewer?.addEventListener("scroll", handleScroll);

		return () => viewer?.removeEventListener("scroll", handleScroll);
	}, [numPages, currentPage, manualChange]);

	const changePageManually = (newPage: any) => {
		if (newPage >= 1 && newPage <= numPages && newPage !== currentPage) {
			setCurrentPage(newPage);
			setManualChange(true);
		}
		if (pageRef.current) {
			const pageHeight = pageRef.current.scrollHeight / numPages;
			pageRef.current.scrollTop = (newPage - 1) * pageHeight;
		}
	};
	function onDocumentLoadSuccess({ numPages }: { numPages: any }) {
		setNumPages(numPages);
		setCurrentPage(1);
	}
	const pageWidthBasedOfDevice = () => {
		if (window.innerWidth > 480 && window.innerWidth < 1025) {
			return 250;
		} else if (window.innerWidth < 480) {
			return 10;
		} else return 100;
	};

	const documentHeightBasedOnDevice = () => {
		return Math.min(window.innerWidth - pageWidthBasedOfDevice(), 1000) * 1.6;
	};
	const documentHeightBasedOnDeviceAndScale = (scale: number) => {
		return Math.min(window.innerWidth - pageWidthBasedOfDevice(), 1000) * 1.4 * scale;
	};
	const paddingLeftBasedOnZoomAndWidthDevice = (pageWidth: number, scale: number) => {
		const pageWidthBasedOnZoom = pageWidth * scale;

		if (pageWidthBasedOnZoom > pageWidth) {
			return (pageWidthBasedOnZoom - pageWidth) / 2;
		} else {
			return 0;
		}
	};
	const paddingTopBasedOnZoomAndHightDevice = (pageHeight: number, scale: number) => {
		const pageHeightBasedOnZoom = pageHeight * scale;

		if (pageHeightBasedOnZoom > pageHeight) {
			return (pageHeightBasedOnZoom - pageHeight) / 2;
		} else {
			return 0;
		}
	};

	return (
		<DocumentWrapper
			height={documentHeightBasedOnDevice()}
			onLoadSuccess={onDocumentLoadSuccess}
			onLoadError={(e) => {
				console.error(e);
			}}
			loading="Lade PDF..."
			className="flex justify-content-center flex-column flex-grow-1 w-12 mb-4"
			file={url}
		>
			<div>
				<div className="flex flex-column sm:flex-row align-items-center justify-content-center">
					<div className="flex flex-row align-items-center justify-content-center max-h-1rem">
						<PrimaryButton
							className="p-1 md:p-2 text-xss md:text-base mr-3 md:mr-3"
							icon="pi pi-chevron-left"
							onClick={() => changePageManually(currentPage - 1)}
							disabled={currentPage === 1}
						/>

						<div className="text-xs md:text-base md:mr-2 mt-3 sm:mt-1 mb-3 sm:mb-1">
							<InputNumber
								inputStyle={{
									width: window.innerWidth < 780 ? 35 : 50,
									fontSize: window.innerWidth < 780 ? 12 : "1rem",
									padding: window.innerWidth < 780 ? 5 : "auto",
								}}
								value={currentPage}
								onValueChange={(e) => changePageManually(e.value)}
								min={1}
								max={numPages}
							/>
							<span className="text-xs md:text-base"> / {numPages}</span>
						</div>

						<PrimaryButton
							className="p-1 md:p-2 ml-2 md: ml-0 text-xs md:text-base"
							icon="pi pi-chevron-right"
							onClick={() => changePageManually(currentPage + 1)}
							disabled={currentPage === numPages}
						/>
					</div>
					<div className=" mr-3 mt-3 sm:ml-3 sm:mt-1 mb-1 sm:mb-1 flex flex-row align-items-center justify-content-center ">
						<Button
							icon="pi pi-plus"
							onClick={() => setZoom((prevZoom) => Math.min(prevZoom + 0.1, 2))}
							className="text-primary p-button-rounded p-button-text text-xs md:text-base"
						/>
						<InputNumber
							inputStyle={{
								width: 60,
								fontSize: window.innerWidth < 780 ? 12 : 15,
								textAlign: "center",
								padding: window.innerWidth < 780 ? 5 : "auto",
							}}
							value={zoom * 100}
							step={10}
							min={10}
							max={200}
							onChange={(e) => {
								const newZoom = e.value ? e.value / 100 : 1;
								setZoom(newZoom);
							}}
							format={true}
							suffix="%"
						/>
						<Button
							icon="pi pi-minus"
							onClick={() => setZoom((prevZoom) => Math.max(prevZoom - 0.1, 1))}
							className="text-primary p-button-rounded p-button-text"
						/>
					</div>
				</div>
			</div>
			<PageWrapper
				className="mt-1 sm:mt-2 lg:mt-3"
				ref={pageRef}
				paddingTop={paddingTopBasedOnZoomAndHightDevice(
					documentHeightBasedOnDeviceAndScale(zoom),
					zoom,
				)}
			>
				{Array.from(new Array(numPages), (el, index) => (
					<PageComponent
						height={documentHeightBasedOnDeviceAndScale(zoom)}
						zoom={zoom}
						scale={zoom}
						paddingLeft={paddingLeftBasedOnZoomAndWidthDevice(
							Math.min(window.innerWidth - pageWidthBasedOfDevice(), 900),
							zoom,
						)}
						className="flex flex-grow-1 justify-content-center"
						width={Math.min(window.innerWidth - pageWidthBasedOfDevice(), 900)}
						maxWidth={Math.min(window.innerWidth - pageWidthBasedOfDevice(), 900)}
						key={`page_${index + 1}`}
						pageNumber={manualChange ? currentPage : index + 1}
						renderTextLayer={false}
						renderAnnotationLayer={true}
						renderMode={"canvas"}
						onScroll={() => setCurrentPage(index + 1)}
						onClick={() => {
							setCurrentPage(index + 1);
						}}
					/>
				))}
			</PageWrapper>
		</DocumentWrapper>
	);
}
interface DocumentProps {
	height: number;
}
const DocumentWrapper = styled(Document)<DocumentProps>`
	padding-top: 5px;
	height: ${(p) => p.height}px;
	overflow: auto;
	@media screen and (min-width: 1181px) {
		max-width: calc(100vw - 400px);
	}
	@media screen and (max-width: 1180px) and (min-width: 600px) {
		max-width: calc(100vw - 100px);
	}
	@media screen and (max-width: 599px) and (min-width: 481px) {
		max-width: calc(100vw - 50px);
	}
	@media screen and (max-width: 480px) {
		padding-top: 10px;
		max-width: 340px;
	}
`;
interface PageWrapperProps {
	paddingTop: number;
}
const PageWrapper = styled.div<PageWrapperProps>`
	overflow: auto;
	padding-bottom: 20px;
`;
interface PageProps {
	zoom: number;
	height: number;
	paddingLeft: number;
	maxWidth?: number;
}
const PageComponent = styled(Page)<PageProps>`
	height: ${(p) => p.height}px;
	max-width: ${(p) => p.maxWidth}px;
	display: block;
	margin: 0 auto;
`;
