import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment, useLazyLoadQuery } from "react-relay";
import styled from "styled-components";
import { Navigate, useMatch } from "react-router-dom";
import { NodeScreen_Query } from "@generated/NodeScreen_Query.graphql";
import { NodeDescription } from "../../infecto-lms-webapp/components/node/NodeDescription.component";
import { usePageTitle } from "../../infecto-lms-webapp/hooks/usePageTitle";
import { DefaultContainer } from "../../infecto-lms-webapp/containers/Default.container";
import { NodeBreadcrumbs } from "../../infecto-lms-webapp/components/node/NodeBreadcrumbs.component";
import { UserStatusMessage } from "../../infecto-lms-webapp/components/UserStatusMessage.component";
import { NodeConfigurationsDisplay } from "../../infecto-lms-webapp/components/node/NodeConfigurationsDisplay.component";
import { Message } from "primereact/message";
import { NodeScreen_NodeFragment$key } from "@generated/NodeScreen_NodeFragment.graphql";
import {
	getLearnOpportunityPath,
	LEARN_OPPORTUNITY_ID_URL_PARAM,
} from "../../infecto-lms-webapp-impl/router/routes/auth/nodes.routes";
import { NodeLeftFrame } from "../components/node/NodeLeftFrame.component";
import { NodeInstructors } from "../../infecto-lms-webapp/components/node/NodeInstructors.component";
import { MainContainerWithTopAndBottomBar } from "../../infecto-lms-webapp/containers/MainContainerWithTopAndBottomBar.container";
import { formatDateTime } from "../../infecto-lms-webapp/components/DateTimeDisplay.component";
import { PaediaTreeAdvancementMessage } from "../components/node/PaediaTreeAdvancementMessage.component";
import { ElementTypeV2 } from "@generated/SearchResults_Query.graphql";
import { LearningOutcomeDescriptionComponent } from "../components/node/LearningOutcomeDescription.component";
import { WrapperContainerWithScrollbar } from "../../infecto-lms-webapp/containers/WrapperContainerWithScrollbar.container";

const QUERY = graphql`
	query NodeScreen_Query($learnOpportunityId: ID!) {
		node(id: $learnOpportunityId) {
			id
			... on LearnOpportunityV2 {
				id
				...NodeScreen_NodeFragment
			}
		}
		Viewer {
			Auth {
				currentUser {
					user {
						...DefaultContainer_UserDataFragment
						...UserStatusMessage_UserFragment
						...LearningOutcomeDescriptionComponent_UserFragment
					}
				}
			}
		}
	}
`;

const LEARN_OPPORTUNITY_FRAGMENT = graphql`
	fragment NodeScreen_NodeFragment on LearnOpportunityV2 {
		id
		image {
			url
		}
		root {
			id
			structureDefinition {
				title
				... on LearnOpportunityRootStructureDefinition {
					extension {
						... on LearnOpportunityRootExtensionImpl {
							dateTimeOnlyAdminsVisibility
							reducedDataInTree {
								elementTypes
								pharmaPoints
							}
							configConsequences {
								rewardExpired
								showAnswerExpired
							}
						}
					}
					icon {
						url
					}
					...NodeTagList_LearnOpportunityRootStructureDefinitionFragment
				}
			}
		}
		shortDescription
		description
		instructors {
			superId
		}
		structureDefinition {
			title
		}
		typeDefinition {
			... on LearnOpportunityBranchTypeDefinition {
				children {
					id
					typeDefinition {
						... on LearnOpportunityELearningContentTypeDefinition {
							containedElementTypes
						}
					}
					...ShowAnswersButton_LearnOpportunityFragment
				}
			}
			definitionType
		}
		...ProjectContentButtonCME_LearnOpportunityFragment
		...AttachmentHeftDisplay_LearnOpportunityV2Fragment
		...NodeNavigationButtons_NodeFragment
		...NodeBreadcrumbs_NodeFragment
		...NodeConfigurationsDisplay_NodeFragment
		...NodeContentElementsList_NodeFragment
		...AttachmentDisplay_LearnOpportunityV2Fragment
		...NodeDescription_NodeFragment
		...ShowAnswersButton_LearnOpportunityFragment
		...PaediaTreeAdvancementMessage_NodeFragment
		...LearningOutcomeDescriptionComponent_NodeFragment
	}
`;

export function NodeScreen() {
	const {
		params: { learnOpportunityId },
	} = useMatch(getLearnOpportunityPath(LEARN_OPPORTUNITY_ID_URL_PARAM))!;

	const query = useLazyLoadQuery<NodeScreen_Query>(
		QUERY,
		{
			learnOpportunityId: learnOpportunityId!,
		},
		{ fetchPolicy: "network-only" },
	);

	const learnOpportunity = useFragment<NodeScreen_NodeFragment$key>(
		LEARN_OPPORTUNITY_FRAGMENT,
		query.node,
	)!;

	if (!learnOpportunity) {
		return <Navigate to="*" />;
	}

	usePageTitle(learnOpportunity.structureDefinition.title);

	const hasMultipleChoice =
		learnOpportunity.typeDefinition.children?.filter(
			(child: {
				readonly id: string;
				readonly typeDefinition: {
					readonly containedElementTypes?: ReadonlyArray<ElementTypeV2>;
				};
			}) => child.typeDefinition.containedElementTypes?.includes("multipleChoice"),
		).length || 0 > 0;

	const hasRandomMultipleChoice =
		learnOpportunity.typeDefinition.children?.filter(
			(child: {
				readonly id: string;
				readonly typeDefinition: {
					readonly containedElementTypes?: ReadonlyArray<ElementTypeV2>;
				};
			}) => child.typeDefinition.containedElementTypes?.includes("randomMultipleChoice"),
		).length || 0 > 0;

	const onlyAdminVisibilityDateTime =
		learnOpportunity?.root?.structureDefinition?.extension?.dateTimeOnlyAdminsVisibility;

	const isExpired =
		learnOpportunity.root?.structureDefinition.extension?.configConsequences?.rewardExpired;

	const hasPharmaPoints = () => {
		return (
			(learnOpportunity.root?.structureDefinition.extension?.reducedDataInTree?.pharmaPoints ||
				-1) > 0
		);
	};
	const footerPixelBasedOnScreenWidth = window.innerWidth < 769 ? 0 : 100;
	return (
		<DefaultContainer
			collapsed
			screenName="learn-opportunity"
			containerId={learnOpportunity.id}
			userDataFragmentRef={query.Viewer.Auth.currentUser?.user}
		>
			<MainContainerWithTopAndBottomBar
				footerPixels={footerPixelBasedOnScreenWidth}
				className="w-12 flex-grow-1 grid grid-nogutter"
			>
				<NodeLeftFrame footerPixels={50} rootId={learnOpportunity?.root?.id!} />
				<DescriptionAndAttachmentsSideBar
					footerPixels={footerPixelBasedOnScreenWidth}
					className="col-12 xl:col-9 p-0 bg-white border-left-1 border-300 flex flex-column flex-grow-1"
				>
					<WrapperContainerWithScrollbar
						width={98}
						marginBottom={100}
						footerPixels={footerPixelBasedOnScreenWidth}
					>
						<DescriptionContainer className="flex pl-5 pt-2 pr-5 flex-grow-1 relative md:justify-content-between">
							<div className="flex-grow-1 md:relative md:pr-3" style={{ maxWidth: 1024 }}>
								<div className="hidden md:block">
									<NodeBreadcrumbs learnOpportunityFragmentRef={learnOpportunity} />
								</div>

								<div className="flex lg:align-items-center flex-column lg:flex-row">
									<h1 className="mb-4">{learnOpportunity.structureDefinition.title}</h1>
									{onlyAdminVisibilityDateTime !== null &&
										learnOpportunity?.root?.id === learnOpportunityId && (
											<Message
												className="lg:mt-4 mb-4 w-12 md:w-12 lg:w-4 p-3 lg:ml-4"
												severity="warn"
												content={
													<div>Sichtbar ab: {formatDateTime(onlyAdminVisibilityDateTime!)}</div>
												}
											/>
										)}
								</div>

								<UserStatusMessage userFragment={query.Viewer.Auth.currentUser?.user!} />
								<PaediaTreeAdvancementMessage nodeFragment={learnOpportunity} />

								{learnOpportunity.image?.url && (
									<div className="mb-4">
										<img
											style={{ maxWidth: "100%", maxHeight: "350px" }}
											alt={learnOpportunity.structureDefinition.title}
											src={learnOpportunity.image.url}
										/>
									</div>
								)}

								{(learnOpportunity.root?.structureDefinition.extension?.configConsequences
									?.rewardExpired ||
									false) && (
									<div className="mb-4">
										<Message
											severity="warn"
											content="ACHTUNG: Dieses Modul ist abgelaufen. Es werden keine Punkte / Zertifikate für das Bestehen vergeben."
										/>
									</div>
								)}

								{(learnOpportunity.root?.structureDefinition.extension?.configConsequences
									?.showAnswerExpired ||
									false) && (
									<div className="mb-4">
										<Message
											severity="warn"
											content="Sie können sich nun die Antworten anzeigen lassen."
										/>
									</div>
								)}

								<NodeDescription learnOpportunityFragmentRef={learnOpportunity} />
								{(hasMultipleChoice || hasRandomMultipleChoice) &&
									hasPharmaPoints() &&
									!isExpired && (
										<LearningOutcomeDescriptionComponent
											userFragmentRef={query.Viewer.Auth.currentUser?.user!}
											learnOpportunityFragmentRef={learnOpportunity}
										/>
									)}
								{learnOpportunity?.instructors.length > 0 && (
									<NodeInstructors ids={learnOpportunity.instructors.map((i) => i.superId)} />
								)}
								<NodeConfigurationsDisplay learnOpportunityFragmentRef={learnOpportunity} />
							</div>
							{/*		<AttachmentDisplay
							openInitially={true}
							learnOpportunityFragmentRef={learnOpportunity}
						/>*/}
						</DescriptionContainer>
					</WrapperContainerWithScrollbar>
				</DescriptionAndAttachmentsSideBar>
			</MainContainerWithTopAndBottomBar>
		</DefaultContainer>
	);
}

const DescriptionContainer = styled.div`
	@media (max-width: 768px) {
		padding-bottom: 102px;
	}
	height: 100%;
`;

interface DescriptionProps {
	footerPixels: number;
}
const DescriptionAndAttachmentsSideBar = styled.div<DescriptionProps>`
	max-height: calc(100vh - ${(props) => props.footerPixels}px);
	min-height: 700px;
	@media (max-width: 768px) {
		max-height: fit-content;
	}
`;

/*const Footer = styled.div`
	min-height: 102px;
`;*/
