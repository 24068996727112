import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import { MainTopBar } from "../components/navigation/MainTopBar.component";
import { MainSidebar } from "../components/navigation/MainSideBar.component";
import { DefaultContainer_UserDataFragment$key } from "@generated/DefaultContainer_UserDataFragment.graphql";
import styled from "styled-components";

const USER_DATA = graphql`
	fragment DefaultContainer_UserDataFragment on User {
		...MainTopBar_UserDataFragment
	}
`;

interface OwnProps {
	userDataFragmentRef?: DefaultContainer_UserDataFragment$key | null;
	children: any;
	rightSideSlot?: ReactNode;
	containerId?: string;
	screenName: string;
	collapsed: boolean;
}

export function DefaultContainer({
	userDataFragmentRef,
	children,
	rightSideSlot,
	containerId,
	screenName,
	collapsed,
}: OwnProps) {
	const userData = useFragment<DefaultContainer_UserDataFragment$key>(
		USER_DATA,
		userDataFragmentRef || null,
	);

	const topBarRef = useRef(null);
	const mainSideBarRef = useRef(null);
	const [mainSideBarWidth, setMainSideBarWidth] = useState(0);
	const [topBarHeight, setTopBarHeight] = useState(0);

	useEffect(() => {
		// @ts-ignore
		setMainSideBarWidth(mainSideBarRef.current.offsetWidth);
		// @ts-ignore
		setTopBarHeight(topBarRef.current.offsetHeight);
	}, []);
	return (
		<div className="flex flex-grow-1 min-h-full" style={{ boxSizing: "border-box" }}>
			<div ref={mainSideBarRef}>
				<MainSidebar collapsed={collapsed} className="border-right-1 border-300 hidden md:flex " />
			</div>
			<RightContentDefaultWrapperContainer
				sideBarWidth={mainSideBarWidth}
				className="flex flex-grow-1 flex-column"
			>
				<div ref={topBarRef}>
					<MainTopBar
						containerId={containerId}
						screen={screenName}
						className="border-bottom-1 z-1"
						userDataFragmentRef={userData}
					/>
				</div>
				<RightSlotContentDefaultContainer
					topBarsHeight={topBarHeight}
					className="flex flex-grow-1 surface-100"
				>
					<div className=" flex-grow-1 justify-content-center flex">{children}</div>
					{rightSideSlot}
				</RightSlotContentDefaultContainer>
			</RightContentDefaultWrapperContainer>
		</div>
	);
}
export interface ContentContainer {
	topBarsHeight: number;
}
export const RightSlotContentDefaultContainer = styled.div<ContentContainer>`
	height: calc(100vh - ${(props) => props.topBarsHeight}px);
`;
export interface WrapperDefaultContainer {
	sideBarWidth: number;
}
export const RightContentDefaultWrapperContainer = styled.div<WrapperDefaultContainer>`
	width: calc(100vw - ${(props) => props.sideBarWidth}px);
`;
