import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useNavigate } from "react-router-dom";
import { useFragment } from "react-relay";
import { DASHBOARD_PATH } from "../../infecto-lms-webapp-impl/router/routes/auth/dashboard.routes";
import { FailedContentButton_ContentSubmissionFragment$key } from "@generated/FailedContentButton_ContentSubmissionFragment.graphql";
import { getLearnOpportunityPath } from "../../infecto-lms-webapp-impl/router/routes/auth/nodes.routes";
import { isPermanentlyBlockedUtil } from "../utils/advancement-result/is-permanently-blocked";
import { PrimaryButton } from "../../infecto-lms-webapp/components/buttons/PrimaryButton";

const CONTENT_SUBMISSION_FRAGMENT = graphql`
	fragment FailedContentButton_ContentSubmissionFragment on ContentSubmission {
		id
		definition {
			status
		}
		learnOpportunity {
			root {
				id
			}
			id
			typeDefinition {
				... on LearnOpportunityELearningContentTypeDefinition {
					extension {
						... on LearnContentExtensionImpl {
							configConsequences {
								isBlockedPermanentlyAfterNumFailedTries
							}
						}
					}
					contentNodeAdvancementResult {
						status
						... on CanNotBeRestartedAfterFailedContentNodeAdvancementResult {
							configResults {
								configType

								... on RestartIfFailedContentConfigResult {
									canBeRestarted
								}

								... on NegativeBlockPermanentlyRestartIfFailedContentConfigResult {
									canBeRestarted
								}

								... on NegativeBlockTemporarilyRestartIfFailedContentConfigResult {
									blockedUntil
								}
							}
						}
					}
				}
			}
		}
	}
`;

interface OwnProps {
	contentSubmissionRef: FailedContentButton_ContentSubmissionFragment$key;
}
export const FailedContentButton = ({ contentSubmissionRef }: OwnProps) => {
	const navigate = useNavigate();
	const contentSubmission = useFragment<FailedContentButton_ContentSubmissionFragment$key>(
		CONTENT_SUBMISSION_FRAGMENT,
		contentSubmissionRef,
	);

	const isPermanentlyBlocked = isPermanentlyBlockedUtil(
		contentSubmission.learnOpportunity?.typeDefinition.contentNodeAdvancementResult?.configResults,
	);

	const isblockedPermanentlyAfterNumFailed =
		!isPermanentlyBlocked &&
		contentSubmission.learnOpportunity?.typeDefinition.extension?.configConsequences
			?.isBlockedPermanentlyAfterNumFailedTries !== null;

	const learnOpportunityId = () => {
		if (contentSubmission.learnOpportunity?.root?.id) {
			return contentSubmission.learnOpportunity?.root?.id;
		} else return contentSubmission?.learnOpportunity?.id;
	};
	return contentSubmission.definition.status == "failed" ? (
		<div className="flex flex-row">
			{isblockedPermanentlyAfterNumFailed && (
				<PrimaryButton
					onClick={() => {
						navigate(getLearnOpportunityPath(learnOpportunityId() || ""));
					}}
					className="p-button-primary bg-primary border-primary ml-0 md:ml-3"
					label="Erneut Versuchen"
				/>
			)}
			<PrimaryButton
				onClick={() => navigate(DASHBOARD_PATH)}
				className="p-button-primary bg-primary border-primary ml-0 md:ml-3"
				label="Zur Startseite"
			/>
		</div>
	) : null;
};
