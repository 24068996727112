import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { NodeSubmitButton } from "../node/NodeSubmitButton";
import { useFragment, useMutation } from "react-relay";
import { SubmitDisplayElementButton_ContentSubmissionFragment$key } from "@generated/SubmitDisplayElementButton_ContentSubmissionFragment.graphql";
import { SubmitDisplayElementButton_SubmitDisplayElementMutation } from "@generated/SubmitDisplayElementButton_SubmitDisplayElementMutation.graphql";

const CONTENT_SUBMISSION_FRAGMENT = graphql`
	fragment SubmitDisplayElementButton_ContentSubmissionFragment on ContentSubmission {
		id
		definition {
			... on ActiveELearningContentSubmissionDefinition {
				currentElementState {
					element {
						id
					}
				}
			}
		}
	}
`;

const SUBMIT_DISPLAY_ELEMENT_MUTATION = graphql`
	mutation SubmitDisplayElementButton_SubmitDisplayElementMutation(
		$input: SubmitDisplayElementInput!
	) {
		LearnV2 {
			submitDisplayElement(input: $input) {
				contentSubmission {
					id
					...ContentSubmissionScreen_ContentSubmissionFragment
				}
			}
		}
	}
`;

interface OwnProps {
	contentSubmissionFragmentRef: SubmitDisplayElementButton_ContentSubmissionFragment$key;
}

export const SubmitDisplayElementButton = ({ contentSubmissionFragmentRef }: OwnProps) => {
	const contentSubmission = useFragment<SubmitDisplayElementButton_ContentSubmissionFragment$key>(
		CONTENT_SUBMISSION_FRAGMENT,
		contentSubmissionFragmentRef,
	);

	const [submitDisplayElement, isSubmittingDisplayElement] =
		useMutation<SubmitDisplayElementButton_SubmitDisplayElementMutation>(
			SUBMIT_DISPLAY_ELEMENT_MUTATION,
		);

	if (!contentSubmission.definition.currentElementState) return null;

	return (
		<NodeSubmitButton
			label={"Weiter"}
			disabled={isSubmittingDisplayElement}
			onClick={() => {
				submitDisplayElement({
					variables: {
						input: {
							displayElementId: contentSubmission.definition.currentElementState!.element.id,
							contentSubmissionId: contentSubmission.id,
						},
					},
				});
			}}
		/>
	);
};
