import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import styled from "styled-components";
import { NodeCertificateDisplay_NodeRootStructureDefinitionFragment$key } from "@generated/NodeCertificateDisplay_NodeRootStructureDefinitionFragment.graphql";

const LEARN_OPPORTUNITY_ROOT_STRUCTURE_DEFINITION_FRAGMENT = graphql`
	fragment NodeCertificateDisplay_NodeRootStructureDefinitionFragment on LearnOpportunityRootStructureDefinition {
		extension {
			... on LearnOpportunityRootExtensionImpl {
				reducedDataInTree {
					certificateTemplateNames
				}
			}
		}
	}
`;

interface OwnProps {
	learnOpportunityRootStructureDefinitionFragmentRef: NodeCertificateDisplay_NodeRootStructureDefinitionFragment$key;
	customedCertificateNames?: any;
}

const CertificateIcon = styled.span`
	width: 20px;
	height: 20px;
`;

export function NodeCertificateDisplay({
	learnOpportunityRootStructureDefinitionFragmentRef,
	customedCertificateNames,
}: OwnProps) {
	const learnOpportunityRootStructureDefinition =
		useFragment<NodeCertificateDisplay_NodeRootStructureDefinitionFragment$key>(
			LEARN_OPPORTUNITY_ROOT_STRUCTURE_DEFINITION_FRAGMENT,
			learnOpportunityRootStructureDefinitionFragmentRef,
		);

	return (
		<div className="flex align-items-center">
			{customedCertificateNames
				? customedCertificateNames.map((cn: string) => (
						<div key={cn}>
							<CertificateIcon className="icon-certificate mr-2 text-primary" />
							<span>{cn}</span>
						</div>
				  ))
				: learnOpportunityRootStructureDefinition.extension.reducedDataInTree?.certificateTemplateNames.map(
						(c) => (
							<div key={c}>
								<CertificateIcon className="icon-certificate mr-2 text-primary" />
								<span>{c}</span>
							</div>
						),
				  )}
		</div>
	);
}
