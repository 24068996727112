import { MenuItem } from "primereact/menuitem";
import React from "react";
import { RouterMenuItem } from "../infecto-lms-webapp/components/navigation/RouterMenuItem";
import { LinkMenuItem } from "../infecto-lms-webapp/components/navigation/LinkMenuItem.component";
import { DASHBOARD_PATH } from "./router/routes/auth/dashboard.routes";
import { SEARCH_SCREEN_PATH } from "./router/routes/auth/search.routes";
import { TREE_STATES_PATH } from "./router/routes/auth/learn-state.routes";
import { MenuType } from "../infecto-lms-webapp/components/navigation/navigation.interface";
import { Award, Book } from "react-feather";
import { CERTIFICATE_SCREEN_PATH } from "./router/routes/auth/certificates.routes";
import { COMMUNICATION_SETTINGS_SCREEN_PATH } from "./router/routes/auth/communication-settings.routes";

export const MAIN_MENU_ITEMS: (menuType: MenuType, collapsed: boolean) => MenuItem[] = (
	menuType: MenuType,
	collapsed: boolean,
) => [
	{
		label: "Alle Inhalte",
		url: DASHBOARD_PATH,
		icon: "pi pi-home",
		template: (item) => (
			<RouterMenuItem
				menuType={menuType}
				icon={<i className={"text-lg " + item.icon} />}
				onClick={() => {}}
				collapsed={collapsed}
				item={item}
			/>
		),
	},
	{
		label: "Meine Aktivitäten",
		url: TREE_STATES_PATH,
		icon: "book",
		template: (item) => (
			<RouterMenuItem
				menuType={menuType}
				icon={<Book size={19} />}
				onClick={() => {}}
				collapsed={collapsed}
				item={item}
			/>
		),
	},
	{
		label: "Meine Zertifikate",
		url: CERTIFICATE_SCREEN_PATH,
		icon: "award",
		template: (item) => (
			<RouterMenuItem
				icon={
					<Award style={item.icon == "award" ? { marginLeft: -1 } : { marginLeft: 0 }} size={19} />
				}
				menuType={menuType}
				onClick={() => {}}
				item={item}
				collapsed={collapsed}
			/>
		),
	},
	{
		label: "Suche",
		url: SEARCH_SCREEN_PATH,
		icon: "pi pi-search",
		template: (item) => (
			<RouterMenuItem
				menuType={menuType}
				icon={<i className={"text-lg " + item.icon} />}
				onClick={() => {}}
				collapsed={collapsed}
				item={item}
			/>
		),
	},
	{
		label: "Servicecenter",
		url: "https://www.paedia.de/fachkreise/servicecenter-apotheke/",
		icon: "icon-book",
		template: (item) => (
			<LinkMenuItem
				icon={<i className={"text-lg " + item.icon} />}
				menuType={menuType}
				collapsed={collapsed}
				item={item}
			/>
		),
	},
	/*	{
		label: "Nachrichten",
		url: "/messaging",
		icon: "pi pi-envelope",
		template: (item) => (
			<RouterMenuItem
				icon={<i className={"text-lg " + item.icon} />}
				onClick={() => {}}
				collapsed={collapsed}
				item={item}
				menuType={menuType}
			/>
		),
	},*/
	{
		label: "Einstellungen",
		url: COMMUNICATION_SETTINGS_SCREEN_PATH,
		icon: "pi pi-sliders-h",
		template: (item) => (
			<RouterMenuItem
				icon={<i className={"text-lg " + item.icon} />}
				onClick={() => {}}
				collapsed={collapsed}
				item={item}
				menuType={menuType}
			/>
		),
	},
];
