/**
 * @generated SignedSource<<8f235dd4d4c3a27053628b7dbd01ebf3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SearchFilterButton_QueryFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ProjectSearchFilters_QueryFragment">;
  readonly " $fragmentType": "SearchFilterButton_QueryFragment";
};
export type SearchFilterButton_QueryFragment$key = {
  readonly " $data"?: SearchFilterButton_QueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SearchFilterButton_QueryFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SearchFilterButton_QueryFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectSearchFilters_QueryFragment"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "48fc31bc38aa639bd13af9dca1964774";

export default node;
