import React from "react";
import { CSSProperties } from "react";
import LogoPaediaInfectopharm from "../assets/LogosPaediaInfectopharm.png";

interface OwnProps {
	width?: number;
	className?: string;
	styles?: CSSProperties;
}
export const ProjectLogoComponent = ({ width, styles, className }: OwnProps) => {
	return (
		<img
			alt="Paedia"
			src={LogoPaediaInfectopharm}
			width={width}
			style={styles}
			className={className}
		/>
	);
};
