/**
 * @generated SignedSource<<f4f32730f0149224ffa789a223927f8d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ContentNodeAdvancementStatus = "BlockedByTree" | "CanBeRestartedAfterFailed" | "CanBeRestartedAfterPassed" | "CanBeStarted" | "CanNotBeRestartedAfterFailed" | "CanNotBeRestartedAfterPassed" | "CanNotBeStarted" | "Continue" | "NotContentNode" | "ViolatesTreeFlow" | "%future added value";
export type ElementTypeV2 = "clozeText" | "file" | "multipleChoice" | "order" | "podcast" | "randomMultipleChoice" | "text" | "video" | "%future added value";
export type StartTreeConfigType = "StartTree_CMECertifiedNeedsSpecificGlobalUserStatus" | "StartTree_CanNot" | "StartTree_SpecificUserRolesCan" | "StartTree_SpecificUserStatusesCan" | "%future added value";
export type TypeDefinitionType = "branch" | "content" | "%future added value";
export type ViewerTreeStateKind = "CanBeStarted" | "CanNotBeStarted" | "IsFinished" | "IsStarted" | "NotVisible" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type NavigateDirectToQuestionsAdvanceTreeButton_LearnOpportunityV2Fragment$data = {
  readonly id: string;
  readonly root: {
    readonly id: string;
    readonly structureDefinition: {
      readonly extension?: {
        readonly wordpressCertificates?: ReadonlyArray<{
          readonly id: string;
        } | null>;
      };
      readonly title: string;
      readonly viewerTreeState?: {
        readonly headContentId?: string;
        readonly kind: ViewerTreeStateKind;
        readonly reasonConfigTypes?: ReadonlyArray<StartTreeConfigType>;
      };
    };
  } | null;
  readonly shortDescription: string | null;
  readonly structureDefinition: {
    readonly title: string;
  };
  readonly typeDefinition: {
    readonly containedElementTypes?: ReadonlyArray<ElementTypeV2>;
    readonly contentNodeAdvancementResult?: {
      readonly status: ContentNodeAdvancementStatus;
    };
    readonly definitionType: TypeDefinitionType;
    readonly extension?: {
      readonly headFirstElementType?: ElementTypeV2 | null;
      readonly pacPoints?: number | null;
      readonly pharmaPoints?: number | null;
    };
  };
  readonly " $fragmentType": "NavigateDirectToQuestionsAdvanceTreeButton_LearnOpportunityV2Fragment";
};
export type NavigateDirectToQuestionsAdvanceTreeButton_LearnOpportunityV2Fragment$key = {
  readonly " $data"?: NavigateDirectToQuestionsAdvanceTreeButton_LearnOpportunityV2Fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NavigateDirectToQuestionsAdvanceTreeButton_LearnOpportunityV2Fragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NavigateDirectToQuestionsAdvanceTreeButton_LearnOpportunityV2Fragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shortDescription",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LearnOpportunityV2",
      "kind": "LinkedField",
      "name": "root",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "structureDefinition",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "extension",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "WordpressCertificateAndLearnState",
                          "kind": "LinkedField",
                          "name": "wordpressCertificates",
                          "plural": true,
                          "selections": [
                            (v0/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "type": "LearnOpportunityRootExtensionImpl",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "viewerTreeState",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "kind",
                      "storageKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "reasonConfigTypes",
                          "storageKey": null
                        }
                      ],
                      "type": "CanNotBeStartedViewerTreeState",
                      "abstractKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "headContentId",
                          "storageKey": null
                        }
                      ],
                      "type": "IsStartedViewerTreeState",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "LearnOpportunityRootStructureDefinition",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "structureDefinition",
      "plural": false,
      "selections": [
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "typeDefinition",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "definitionType",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "containedElementTypes",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "contentNodeAdvancementResult",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "status",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "extension",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "pacPoints",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "pharmaPoints",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "headFirstElementType",
                      "storageKey": null
                    }
                  ],
                  "type": "LearnContentExtensionImpl",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "LearnOpportunityELearningContentTypeDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "LearnOpportunityV2",
  "abstractKey": null
};
})();

(node as any).hash = "431ce752afcaf42e23719a3edac69856";

export default node;
