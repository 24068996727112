import { createContext } from "react";

type DynamicSlideMenuContext = {
	path: string[];
	depth: number;
	navigate: (path?: string, replace?: boolean) => void;
	goBack: () => void;
};

export const DynamicSlideMenuContext = createContext<DynamicSlideMenuContext>({
	path: [],
	depth: 1,
	navigate: () => {},
	goBack: () => {},
});
