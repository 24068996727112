/**
 * @generated SignedSource<<4a8758b218c465c7bb37d1d19344189d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type MessageDefinitionButtonType = "confirm" | "link" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type MessageDefinitionButton_MessageDefinitionButtonFragment$data = {
  readonly type: MessageDefinitionButtonType;
  readonly " $fragmentSpreads": FragmentRefs<"ConfirmButton_MessageDefinitionConfirmButtonFragment" | "LinkButton_MessageDefinitionLinkButtonFragment">;
  readonly " $fragmentType": "MessageDefinitionButton_MessageDefinitionButtonFragment";
};
export type MessageDefinitionButton_MessageDefinitionButtonFragment$key = {
  readonly " $data"?: MessageDefinitionButton_MessageDefinitionButtonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"MessageDefinitionButton_MessageDefinitionButtonFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MessageDefinitionButton_MessageDefinitionButtonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "LinkButton_MessageDefinitionLinkButtonFragment"
        }
      ],
      "type": "MessageDefinitionLinkButton",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ConfirmButton_MessageDefinitionConfirmButtonFragment"
        }
      ],
      "type": "MessageDefinitionConfirmButton",
      "abstractKey": null
    }
  ],
  "type": "MessageDefinitionButtonInterface",
  "abstractKey": "__isMessageDefinitionButtonInterface"
};

(node as any).hash = "35a0f2805f4b030da85649e8d2c1e717";

export default node;
