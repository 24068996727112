/**
 * @generated SignedSource<<cc0f7b9d8d288e65c5db5e1145976a84>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PodcastSourceTypeV2 = "none" | "podigee" | "vimeo" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PodcastElementDisplayWithAnswers_PodcastElementV2Fragment$data = {
  readonly podcastSource: {
    readonly podigeeUrl?: string;
    readonly sourceType: PodcastSourceTypeV2;
  };
  readonly releasedFileOpt: {
    readonly file: {
      readonly url: string | null;
    } | null;
  } | null;
  readonly releasedSubtitlesOpt: {
    readonly subtitles: {
      readonly subtitles: ReadonlyArray<{
        readonly endTime: any;
        readonly startTime: any;
        readonly text: string;
      }>;
    } | null;
  } | null;
  readonly title: string;
  readonly " $fragmentType": "PodcastElementDisplayWithAnswers_PodcastElementV2Fragment";
};
export type PodcastElementDisplayWithAnswers_PodcastElementV2Fragment$key = {
  readonly " $data"?: PodcastElementDisplayWithAnswers_PodcastElementV2Fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PodcastElementDisplayWithAnswers_PodcastElementV2Fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PodcastElementDisplayWithAnswers_PodcastElementV2Fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "podcastSource",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sourceType",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "podigeeUrl",
              "storageKey": null
            }
          ],
          "type": "PodigeePodcastSourceV2",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ReleasedFileV2",
      "kind": "LinkedField",
      "name": "releasedFileOpt",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "File",
          "kind": "LinkedField",
          "name": "file",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "url",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ReleasedSubtitlesV2",
      "kind": "LinkedField",
      "name": "releasedSubtitlesOpt",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SubtitlesV2",
          "kind": "LinkedField",
          "name": "subtitles",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SubtitleV2",
              "kind": "LinkedField",
              "name": "subtitles",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "startTime",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endTime",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "text",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    }
  ],
  "type": "PodcastElementV2",
  "abstractKey": null
};

(node as any).hash = "3b36f8475953ab17834c58f0f839349c";

export default node;
