import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { NodeDescription_NodeFragment$key } from "@generated/NodeDescription_NodeFragment.graphql";

const LEARN_OPPORTUNITY_FRAGMENT = graphql`
	fragment NodeDescription_NodeFragment on LearnOpportunityV2 {
		description
	}
`;

interface OwnProps {
	learnOpportunityFragmentRef: NodeDescription_NodeFragment$key;
}

export function NodeDescription({ learnOpportunityFragmentRef }: OwnProps) {
	const learnOpportunity = useFragment<NodeDescription_NodeFragment$key>(
		LEARN_OPPORTUNITY_FRAGMENT,
		learnOpportunityFragmentRef,
	);

	if (!learnOpportunity.description) {
		return null;
	}

	return (
		<div>
			<div
				className="text-base"
				dangerouslySetInnerHTML={{ __html: learnOpportunity.description }}
			/>
		</div>
	);
}
