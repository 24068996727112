import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { AttachmentButtonsDisplayFragment_LearnOpportunityV2Fragment$key } from "@generated/AttachmentButtonsDisplayFragment_LearnOpportunityV2Fragment.graphql";
import styled from "styled-components";
import { PrimaryButton } from "../buttons/PrimaryButton";
import { Label } from "../buttons/Label";

const ATTACHMENTS_FRAGMENT = graphql`
	fragment AttachmentButtonsDisplayFragment_LearnOpportunityV2Fragment on LearnOpportunityV2 {
		root {
			id
			attachmentsV2 {
				title
				description
				... on InfectopharmFileAttachment {
					file {
						id
						url
						name
					}
				}
			}
		}
	}
`;
interface OwnProps {
	learnOpportunityFragmentRef: AttachmentButtonsDisplayFragment_LearnOpportunityV2Fragment$key;
}
export const AttachmentButtonsDisplay = ({ learnOpportunityFragmentRef }: OwnProps) => {
	const learnOpportunity =
		useFragment<AttachmentButtonsDisplayFragment_LearnOpportunityV2Fragment$key>(
			ATTACHMENTS_FRAGMENT,
			learnOpportunityFragmentRef,
		);

	return (
		<AttachmentsContainer>
			{learnOpportunity.root?.attachmentsV2.map((attachment) => {
				return (
					<a
						href={attachment.file?.url!}
						rel="noopener noreferrer"
						className="p-button-text text-primary p-0 text-sm md:text-base w-full"
						download={attachment.file?.name!}
						target="_blank"
						key={attachment?.file?.name}
					>
						<AttachmentButton className="mb-2">
							<PrimaryButton className="p-2 flex flex-column w-full text-sm">
								<Label>
									{attachment.title ? (
										<span dangerouslySetInnerHTML={{ __html: attachment.title }} />
									) : (
										<span dangerouslySetInnerHTML={{ __html: attachment.description! }} />
									)}
								</Label>
							</PrimaryButton>
						</AttachmentButton>
					</a>
				);
			})}
		</AttachmentsContainer>
	);
};

const AttachmentButton = styled.div`
	text-align: center;
	cursor: pointer;
	max-width: 400px;
`;
const AttachmentsContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`;
