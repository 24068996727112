import React from "react";
import { PrivateRouteDefinition } from "../../../../infecto-lms-webapp/router/interfaces";
import { CertificatesScreen } from "../../../../app/screens/Certificate.screen";

export const CERTIFICATE_SCREEN_PATH = "/meine-zertifikate";

export const CertificatesRoutes: PrivateRouteDefinition[] = [
	{
		path: CERTIFICATE_SCREEN_PATH,
		component: <CertificatesScreen />,
	},
];
