import { useCallback } from "react";
import { TkaCopySpan } from "../../utils";
import { white } from "../../../infecto-lms-webapp-impl/color";
import { RadioButton } from "../radio-button";
import { RadioButtonChangeEvent, RadioOptionProps } from "./radio-option.interface";
import React from "react";
import styled from "styled-components";

export const RadioOption = <T,>({ checked, label, value, onChange }: RadioOptionProps<T>) => {
	const handleOnChange = useCallback(
		(event: RadioButtonChangeEvent<T>) => {
			onChange && onChange(event);
		},
		[onChange],
	);
	return (
		<Wrapper>
			<RadioButton
				inputId={label ?? ""}
				name={label ?? ""}
				value={value ?? ""}
				onChange={handleOnChange}
				checked={checked ?? false}
				status={"default"}
			/>
			{label && (
				<TkaCopySpan className="ml-2" paletteColor={white}>
					{label}
				</TkaCopySpan>
			)}
		</Wrapper>
	);
};

export const Wrapper = styled.div`
	display: flex;
	align-items: center;
`;
