import React, { useEffect } from "react";
import { useTypedDispatch, useTypedSelector } from "../../redux/redux-store";
import { JwtTokenData, selectAuthSlice, setLoggedIn } from "../../redux/slices/auth.slice";
import { LMS_WEBAPP_CONFIG_IMPL } from "../../../infecto-lms-webapp-impl/config";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ERROR_MESSAGES } from "../../ERROR_MESSAGES";
import { Loader } from "../loading.component";

export function LoginPromptComponent() {
	const dispatch = useTypedDispatch();
	const authSlice = useTypedSelector(selectAuthSlice);

	const [searchParams] = useSearchParams();
	const refreshToken = searchParams.get("refreshToken");
	const accountId = searchParams.get("accountId");
	useEffect(() => {
		if (refreshToken && accountId) {
			fetch(process.env.REACT_APP_API_BASE + "/api/refresh-token", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					refreshToken: refreshToken,
					accountId: accountId,
					base64BrandId: LMS_WEBAPP_CONFIG_IMPL.brandId,
				}),
			}).then((result) => {
				if (result.status === 200) {
					result.text().then((text) => {
						const tokenData: JwtTokenData = JSON.parse(text);

						dispatch(
							setLoggedIn({
								tokenData: tokenData,
							}),
						);

						const redirect = searchParams.get("redirect_url");

						window.location.replace(redirect ? atob(redirect) : window.location.href);
					});
				} else {
					toast.error(ERROR_MESSAGES["auth_login_with_email_and_password_unspecified_auth"]);
				}
			});
		} else if (authSlice.shouldLogout) {
			window.location.replace(
				`${process.env.REACT_APP_USER_SYSTEM_BASE}/logout/${LMS_WEBAPP_CONFIG_IMPL.brandId}`,
			);
		} else {
			const currentUrl = window.location.href;
			const base64Url = btoa(currentUrl);
			window.location.replace(
				`${process.env.REACT_APP_USER_SYSTEM_BASE}/login/${LMS_WEBAPP_CONFIG_IMPL.brandId}/${base64Url}`,
			);
		}
		// eslint-disable-next-line
  }, []);

	return <Loader />;
}
