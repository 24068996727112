import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useMobileOrientation } from "react-device-detect";
import { SecondaryButton } from "../../../infecto-lms-webapp/components/buttons/SecondaryButton";

const Header = styled.div`
	z-index: 1000;
	background-color: #fff;
`;

export function PaediaNodeTopBar() {
	const navigate = useNavigate();
	const orientation = useMobileOrientation();
	return (
		<div>
			{(orientation.isPortrait || window.innerWidth > 1180) && (
				<Header className={`border-bottom-1 border-300 p-2 md:p-3 flex`}>
					<div className="flex align-items-center">
						<SecondaryButton
							onClick={() => {
								navigate("/", { replace: true });
							}}
							className="border-300 bg-white p-2"
							icon="pi pi-times"
						/>
					</div>
				</Header>
			)}
		</div>
	);
}
