export const EXTEND_ERROR_MESSAGE_EFN_OAK: { [key: string]: (value: string) => string } = {
	auth_efn_number_has_wrong_format: (value) =>
		`Bitte prüfen Sie die Eingabe Ihrer elektronischen ${value}. Laut unserem Prüfalgorithmus liegt ein Tippfehler vor.`,
	auth_efn_number_already_in_use: (value) =>
		`Die von Ihnen angegebene elektronische ${value} liegt uns bereits in einem anderen Account vor. Ggf. liegt hier eine Dublette vor. Nehmen Sie bitte mit uns Kontakt (kontakt@infectopharm.com) auf, damit wir das aufgetretene Problem beheben können.`,
	auth_user_is_locked_exception: (value) =>
		`Für Ihren Account liegt eine Sperrung vor, so dass die Eingabe Ihrer elektronischen ${value} aktuell nicht möglich ist. Bei Rückfragen wenden Sie sich gerne über kontakt@infectopharm.com an uns.`,
	auth_efn_cannot_be_supplied_for_this_role: (value) =>
		`Laut den in Ihrem Account hinterlegten Daten, dürfte Ihnen keine elektronische ${value} vorliegen. Eine Eingabe der ${value} ist daher aktuell nicht möglich. Nehmen Sie hierzu bitte über kontakt@infectopharm.com mit uns Kontakt auf.`,
};

export function getEfnOAKErrorMessage(errorKey: string, value: string): string {
	const error = EXTEND_ERROR_MESSAGE_EFN_OAK[errorKey];
	const dynamicMessage = error(value).toString();

	if (error.length > 0) {
		return dynamicMessage;
	} else {
		return "Efn/ÖÄK Messages Error";
	}
}
