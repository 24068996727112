import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { getCookie, setCookie } from "../functions/cookie-utils";
import { DATA_PRIVACY_SCREEN_PATH } from "../../infecto-lms-webapp-impl/router/routes/auth/legal.routes";
import {
	ButtonDialogProps,
	CustomButtonDialog,
} from "./content-element/multiplechoice/ShowUserAnswersSummaryDialog.component";
import { LMS_WEBAPP_CONFIG_IMPL } from "../../infecto-lms-webapp-impl/config";
import styled from "styled-components";
import { SecondaryButton } from "./buttons/SecondaryButton";

export function TrackingOptIn() {
	const hasOptedIn = getCookie("inf-optin") !== undefined;
	const hasOptedOut = getCookie("inf-optout") !== undefined;

	const [isVisible, setVisible] = useState<boolean>(false);

	useEffect(() => {
		setTimeout(() => {
			setVisible(!hasOptedIn && !hasOptedOut);
		}, 1500);

		if (hasOptedIn) {
			const script = document.createElement("script");

			script.src = "/gtm.js";
			script.async = true;

			document.body.appendChild(script);

			return () => {
				document.body.removeChild(script);
			};
		}
	}, [hasOptedIn]);

	return (
		<Dialog
			className="w-12 md:w-9"
			visible={isVisible}
			closeOnEscape={false}
			closable={false}
			onHide={() => {
				setVisible(false);
			}}
		>
			<div className="text-center">
				<h1>Datenschutzeinstellungen</h1>
				<p>
					Wir nutzen Cookies und andere Technologien auf dieser Website. Einige von ihnen sind
					essenziell, während andere uns helfen, diese Website in Ihrem Sinne zu verbessern.
				</p>
				<p>
					{" "}
					Wenn Sie unter 16 Jahre alt sind und Ihre Zustimmung zu freiwilligen Diensten geben
					möchten, müssen Sie Ihre Erziehungsberechtigten um Erlaubnis bitten.
				</p>
				<p>
					{" "}
					Personenbezogene Daten können verarbeitet werden (z. B. IP-Adressen), z. B. für
					personalisierte Anzeigen und Inhalte oder Anzeigen- und Inhaltsmessung. Weitere
					Informationen über die Verwendung Ihrer Daten finden Sie in unserer{" "}
					<a target="_blank" rel="noreferrer" href={DATA_PRIVACY_SCREEN_PATH}>
						<TextButton
							color={LMS_WEBAPP_CONFIG_IMPL.brandColor}
							label="Datenschutzerklärung."
							className="p-0 border-none bg-transparent"
						/>
					</a>
				</p>
				<div className="flex justify-content-center flex-column">
					<CustomButtonDialog
						color={LMS_WEBAPP_CONFIG_IMPL.brandColor}
						className="mb-3"
						label="Alle akzeptieren"
						onClick={() => {
							setCookie("inf-optin", "true", 90);
							setVisible(false);
							window.location.reload();
						}}
					/>
					<SecondaryButton
						className="p-button-secondary p-button-outlined"
						label="Ablehnen"
						onClick={() => {
							setCookie("inf-optout", "true", 90);
							setVisible(false);
							window.location.reload();
						}}
					/>
				</div>
			</div>
		</Dialog>
	);
}

const TextButton = styled(Button)<ButtonDialogProps>`
	color: ${(props) => props.color} !important;
	.p-button .p-button-text {
		color: ${(props) => props.color} !important;
	}
`;
