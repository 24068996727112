import styled, { css } from "styled-components";
import { PaletteColor } from "../../../infecto-lms-webapp-impl/color";

export const IconWrapper = styled.div<{ backgroundColor?: PaletteColor; fillAlsoColor?: string }>`
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: min-content;
	${({ backgroundColor }) => backgroundColor && CircleCss};
	${({ fillAlsoColor }) => fillAlsoColor && PathFillAlsoCss}
`;

export const CircleCss = css<{ backgroundColor?: PaletteColor }>`
	background-color: ${({ backgroundColor }) =>
		backgroundColor ? backgroundColor.rgbValue() : "initial"};
	padding: 0.75rem;
	border-radius: 100%;
`;

export const PathFillAlsoCss = css<{ fillAlsoColor?: string }>`
	path.fill-also {
		fill: ${({ fillAlsoColor }) => fillAlsoColor};
	}
`;
