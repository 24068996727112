import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { FileElementDisplay_FileElementV2Fragment$key } from "@generated/FileElementDisplay_FileElementV2Fragment.graphql";
import { PdfViewer } from "../../PdfViewer.component";
import { useMobileOrientation } from "react-device-detect";

const FILE_ELEMENT_FRAGMENT = graphql`
	fragment FileElementDisplay_FileElementV2Fragment on FileLearnElement {
		file {
			url
		}
		fileContentType
		title
	}
`;

interface OwnProps {
	fileElementFragmentRef: FileElementDisplay_FileElementV2Fragment$key;
}

export function FileElementDisplay({ fileElementFragmentRef }: OwnProps) {
	const fileElement = useFragment<FileElementDisplay_FileElementV2Fragment$key>(
		FILE_ELEMENT_FRAGMENT,
		fileElementFragmentRef,
	);

	if (!fileElement) return null;

	const orientation = useMobileOrientation();

	return (
		<div className="p-2 md:pt-2 md:pl-4 md:pr-4 md:pb-4 lg:p-5 ">
			{(orientation.isPortrait || window.innerWidth > 1180) && (
				<p
					className="m-2 font-bold lg:text-2xl"
					dangerouslySetInnerHTML={{ __html: fileElement.title }}
				/>
			)}
			{fileElement.fileContentType === "pdf" && fileElement.file?.url && (
				<div className="flex-1 flex justify-content-center">
					<PdfViewer url={fileElement.file.url} />
				</div>
			)}

			{["png", "jpg"].includes(fileElement.fileContentType || "") && fileElement.file?.url && (
				<div>
					<img
						style={{ maxWidth: "100%", maxHeight: 500 }}
						src={fileElement.file?.url}
						alt={fileElement.title}
						width="auto"
					/>
				</div>
			)}

			{!fileElement.file?.url && <div>DIESE DATEI KONNTE NICHT ANGEZEIGT WERDEN</div>}
		</div>
	);
}
