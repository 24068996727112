/**
 * @generated SignedSource<<cdbfec7fdcf925b7b29747adfaa04039>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ContentNodeAdvancementStatus = "BlockedByTree" | "CanBeRestartedAfterFailed" | "CanBeRestartedAfterPassed" | "CanBeStarted" | "CanNotBeRestartedAfterFailed" | "CanNotBeRestartedAfterPassed" | "CanNotBeStarted" | "Continue" | "NotContentNode" | "ViolatesTreeFlow" | "%future added value";
export type ElementTypeV2 = "clozeText" | "file" | "multipleChoice" | "order" | "podcast" | "randomMultipleChoice" | "text" | "video" | "%future added value";
export type RestartIfFailedContentConfigType = "RestartIfFailedContent_BlockPermanently" | "RestartIfFailedContent_BlockPermanently_WhenRewarded" | "RestartIfFailedContent_BlockTemporarily" | "RestartIfFailedContent_BlockTemporarily_WhenRewarded" | "RestartIfFailedContent_CanNot" | "%future added value";
export type StartContentConfigType = "StartContent_CanIfInTreeFlow" | "StartContent_CanNot" | "StartContent_SpecificUserStatusesCan" | "%future added value";
export type TypeDefinitionType = "branch" | "content" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type NodeContentElementsList_NodeFragment$data = {
  readonly id: string;
  readonly shortDescription: string | null;
  readonly structureDefinition: {
    readonly title: string;
  };
  readonly typeDefinition: {
    readonly children?: ReadonlyArray<{
      readonly id: string;
      readonly shortDescription: string | null;
      readonly structureDefinition: {
        readonly title: string;
      };
      readonly typeDefinition: {
        readonly containedElementTypes?: ReadonlyArray<ElementTypeV2>;
        readonly contentNodeAdvancementResult?: {
          readonly activeContentSubmissionId?: string;
          readonly configResults?: ReadonlyArray<{
            readonly blockedUntil?: string;
            readonly canBeRestarted: boolean;
            readonly configType: RestartIfFailedContentConfigType;
          }>;
          readonly reasonConfigTypes?: ReadonlyArray<StartContentConfigType>;
          readonly status: ContentNodeAdvancementStatus;
        };
        readonly definitionType: TypeDefinitionType;
      };
      readonly " $fragmentSpreads": FragmentRefs<"ProjectContentButtonCME_LearnOpportunityFragment">;
    }>;
    readonly containedElementTypes?: ReadonlyArray<ElementTypeV2>;
    readonly definitionType: TypeDefinitionType;
  };
  readonly " $fragmentSpreads": FragmentRefs<"ProjectContentButtonCME_LearnOpportunityFragment">;
  readonly " $fragmentType": "NodeContentElementsList_NodeFragment";
};
export type NodeContentElementsList_NodeFragment$key = {
  readonly " $data"?: NodeContentElementsList_NodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NodeContentElementsList_NodeFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shortDescription",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "structureDefinition",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "definitionType",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "containedElementTypes",
  "storageKey": null
},
v5 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "ProjectContentButtonCME_LearnOpportunityFragment"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NodeContentElementsList_NodeFragment",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "typeDefinition",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            (v4/*: any*/)
          ],
          "type": "LearnOpportunityELearningContentTypeDefinition",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "LearnOpportunityV2",
              "kind": "LinkedField",
              "name": "children",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "typeDefinition",
                  "plural": false,
                  "selections": [
                    (v3/*: any*/),
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v4/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": null,
                          "kind": "LinkedField",
                          "name": "contentNodeAdvancementResult",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "status",
                              "storageKey": null
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "reasonConfigTypes",
                                  "storageKey": null
                                }
                              ],
                              "type": "CanNotBeStartedContentNodeAdvancementResult",
                              "abstractKey": null
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "activeContentSubmissionId",
                                  "storageKey": null
                                }
                              ],
                              "type": "ContinueContentNodeAdvancementResult",
                              "abstractKey": null
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": null,
                                  "kind": "LinkedField",
                                  "name": "configResults",
                                  "plural": true,
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "configType",
                                      "storageKey": null
                                    },
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "canBeRestarted",
                                      "storageKey": null
                                    },
                                    {
                                      "kind": "InlineFragment",
                                      "selections": [
                                        {
                                          "alias": null,
                                          "args": null,
                                          "kind": "ScalarField",
                                          "name": "blockedUntil",
                                          "storageKey": null
                                        }
                                      ],
                                      "type": "NegativeBlockTemporarilyRestartIfFailedContentConfigResult",
                                      "abstractKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "type": "CanNotBeRestartedAfterFailedContentNodeAdvancementResult",
                              "abstractKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "type": "LearnOpportunityELearningContentTypeDefinition",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                },
                (v5/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "type": "LearnOpportunityBranchTypeDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    (v5/*: any*/)
  ],
  "type": "LearnOpportunityV2",
  "abstractKey": null
};
})();

(node as any).hash = "cfd5cb5c0d55329b238fcdd8f4d86528";

export default node;
