import React from "react";
import { DefaultContainer } from "../../infecto-lms-webapp/containers/Default.container";
import { usePageTitle } from "../../infecto-lms-webapp/hooks/usePageTitle";
import { MainContainer } from "../containers/MainContainer";
import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay";
import { DataPrivacyScreen_Query } from "@generated/DataPrivacyScreen_Query.graphql";
import { LMS_WEBAPP_CONFIG_IMPL } from "../../infecto-lms-webapp-impl/config";

const QUERY = graphql`
	query DataPrivacyScreen_Query($brandId: ID!) {
		Viewer {
			Auth {
				currentUser {
					user {
						...DefaultContainer_UserDataFragment
					}
				}
			}
			Privacy {
				LatestPrivacy(brandId: $brandId) {
					id
					content
				}
			}
		}
	}
`;

export function DataPrivacyScreen() {
	usePageTitle("Datenschutz");

	const query = useLazyLoadQuery<DataPrivacyScreen_Query>(QUERY, {
		brandId: LMS_WEBAPP_CONFIG_IMPL.brandId,
	});

	return (
		<DefaultContainer
			userDataFragmentRef={query.Viewer.Auth.currentUser?.user!}
			collapsed={false}
			screenName="DataPrivacyScreen"
		>
			<MainContainer className="bg-white p-5 overflow-scroll flex-shrink-1">
				<div
					dangerouslySetInnerHTML={{
						__html: query.Viewer.Privacy.LatestPrivacy?.content as string,
					}}
				></div>
			</MainContainer>
		</DefaultContainer>
	);
}
