import React, { useEffect } from "react";

import styled from "styled-components";

interface OwnProps {
	url?: string;
}

const PodigeeContainer = styled.div`
	max-width: 1024px;
	width: 100%;
`;

export function PodigeePlayer({ url }: OwnProps) {
	useEffect(() => {
		const script = document.createElement("script");

		script.src =
			"https://player.podigee-cdn.net/podcast-player/javascripts/podigee-podcast-player.js";
		script.async = true;

		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		};
	}, []);

	return (
		<PodigeeContainer>
			<div data-configuration={url + "&locale=de-DE"} className="podigee-podcast-player" />
		</PodigeeContainer>
	);
}
