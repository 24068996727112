/**
 * @generated SignedSource<<3ce5257f4e836649ea029a2dcde4da5d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ConfirmButton_MessageDefinitionConfirmButtonFragment$data = {
  readonly title: string;
  readonly " $fragmentType": "ConfirmButton_MessageDefinitionConfirmButtonFragment";
};
export type ConfirmButton_MessageDefinitionConfirmButtonFragment$key = {
  readonly " $data"?: ConfirmButton_MessageDefinitionConfirmButtonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ConfirmButton_MessageDefinitionConfirmButtonFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ConfirmButton_MessageDefinitionConfirmButtonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    }
  ],
  "type": "MessageDefinitionConfirmButton",
  "abstractKey": null
};

(node as any).hash = "01a8356df22af68ffdc6a427ea9f7119";

export default node;
