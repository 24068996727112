import React from "react";
import { MenuItem } from "primereact/menuitem";
import styled from "styled-components";
import { classNames } from "primereact/utils";
import { MenuType } from "./navigation.interface";

interface OwnProps {
	item: MenuItem;
	collapsed: boolean;
	menuType: MenuType;
	icon: any;
	customedLabel?: any;
}

const StyledItem = styled.a`
	:hover {
		cursor: pointer;
		background: var(--surface-100);
	}
`;

export function LinkMenuItem({ item, collapsed, menuType, icon, customedLabel }: OwnProps) {
	return (
		<StyledItem
			href={item.url!}
			target="_blank"
			rel="noreferrer"
			className={
				"p-3 border-round font-bold flex align-items-center  " +
				(menuType === "SideMenu" ? "mb-4" : "mb-1")
			}
		>
			{item.icon && (
				<span className={`flex align-items-center ${classNames({ "mr-3": !collapsed })}`}>
					{icon}
				</span>
			)}
			{!collapsed &&
				(customedLabel ? customedLabel : <span className="text-sm">{item?.label}</span>)}
		</StyledItem>
	);
}
