import React, { useContext } from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { Checkbox } from "primereact/checkbox";
import styled from "styled-components";
import {
	ElementTypeV2,
	SearchLearnOpportunitiesFormatTypeInput,
	SearchLearnOpportunitiesSearchOptionsInput,
} from "@generated/SearchResults_Query.graphql";
import { SearchContext } from "../../../infecto-lms-webapp/context/search-context/SearchContextProvider";
import { CERTIFIED_LABEL } from "../../../infecto-lms-webapp/components/search/i18n/isCertified-label";
import { ProjectExtendFormatFilters } from "../ProjectExtendFormatFilters";
import { ProjectSearchFilters_QueryFragment$key } from "@generated/ProjectSearchFilters_QueryFragment.graphql";
import { LMS_WEBAPP_CONFIG_IMPL } from "../../config";
import { TertiaryButton } from "../../../infecto-lms-webapp/components/buttons/TertiaryButton";
import { calculateButtonStateColor } from "src/infecto-lms-webapp/functions/helpers/calculateButtonStateColor";

const QUERY_FRAGMENT = graphql`
	fragment ProjectSearchFilters_QueryFragment on Query {
		Learn {
			GetAvailableTags {
				id
				name
			}
			Tags {
				edges {
					node {
						id
						name
						colorHex
					}
				}
			}
		}
		LearnV2 {
			GetAvailableFilters {
				elementTypes
				availableCertificates
				availableAttachmentTypes
			}
		}
	}
`;

const FilterBox = styled.div`
	max-height: calc(100vh - 75px);
	height: 100%;
	overflow: scroll;
	@media only screen and (max-width: 1024px) {
		max-height: calc(100vh - 90px);
	}
`;

const FixedBox = styled.div``;

interface OwnProps {
	queryFragmentRef: ProjectSearchFilters_QueryFragment$key;
}

export function ProjectSearchFilters({ queryFragmentRef }: OwnProps) {
	const query = useFragment<ProjectSearchFilters_QueryFragment$key>(
		QUERY_FRAGMENT,
		queryFragmentRef,
	);
	const { filters, setFilters, clearFilters } = useContext(SearchContext);

	const noTrainingTags = query.Learn.GetAvailableTags.filter(
		(tag) => !tag.name.includes("Produktschulungen"),
	);
	const trainingProductTags = query.Learn.GetAvailableTags.filter((tag) =>
		tag.name.includes("Produktschulungen"),
	);
	const trainingTagsIds = trainingProductTags.map((t) => t.id);

	return (
		<FilterBox className="bg-white flex-grow-1">
			<FixedBox>
				<div className="flex pt-5 pb-3 pr-5 pl-5 align-items-end">
					<h3 className="mb-0">Filter</h3>
					<TertiaryButton
						className="p-button-text ml-2 p-0"
						onClick={() => clearFilters()}
						label="zurücksetzen"
					/>
				</div>
				<hr className="mb-5" />
				<h4 className="opacity-30 pl-5 pr-5 mb-2">Fachbereich / Anbieter</h4>
				<div className="pl-5 pr-5">
					{noTrainingTags.map((tag) => (
						<div key={"tag-" + tag.id} className="flex pt-3 pb-3">
							<label className="flex align-items-center flex-grow-1">
								<span className="font-bold mr-2">{tag.name}</span>
								<StyledCheckbox
									colorPress={calculateButtonStateColor().pressColor}
									color={LMS_WEBAPP_CONFIG_IMPL.brandColor}
									className="ml-auto"
									checked={filters.tagIds?.includes(tag.id)}
									onChange={(e) => {
										setFilters({
											...filters,
											tagIds: [
												...(filters.tagIds?.filter((t) => t !== tag.id) || []),
												...(e.checked ? [tag.id] : []),
											],
										});
									}}
								/>
							</label>
						</div>
					))}
					{trainingProductTags.length > 0 && (
						<div key={"tag-product"} className="pt-3 pb-3">
							<label className="flex align-items-center flex-grow-1">
								<span className="font-bold mr-2">Produktschulungen </span>
								<StyledCheckbox
									colorPress={calculateButtonStateColor().pressColor}
									color={LMS_WEBAPP_CONFIG_IMPL.brandColor}
									className="ml-auto"
									checked={filters?.tagIds?.includes(trainingTagsIds[0])}
									onChange={(e) => {
										if (trainingTagsIds.length == 2) {
											setFilters({
												...filters,
												tagIds: [
													...(filters.tagIds?.filter(
														(t) =>
															t !== trainingTagsIds[0] &&
															trainingTagsIds[1] &&
															t !== trainingTagsIds[1],
													) || []),
													...(e.checked ? [trainingTagsIds[0], trainingTagsIds[1]] : []),
												],
											});
										} else {
											setFilters({
												...filters,
												tagIds: [
													...(filters.tagIds?.filter((t) => t !== trainingTagsIds[0]) || []),
													...(e.checked ? [trainingTagsIds[0]] : []),
												],
											});
										}
									}}
								/>
							</label>
						</div>
					)}
				</div>
				<hr />
				<h4 className="opacity-30 pl-5 pr-5 mb-2">Ist BAK-zertifiziert</h4>
				<div className="pl-5 pr-5">
					<div className="flex pt-3 pb-3">
						<label className="flex align-items-center flex-grow-1">
							<span className="font-bold mr-2">{CERTIFIED_LABEL["is_certified"]}</span>
							<StyledCheckbox
								colorPress={calculateButtonStateColor().pressColor}
								color={LMS_WEBAPP_CONFIG_IMPL.brandColor}
								className="ml-auto"
								checked={filters.isCertified}
								onChange={(e) =>
									setFilters({
										...filters,
										isCertified: e.checked ? e.checked : undefined,
									})
								}
							/>
						</label>
					</div>
				</div>
				<hr />
				<h4 className="opacity-30 pl-5 pr-5 mb-2">Formate</h4>
				<div className="pl-5 pr-5">
					<div className="flex pt-3 pb-3">
						<label className="flex align-items-center flex-grow-1">
							<span className="font-bold mr-2">
								<span className="icon-book mr-2" />
								Heft
							</span>
							<StyledCheckbox
								colorPress={calculateButtonStateColor().pressColor}
								color={LMS_WEBAPP_CONFIG_IMPL.brandColor}
								className="ml-auto"
								checked={filters.elementTypes?.includes("file")}
								onChange={(e) =>
									setFilters({
										...filters,
										elementTypes: [
											...(filters.elementTypes?.filter((t) => t !== "file") || []),
											...(e.checked ? ["file" as ElementTypeV2] : []),
										],
									})
								}
							/>
						</label>
					</div>
					<div className="flex pt-3 pb-3">
						<label className="flex align-items-center flex-grow-1">
							<span className="font-bold mr-2">
								<span className="icon-play mr-2" />
								Video
							</span>
							<StyledCheckbox
								colorPress={calculateButtonStateColor().pressColor}
								color={LMS_WEBAPP_CONFIG_IMPL.brandColor}
								className="ml-auto"
								checked={filters.formatTypes?.includes("Video")}
								onChange={(e) =>
									setFilters({
										...filters,
										formatTypes: [
											...(filters.formatTypes?.filter((t) => t !== "Video") || []),
											...(e.checked ? ["Video" as SearchLearnOpportunitiesFormatTypeInput] : []),
										],
									})
								}
							/>
						</label>
					</div>
					<ProjectExtendFormatFilters />
				</div>
				<hr />
				<h4 className="opacity-30 pl-5 pr-5 mb-2">Einstellungen</h4>
				<div className="pl-5 pr-5 pb-5">
					<div className="flex pt-3 pb-3">
						<label className="flex align-items-center flex-grow-1">
							<span className="font-bold mr-2">Gestartete ausblenden</span>
							<StyledCheckbox
								colorPress={calculateButtonStateColor().pressColor}
								color={LMS_WEBAPP_CONFIG_IMPL.brandColor}
								className="ml-auto"
								checked={filters.searchOptions?.includes("hideStarted")}
								onChange={(e) =>
									setFilters({
										...filters,
										searchOptions: [
											...(filters.searchOptions?.filter((t) => t !== "hideStarted") || []),
											...(e.checked ? ["hideStarted"] : []),
										] as SearchLearnOpportunitiesSearchOptionsInput[],
									})
								}
							/>
						</label>
					</div>
					{/*<div className="flex pt-3 pb-3">
						<label className="flex align-items-center flex-grow-1">
							<span className="font-bold mr-2">Abgelaufene ausblenden</span>
							<Checkbox
								className="ml-auto"
								checked={filters.searchOptions?.includes("hideTreeNotRewarded")}
								onChange={(e) =>
									setFilters({
										...filters,
										searchOptions: [
											...(filters.searchOptions?.filter((t) => t !== "hideTreeNotRewarded") || []),
											...(e.checked ? ["hideTreeNotRewarded"] : []),
										] as SearchLearnOpportunitiesSearchOptionsInput[],
									})
								}
							/>
						</label>
					</div>*/}
					<div className="flex pt-3 pb-3">
						<label className="flex align-items-center flex-grow-1">
							<span className="font-bold mr-2">Absolvierte ausblenden</span>
							<StyledCheckbox
								colorPress={calculateButtonStateColor().pressColor}
								color={LMS_WEBAPP_CONFIG_IMPL.brandColor}
								className="ml-auto"
								checked={filters.searchOptions?.includes("hideFinished")}
								onChange={(e) =>
									setFilters({
										...filters,
										searchOptions: [
											...(filters.searchOptions?.filter((t) => t !== "hideFinished") || []),
											...(e.checked ? ["hideFinished"] : []),
										] as SearchLearnOpportunitiesSearchOptionsInput[],
									})
								}
							/>
						</label>
					</div>
				</div>
			</FixedBox>
		</FilterBox>
	);
}
interface CheckboxColorProps {
	color: string;
	colorPress: string;
}
export const StyledCheckbox = styled(Checkbox)<CheckboxColorProps>`
	.p-highlight {
		border-color: ${(props) => props.color} !important;
		background-color: ${(props) => props.color} !important;
	}
	.p-focus {
		border-color: ${(props) => props.colorPress} !important;
	}
`;
