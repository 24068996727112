import React from "react";
import { Message } from "primereact/message";

interface OwnProps {
	user: any;
}

export const ProjectUserStatusMessageComponent = ({ user }: OwnProps) => {
	switch (user.globalUserStatus) {
		case "noProcess":
			return (
				<Message
					className="mt-4 mb-4 w-12 p-5"
					severity="warn"
					content={
						<div>
							<strong>ACHTUNG:</strong> Ihr Konto ist nicht finalisiert. Sie können keine
							Fortbildungen durchführen. Bitte finalisieren Sie Ihr Konto über{" "}
							<strong>
								<a className="text-primary" href="https://identity.infectopharm.com">
									diesen Link.
								</a>
							</strong>
						</div>
					}
				/>
			);

		case "doccheck":
			return (
				<Message
					className="mt-4 mb-4 w-12 p-5"
					severity="warn"
					content={
						<div>
							<strong>ACHTUNG:</strong> Ihr Nutzerstatus erlaubt keine Teilnahme an der
							Lernerfolgskontrolle. Bitte registrieren Sie sich unter{" "}
							<strong>
								<a className="text-primary" href="https://identity.infectopharm.com">
									identity.infectopharm.com
								</a>
							</strong>{" "}
							oder melden Sie sich mit Ihren InfectoPharm-Zugangsdaten an.
						</div>
					}
				/>
			);
		case "initialValidation":
			return (
				<Message
					className="mt-4 mb-4 w-12 p-5"
					severity="warn"
					content={
						<div>
							<strong>ACHTUNG:</strong> Ihr Konto wird derzeit geprüft. Dies kann bis zu 7 Tagen
							dauern. Währenddessen können Sie keine zertifizierten Fortbildungen durchführen.
							Nicht-zertifizierte Fortbildungen können Sie normal durchführen.
						</div>
					}
				/>
			);
		case "changeInProgress":
			return (
				<Message
					className="mt-4 mb-4 w-12 p-5"
					severity="warn"
					content={
						<div>
							<strong>ACHTUNG:</strong> Nach Änderung Ihrer Dienst-Adressdaten wird Ihre Adresse
							geprüft. Dies kann bis zu 7 Tagen dauern. Währenddessen können Sie keine
							zertifizierten Fortbildungen durchführen. Nicht-zertifizierte Fortbildungen können Sie
							normal durchführen.
						</div>
					}
				/>
			);
		default:
			return null;
	}
};
