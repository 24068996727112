import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import styled from "styled-components";
import React, { ReactNode } from "react";
import { NodeContainer_LearnOpportunityV2Fragment$key } from "@generated/NodeContainer_LearnOpportunityV2Fragment.graphql";
import { NodeTagList } from "../../infecto-lms-webapp/components/node/NodeTagList.component";
import { DurationDisplay } from "../../infecto-lms-webapp/functions/helpers/DurationDisplay";
import { MainContainerWithTopAndBottomBar } from "../../infecto-lms-webapp/containers/MainContainerWithTopAndBottomBar.container";
import { PaediaNodeTopBar } from "../../app/components/node/PaediaNodeTopBar";
import { selectLogoBasedOnTag } from "../../app/components/node/SelectLogoBasedOnTag";
import { NodeConfigurationsDisplay } from "../../infecto-lms-webapp/components/node/NodeConfigurationsDisplay.component";
import { NodeContentElementsList } from "../../infecto-lms-webapp/components/node/NodeContentElementsList.component";
import { singularPluralPointsDisplay } from "../../infecto-lms-webapp/functions/helpers/singularPluralPointsDisplay";
import { AttachmentHeftDisplay } from "../../infecto-lms-webapp/components/attachment/AttachmentHeftDisplay.component";
import { WrapperContainerWithScrollbar } from "../../infecto-lms-webapp/containers/WrapperContainerWithScrollbar.container";
import {
	footerPixelsLeftFrame,
	footerPixelsMainContainer,
} from "../../infecto-lms-webapp/hooks/scrollbarComponentHelpers";

const LEARN_OPPORTUNITY_FRAGMENT = graphql`
	fragment NodeContainer_LearnOpportunityV2Fragment on LearnOpportunityV2 {
		root {
			...NodeContentElementsList_NodeFragment
			id
			typeDefinition {
				definitionType
				... on LearnOpportunityELearningContentTypeDefinition {
					containedElementTypes
				}
				... on LearnOpportunityBranchTypeDefinition {
					children {
						...ProjectContentButtonCME_LearnOpportunityFragment
						id
						shortDescription
						structureDefinition {
							title
						}
						typeDefinition {
							definitionType
							... on LearnOpportunityELearningContentTypeDefinition {
								containedElementTypes
								contentNodeAdvancementResult {
									status
									... on CanNotBeStartedContentNodeAdvancementResult {
										reasonConfigTypes
									}
									... on ContinueContentNodeAdvancementResult {
										activeContentSubmissionId
									}
									... on CanNotBeRestartedAfterFailedContentNodeAdvancementResult {
										configResults {
											configType

											... on NegativeBlockPermanentlyRestartIfFailedContentConfigResult {
												canBeRestarted
											}

											... on NegativeBlockTemporarilyRestartIfFailedContentConfigResult {
												blockedUntil
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
		root {
			structureDefinition {
				title
				... on LearnOpportunityRootStructureDefinition {
					tags {
						name
						id
					}
					icon {
						url
					}
					extension {
						... on LearnOpportunityRootExtensionImpl {
							configConsequences {
								restartTriesCount
								isRewardedTo
								rewardExpired
								showAnswerExpired
							}
							totalUserTries
							reducedDataInTree {
								pacPoints
								pharmaPoints
							}
						}
					}
					...NodeTagList_LearnOpportunityRootStructureDefinitionFragment
				}
			}
		}
		structureDefinition {
			title
		}
		typeDefinition {
			... on LearnOpportunityELearningContentTypeDefinition {
				containedElementTypes
				extension {
					... on LearnContentExtensionImpl {
						lengthInSecondsOverride
						pacPoints
						pharmaPoints
					}
				}
			}
		}
		...NodeTopBar_LearnOpportunityV2Fragment
		...NodeConfigurationsDisplay_NodeFragment
		...NodeDescription_NodeFragment
		...AttachmentHeftDisplay_LearnOpportunityV2Fragment
	}
`;

interface OwnProps {
	learnOpportunityFragmentRef: NodeContainer_LearnOpportunityV2Fragment$key;
	sidebarSlot?: ReactNode;
	children: any;
}

const Icon = styled.img`
	max-width: 120px;
`;

const LeftFrame = styled.div`
	position: fixed;
	left: 0;
	height: 85%;
	width: 25%;
	@media screen and (max-width: 1240px) {
		display: none !important;
	}
`;

const ContentFrame = styled.div`
	margin-left: 25%;
	@media screen and (max-width: 1240px) {
		margin-left: 0;
	}
`;

export function NodeContainer({ learnOpportunityFragmentRef, sidebarSlot, children }: OwnProps) {
	const learnOpportunity = useFragment<NodeContainer_LearnOpportunityV2Fragment$key>(
		LEARN_OPPORTUNITY_FRAGMENT,
		learnOpportunityFragmentRef,
	);

	const hasPoints = () => {
		return (
			(learnOpportunity.typeDefinition.extension?.pacPoints || -1) > 0 ||
			(learnOpportunity.typeDefinition.extension?.pharmaPoints || -1) > 0
		);
	};

	const lengthInSeconds = learnOpportunity.typeDefinition.extension?.lengthInSecondsOverride;

	const restartTriesCount =
		learnOpportunity.root?.structureDefinition.extension?.configConsequences?.restartTriesCount ||
		0;
	const totalUserTries = learnOpportunity.root?.structureDefinition.extension?.totalUserTries || 0;

	interface PointsProps {
		points: number | null | undefined;
		type: string;
	}

	const AvailablePoints = ({ points, type }: PointsProps) => {
		return (
			<div className="text-base" style={{ textAlign: "right" }}>
				{(points || -1) > 0 && (
					<div>
						{type}
						{singularPluralPointsDisplay(points!)}
						<span className="ml-2 text-base font-bold">{points}</span>
					</div>
				)}
			</div>
		);
	};

	const hasMultiplechoice =
		learnOpportunity.typeDefinition.containedElementTypes?.includes("multipleChoice") ||
		learnOpportunity.typeDefinition.containedElementTypes?.includes("randomMultipleChoice");

	return (
		<div className="flex flex-column flex-grow-1">
			<PaediaNodeTopBar />
			<MainContainerWithTopAndBottomBar
				footerPixels={footerPixelsMainContainer(hasMultiplechoice || false)}
				className="flex-column lg:flex-row flex-nowrap lg:flex-wrap grid flex-grow-1"
			>
				<LeftFrame className="col-12 xl:col-3 flex-column pl-4 surface-100 border-right-1 border-300 hidden md:flex lg:pr-0">
					<WrapperContainerWithScrollbar
						marginBottom={0}
						width={100}
						footerPixels={footerPixelsLeftFrame(hasMultiplechoice || false)}
					>
						<div className="flex pt-5 pr-3 align-items-center">
							{learnOpportunity.root?.structureDefinition.tags &&
								learnOpportunity.root?.structureDefinition.tags.map((tag) => (
									<Icon
										key={tag.name}
										className="mr-2 mb-2"
										alt={tag.name}
										src={selectLogoBasedOnTag(tag.id)}
									/>
								))}

							{learnOpportunity.root?.structureDefinition && (
								<NodeTagList
									className="justify-content-end align-items-center flex-grow-1"
									learnOpportunityRootStructureDefinitionFragmentRef={
										learnOpportunity.root.structureDefinition
									}
								/>
							)}
						</div>
						<div className="flex align-items-center pr-4 pt-4 pb-2 flex-wrap">
							<table className="w-full">
								<tbody>
									<tr>
										<td>
											{" "}
											{lengthInSeconds && lengthInSeconds > 0 && (
												<div className="text-base">
													Dauer: <DurationDisplay duration={lengthInSeconds || 0} />
												</div>
											)}
										</td>
										{learnOpportunity.root?.structureDefinition.extension?.configConsequences
											?.rewardExpired || false ? (
											<td className="text-base" style={{ textAlign: "right" }}>
												Übungsmodul
											</td>
										) : (
											<td>
												{hasPoints() ? (
													<AvailablePoints
														type={"PAC-"}
														points={learnOpportunity.typeDefinition.extension?.pacPoints}
													/>
												) : null}
											</td>
										)}
									</tr>
									<tr>
										{learnOpportunity.root?.structureDefinition.extension?.configConsequences
											?.rewardExpired ||
										false ||
										!hasPoints() ? (
											<td></td>
										) : (
											<td>
												{" "}
												{learnOpportunity.root?.structureDefinition.extension?.configConsequences
													?.restartTriesCount &&
													hasMultiplechoice && (
														<span className="text-base">{`Versuche: ${
															restartTriesCount - totalUserTries
														} von ${restartTriesCount}`}</span>
													)}
											</td>
										)}
										{learnOpportunity.root?.structureDefinition.extension?.configConsequences
											?.rewardExpired || false ? (
											<td></td>
										) : (
											<td>
												{hasPoints() ? (
													<AvailablePoints
														type={"BAK-"}
														points={learnOpportunity.typeDefinition.extension?.pharmaPoints}
													/>
												) : null}
											</td>
										)}
									</tr>
								</tbody>
							</table>
						</div>
						<hr className="w-12" />
						<h2 className="mt-0 mb-0 text-base pr-4">
							{learnOpportunity.structureDefinition.title}
						</h2>
						<hr className="w-12" />
						<div className="pr-4">
							<NodeConfigurationsDisplay
								className={"text-base"}
								learnOpportunityFragmentRef={learnOpportunity}
							/>
						</div>
						<NodeContentElementsList learnOpportunityFragmentRef={learnOpportunity.root!} />
						<AttachmentHeftDisplay
							learnOpportunityFragmentRef={learnOpportunity}
							marginTopValue={"auto"}
						/>

						{sidebarSlot}
					</WrapperContainerWithScrollbar>
				</LeftFrame>
				<ContentFrame className="col-12 xl:col-9 p-0 flex flex-grow-1">{children}</ContentFrame>
			</MainContainerWithTopAndBottomBar>
		</div>
	);
}
