import React from "react";
import styled from "styled-components";
import { Dialog } from "primereact/dialog";
import { PreloadedQuery, usePreloadedQuery } from "react-relay";
import { StripHtml } from "../../infecto-lms-webapp/functions/helpers/StripHtml";
import { ContentSubmissionEnd_AnswersResultQuery } from "@generated/ContentSubmissionEnd_AnswersResultQuery.graphql";
import { ANSWERS_RESULTS_QUERY } from "./ContentSubmissionEnd.component";
import { SecondaryButton } from "../../infecto-lms-webapp/components/buttons/SecondaryButton";

interface OwnProps {
	isVisible: boolean;
	onHideDialog(): void;
	queryRef: PreloadedQuery<ContentSubmissionEnd_AnswersResultQuery, Record<string, unknown>>;
}

export function ShowUserAnswersResultDialog({ isVisible, onHideDialog, queryRef }: OwnProps) {
	const data = usePreloadedQuery<ContentSubmissionEnd_AnswersResultQuery>(
		ANSWERS_RESULTS_QUERY,
		queryRef,
	);

	const answersRenderer = (
		answers: readonly string[],
		rightAnswers: readonly number[],
		selectedAnswers: readonly number[],
	): JSX.Element[] => {
		return answers?.map((answer, index) => (
			<p key={index}>
				<strong>{`${selectedAnswers.indexOf(index) > -1 ? "Ihre Auswahl: " : ""}`}</strong>
				<span
					className={`${
						selectedAnswers.indexOf(index) > -1 && rightAnswers.indexOf(index) > -1
							? "text-green-500"
							: selectedAnswers.indexOf(index) > -1 && !(rightAnswers.indexOf(index) > -1)
							? "text-red-500"
							: rightAnswers.indexOf(index) > -1
							? "text-green-500"
							: ""
					}`}
				>
					{StripHtml(answer)}
				</span>
			</p>
		));
	};

	const itemRenderer = (index: number, question: string, answers: JSX.Element[]): JSX.Element => {
		return (
			<div key={index}>
				<span className="font-bold text-sm md:text-base">
					<span>{index + 1}. Frage:</span> {StripHtml(question)}
				</span>
				<br />
				<br />
				<div className="flex flex-row">
					<div className="text-sm md:text-base ml-4 mb-2">{answers}</div>
				</div>
				<br />
			</div>
		);
	};

	const contentRenderer = data.LearnV2.GetAnswersResult.map((decision, index) =>
		itemRenderer(
			index,
			decision.question,
			answersRenderer(
				decision.answers,
				decision.correctAnswerIndices,
				decision.selectedAnswersIndices,
			),
		),
	);

	const goBackButton = () => {
		onHideDialog();
		contentRenderer.pop();
	};

	const footer = () => {
		return (
			<div>
				<SecondaryButton label="Zurück" onClick={goBackButton} />
			</div>
		);
	};

	return (
		<StyledDialog
			header="Zusammenfassung der Anworten"
			visible={isVisible}
			footer={footer}
			onHide={onHideDialog}
		>
			{contentRenderer}
		</StyledDialog>
	);
}

const StyledDialog = styled(Dialog)`
	width: 70%;
	@media (max-width: 768px) {
		width: 90%;
	}
`;
