import React, { ReactNode, useState } from "react";

interface State {
	imageUrlIsLoaded: boolean[];
}

interface OwnProps {
	imageUrls: string[];
	showBeforeLoaded: ReactNode;
	children: ReactNode;
}

export function ImageLazyLoad({ imageUrls, showBeforeLoaded, children }: OwnProps) {
	const [state, setState] = useState<State>({
		imageUrlIsLoaded: imageUrls.map(() => false),
	});

	return !state.imageUrlIsLoaded.every((iuil) => iuil) ? (
		<>
			{showBeforeLoaded}
			<div style={{ display: "none" }}>
				{imageUrls.map((url, i) => (
					<img
						key={i}
						src={url}
						alt=""
						onLoad={() => {
							const { imageUrlIsLoaded } = state;
							imageUrlIsLoaded[i] = true;
							setState((state) => ({ ...state, imageUrlIsLoaded }));
						}}
					/>
				))}
			</div>
		</>
	) : (
		<>{children}</>
	);
}
