/**
 * @generated SignedSource<<19fbaf9721eeb884d8ba1fb0da9a8a27>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ContentNodeAdvancementStatus = "BlockedByTree" | "CanBeRestartedAfterFailed" | "CanBeRestartedAfterPassed" | "CanBeStarted" | "CanNotBeRestartedAfterFailed" | "CanNotBeRestartedAfterPassed" | "CanNotBeStarted" | "Continue" | "NotContentNode" | "ViolatesTreeFlow" | "%future added value";
export type ContentSubmissionStatus = "active" | "failed" | "passed" | "%future added value";
export type RestartIfFailedContentConfigType = "RestartIfFailedContent_BlockPermanently" | "RestartIfFailedContent_BlockPermanently_WhenRewarded" | "RestartIfFailedContent_BlockTemporarily" | "RestartIfFailedContent_BlockTemporarily_WhenRewarded" | "RestartIfFailedContent_CanNot" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type FailedContentButton_ContentSubmissionFragment$data = {
  readonly definition: {
    readonly status: ContentSubmissionStatus;
  };
  readonly id: string;
  readonly learnOpportunity: {
    readonly id: string;
    readonly root: {
      readonly id: string;
    } | null;
    readonly typeDefinition: {
      readonly contentNodeAdvancementResult?: {
        readonly configResults?: ReadonlyArray<{
          readonly blockedUntil?: string;
          readonly canBeRestarted: boolean;
          readonly configType: RestartIfFailedContentConfigType;
        }>;
        readonly status: ContentNodeAdvancementStatus;
      };
      readonly extension?: {
        readonly configConsequences?: {
          readonly isBlockedPermanentlyAfterNumFailedTries: number | null;
        };
      };
    };
  } | null;
  readonly " $fragmentType": "FailedContentButton_ContentSubmissionFragment";
};
export type FailedContentButton_ContentSubmissionFragment$key = {
  readonly " $data"?: FailedContentButton_ContentSubmissionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"FailedContentButton_ContentSubmissionFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FailedContentButton_ContentSubmissionFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "definition",
      "plural": false,
      "selections": [
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LearnOpportunityV2",
      "kind": "LinkedField",
      "name": "learnOpportunity",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "LearnOpportunityV2",
          "kind": "LinkedField",
          "name": "root",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "typeDefinition",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "extension",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ContentConfigConsequences",
                          "kind": "LinkedField",
                          "name": "configConsequences",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "isBlockedPermanentlyAfterNumFailedTries",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "type": "LearnContentExtensionImpl",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "contentNodeAdvancementResult",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": null,
                          "kind": "LinkedField",
                          "name": "configResults",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "configType",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "canBeRestarted",
                              "storageKey": null
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "blockedUntil",
                                  "storageKey": null
                                }
                              ],
                              "type": "NegativeBlockTemporarilyRestartIfFailedContentConfigResult",
                              "abstractKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "type": "CanNotBeRestartedAfterFailedContentNodeAdvancementResult",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "LearnOpportunityELearningContentTypeDefinition",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ContentSubmission",
  "abstractKey": null
};
})();

(node as any).hash = "8e77059d3f917ddbe0179fd5dfe502ec";

export default node;
