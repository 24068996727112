import { RadioButton } from "primereact/radiobutton";
import styled from "styled-components";
import {
	basic80,
	error100,
	error20,
	white,
	lightGrey,
	PaletteColor,
	disabledButton,
} from "../../../infecto-lms-webapp-impl/color";

export const StyledRadioButton = styled(RadioButton)<{
	backgroundColor?: PaletteColor;
	foregroundColor?: PaletteColor;
}>`
	&.p-radiobutton .p-radiobutton-box .p-radiobutton-icon:not(.pi) {
		width: 0.5rem;
		height: 0.5rem;
		transition-duration: 0.2s;
	}

	&.p-radiobutton .p-radiobutton-box {
		border: 0.0625rem solid ${basic80.rgbValue()};
		width: 1.5rem;
		height: 1.5rem;
		background: transparent;
	}

	&.p-radiobutton {
		width: 1.5rem;
		height: 1.5rem;
	}

	&.p-radiobutton .p-radiobutton-box.p-highlight {
		border-color: ${(p) => p.backgroundColor?.rgbValue() || white.rgbValue()};
		background: ${(p) => p.backgroundColor?.rgbValue() || white.rgbValue()};
	}

	&.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-highlight:hover {
		border-color: ${lightGrey.rgbValue()};
		background: ${lightGrey.rgbValue()};
	}

	&.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
		border-color: ${lightGrey.rgbValue()};
	}

	&.p-radiobutton .p-radiobutton-box.p-disabled.p-highlight {
		background-color: ${disabledButton.rgbValue()};

		.p-radiobutton-icon:not(.pi) {
			background-color: ${disabledButton.rgbValue()};
		}
	}

	&.p-radiobutton .p-radiobutton-box.p-disabled {
		background-color: ${disabledButton.rgbValue()};
		border-color: ${disabledButton.rgbValue()};
	}

	&.p-radiobutton .p-radiobutton-box:not(.p-highlight).p-disabled .p-radiobutton-icon:not(.pi) {
		width: 0;
		height: 0;
	}

	&.p-radiobutton .p-radiobutton-box.p-highlight.p-disabled .p-radiobutton-icon:not(.pi) {
		background-color: ${basic80.rgbValue()};
	}

	&.p-radiobutton.p-invalid .p-radiobutton-box {
		border-color: ${error100.rgbValue()};
		background-color: ${error20.rgbValue()};
	}

	&.p-radiobutton .p-radiobutton-box .p-radiobutton-icon.pi {
		background-color: unset;
		color: ${(p) => p.foregroundColor?.rgbValue()};
	}

	&.p-radiobutton .p-radiobutton-box:hover .p-radiobutton-icon.pi {
		color: ${white.rgbValue()};
	}

	&.p-radiobutton.p-invalid .p-radiobutton-box .p-radiobutton-icon.pi {
		color: ${white.rgbValue()};
	}

	&.p-radiobutton .p-radiobutton-box.p-disabled .p-radiobutton-icon.pi {
		color: ${basic80.rgbValue()};
	}

	& .p-radiobutton-box.p-focus {
		box-shadow: initial !important;
	}
`;
