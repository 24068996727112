import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { Message } from "primereact/message";
import { PaediaTreeAdvancementMessage_NodeFragment$key } from "@generated/PaediaTreeAdvancementMessage_NodeFragment.graphql";

const LEARN_OPPORTUNITY_FRAGMENT = graphql`
	fragment PaediaTreeAdvancementMessage_NodeFragment on LearnOpportunityV2 {
		structureDefinition {
			... on LearnOpportunityRootStructureDefinition {
				viewerTreeState {
					kind
				}
				extension {
					... on LearnOpportunityRootExtensionImpl {
						wordpressCertificates {
							id
						}
					}
				}
			}
		}
	}
`;

interface OwnProps {
	nodeFragment: PaediaTreeAdvancementMessage_NodeFragment$key;
}

export function PaediaTreeAdvancementMessage({ nodeFragment }: OwnProps) {
	const node = useFragment<PaediaTreeAdvancementMessage_NodeFragment$key>(
		LEARN_OPPORTUNITY_FRAGMENT,
		nodeFragment,
	);

	const FinishedMessage = () => (
		<Message
			className="mt-4 mb-4 w-12 p-5"
			severity="warn"
			content={
				<div>
					<strong>Hinweis:</strong> Sie haben dieses Modul bereits erfolgreich absolviert.
				</div>
			}
		/>
	);

	if (
		node.structureDefinition.extension?.wordpressCertificates &&
		node.structureDefinition.extension?.wordpressCertificates?.length > 0
	) {
		return <FinishedMessage />;
	} else {
		switch (node.structureDefinition.viewerTreeState?.kind) {
			case "IsFinished":
				return <FinishedMessage />;
			default:
				return null;
		}
	}
}
