import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import styled from "styled-components";
import { MessagePreview_MessageFragment$key } from "@generated/MessagePreview_MessageFragment.graphql";

const CUSTOMER_MESSAGE_FRAGMENT = graphql`
	fragment MessagePreview_MessageFragment on Message {
		messageDefinition {
			pages {
				edges {
					node {
						internalTitle
						image {
							url
						}
					}
				}
			}
		}
		hasSeen
	}
`;

interface OwnProps {
	selectMessage: () => void;
	messageFragmentRef: MessagePreview_MessageFragment$key;
}

export function MessagePreview({ selectMessage, messageFragmentRef }: OwnProps) {
	const customerMessage = useFragment<MessagePreview_MessageFragment$key>(
		CUSTOMER_MESSAGE_FRAGMENT,
		messageFragmentRef,
	);
	const edges = customerMessage.messageDefinition?.pages.edges;

	if (!edges) return null;

	const url = edges[0]?.node.image?.url;
	const title = edges[0]?.node.internalTitle;

	if (!url) return null;

	return (
		<div>
			{!customerMessage.hasSeen && <Badge />}
			<PreviewContainer url={url} onClick={selectMessage}>
				<MaskContentContainer>
					<Title>{title}</Title>
				</MaskContentContainer>
			</PreviewContainer>
		</div>
	);
}

const Badge = styled.div`
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: red;
	position: absolute;
	margin-top: 10px;
	margin-left: 10px;
`;

const PreviewContainer = styled.div<{ url: string }>`
	width: 223px;
	height: 383px;
	background-image: url(${(props) => props.url});
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	cursor: pointer;
`;

const Title = styled.p`
	text-align: center;
	font-size: 15px;
	color: white;
	padding: 10px;
	width: 90%;
	height: 80%;
	overflow: auto;
	@media (max-width: 900px) {
		font-size: 13px;
	} ;
`;
const MaskContentContainer = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	flex-direction: column;
	align-items: center;
	background: rgba(0, 0, 0, 0.5);
	border-radius: 10px;
`;
