import React, { useState } from "react";
import styled from "styled-components";
import { classNames } from "primereact/utils";
import { NavLink } from "react-router-dom";
import { ProgressBar } from "primereact/progressbar";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { useTypedSelector } from "../../infecto-lms-webapp/redux/redux-store";
import { NodeTagList } from "../../infecto-lms-webapp/components/node/NodeTagList.component";
import { NodeCertificateDisplay } from "../../infecto-lms-webapp/components/node/NodeCertificateDisplay.component";
import { DateTimeDisplay } from "../../infecto-lms-webapp/components/DateTimeDisplay.component";
import { DurationDisplay } from "../../infecto-lms-webapp/functions/helpers/DurationDisplay";
import { NodeIcons } from "../../infecto-lms-webapp/components/node/NodeIcons.component";
import { DASHBOARD_PATH } from "../router/routes/auth/dashboard.routes";
import { getLearnOpportunityPath } from "../router/routes/auth/nodes.routes";
import { NodeCard_NodeFragment$key } from "@generated/NodeCard_NodeFragment.graphql";
import { NodeCard_LandingPageNodeFragment$key } from "@generated/NodeCard_LandingPageNodeFragment.graphql";
import { NodePointsDisplay } from "../../app/components/node/NodePointsDisplay";
import {
	selectAuthSlice,
	selectDecodedAccountId,
	selectJwtClaimData,
	selectLoginData,
} from "../../infecto-lms-webapp/redux/slices/auth.slice";
import { RefreshToken } from "../../infecto-lms-webapp/redux/slices/RefreshToken";
import { LMS_WEBAPP_CONFIG_IMPL } from "../config";
import { PrimaryButton } from "../../infecto-lms-webapp/components/buttons/PrimaryButton";

const LEARN_OPPORTUNITY_FRAGMENT = graphql`
	fragment NodeCard_NodeFragment on LearnOpportunityV2 {
		id
		shortDescription
		structureDefinition {
			title
			... on LearnOpportunityRootStructureDefinition {
				extension {
					... on LearnOpportunityRootExtensionImpl {
						lengthInSecondsOverride
						isVisibleOnlyForAdmins
						wordpressCertificates {
							finishedAt
							id
						}
						firstLearnOpportunityIdWithMultipleChoiceElement
						configConsequences {
							rewardExpired
						}
						issuedCertificates {
							issuedAt
							fileRef {
								url
							}
						}
						reducedDataInTree {
							pacPoints
							pharmaPoints
							certificateTemplateNames
						}
					}
				}
				icon {
					url
				}
				progressPercentage
				...NodeTagList_LearnOpportunityRootStructureDefinitionFragment
				...NodeCertificateDisplay_NodeRootStructureDefinitionFragment
				...NodePointsDisplay_NodeRootStructureDefinitionFragment
			}
		}
		...NodeIcons_NodeFragment
	}
`;

const LANDING_PAGE_FRAGMENT = graphql`
	fragment NodeCard_LandingPageNodeFragment on LearnOpportunityV2 {
		id
		shortDescription
		structureDefinition {
			title

			... on LearnOpportunityRootStructureDefinition {
				icon {
					url
				}
				extension {
					... on LearnOpportunityRootExtensionImpl {
						configConsequences {
							rewardExpired
						}

						reducedDataInTree {
							pacPoints
							pharmaPoints
						}
					}
				}
				...NodeTagList_LearnOpportunityRootStructureDefinitionFragment
			}
		}
		...NodeIcons_NodeFragment
	}
`;

const IconWrapper = styled.div<{ isFeatured: boolean }>`
	${(props) =>
		props.isFeatured ? "border-top: none;" : "border-top: 1px solid; border-bottom: 1px solid;"}
`;

const LearnOpportunityCardWrapper = styled.div<{ isVisibleOnlyForAdmins: boolean }>`
	${(props) =>
		props.isVisibleOnlyForAdmins ? "border-bottom: 8px solid #5f6bfb;" : "border-bottom: none;"}
	height: 100%;
	display: flex;
`;

const Icon = styled.img<{ isFeatured: boolean }>`
	max-width: ${(props) => (props.isFeatured ? "120px" : "100px")};
	margin-right: 10px;
`;

interface OwnProps {
	isFeatured?: boolean;
	className?: string;
	learnOpportunityFragmentRef?: NodeCard_NodeFragment$key;
	landingPageFragmentRef?: NodeCard_LandingPageNodeFragment$key;
	isLandingPage: boolean;
}

export function NodeCard({
	isFeatured,
	className,
	learnOpportunityFragmentRef,
	isLandingPage,
	landingPageFragmentRef,
}: OwnProps) {
	const isLoggedIn = useTypedSelector(selectAuthSlice).isLoggedIn;
	const [isFileGenerating, setIsFileGenerating] = useState(false);
	const loginData = useTypedSelector(selectLoginData);
	const accountId = useTypedSelector(selectDecodedAccountId);
	const jwtClaimData = useTypedSelector(selectJwtClaimData);

	const landingPage = useFragment<NodeCard_LandingPageNodeFragment$key>(
		LANDING_PAGE_FRAGMENT,
		landingPageFragmentRef!,
	);
	const learnOpportunityLoggedIn = useFragment<NodeCard_NodeFragment$key>(
		LEARN_OPPORTUNITY_FRAGMENT,
		learnOpportunityFragmentRef!,
	);

	const learnOpportunity = learnOpportunityLoggedIn || landingPage;

	const redirectToLearnOpportunityAfterLogin = () => {
		localStorage.setItem("landingPage", "true");
		localStorage.setItem("learnOpportunityId", learnOpportunity.id);
	};

	const numIssuedCertificates =
		(!isLandingPage &&
			learnOpportunity.structureDefinition.extension?.issuedCertificates! &&
			learnOpportunity.structureDefinition.extension.issuedCertificates?.length) ||
		0;
	const numWordpressCertificates =
		(!isLandingPage &&
			learnOpportunity.structureDefinition.extension?.wordpressCertificates! &&
			learnOpportunity.structureDefinition.extension.wordpressCertificates.length) ||
		0;

	const hasPoints = () => {
		return (
			(learnOpportunity.structureDefinition.extension?.reducedDataInTree?.pacPoints || -1) > 0 ||
			(learnOpportunity.structureDefinition.extension?.reducedDataInTree?.pharmaPoints || -1) > 0
		);
	};
	const customedCertificateNames =
		learnOpportunity.structureDefinition.extension?.reducedDataInTree?.certificateTemplateNames.map(
			(n) => {
				if (n == "BAK-Zertifikat") {
					return "BAK-zertifiziert";
				} else {
					return n;
				}
			},
		);
	return (
		<LearnOpportunityCardWrapper
			onClick={redirectToLearnOpportunityAfterLogin}
			isVisibleOnlyForAdmins={
				learnOpportunity.structureDefinition.extension?.isVisibleOnlyForAdmins as boolean
			}
			className={`p-card p-0 relative flex-column  ${className} `}
		>
			<div
				className={`w-12 pb-3 h-full flex flex-column ${classNames({
					"md:flex-column": !isFeatured,
					"md:flex-row": isFeatured,
				})}`}
			>
				<NavLink to={isLoggedIn ? getLearnOpportunityPath(learnOpportunity.id) : DASHBOARD_PATH}>
					<div
						className={`pt-3 pl-3 pr-3 ${classNames({
							"flex flex-row align-items-start justify-content-between": !isFeatured,
							"pr-3": isFeatured,
						})}`}
					>
						{learnOpportunity.structureDefinition.icon?.url && (
							<Icon
								isFeatured={isFeatured || false}
								alt={learnOpportunity.structureDefinition.title}
								src={learnOpportunity.structureDefinition.icon.url}
							/>
						)}{" "}
						<NodeTagList
							className="ml-auto align-items-end mb-3 flex-wrap justify-content-end w-auto"
							learnOpportunityRootStructureDefinitionFragmentRef={
								learnOpportunity.structureDefinition
							}
						/>
					</div>
				</NavLink>
				<div
					className={`flex pl-3 pr-3 flex-grow-1 flex-column justify-content-between  ${classNames({
						"pt-3": !isFeatured,
					})}`}
				>
					<NavLink to={isLoggedIn ? getLearnOpportunityPath(learnOpportunity.id) : DASHBOARD_PATH}>
						{!isLandingPage && learnOpportunityFragmentRef && (
							<IconWrapper isFeatured={isFeatured || false} className="flex mb-3 border-300">
								<NodeCertificateDisplay
									learnOpportunityRootStructureDefinitionFragmentRef={
										learnOpportunity.structureDefinition
									}
									customedCertificateNames={customedCertificateNames}
								/>
								<NodePointsDisplay
									learnOpportunityRootStructureDefinitionFragmentRef={
										learnOpportunity.structureDefinition
									}
								/>
								<NodeIcons
									className="ml-auto flex flex-wrap"
									learnOpportunityFragmentRef={learnOpportunity}
								/>
							</IconWrapper>
						)}
						<TitleContainer className="mt-0">
							{learnOpportunity.structureDefinition.title}
						</TitleContainer>
						{learnOpportunity.shortDescription && (
							<div dangerouslySetInnerHTML={{ __html: learnOpportunity.shortDescription }} />
						)}
						{!isLandingPage && (
							<div className="flex flex-grow-1 align-items-center mt-3 mb-3">
								{learnOpportunity.structureDefinition.progressPercentage &&
								numWordpressCertificates === 0 ? (
									<div className="flex flex-row w-12 align-items-center">
										<ProgressBar
											color={LMS_WEBAPP_CONFIG_IMPL.brandColor}
											className="mt-2 mb-1 flex-grow-1"
											value={learnOpportunity.structureDefinition.progressPercentage}
											showValue={false}
											style={{ height: 5 }}
										/>
										<div className="ml-3 ">
											{learnOpportunity.structureDefinition.progressPercentage}%
										</div>
									</div>
								) : numWordpressCertificates > 0 ? (
									<div className="flex flex-row w-12 align-items-center">
										<ProgressBar
											color={LMS_WEBAPP_CONFIG_IMPL.brandColor}
											className="mt-2 mb-1 flex-grow-1"
											value={100}
											showValue={false}
											style={{ height: 5 }}
										/>
										<div className="ml-3 ">{100}%</div>
									</div>
								) : (
									<hr className="w-12" />
								)}
							</div>
						)}
						{isLandingPage && <hr className="w-12 mb-3 mt-3" />}
					</NavLink>
					{(!isLandingPage && numIssuedCertificates > 0) ||
					(!isLandingPage && numWordpressCertificates > 0) ? (
						<div className="flex align-items-center">
							<div>
								Zertifikat erhalten am{" "}
								{numIssuedCertificates > 0 && (
									<DateTimeDisplay
										hideTimezone
										value={
											learnOpportunity.structureDefinition.extension?.issuedCertificates![0]
												.issuedAt as string
										}
									/>
								)}
								{numWordpressCertificates > 0 &&
									learnOpportunity?.structureDefinition?.extension?.wordpressCertificates && (
										<span>
											{
												learnOpportunity?.structureDefinition?.extension?.wordpressCertificates[0]!
													.finishedAt!
											}
										</span>
									)}
							</div>

							{/*<div className="ml-auto flex align-items-center">
								{(learnOpportunity.structureDefinition.extension?.reducedDataInTree?.pacPoints ||
									-1) > 0 ? (
									<>
										PAC-Punkte
										<span className="ml-2 text-lg font-bold">
											{learnOpportunity.structureDefinition.extension?.reducedDataInTree?.pacPoints}
										</span>
									</>
								) : (
									""
								)}
							</div>*/}
						</div>
					) : (
						<DurationAndButtonWrapper className="flex align-items-center">
							<NavLink
								to={isLoggedIn ? getLearnOpportunityPath(learnOpportunity.id) : DASHBOARD_PATH}
							>
								<div>
									Dauer:{" "}
									<DurationDisplay
										duration={
											learnOpportunity.structureDefinition.extension?.lengthInSecondsOverride || 0
										}
									/>
								</div>
							</NavLink>

							<div className="ml-auto flex align-items-center">
								{learnOpportunity.structureDefinition.extension?.configConsequences
									?.rewardExpired || false ? (
									"Übungsmodul"
								) : hasPoints() ? (
									<div>
										{" "}
										<NavLink
											to={
												isLoggedIn ? getLearnOpportunityPath(learnOpportunity.id) : DASHBOARD_PATH
											}
										>
											<LEKButtonOnlyDesign className="ml-2 text-sm bg-primary">
												Zur Fortbildung
											</LEKButtonOnlyDesign>
										</NavLink>
									</div>
								) : (
									""
								)}
							</div>
						</DurationAndButtonWrapper>
					)}
				</div>
			</div>
			{!isLandingPage && numIssuedCertificates > 0 && (
				<a
					target="_blank"
					rel="noopener noreferrer"
					download
					href={
						learnOpportunity.structureDefinition.extension?.issuedCertificates![0].fileRef?.url!
					}
				>
					<div
						className="pl-3 pr-3 pb-3 pt-3 flex"
						style={{ backgroundColor: "rgba(85,177,74, 0.1)" }}
					>
						<h4 className="m-0 text-primary">Zertifikat herunterladen</h4>
						<div className="ml-auto">
							<span className="font-bold  pi pi-cloud-download text-primary" />
						</div>
					</div>
				</a>
			)}

			{!isLandingPage &&
				learnOpportunity?.structureDefinition?.extension?.wordpressCertificates &&
				numWordpressCertificates > 0 && (
					<div
						className="pl-3 pr-3 pb-3 pt-3 flex"
						style={{ backgroundColor: "rgba(85,177,74, 0.1)" }}
						onClick={() => {
							setIsFileGenerating(true);

							RefreshToken(
								loginData?.refreshToken as string,
								jwtClaimData?.accountId as string,
							).then((currentAccessToken) => {
								setIsFileGenerating(false);
								window.open(
									`${
										process.env.REACT_APP_API_BASE
									}/api/download-legacy-certificate?token=${currentAccessToken}&accountId=${accountId}&certificateId=${learnOpportunity?.structureDefinition?.extension?.wordpressCertificates?.map(
										(c) => c?.id,
									)}`,
								);
							});
						}}
					>
						<h4 className="m-0 text-primary">Zertifikat herunterladen</h4>
						<div className="ml-auto">
							{!isFileGenerating ? (
								<span className={`font-bold pi pi-cloud-download text-primary`} />
							) : (
								<span className={`font-bold pi pi-spin pi-spinner text-primary`} />
							)}
						</div>
					</div>
				)}
		</LearnOpportunityCardWrapper>
	);
}

const LEKButtonOnlyDesign = styled(PrimaryButton)`
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid;
	cursor: pointer;
	padding: 5px;
`;

const DurationAndButtonWrapper = styled.div`
	min-height: 32px;
`;
const TitleContainer = styled.h3`
	@media only screen and (max-width: 1024px) {
		font-size: 14px;
	}
`;
