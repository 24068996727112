import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { LinkButton_MessageDefinitionLinkButtonFragment$key } from "@generated/LinkButton_MessageDefinitionLinkButtonFragment.graphql";
import { ButtonWithTitle } from "./ButtonWithTitle.component";

const MESSAGE_DEFINITION_LINK_BUTTON_FRAGMENT = graphql`
	fragment LinkButton_MessageDefinitionLinkButtonFragment on MessageDefinitionLinkButton {
		title
		link
	}
`;

interface OwnProps {
	onClose: () => void;
	modalWidth: number;
	messageDefinitionLinkButtonFragmentRef: LinkButton_MessageDefinitionLinkButtonFragment$key;
}

export function LinkButton({
	onClose,
	modalWidth,
	messageDefinitionLinkButtonFragmentRef,
}: OwnProps) {
	const button = useFragment<LinkButton_MessageDefinitionLinkButtonFragment$key>(
		MESSAGE_DEFINITION_LINK_BUTTON_FRAGMENT,
		messageDefinitionLinkButtonFragmentRef,
	);

	return (
		<ButtonWithTitle
			title={button.title}
			onClick={() => {
				onClose();
				window.open(button.link);
			}}
			modalWidth={modalWidth}
		/>
	);
}
