import { RestartIfFailedContentConfigType } from "@generated/ContentSubmissionEnd_ContentSubmissionFragment.graphql";

export const isTemporarilyBlockedUntilUtil = (
	configResults?: ReadonlyArray<{
		readonly blockedUntil?: string;
		readonly canBeRestarted?: boolean;
		readonly configType: RestartIfFailedContentConfigType;
	}>,
):
	| {
			blockedUntil?: string;
			canBeRestarted?: boolean | undefined;
			configType: RestartIfFailedContentConfigType;
	  }
	| undefined => {
	return configResults?.find((e) => e.blockedUntil !== undefined);
};
