/**
 * @generated SignedSource<<4efcdae08189551c0df81b21e3eab4c0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NodeBreadcrumbs_NodeFragment$data = {
  readonly id: string;
  readonly path: ReadonlyArray<{
    readonly id: string;
    readonly structureDefinition: {
      readonly title: string;
    };
  }>;
  readonly structureDefinition: {
    readonly title: string;
  };
  readonly " $fragmentType": "NodeBreadcrumbs_NodeFragment";
};
export type NodeBreadcrumbs_NodeFragment$key = {
  readonly " $data"?: NodeBreadcrumbs_NodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NodeBreadcrumbs_NodeFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "structureDefinition",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NodeBreadcrumbs_NodeFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "LearnOpportunityV2",
      "kind": "LinkedField",
      "name": "path",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    (v1/*: any*/)
  ],
  "type": "LearnOpportunityV2",
  "abstractKey": null
};
})();

(node as any).hash = "77a71e917dc8f42ce1a3656522669641";

export default node;
