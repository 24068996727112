import React from "react";
import ReactPlayer from "react-player";
import { memo, useCallback, useContext, useEffect, useRef, useState } from "react";
import screenfull from "screenfull";
import { Icon } from "../../icon";
import { white } from "../../../../infecto-lms-webapp-impl/color";
import { SliderAtom } from "../SliderAtom.component";
import PlayButton from "../../../assets/play-button.png";
import {
	HlsPlayer,
	OverlayInitiator,
	VideoPlayerProps,
	VideoPlayeWithProvidersProps,
} from "./video-player.interface";
import {
	BottomControls,
	CenterControls,
	CenterPlayBackground,
	CenterPlayControls,
	CenterRewindBackground,
	CenterWrapper,
	ControlsWrapper,
	MenuWrapper,
	MenuWrapperAnchor,
	Overlay,
	SmallVideoTime,
	StyledReactPlayer,
	TopControls,
	TopRightControls,
	VolumeSlider,
	VolumeSliderWrapper,
	VolumeWrapper,
	Wrapper,
	Mask,
} from "./video-player.styles";
import { DynamicSlideMenuContext, DynamicSlideMenuProvider } from "../dynamic-slide-menu-provider";
import { VideoMenu } from "../video-menu";
import { HLSLevelAuto, VideoMenuRoute, VideoSettingsSpeed } from "../video-menu";
import { SliderChangeParams } from "primereact/slider";
import { TkaCopySmallSpan } from "../../../utils";
import { formatSeconds } from "./video-player.utils";
import { VideoPlayerContext } from "./video-player.context";
import { useWindowSize } from "../../../hooks/use-window-size";
import { track } from "../../../functions/gtm";

export const VideoPlayerWithProviders = ({ onProgress, url }: VideoPlayeWithProvidersProps) => {
	const { path: menuPath, navigate } = useContext(DynamicSlideMenuContext);
	const playerIdRef = useRef(`video-player-id-${Math.floor(Math.random() * 100000)}`);

	const playerRef = useRef<ReactPlayer>(null);
	const [isPlaying, setIsPlaying] = useState(false);
	const [controlsVisible, setControlsVisible] = useState(false);
	const [isFullscreen, setIsFullscreen] = useState(false);
	const [isVolumeVisible, setVolumeVisible] = useState(false);
	const [volume, setVolume] = useState(1);
	const [playbackRate, setPlaybackRate] = useState(1);
	const [availableHeights, setAvailableHeights] = useState<number[]>([]);
	const { isLargeUp, isMediumUp } = useWindowSize();
	const duration = playerRef.current?.getDuration() || 100;
	const [progressBarValue, setProgressBarValue] = useState(0);
	const [showMask, setShowMask] = useState(false);
	const [isPlayerReady, setPlayerReady] = useState(false);

	const hideMenu = useCallback(() => {
		menuPath.length > 0 && navigate(undefined, true);
	}, [menuPath.join("/"), navigate]);

	const hideVolume = useCallback(() => setVolumeVisible(false), []);

	const hideOtherOverlays = useCallback(
		(initiator: OverlayInitiator) => {
			if (initiator !== OverlayInitiator.Volume) {
				hideVolume();
			}
			if (initiator !== OverlayInitiator.Settings && initiator !== OverlayInitiator.Transcripts) {
				hideMenu();
			}
		},
		[hideVolume, hideMenu],
	);

	const togglePlayPause = useCallback(
		(event: React.MouseEvent<SVGSVGElement | HTMLElement>) => {
			event.stopPropagation();
			hideOtherOverlays(OverlayInitiator.OverlayButton);
			setIsPlaying((boo) => !boo);
		},
		[hideOtherOverlays],
	);

	const settingsActive = menuPath.includes(VideoMenuRoute.settings.path);

	const handleSettingsOnClick = useCallback(
		(event: React.MouseEvent<HTMLElement>) => {
			event.stopPropagation();
			if (settingsActive) {
				hideMenu();
			} else {
				hideOtherOverlays(OverlayInitiator.Settings);
				navigate(VideoMenuRoute.settings.path, true);
			}
		},
		[settingsActive, navigate, hideOtherOverlays, hideMenu],
	);

	const handleOnProgress = useCallback(
		(progress: any) => {
			onProgress(progress.playedSeconds);
			if (Math.floor(progress.playedSeconds) % 10 === 0) {
				track({ event: "video_progress", videoFile: url, currentTime: progress.playedSeconds });
			}
		},
		[onProgress],
	);

	const handleOnSlideEnd = useCallback(
		(event: SliderChangeParams) => {
			event.originalEvent.stopPropagation();
			const position = Array.isArray(event.value) ? event.value[0] : event.value;
			playerRef.current?.seekTo(position, "seconds");
			hideOtherOverlays(OverlayInitiator.OverlayButton);
		},
		[playerRef.current, hideOtherOverlays],
	);

	const handleOnEnded = useCallback(() => {
		setIsPlaying(false);
		track({ event: "video_ended", videoFile: url });
	}, []);

	const handleOnReady = useCallback(() => {
		const hlsPlayer = playerRef.current?.getInternalPlayer("hls") as HlsPlayer;
		if (!hlsPlayer) return;
		const availableLevels = hlsPlayer.levels.map((level) => level.height);
		setAvailableHeights(availableLevels);
		track({ event: "video_loaded", videoFile: url });
	}, [playerRef.current]);

	const handleOnResolutionChanged = useCallback(
		(resolutionHeight: number) => {
			const hlsPlayer = playerRef.current?.getInternalPlayer("hls") as HlsPlayer;
			if (!hlsPlayer) return;
			const setAutoLevelSelection = () => {
				hlsPlayer.nextLevel = HLSLevelAuto;
				hlsPlayer.loadLevel = HLSLevelAuto;
			};
			if (resolutionHeight === HLSLevelAuto) {
				setAutoLevelSelection();
				return;
			}
			const nextLevel = hlsPlayer.levels.findIndex((level) => level.height === resolutionHeight);
			if (nextLevel !== undefined) {
				hlsPlayer.nextLevel = nextLevel;
				hlsPlayer.loadLevel = nextLevel;
				return;
			}
			setAutoLevelSelection();
		},
		[playerRef.current],
	);

	const handlePlaybackRateOnChange = useCallback((rate: VideoSettingsSpeed) => {
		setPlaybackRate(Number(rate));
	}, []);

	useEffect(() => {
		if (!screenfull.isEnabled) return;
		const onFullscreenChanged = () => {
			isFullscreen && !screenfull.isFullscreen && setIsFullscreen(false);
		};
		screenfull.on("change", onFullscreenChanged);
		return () => {
			screenfull.off("change", onFullscreenChanged);
		};
	}, [isFullscreen]);

	const handleFullScreenOnClick = useCallback(
		(event: React.MouseEvent<HTMLElement>) => {
			event.stopPropagation();
			hideOtherOverlays(OverlayInitiator.OverlayButton);
			if (screenfull.isEnabled) {
				if (isFullscreen) {
					screenfull.isEnabled && screenfull.exit();
				} else {
					const element = document.getElementById(playerIdRef.current);
					if (screenfull.isEnabled && element) {
						screenfull.request(element, { navigationUI: "hide" });
					}
				}
			}
			setIsFullscreen(!isFullscreen);
		},
		[playerIdRef.current, isFullscreen, hideOtherOverlays],
	);

	const handleRewind10OnClick = useCallback(
		(event: React.MouseEvent<HTMLElement>) => {
			event.stopPropagation();
			hideOtherOverlays(OverlayInitiator.OverlayButton);
			const time = playerRef.current?.getCurrentTime() ?? 0;
			playerRef.current?.seekTo(time - 10, "seconds");
		},
		[playerRef.current, hideOtherOverlays],
	);

	const handleForward10OnClick = useCallback(
		(event: React.MouseEvent<HTMLElement>) => {
			event.stopPropagation();
			hideOtherOverlays(OverlayInitiator.OverlayButton);
			const time = playerRef.current?.getCurrentTime() ?? 0;
			playerRef.current?.seekTo(time + 10, "seconds");
		},
		[playerRef.current, hideOtherOverlays],
	);

	const handleOnPause = useCallback(() => {
		setIsPlaying(false);
		track({
			event: "video_paused",
			videoFile: url,
		});
	}, []);

	const handleOnPlay = useCallback(() => {
		setIsPlaying(true);
		track({
			event: "video_playing",
			videoFile: url,
			currentTime: playerRef.current?.getCurrentTime(),
		});
	}, []);

	const handleOverlayClicked = useCallback(() => {
		menuPath.length === 0 && setControlsVisible((controlsVisible) => !controlsVisible);
		hideOtherOverlays(OverlayInitiator.OverlayButton);
	}, [hideOtherOverlays, menuPath]);

	const handleOnMouseLeave = useCallback(() => {
		if (isLargeUp) {
			hideOtherOverlays(OverlayInitiator.OutsideFocus);
		}
	}, [isLargeUp, hideOtherOverlays]);

	const handleOnVolumeClick = useCallback(
		(event: React.MouseEvent<HTMLElement>) => {
			event.stopPropagation();
			hideOtherOverlays(OverlayInitiator.Volume);
			setVolumeVisible((volumeVisible) => !volumeVisible);
		},
		[hideOtherOverlays],
	);

	const handleOnVolumeChange = useCallback((event: SliderChangeParams) => {
		const position = Array.isArray(event.value) ? event.value[0] : event.value;
		setVolume(position);
	}, []);

	const handleSliderMovement = useCallback(
		(event: React.MouseEvent<HTMLElement>) => {
			event.stopPropagation();
			playerRef.current?.seekTo(progressBarValue);
		},
		[playerRef.current, progressBarValue],
	);
	const handleMaskMouseOver = () => {
		setShowMask(true);
	};
	useEffect(() => {
		if (menuPath.length == 0) {
			const timer = setTimeout(() => {
				setShowMask(false);
			}, 3000);
			return () => {
				// Return callback to run on unmount.
				clearInterval(timer);
			};
		}
	}, [showMask, menuPath]);

	return (
		<Wrapper
			hideMouse={!showMask}
			onMouseLeave={() => setControlsVisible(false)}
			id={playerIdRef.current}
			fullScreen={isFullscreen}
		>
			<CenterWrapper fullScreen={isFullscreen}>
				<StyledReactPlayer
					ref={playerRef}
					url={url}
					width={"100%"}
					height={"100%"}
					volume={volume}
					playing={isPlaying}
					onPause={handleOnPause}
					onPlay={handleOnPlay}
					onEnded={handleOnEnded}
					progressInterval={0.2}
					onProgress={handleOnProgress}
					onReady={() => {
						handleOnReady();
						setPlayerReady(true);
					}}
					playbackRate={playbackRate}
					playsinline={true}
					style={{ maxHeight: isFullscreen ? "100%" : "calc(100vh - 300px)" }}
				/>

				<Mask
					onMouseLeave={() => {
						setShowMask(false);
					}}
					onMouseMoveCapture={handleMaskMouseOver}
					onClick={() => {
						setIsPlaying((prevState) => !prevState);
					}}
					showBottomControls={!isPlaying || showMask}
				>
					<Overlay
						hideControls={isPlaying && !showMask}
						className="video-controls"
						showBottomControls={controlsVisible || !isPlaying || (isPlaying && showMask)}
						onClick={handleOverlayClicked}
						onMouseLeave={handleOnMouseLeave}
					>
						<TopControls>
							<TopRightControls
								className="md:hidden"
								showControls={controlsVisible || !isPlaying || (isPlaying && showMask)}
							>
								<Icon
									icon="settings"
									sizeInRem={1.25}
									active={settingsActive}
									paletteColor={white}
									onClick={handleSettingsOnClick}
								/>
								<Icon
									icon={isFullscreen ? "closeFullscreen" : "fullscreen"}
									sizeInRem={1.25}
									paletteColor={white}
									onClick={handleFullScreenOnClick}
								/>
							</TopRightControls>
						</TopControls>
						<CenterControls className="md:p-2">
							<CenterPlayControls className="center-play-controls" hidden={isPlaying}>
								{window.innerWidth < 480 && (
									<CenterRewindBackground onClick={handleRewind10OnClick}>
										<Icon icon="rewind10" sizeInRem={1.5} paletteColor={white} />
									</CenterRewindBackground>
								)}
								<CenterPlayBackground onClick={togglePlayPause}>
									{!isPlaying && (
										<img
											style={{ width: "100%", height: "100%" }}
											alt="Video starten"
											src={PlayButton}
										/>
									)}
								</CenterPlayBackground>
								{window.innerWidth < 480 && (
									<CenterRewindBackground onClick={handleForward10OnClick}>
										<Icon icon="forward10" sizeInRem={1.5} paletteColor={white} />
									</CenterRewindBackground>
								)}
							</CenterPlayControls>
							<MenuWrapperAnchor>
								<MenuWrapper className="absolute flex bottom-0 right-0 max-h-full z-10">
									<VideoMenu
										availableHeights={availableHeights}
										onResolutionChanged={handleOnResolutionChanged}
										onSpeedChanged={handlePlaybackRateOnChange}
									/>
								</MenuWrapper>
							</MenuWrapperAnchor>
						</CenterControls>
						{isPlayerReady && (
							<BottomControls
								hidden={controlsVisible || !isPlaying || (isPlaying && showMask)}
								className="bottom-controls"
							>
								{!isMediumUp && (
									<SmallVideoTime>
										<VideoPlayerContext.Consumer>
											{({ progress }) => (
												<TkaCopySmallSpan paletteColor={white}>{`${formatSeconds(
													progress,
												)} / ${formatSeconds(duration)}`}</TkaCopySmallSpan>
											)}
										</VideoPlayerContext.Consumer>
									</SmallVideoTime>
								)}
								<VideoPlayerContext.Consumer>
									{({ progress, setProgress }) => (
										<SliderAtom
											value={progress}
											onSlideEnd={handleOnSlideEnd}
											max={duration}
											step={0.2}
											showHandle={true}
											onClickCapture={(e) => {
												handleSliderMovement(e);
											}}
											onMouseDown={() => setIsPlaying(false)}
											onMouseUp={() => setIsPlaying(true)}
											onTouchEndCapture={() => {
												setProgress(progressBarValue);
												setIsPlaying(true);
											}}
											onTouchStartCapture={() => setIsPlaying(false)}
											onChange={(e: any) => {
												setProgressBarValue(e.value);
												setProgress(e.value);
											}}
										/>
									)}
								</VideoPlayerContext.Consumer>
								<div className="hidden md:flex flex-row pt-3 pl-2 pr-2 lg:pt-4 lg:pl-4 lg:pr-4 justify-content-between">
									<ControlsWrapper>
										<Icon
											icon="rewind10"
											sizeInRem={1.25}
											paletteColor={white}
											onClick={handleRewind10OnClick}
										/>
										<Icon
											icon={isPlaying ? "pauseArrow" : "playArrow"}
											sizeInRem={1.25}
											paletteColor={white}
											onClick={togglePlayPause}
										/>
										<Icon
											icon="forward10"
											sizeInRem={1.25}
											paletteColor={white}
											onClick={handleForward10OnClick}
										/>
										<VolumeWrapper>
											{isVolumeVisible && (
												<VolumeSliderWrapper>
													<VolumeSlider
														showHandle
														value={volume}
														max={1}
														step={0.01}
														orientation="vertical"
														onChange={handleOnVolumeChange}
													/>
												</VolumeSliderWrapper>
											)}
											<Icon
												icon="volume"
												sizeInRem={1.25}
												active={isVolumeVisible}
												paletteColor={white}
												onClick={handleOnVolumeClick}
											/>
										</VolumeWrapper>
										<VideoPlayerContext.Consumer>
											{({ progress }) => (
												<TkaCopySmallSpan paletteColor={white}>{`${formatSeconds(
													progress,
												)} / ${formatSeconds(duration)}`}</TkaCopySmallSpan>
											)}
										</VideoPlayerContext.Consumer>
									</ControlsWrapper>
									<ControlsWrapper>
										<Icon
											icon="settings"
											sizeInRem={1.25}
											active={settingsActive}
											paletteColor={white}
											onClick={handleSettingsOnClick}
										/>
										<Icon
											icon={isFullscreen ? "closeFullscreen" : "fullscreen"}
											sizeInRem={1.25}
											paletteColor={white}
											onClick={handleFullScreenOnClick}
										/>
									</ControlsWrapper>
								</div>
							</BottomControls>
						)}
					</Overlay>
				</Mask>
			</CenterWrapper>
		</Wrapper>
	);
};

const MemoizedVideoPlayerWithProviders = memo(VideoPlayerWithProviders);

export const VideoPlayer = (props: VideoPlayerProps) => {
	const [progress, setProgress] = useState(0);
	const handleOnProgress = useCallback((progress: number) => {
		setProgress(progress);
	}, []);
	return (
		<DynamicSlideMenuProvider>
			<VideoPlayerContext.Provider value={{ progress, setProgress }}>
				<MemoizedVideoPlayerWithProviders {...props} onProgress={handleOnProgress} />
			</VideoPlayerContext.Provider>
		</DynamicSlideMenuProvider>
	);
};
