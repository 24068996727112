import { useCallback, useContext } from "react";
import { TkaCopySpan, TkaH2Span } from "../../../utils";
import { white } from "../../../../infecto-lms-webapp-impl/color";
import { DynamicSlideMenuContext } from "../dynamic-slide-menu-provider";
import { Icon } from "../../icon";
import { DynamicSlideMenuNavigateItemProps } from "./dynamic-slide-menu-navigate-item.interface";
import React from "react";
import styled from "styled-components";
export const DynamicSlideMenuNavigateItem = ({
	title,
	content,
	path,
	children,
}: DynamicSlideMenuNavigateItemProps) => {
	const context = useContext(DynamicSlideMenuContext);
	const { path: currentPath, navigate, depth } = context;
	const handleOnClick = useCallback(() => {
		navigate(path);
	}, [navigate, path]);
	if (currentPath.length > depth && !currentPath.includes(path)) return null;

	return currentPath.includes(path) ? (
		<DynamicSlideMenuContext.Provider value={{ ...context, depth: depth + 1 }}>
			{children}
		</DynamicSlideMenuContext.Provider>
	) : (
		<NavigateWrapper className="hover:cursor-pointer" onClick={handleOnClick}>
			<NavigateTitle paletteColor={white}>{title}</NavigateTitle>
			{content && <TkaCopySpan paletteColor={white}>{content}</TkaCopySpan>}
			<Icon icon="arrowRight2" sizeInRem={0.75} paletteColor={white} />
		</NavigateWrapper>
	);
};

export const NavigateWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 3rem;
	@media screen and (max-width: 480px) {
		gap: 2rem;
	}
	hover: cursor-pointer;
`;

export const NavigateTitle = styled(TkaH2Span)`
	flex-grow: 1;
	@media screen and (max-width: 480px) {
		font-size: 0.75rem;
	}
`;
