import React from "react";
import { PrivateRouteDefinition } from "../../../../infecto-lms-webapp/router/interfaces";
import { AllMessagesScreen } from "../../../../app/screens/AllMessages.screen";

export const MessagingRoutes: PrivateRouteDefinition[] = [
	{
		path: "/messaging",
		component: <AllMessagesScreen />,
	},
];
