import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { Button } from "primereact/button";
import styled from "styled-components";
import { NodeIcons_NodeFragment$key } from "@generated/NodeIcons_NodeFragment.graphql";
import { ProjectNodeIcons } from "../../../infecto-lms-webapp-impl/components/ProjectNodeIcons";

const LEARN_OPPORTUNITY_FRAGMENT = graphql`
	fragment NodeIcons_NodeFragment on LearnOpportunityV2 {
		structureDefinition {
			... on LearnOpportunityRootStructureDefinition {
				extension {
					... on LearnOpportunityRootExtensionImpl {
						reducedDataInTree {
							elementTypes
						}
					}
				}
			}
		}
		...ProjectNodeIcons_NodeFragment
	}
`;

interface OwnProps {
	className?: string;
	learnOpportunityFragmentRef: NodeIcons_NodeFragment$key;
}

export const IconButton = styled(Button)`
	color: var(--secondary-color) !important;

	&:disabled {
		opacity: 0.3;
	}
`;

export function NodeIcons({ className, learnOpportunityFragmentRef }: OwnProps) {
	const learnOpportunity = useFragment<NodeIcons_NodeFragment$key>(
		LEARN_OPPORTUNITY_FRAGMENT,
		learnOpportunityFragmentRef,
	);

	const hasBook =
		learnOpportunity.structureDefinition.extension?.reducedDataInTree?.elementTypes.includes(
			"file",
		);
	const hasVideo =
		learnOpportunity.structureDefinition.extension?.reducedDataInTree?.elementTypes.includes(
			"video",
		);

	return (
		<div className={`${className || ""}`}>
			<IconButton
				disabled={!hasBook}
				label=""
				className="p-button-text"
				icon="icon-book"
				tooltip={hasBook ? "Enthält Heft" : "Enthält kein Heft"}
				tooltipOptions={{ position: "left", showOnDisabled: true }}
			/>

			<IconButton
				disabled={!hasVideo}
				label=""
				className="p-button-text"
				icon="icon-play"
				tooltip={`Enthält ${!hasVideo ? "keine" : ""} Video-Elemente`}
				tooltipOptions={{ position: "left", showOnDisabled: true }}
			/>
			<ProjectNodeIcons learnOpportunityFragmentRef={learnOpportunity} />
		</div>
	);
}
