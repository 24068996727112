import React, { useEffect, useRef, useState } from "react";
import { useFragment, useMutation } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import styled from "styled-components";
import { Carousel } from "primereact/carousel";
import { Dialog } from "primereact/dialog";
import { MessageModal_MessageFragment$key } from "@generated/MessageModal_MessageFragment.graphql";
import { MessageModal_AcknowledgeMessagesMutation } from "@generated/MessageModal_AcknowledgeMessagesMutation.graphql";
import { useViewportDimensions } from "../../hooks/useViewportDimensions";
import { ImageLazyLoad } from "../ImageLazyLoad.component";
import { MessagePage } from "./MessagePage.component";
import ArrowBackward from "../../assets/arrow-backward.svg";
import ArrowForward from "../../assets/arrow-forward.svg";
import { PageIndicators } from "./PageIndicators.component";

const MESSAGE_FRAGMENT = graphql`
	fragment MessageModal_MessageFragment on Message {
		id
		messageDefinition {
			pages {
				edges {
					node {
						id
						image {
							url
						}
						...MessagePage_MessageDefinitionPageFragment
					}
				}
			}
		}
		hasSeen
	}
`;

const ACKNOWLEDGE_MESSAGE_MUTATION = graphql`
	mutation MessageModal_AcknowledgeMessagesMutation($messageId: ID!) {
		Viewer {
			Messaging {
				acknowledgeMessages(input: { messageIds: [$messageId] }) {
					messages {
						id
						hasSeen
					}
					messageInformation {
						id
						numUnseenMessages
					}
				}
			}
		}
	}
`;

interface State {
	page: number;
}

interface OwnProps {
	hideModal: () => void;
	messageFragment?: MessageModal_MessageFragment$key;
}

export function MessageModal({ hideModal, messageFragment }: OwnProps) {
	const carousel = useRef<any>(null);

	const message = useFragment<MessageModal_MessageFragment$key>(
		MESSAGE_FRAGMENT,
		messageFragment || null,
	);

	const [acknowledgeMessage] = useMutation<MessageModal_AcknowledgeMessagesMutation>(
		ACKNOWLEDGE_MESSAGE_MUTATION,
	);

	const { height: viewportHeight, width: viewportWidth } = useViewportDimensions();

	const [state, setState] = useState<State>({
		page: 0,
	});

	const pages =
		message?.messageDefinition?.pages.edges
			?.filter((e) => e)
			.map((e) => e!.node)
			.filter((n) => n.image?.url) || [];

	const showMessage = message && pages.length > 0 && viewportWidth > 300;

	useEffect(() => {
		if (showMessage && !message!.hasSeen) {
			acknowledgeMessage({
				variables: { messageId: message!.id },
			});
		}
		// eslint-disable-next-line
        }, [showMessage]
    )

	if (!showMessage) return null;

	const limitWidth = 400;
	const modalHeightBellowMaxHeight = 0.8 * viewportHeight;
	const modalWidthBellowMaxWidth = (modalHeightBellowMaxHeight / 16) * 9;
	const modalWidth =
		(modalHeightBellowMaxHeight / 16) * 9 > limitWidth ? limitWidth : modalWidthBellowMaxWidth;

	const canMoveForward = state.page < pages.length - 1;
	const canMoveBackward = state.page > 0;

	const navForward = () => {
		if (carousel.current) {
			setState((state) => ({ ...state, page: state.page + 1 }));
			carousel.current.navForward({ cancelable: false }, state.page + 1);
		}
	};

	const navBackward = () => {
		if (carousel.current) {
			setState((state) => ({ ...state, page: state.page - 1 }));
			carousel.current.navBackward({ cancelable: false }, state.page - 1);
		}
	};

	return (
		<ImageLazyLoad imageUrls={pages.map((p) => p.image!.url!)} showBeforeLoaded={null}>
			<StyledDialog
				onHide={hideModal}
				visible
				contentStyle={{ borderRadius: 10 }}
				resizable={false}
				draggable={false}
				modal
				closable={false}
				dismissableMask
				maskStyle={{ backgroundColor: "rgba(3,29,60,0.8)" }}
				modalWidth={modalWidth}
			>
				<PageIndicators
					numPages={pages.length}
					modalWidth={modalWidth}
					activePageIndex={state.page}
				/>

				{canMoveForward && (
					<NavImage
						modalWidth={modalWidth}
						forward
						onClick={navForward}
						src={ArrowForward}
						alt=""
					/>
				)}
				{canMoveBackward && (
					<NavImage
						modalWidth={modalWidth}
						forward={false}
						onClick={navBackward}
						src={ArrowBackward}
						alt=""
					/>
				)}
				<StyledCarousel
					page={0}
					value={pages}
					itemTemplate={(page) => (
						<MessagePage
							key={page.id}
							modalWidth={modalWidth}
							hideModal={hideModal}
							onClose={page.id === pages[pages.length - 1].id ? hideModal : undefined}
							messageDefinitionPageFragmentRef={page}
						/>
					)}
					numVisible={1}
					numScroll={1}
					ref={carousel}
				/>
			</StyledDialog>
		</ImageLazyLoad>
	);
}

const NavImage = styled.img<{ modalWidth: number; forward: boolean }>`
	position: absolute;
	top: ${(props) => (16 / 18 - 0.05) * props.modalWidth}px;
	z-index: 1;
	${(props) =>
		props.forward
			? "right: " + props.modalWidth / 40 + "px;"
			: "left: " + props.modalWidth / 40 + "px;"}
	cursor: pointer;
	width: ${(props) => props.modalWidth * 0.05}px;
	height: ${(props) => props.modalWidth * 0.05}px;
`;

const StyledDialog = styled(Dialog)<{ modalWidth: number }>`
	width: 350px;
	border-radius: 10px;
	@media (max-width: 500px) {
		width: 308px;
	}

	.p-dialog-header {
		display: none;
	}
	.p-dialog-content {
		padding: 0;
	}
`;

const StyledCarousel = styled(Carousel)`
	.p-carousel-indicators {
		display: none;
	}

	.p-carousel-next {
		display: none;
	}

	.p-carousel-prev {
		display: none;
	}
`;
