/**
 * @generated SignedSource<<7a826164b8d337151d607ccbe864c8d9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ElementTypeV2 = "clozeText" | "file" | "multipleChoice" | "order" | "podcast" | "randomMultipleChoice" | "text" | "video" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CarousalFeaturedNode_NodeFragment$data = {
  readonly id: string;
  readonly image: {
    readonly url: string | null;
  } | null;
  readonly nextContentNode: {
    readonly typeDefinition: {
      readonly containedElementTypes?: ReadonlyArray<ElementTypeV2>;
    };
  } | null;
  readonly structureDefinition: {
    readonly tags?: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }>;
    readonly title: string;
  };
  readonly " $fragmentType": "CarousalFeaturedNode_NodeFragment";
};
export type CarousalFeaturedNode_NodeFragment$key = {
  readonly " $data"?: CarousalFeaturedNode_NodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CarousalFeaturedNode_NodeFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CarousalFeaturedNode_NodeFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "LearnOpportunityV2",
      "kind": "LinkedField",
      "name": "nextContentNode",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "typeDefinition",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "containedElementTypes",
                  "storageKey": null
                }
              ],
              "type": "LearnOpportunityELearningContentTypeDefinition",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "structureDefinition",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Tag",
              "kind": "LinkedField",
              "name": "tags",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "LearnOpportunityRootStructureDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "image",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "LearnOpportunityV2",
  "abstractKey": null
};
})();

(node as any).hash = "55c8580dd8c4c7d8fb0ae8e44377e7d7";

export default node;
