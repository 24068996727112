import React, { useEffect, useState } from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery, usePaginationFragment } from "react-relay";
import { CertificatesScreen_QueryFragment$key } from "@generated/CertificatesScreen_QueryFragment.graphql";
import {
	CertificateAchievedCursorSortDirectionInput,
	CertificatesScreen_Refetch,
} from "@generated/CertificatesScreen_Refetch.graphql";
import { Card } from "primereact/card";
import { usePageTitle } from "../../infecto-lms-webapp/hooks/usePageTitle";
import { DefaultContainer } from "../../infecto-lms-webapp/containers/Default.container";
import { MainContainerWithTopBar } from "../../infecto-lms-webapp/containers/MainContainerWithTopBar.container";
import styled from "styled-components";
import { CertificatesScreen_Query } from "@generated/CertificatesScreen_Query.graphql";
import { Dropdown } from "primereact/dropdown";
import { WordpressCertificates_Refetch } from "@generated/WordpressCertificates_Refetch.graphql";
import { CertificatesScreen_WordpressCertificatesQueryFragment$key } from "@generated/CertificatesScreen_WordpressCertificatesQueryFragment.graphql";
import { CertificatesScreen_RenewedFachberaterCertificatesQuery } from "@generated/CertificatesScreen_RenewedFachberaterCertificatesQuery.graphql";
import { formatDateBasedOnInput } from "../../infecto-lms-webapp/components/DateTimeDisplay.component";
import { PrimaryButton } from "../../infecto-lms-webapp/components/buttons/PrimaryButton";

const QUERY = graphql`
	query CertificatesScreen_Query($sortDirection: CertificateAchievedCursorSortDirectionInput) {
		...CertificatesScreen_QueryFragment @arguments(sortDirection: $sortDirection)
		...CertificatesScreen_WordpressCertificatesQueryFragment
	}
`;

const RENEWED_FACHBERATER_CERTIFICATES_QUERY = graphql`
	query CertificatesScreen_RenewedFachberaterCertificatesQuery {
		Viewer {
			FachberaterCertificates {
				renewedFachberaterCertificates {
					certificateFile {
						name
						url
					}
					issuedAt
				}
			}
		}
	}
`;

const QUERY_FRAGMENT = graphql`
	fragment CertificatesScreen_QueryFragment on Query
	@refetchable(queryName: "CertificatesScreen_Refetch")
	@argumentDefinitions(
		first: { type: "Int", defaultValue: 10 }
		after: { type: "String" }
		sortDirection: { type: "CertificateAchievedCursorSortDirectionInput" }
	) {
		CertificateAchievedByUser {
			certificates(first: $first, after: $after, sortDirection: $sortDirection)
				@connection(key: "CertificatesScreen_certificates") {
				edges {
					node {
						name
						fileRef {
							url
						}
						issuedAt
					}
				}
			}
		}
		Viewer {
			Auth {
				currentUser {
					user {
						...DefaultContainer_UserDataFragment
						...UserStatusMessage_UserFragment
					}
				}
			}
		}
	}
`;

const LEGACY_CERTIFICATES_FRAGMENT = graphql`
	fragment CertificatesScreen_WordpressCertificatesQueryFragment on Query
	@refetchable(queryName: "WordpressCertificates_Refetch")
	@argumentDefinitions(
		first: { type: "Int", defaultValue: 10 }
		after: { type: "String" }
		sortDirection: { type: "WordpressCertificatesAchievedCursorSortDirectionInput" }
	) {
		Viewer {
			WordpressCertificateAchievedByUser {
				wordPressCertificates(first: $first, after: $after, sortDirection: $sortDirection)
					@connection(key: "CertificatesScreen_wordPressCertificates") {
					edges {
						node {
							id
							contentNodeTitle
							userName
							certificateFile {
								url
							}
							finishedAt
						}
					}
				}
			}
		}
	}
`;

export const CertificatesScreen = () => {
	usePageTitle("Meine Zertifikate");

	const [sortDirectionFilter, setSortDirectionFilter] = useState(
		"asc" as CertificateAchievedCursorSortDirectionInput,
	);

	const queryData = useLazyLoadQuery<CertificatesScreen_Query>(
		QUERY,
		{ sortDirection: sortDirectionFilter },
		{ fetchPolicy: "network-only" },
	);

	const { data, hasNext, loadNext, refetch } = usePaginationFragment<
		CertificatesScreen_Refetch,
		CertificatesScreen_QueryFragment$key
	>(QUERY_FRAGMENT, queryData);

	const legacyCertificates = usePaginationFragment<
		WordpressCertificates_Refetch,
		CertificatesScreen_WordpressCertificatesQueryFragment$key
	>(LEGACY_CERTIFICATES_FRAGMENT, queryData);

	const renewedFachberaterCertificatesQuery =
		useLazyLoadQuery<CertificatesScreen_RenewedFachberaterCertificatesQuery>(
			RENEWED_FACHBERATER_CERTIFICATES_QUERY,
			{ fetchPolicy: "network-only" },
		);

	useEffect(() => {
		refetch({
			sortDirection: sortDirectionFilter,
		});

		legacyCertificates.refetch({
			sortDirection: sortDirectionFilter,
		});
	}, [sortDirectionFilter]);

	const certificates = data.CertificateAchievedByUser.certificates.edges?.map((c) => c?.node);

	const legacyCertificatesData =
		legacyCertificates.data.Viewer.WordpressCertificateAchievedByUser.wordPressCertificates.edges?.map(
			(c) => c?.node,
		);
	const renewedCertificates =
		renewedFachberaterCertificatesQuery?.Viewer.FachberaterCertificates
			.renewedFachberaterCertificates;

	interface CertificatesProps {
		date: string;
		name: string;
		url: string | null | undefined;
	}
	const issuedCertificateArray: CertificatesProps[] = certificates
		? certificates?.map((certificate) => ({
				["name"]: certificate?.name!,
				["url"]: certificate?.fileRef?.url!,
				["date"]: certificate?.issuedAt!,
		  }))
		: [];

	const legacyCertificateArray: CertificatesProps[] = legacyCertificatesData
		? legacyCertificatesData?.map((certificate) => ({
				["name"]: certificate?.contentNodeTitle!,
				["url"]: certificate?.certificateFile?.url!,
				["date"]: certificate?.finishedAt!,
		  }))
		: [];

	const renewedFachberaterCertificateArray: CertificatesProps[] = renewedCertificates
		? renewedCertificates?.map((certificate) => ({
				["name"]: certificate?.certificateFile?.name!,
				["url"]: certificate?.certificateFile?.url,
				["date"]: certificate?.issuedAt,
		  }))
		: [];

	const unifiedCertificatesArray = issuedCertificateArray
		.concat(renewedFachberaterCertificateArray)
		.concat(legacyCertificateArray);

	const sortedByDateUnifiedArray = unifiedCertificatesArray.sort((a, b) => {
		return sortDirectionFilter === "asc"
			? a.date.localeCompare(b.date)
			: b.date.localeCompare(a.date);
	});
	return (
		<DefaultContainer
			collapsed={false}
			screenName="certificates"
			userDataFragmentRef={data.Viewer.Auth.currentUser?.user!}
		>
			<MainContainerWithTopBar>
				<h1>Meine Zertifikate</h1>
				<p className="text-base mb-4">
					Hier finden Sie alle Ihre erworbenen Zertifikate zum Herunterladen.
				</p>
				<div className="w-full flex mb-4">
					<Dropdown
						className="ml-auto justify-content-end"
						placeholder="Sort by"
						options={[
							{ value: "asc", label: "Datum aufsteigend" },
							{ value: "desc", label: "Datum absteigend" },
						]}
						value={sortDirectionFilter}
						onChange={(e) => setSortDirectionFilter(e.value)}
					/>
				</div>
				{(certificates && certificates?.length > 0) ||
				(legacyCertificatesData && legacyCertificatesData?.length > 0) ||
				(renewedCertificates && renewedCertificates?.length > 0) ? (
					<div className="flex flex-column align-items-center justify-content-center">
						<div className="grid w-12">
							{sortedByDateUnifiedArray.map((certificate, index) => (
								<div key={index} className={`lg:col-6 col-12 `}>
									<CardContainer>
										<a target="_blank" rel="noopener noreferrer" download href={certificate.url!}>
											<div className="p-1 flex">
												<h4 className="m-0 text-sm">{certificate.name}</h4>
												<div className="ml-auto">
													<span className="font-bold ml-2 pi pi-cloud-download text-primary" />
												</div>
											</div>
										</a>
										<span className="p-1 text-xs">
											{formatDateBasedOnInput(certificate?.date!, false)}
										</span>
									</CardContainer>
								</div>
							))}

							<div className="flex justify-content-center mt-4 w-full">
								{hasNext && <PrimaryButton label="Mehr laden..." onClick={() => loadNext(10)} />}

								{!hasNext && legacyCertificates.hasNext && (
									<PrimaryButton
										label="Mehr laden..."
										onClick={() => legacyCertificates.loadNext(10)}
									/>
								)}
							</div>
						</div>
					</div>
				) : (
					<p className="text-base w-full mt-5 flex justify-content-center align-items-center">
						Sie haben noch keine Zertifikate gesammelt
					</p>
				)}
			</MainContainerWithTopBar>
		</DefaultContainer>
	);
};

const CardContainer = styled(Card)`
	display: flex;
	align-items: center;
	.p-card-body {
		width: 100% !important;
	}
`;
