/**
 * @generated SignedSource<<d76e5e9cd2b21955233893bb2e618aa4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DebugScreen_SendTestEmailMutation$variables = {};
export type DebugScreen_SendTestEmailMutation$data = {
  readonly Auth: {
    readonly testEmail: {
      readonly clientMutationId: string | null;
    } | null;
  };
};
export type DebugScreen_SendTestEmailMutation = {
  response: DebugScreen_SendTestEmailMutation$data;
  variables: DebugScreen_SendTestEmailMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AuthMutations",
    "kind": "LinkedField",
    "name": "Auth",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "input",
            "value": {}
          }
        ],
        "concreteType": "EmailTestPayload",
        "kind": "LinkedField",
        "name": "testEmail",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "clientMutationId",
            "storageKey": null
          }
        ],
        "storageKey": "testEmail(input:{})"
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DebugScreen_SendTestEmailMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DebugScreen_SendTestEmailMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "4e13ae1952eef83938892f5677b3b130",
    "id": null,
    "metadata": {},
    "name": "DebugScreen_SendTestEmailMutation",
    "operationKind": "mutation",
    "text": "mutation DebugScreen_SendTestEmailMutation {\n  Auth {\n    testEmail(input: {}) {\n      clientMutationId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "bf5bf6e4a1b6ef4daac1e97ba4dd02e8";

export default node;
