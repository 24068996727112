import { FormikState } from "formik";
import React, { ReactNode } from "react";

// @ts-ignore
import { classNames } from "primereact/utils";

export interface RenderConfig<FieldType> {
	fieldValue: FieldType | undefined;
	updateField: (newValue: FieldType | undefined) => void;
	fieldName: string;
	required: boolean;
	isValid: boolean;
	placeholder?: string;
	disabled: boolean;
}

interface ValidatedFieldProps<State, FieldType> {
	label?: string;
	name: keyof State & string;
	required?: boolean;
	disabled?: boolean;
	placeholder?: string;
	formikConfig: FormikState<State> & {
		setFieldTouched: (
			field: string,
			touched?: boolean,
			shouldValidate?: boolean | undefined,
		) => any;
		setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any;
	};

	iconClass?: string;
	className?: string;
	helpText?: ReactNode;

	component: (renderConfig: RenderConfig<FieldType>) => ReactNode;
}

export function ValidatedField<State, FieldType>({
	className,
	iconClass,
	name,
	label,
	formikConfig,
	helpText,
	component,
	placeholder,
	required,
	disabled,
}: ValidatedFieldProps<State, FieldType>) {
	const hasError = formikConfig.errors[name] && formikConfig.touched[name];

	const value = formikConfig.values[name] as unknown as FieldType;

	const updateValue = (updatedValue: FieldType | undefined) => {
		formikConfig.setFieldTouched(name, true);
		formikConfig.setFieldValue(name, updatedValue);
	};

	const FieldContent = (
		<>
			{iconClass && <i className={`pi ${iconClass}`} />}

			{component({
				fieldValue: value,
				isValid: !hasError,
				fieldName: name,
				updateField: updateValue,
				required: required || false,
				disabled: disabled || false,
				placeholder,
			})}
		</>
	);

	return (
		<div className={`field ${className || ""}`}>
			{label ? (
				<label htmlFor={name} className={classNames({ "p-error": hasError })}>
					{label} {required ? "*" : ""}
				</label>
			) : null}

			{iconClass ? <span className="p-input-icon-right">{FieldContent}</span> : FieldContent}

			{hasError ? <small className="p-error">{(formikConfig.errors as any)[name]}</small> : null}

			{helpText ? <small>{helpText}</small> : null}
		</div>
	);
}
