/**
 * @generated SignedSource<<d38307e3f6016d780a8eeaa5f49b01f2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NodeInstructorCard_InstructorFragment$data = {
  readonly description: string | null;
  readonly name: string;
  readonly portraitFile: {
    readonly id: string;
    readonly url: string | null;
  } | null;
  readonly title: string | null;
  readonly " $fragmentType": "NodeInstructorCard_InstructorFragment";
};
export type NodeInstructorCard_InstructorFragment$key = {
  readonly " $data"?: NodeInstructorCard_InstructorFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NodeInstructorCard_InstructorFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NodeInstructorCard_InstructorFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "portraitFile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "InstructorImpl",
  "abstractKey": null
};

(node as any).hash = "973e5ee3d17e00768c3ae88c14016230";

export default node;
