import React from "react";
import styled from "styled-components";

interface OwnProps {
	title: string;
	onClick?: () => void;
	modalWidth: number;
}

export function ButtonWithTitle({ title, onClick, modalWidth }: OwnProps) {
	return (
		<StyledButton onClick={onClick} modalWidth={modalWidth}>
			{title}
		</StyledButton>
	);
}

const StyledButton = styled.button<{ modalWidth: number }>`
	margin-left: ${(props) => props.modalWidth * 0.1}px;
	margin-right: ${(props) => props.modalWidth * 0.1}px;
	height: ${(props) => props.modalWidth * 0.125}px;
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: ${(props) => props.modalWidth * 0.045}px;
	font-weight: bold;
	border-radius: 30px;
	border: none;
`;
