/**
 * @generated SignedSource<<ce4b6fadaac0b06098dcfdeb8e2fbb7e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ElementTypeV2 = "clozeText" | "file" | "multipleChoice" | "order" | "podcast" | "randomMultipleChoice" | "text" | "video" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ContentElement_ContentSubmissionFragment$data = {
  readonly definition: {
    readonly currentElementState?: {
      readonly element: {
        readonly elementType: ElementTypeV2;
      };
    } | null;
  };
  readonly " $fragmentSpreads": FragmentRefs<"FileElementPart_ContentSubmissionFragment" | "MultipleChoiceElementPart_ContentSubmissionFragment" | "PodcastElementPart_ContentSubmissionFragment" | "TextElementPart_ContentSubmissionFragment" | "VideoElementPart_ContentSubmissionFragment">;
  readonly " $fragmentType": "ContentElement_ContentSubmissionFragment";
};
export type ContentElement_ContentSubmissionFragment$key = {
  readonly " $data"?: ContentElement_ContentSubmissionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentElement_ContentSubmissionFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentElement_ContentSubmissionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "definition",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "currentElementState",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "element",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "elementType",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "ActiveELearningContentSubmissionDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FileElementPart_ContentSubmissionFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MultipleChoiceElementPart_ContentSubmissionFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PodcastElementPart_ContentSubmissionFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TextElementPart_ContentSubmissionFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "VideoElementPart_ContentSubmissionFragment"
    }
  ],
  "type": "ContentSubmission",
  "abstractKey": null
};

(node as any).hash = "cecec730e4c602474902f9e05b1f59ec";

export default node;
