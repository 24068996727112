import React, { Suspense, useEffect, useState } from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment, useMutation, useQueryLoader } from "react-relay";
import { MultipleChoiceElementPart_SubmitMultipleChoiceElementMutation } from "@generated/MultipleChoiceElementPart_SubmitMultipleChoiceElementMutation.graphql";
import { MultipleChoiceElementPart_ContentSubmissionFragment$key } from "@generated/MultipleChoiceElementPart_ContentSubmissionFragment.graphql";
import { NodeSubmitButton } from "../../node/NodeSubmitButton";
import { ProjectContentElementContainer } from "../../../../infecto-lms-webapp-impl/containers/ProjectContentElement.container";
import {
	MultipleChoiceElementDisplay,
	MultipleChoiceState,
} from "./MultipleChoiceElementDisplay.component";
import { MultipleChoiceElementPart_SubmittedAnswersQuery } from "@generated/MultipleChoiceElementPart_SubmittedAnswersQuery.graphql";
import { ShowUserAnswersSummaryDialog } from "./ShowUserAnswersSummaryDialog.component";

const CONTENT_SUBMISSION_FRAGMENT = graphql`
	fragment MultipleChoiceElementPart_ContentSubmissionFragment on ContentSubmission {
		id
		learnOpportunity {
			id
		}
		definition {
			... on ActiveELearningContentSubmissionDefinition {
				progress {
					percentage
					... on ActiveELearningContentSubmissionProgress {
						isAtLastElement
						numElements
						currentElementIndex
					}
				}

				currentElementState {
					kind

					... on UntouchedElementState {
						element {
							id
							... on MultipleChoiceLearnElement {
								question
								answerOptions {
									answer
								}
								...MultipleChoiceElementDisplay_MultipleChoiceElementV2Fragment
							}
						}
					}

					... on MultipleChoiceInputElementState {
						element {
							id
							... on MultipleChoiceLearnElement {
								answerOptions {
									answer
								}
								...MultipleChoiceElementDisplay_MultipleChoiceElementV2Fragment
							}
						}

						checkedAnswerIndices
					}
				}
			}
		}
		...ProjectContentElementContainer_ContentSubmissionFragment
	}
`;

const SUBMIT_MUTATION = graphql`
	mutation MultipleChoiceElementPart_SubmitMultipleChoiceElementMutation(
		$input: SubmitMultipleChoiceElementV2Input!
	) {
		LearnV2 {
			submitMultipleChoiceElement(input: $input) {
				contentSubmission {
					id
					...ContentSubmissionScreen_ContentSubmissionFragment
				}
			}
		}
	}
`;

export const SUBMITTED_ANSWERS_QUERY = graphql`
	query MultipleChoiceElementPart_SubmittedAnswersQuery($submissionId: ID!) {
		LearnV2 {
			GetSubmittedAnswers(submissionId: $submissionId) {
				question
				answers
			}
		}
	}
`;

interface OwnProps {
	contentSubmissionFragmentRef: MultipleChoiceElementPart_ContentSubmissionFragment$key;
}

export function MultipleChoiceElementPart({ contentSubmissionFragmentRef }: OwnProps) {
	const contentSubmission = useFragment<MultipleChoiceElementPart_ContentSubmissionFragment$key>(
		CONTENT_SUBMISSION_FRAGMENT,
		contentSubmissionFragmentRef,
	);

	const [submitElement, isSubmitting] =
		useMutation<MultipleChoiceElementPart_SubmitMultipleChoiceElementMutation>(SUBMIT_MUTATION);

	const [state, setState] = useState<MultipleChoiceState>({
		checkedAnswerIndices: [
			...(contentSubmission.definition.currentElementState?.checkedAnswerIndices || []),
		],
	});

	useEffect(() => {
		setState({
			checkedAnswerIndices: [
				...(contentSubmission.definition.currentElementState?.checkedAnswerIndices || []),
			],
		});
	}, [
		contentSubmission.definition.currentElementState?.element?.id,
		contentSubmission.definition.currentElementState,
	]);

	if (!contentSubmission.definition.currentElementState) return null;

	const [submittedAnswersQueryRef, loadSubmittedAnswersQuery] =
		useQueryLoader<MultipleChoiceElementPart_SubmittedAnswersQuery>(SUBMITTED_ANSWERS_QUERY);

	const [isUserAnswersSummaryVisible, setIsUserAnswersSummaryVisible] = useState(false);

	const onHideDialog = (): void => {
		setIsUserAnswersSummaryVisible(false);
	};

	const submitLearnElement = () => {
		submitElement({
			variables: {
				input: {
					multipleChoiceElementId: contentSubmission.definition.currentElementState!.element
						?.id as string,
					contentSubmissionId: contentSubmission.id!,
					checkedAnswerIndices: state.checkedAnswerIndices,
				},
			},
		});
	};

	return (
		<ProjectContentElementContainer
			contentSubmissionFragmentRef={contentSubmission}
			nextButton={
				<NodeSubmitButton
					label={
						contentSubmission.definition.progress?.isAtLastElement
							? "Zusammenfassung"
							: "Speichern & weiter"
					}
					disabled={isSubmitting || state.checkedAnswerIndices.length === 0}
					onClick={() => {
						if (contentSubmission.definition.progress?.isAtLastElement) {
							loadSubmittedAnswersQuery(
								{
									submissionId: contentSubmission.id,
								},
								{ fetchPolicy: "network-only" },
							);
							setIsUserAnswersSummaryVisible(true);
						} else {
							submitLearnElement();
						}
					}}
				/>
			}
		>
			<Suspense fallback="Lade...">
				{submittedAnswersQueryRef! && contentSubmission.definition.progress?.isAtLastElement ? (
					<ShowUserAnswersSummaryDialog
						isVisible={isUserAnswersSummaryVisible}
						onHideDialog={onHideDialog}
						queryRef={submittedAnswersQueryRef!}
						submitLearnElement={submitLearnElement}
						totalElementsCount={contentSubmission.definition.progress?.numElements!}
						currentElement={contentSubmission.definition.currentElementState.element}
						checkedAnswerIndices={state.checkedAnswerIndices}
					/>
				) : null}
			</Suspense>

			<MultipleChoiceElementDisplay
				checkedAnswerIndices={state.checkedAnswerIndices}
				setCheckedAnswerIndices={(indices) => setState({ checkedAnswerIndices: indices })}
				multipleChoiceElementFragmentRef={contentSubmission.definition.currentElementState.element!}
				currentElementIndex={contentSubmission.definition.progress?.currentElementIndex!}
			/>
		</ProjectContentElementContainer>
	);
}
