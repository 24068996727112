import React from "react";
import { ThemedStyledFunction } from "styled-components";
import { TypographyFactory } from "../typography.factory";
import { PaletteColor } from "../../../../infecto-lms-webapp-impl/color";
import { TkaTypography } from "../typography";

export class ReactStyledComponentTypographyFactory<
	Element extends keyof JSX.IntrinsicElements,
> extends TypographyFactory {
	private extraStyles?: string = "";
	constructor(
		protected readonly typography: TkaTypography,
		private readonly styledComponentBase: ThemedStyledFunction<Element, any, {}, never>,
	) {
		super(typography);
	}
	with(extraStyles: string): ReactStyledComponentTypographyFactory<Element> {
		this.extraStyles = extraStyles;
		return this;
	}
	create(): React.FC<{ paletteColor: PaletteColor } & JSX.IntrinsicElements[Element]> {
		const base = this.styledComponentBase<{
			paletteColor: PaletteColor;
		}>`
      color: ${(p) => p.paletteColor.rgbValue()};
      ${this.typography.getCSS()};
      ${this.extraStyles}
    `;
		return base as React.FC<{ paletteColor: PaletteColor } & JSX.IntrinsicElements[Element]>;
	}
}
