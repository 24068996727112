import React from "react";
import { useCallback, useContext, useMemo, useState } from "react";
import { DynamicSlideMenuNavigateItem } from "../dynamic-slide-menu-navigate-item";
import { DynamicSlideMenuParent } from "../dynamic-slide-menu-parent";
import { DynamicSlideMenuContext } from "../dynamic-slide-menu-provider";
import { RadioOption } from "../../radio-option";
import { RadioButtonChangeEvent } from "../../radio-option/radio-option.interface";
import { HLSLevelAuto, VideoMenuRoute, VideoSettingsSpeed } from "./video-menu.const";
import { VideoMenuProps } from "./video-menu.interface";

export const VideoMenu = ({
	availableHeights,
	onResolutionChanged,
	onSpeedChanged,
}: VideoMenuProps) => {
	const { goBack } = useContext(DynamicSlideMenuContext);
	const [resolution, setResolution] = useState(HLSLevelAuto);
	const [speed, setSpeed] = useState(VideoSettingsSpeed.S1_0);

	const handleResolutionOnChange = useCallback(
		(event: RadioButtonChangeEvent<number>) => {
			event.checked && setResolution(event.value);
			onResolutionChanged && onResolutionChanged(event.value);
			goBack();
		},
		[goBack, onResolutionChanged],
	);
	const handleSpeedOnChange = useCallback(
		(event: RadioButtonChangeEvent<VideoSettingsSpeed>) => {
			event.checked && setSpeed(event.value);
			onSpeedChanged && onSpeedChanged(event.value);
			goBack();
		},
		[goBack, onSpeedChanged],
	);

	const useLevels = useMemo(() => {
		const slice = (availableHeights || []).slice();
		slice.unshift(HLSLevelAuto);
		return slice;
	}, [availableHeights]);

	return (
		<>
			<DynamicSlideMenuParent
				width={window.innerWidth < 769 ? 18 : 20}
				path={VideoMenuRoute.settings.path}
			>
				<DynamicSlideMenuNavigateItem
					title={"Geschwíndigkeit"}
					path={VideoMenuRoute.settings.speed.path}
					content={speed}
				>
					<DynamicSlideMenuParent
						width={window.innerWidth < 769 ? 18 : 20}
						path={VideoMenuRoute.settings.speed.path}
						title={"Geschwíndigkeit"}
					>
						{Object.values(VideoSettingsSpeed).map((value) => (
							<RadioOption
								key={value}
								label={value}
								value={value}
								checked={speed === value}
								onChange={handleSpeedOnChange}
							/>
						))}
					</DynamicSlideMenuParent>
				</DynamicSlideMenuNavigateItem>
				<DynamicSlideMenuNavigateItem
					title={"Qualität"}
					path={VideoMenuRoute.settings.resolution.path}
					content={resolution === HLSLevelAuto ? "Auto" : `${resolution}p`}
				>
					<DynamicSlideMenuParent
						width={window.innerWidth < 769 ? 18 : 20}
						path={VideoMenuRoute.settings.resolution.path}
						title={"Qualität"}
					>
						{useLevels.map((value) => (
							<RadioOption
								key={value}
								label={value === HLSLevelAuto ? "Auto" : `${value}p`}
								value={value}
								checked={resolution === value}
								onChange={handleResolutionOnChange}
							/>
						))}
					</DynamicSlideMenuParent>
				</DynamicSlideMenuNavigateItem>
			</DynamicSlideMenuParent>
		</>
	);
};
