import React from "react";
import styled from "styled-components";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { MultipleChoiceElementDisplayWithAnswers_MultipleChoiceElementV2Fragment$key } from "@generated/MultipleChoiceElementDisplayWithAnswers_MultipleChoiceElementV2Fragment.graphql";

const MULTIPLE_CHOICE_ELEMENT_FRAGMENT = graphql`
	fragment MultipleChoiceElementDisplayWithAnswers_MultipleChoiceElementV2Fragment on MultipleChoiceElementV2 {
		question
		image {
			url
		}
		answeringType
		answerOptions {
			answer
			image {
				url
			}
		}
	}
`;

const Image = styled.img`
	max-width: 50%;
`;

const AnswerContainer = styled.div`
	max-width: 1024px;
`;

const ASCII = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

interface OwnProps {
	checkedAnswerIndices: number[];
	setCheckedAnswerIndices: (indices: number[]) => void;
	multipleChoiceElementFragmentRef: MultipleChoiceElementDisplayWithAnswers_MultipleChoiceElementV2Fragment$key;
}

export function MultipleChoiceElementDisplayWithAnswers({
	checkedAnswerIndices,
	setCheckedAnswerIndices,
	multipleChoiceElementFragmentRef,
}: OwnProps) {
	const multipleChoiceElement =
		useFragment<MultipleChoiceElementDisplayWithAnswers_MultipleChoiceElementV2Fragment$key>(
			MULTIPLE_CHOICE_ELEMENT_FRAGMENT,
			multipleChoiceElementFragmentRef,
		);

	if (!multipleChoiceElement) return null;

	const toggleAnswer = (index: number) => {
		const newIndices =
			multipleChoiceElement.answeringType === "allowOnlyOneAnswer"
				? []
				: checkedAnswerIndices.filter((x) => x !== index);
		setCheckedAnswerIndices([
			...newIndices,
			...(checkedAnswerIndices.includes(index) ? [] : [index]),
		]);
	};

	return (
		<div className="p-5">
			<h1 dangerouslySetInnerHTML={{ __html: multipleChoiceElement.question }} className="mb-5" />

			{multipleChoiceElement.image?.url && (
				<Image className="mb-5" src={multipleChoiceElement.image.url} alt="" />
			)}

			<div className="mb-3">
				{multipleChoiceElement.answeringType === "allowOnlyOneAnswer" && (
					<strong>Bitte wählen Sie EINE Antwort aus.</strong>
				)}
				{multipleChoiceElement.answeringType === "allowMultipleAnswersAllCorrectMustBeGiven" && (
					<strong>Bitte wählen Sie ALLE zutreffenden Antworten aus.</strong>
				)}
				{multipleChoiceElement.answeringType ===
					"allowMultipleAnswersOnlySomeCorrectMustBeGiven" && (
					<strong>Bitte wählen Sie die richtigen Antworten aus.</strong>
				)}
			</div>

			<AnswerContainer>
				{multipleChoiceElement.answerOptions?.map((ao, index) => (
					<div
						key={`answerOption-${index}`}
						className="cursor-pointer"
						onClick={() => {
							toggleAnswer(index);
						}}
					>
						<div
							key={ao.answer + index}
							className={`${
								checkedAnswerIndices.includes(index) ? "bg-bluegray-200" : ""
							} border-round surface-200 border-400 border-1 mb-3 p-4`}
						>
							<div className="flex align-items-center">
								<div className="pl-3 pr-5 font-bold flex align-items-center text-lg ">
									{ASCII[index]}
								</div>
								<div
									className="flex-grow-1 flex flex-column align-items-center"
									dangerouslySetInnerHTML={{ __html: ao.answer }}
								/>
							</div>
							{ao.image?.url && <Image className="ml-3 mt-3" src={ao.image.url} alt="" />}
						</div>
					</div>
				))}
			</AnswerContainer>
		</div>
	);
}
