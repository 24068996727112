import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { CarousalFeaturedNode_NodeFragment$key } from "@generated/CarousalFeaturedNode_NodeFragment.graphql";
import { useFragment } from "react-relay";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { getLearnOpportunityPath } from "../../infecto-lms-webapp-impl/router/routes/auth/nodes.routes";
import { SINGULAR_TAGS } from "./i18n/singularTags";
import { Button } from "primereact/button";

const FEATURED_NODE_FRAGMENT = graphql`
	fragment CarousalFeaturedNode_NodeFragment on LearnOpportunityV2 {
		id
		nextContentNode {
			typeDefinition {
				... on LearnOpportunityELearningContentTypeDefinition {
					containedElementTypes
				}
			}
		}
		structureDefinition {
			title
			... on LearnOpportunityRootStructureDefinition {
				tags {
					id
					name
				}
			}
		}
		image {
			url
		}
	}
`;

interface OwnProps {
	nodeRef: CarousalFeaturedNode_NodeFragment$key;
}
export const CarousalFeaturedNode = ({ nodeRef }: OwnProps) => {
	const node = useFragment<CarousalFeaturedNode_NodeFragment$key>(FEATURED_NODE_FRAGMENT, nodeRef);

	const navigate = useNavigate();
	if (!node) return null;

	/*	const labelToNextButtonText = (tag: string | null | undefined) => {
		switch (tag) {
			case "VGFnOlRhZzo0MGYyY2VlMy02YTUyLTRhMWMtYTAwMC0zZjk0YWUxOTdmZDY=":
				return "Zur Produktschulung";
			case "VGFnOlRhZzplNjc2YTc1Yy00YTY5LTRhY2EtYjZjMi03ZThjMzI0ODgwYjg=":
				return "Zur Produktschulung";
			case "VGFnOlRhZzphZTk4OWRjNC05MmZiLTQ5ODQtOWRjMy0yOTY0NTZkYjU2ZjY=":
				return "Zur Onlineschulung";
			case "VGFnOnBhZWRpYS10YWctMQ==":
				return "Zum Themenheft";
			case "VGFnOlRhZzoxMDU2YWE4OS1lNTRkLTRiN2QtODIxYy01NTIxNDAxYWQwZDg=":
				return "Zur Lernerfolgskontrolle";
			default:
				return "Zum Modul";
		}
	};*/

	return (
		<div>
			<MainContainer key={node.id}>
				<StyledFeaturedNode imageurl={node.image?.url as string}>
					<GradientWhiteBlue></GradientWhiteBlue>
					<TextAndButtonContainer>
						<Flex2 />
						<TitleContainer className={"lg:flex-1 z-5"}>
							{node.structureDefinition.tags && node.structureDefinition.tags[0] && (
								<Tag className={"text-white text-sm md:text-base  m-0"}>
									{node.structureDefinition &&
									node.structureDefinition.tags &&
									SINGULAR_TAGS[node.structureDefinition.tags[0]?.id]
										? SINGULAR_TAGS[node.structureDefinition.tags[0]?.id]
										: node.structureDefinition.tags[0]?.name}
								</Tag>
							)}
							<Title className={"text-white text-xl md:text-lg lg:text-2xl mt-2 mh-0 mb-5"}>
								{node.structureDefinition.title}
							</Title>
							<ElementButton
								className="bg-white border-none text-primary text-xs md:text-base"
								onClick={() => navigate(getLearnOpportunityPath(node.id))}
							>
								<Label>Zur Fortbildung</Label>
							</ElementButton>
						</TitleContainer>
					</TextAndButtonContainer>
				</StyledFeaturedNode>
			</MainContainer>
		</div>
	);
};
interface StyledFeaturedNodeProps {
	imageurl: string;
}

const MainContainer = styled.div`
	@media screen and (min-width: 481px) and (max-width: 786px) {
		width: 100%;
	}
	@media screen and (max-width: 480px) {
		width: 100%;
	}
`;
const StyledFeaturedNode = styled.div<StyledFeaturedNodeProps>`
	position: relative;
	width: 100%;
	height: 350px;
	background-image: url(${(p) => p.imageurl});
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	border: 1px solid #d5d5d5;
	border-radius: 5px;
	@media screen and (max-width: 480px) {
		height: 300px;
	}
`;

const Flex2 = styled.div`
	flex: 2;
	@media screen and (max-width: 767px) {
		display: none;
	}
`;

const GradientWhiteBlue = styled.div`
	position: absolute;
	z-index: 5;
	width: 100%;
	background: #ffffff;
	background: linear-gradient(
		90deg,
		rgba(255, 255, 255, 0) 40%,
		rgba(0, 153, 204, 0.9) 64%,
		rgba(0, 153, 204, 0.9) 100%
	);
	@media screen and (min-width: 768px) and (max-width: 1024px) {
		background: linear-gradient(
			90deg,
			rgba(255, 255, 255, 0) 20%,
			rgba(0, 153, 204, 0.9) 44%,
			rgba(0, 153, 204, 0.9) 100%
		);
	}
	@media screen and (max-width: 767px) {
		background: linear-gradient(
			0deg,
			rgba(255, 255, 255, 0) 20%,
			rgba(0, 153, 204, 0.9) 64%,
			rgba(0, 153, 204, 0.9) 100%
		);
	}
	height: 100%;
`;

const Tag = styled.p`
	font-size: 18px;
	font-weight: bold;
`;

const Title = styled.p`
	font-size: 36px;
	font-weight: bold;
`;

const TextAndButtonContainer = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	align-items: center;
	@media screen and (min-width: 768px) and (max-width: 1024px) {
		width: 50%;
		margin-left: auto;
		justify-content: flex-end;
	}
	@media screen and (max-width: 767px) and (min-width: 481px) {
		justify-content: center;
		align-items: flex-start;
		padding: 20px;
	}
	@media screen and (max-width: 480px) {
		justify-content: flex-start;
		align-items: flex-start;
		padding: 20px;
	}
`;
const TitleContainer = styled.div`
	padding: 10px 5px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-content: flex-start;
	margin-right: 25px;
	margin-left: 5px;
	@media screen and (max-width: 767px) {
		margin: 0;
	}
`;

const ElementButton = styled(Button)`
	width: fit-content;
	font-weight: bold;
	border-radius: 5px;
`;

const Label = styled.p`
	font-family: "Open Sans", sans-serif;
	font-weight: bold;
	margin: 0px;
`;
