import React, { useEffect, useState } from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useNavigate } from "react-router-dom";
import { useFragment, useQueryLoader } from "react-relay";
import { Knob } from "primereact/knob";
import styled from "styled-components";
import { Message } from "primereact/message";
import { formatDateTime } from "../../infecto-lms-webapp/components/DateTimeDisplay.component";
import { ContentSubmissionFinishedMessageCard } from "./ContentSubmissionFinishedMessageCard.component";
import {
	ContentSubmissionEnd_ContentSubmissionFragment$key,
	RewardKind,
	RewardResultKind,
} from "@generated/ContentSubmissionEnd_ContentSubmissionFragment.graphql";
import { FailedContentButton } from "./FailedContentButton";
import { NavigateDirectToQuestionsAdvanceTreeButton } from "./NavigateDirectToQuestionsAdvanceTreeButton.component";
import { ProjectContentElementContainer } from "../../infecto-lms-webapp-impl/containers/ProjectContentElement.container";
import { singularPluralPointsDisplay } from "../../infecto-lms-webapp/functions/helpers/singularPluralPointsDisplay";
import { PrivateAddressDialog } from "../../infecto-lms-webapp/components/private-address/PrivateAddressDialog.component";
import { ContentSubmissionEnd_UserFragment$key } from "@generated/ContentSubmissionEnd_UserFragment.graphql";
import { Button } from "primereact/button";
import { ContentSubmissionEnd_AnswersResultQuery } from "@generated/ContentSubmissionEnd_AnswersResultQuery.graphql";
import { ShowUserAnswersResultDialog } from "./ShowUserAnswersResultDialog.component";
import { DASHBOARD_PATH } from "../../infecto-lms-webapp-impl/router/routes/auth/dashboard.routes";
import { isPermanentlyBlockedUtil } from "../utils/advancement-result/is-permanently-blocked";
import { isTemporarilyBlockedUntilUtil } from "../../infecto-lms-webapp/utils/advancement-result/is-temporarily-blocked-until";
import { PrimaryButton } from "../../infecto-lms-webapp/components/buttons/PrimaryButton";

const USER_FRAGMENT = graphql`
	fragment ContentSubmissionEnd_UserFragment on User {
		id
		...PrivateAddressDialog_UserFragment
	}
`;

export const ANSWERS_RESULTS_QUERY = graphql`
	query ContentSubmissionEnd_AnswersResultQuery($submissionId: ID!) {
		LearnV2 {
			GetAnswersResult(submissionId: $submissionId) {
				question
				answers
				correctAnswerIndices
				selectedAnswersIndices
			}
		}
	}
`;

const CONTENT_SUBMISSION_FRAGMENT = graphql`
	fragment ContentSubmissionEnd_ContentSubmissionFragment on ContentSubmission {
		id
		learnOpportunity {
			id
			nextContentNode {
				structureDefinition {
					title
				}
				typeDefinition {
					... on LearnOpportunityELearningContentTypeDefinition {
						containedElementTypes
					}
				}
			}
			... on LearnOpportunityV2 {
				nextContentNode {
					structureDefinition {
						title
					}
				}
				root {
					structureDefinition {
						title
						... on LearnOpportunityRootStructureDefinition {
							tags {
								name
							}
							extension {
								... on LearnOpportunityRootExtensionImpl {
									configConsequences {
										rewardExpired
										showAnswerExpired
									}
								}
							}
						}
					}
				}
			}
			typeDefinition {
				... on LearnOpportunityELearningContentTypeDefinition {
					containedElementTypes
					contentNodeAdvancementResult {
						status
						... on CanNotBeRestartedAfterFailedContentNodeAdvancementResult {
							configResults {
								configType

								... on RestartIfFailedContentConfigResult {
									canBeRestarted
								}

								... on NegativeBlockPermanentlyRestartIfFailedContentConfigResult {
									canBeRestarted
								}

								... on NegativeBlockTemporarilyRestartIfFailedContentConfigResult {
									blockedUntil
								}
							}
						}
					}
				}
			}
			...NavigateDirectToQuestionsAdvanceTreeButton_LearnOpportunityV2Fragment
			...NavigateDirectToQuestionsAdvanceContentButton_LearnOpportunityFragment
		}
		definition {
			status

			... on FailedELearningContentSubmissionDefinition {
				relativeProgress {
					numCorrect
					numElements
					percentageCorrect
				}
			}

			... on PassedELearningContentSubmissionDefinition {
				relativeProgress {
					numCorrect
					numElements
					percentageCorrect
				}
				rewardResults {
					kind
					rewardKind
					... on PharmaPointsRewardWasPossibleResult {
						amount
					}
					... on PacPointsRewardWasPossibleResult {
						amount
					}
					... on FachberaterWasAwardedResult {
						certificateName
						issuedCertificate {
							fileRef {
								url
							}
						}
					}
					... on CertificateRewardWasPossibleResult {
						certificateName
						issuedCertificate {
							fileRef {
								url
							}
						}
					}
				}
			}
		}
		...ContentElementContainer_ContentSubmissionFragment
		...FailedContentButton_ContentSubmissionFragment
		...ProjectContentElementContainer_ContentSubmissionFragment
	}
`;

interface OwnProps {
	contentSubmissionFragmentRef: ContentSubmissionEnd_ContentSubmissionFragment$key;
	userFragmentRef: ContentSubmissionEnd_UserFragment$key | null;
}

const RewardsContainer = styled.div`
	max-width: 1024px;
`;

export function ContentSubmissionEnd({ contentSubmissionFragmentRef, userFragmentRef }: OwnProps) {
	const user = useFragment<ContentSubmissionEnd_UserFragment$key>(USER_FRAGMENT, userFragmentRef);
	const navigate = useNavigate();

	window.onpopstate = () => {
		navigate(DASHBOARD_PATH);
	};

	const contentSubmission = useFragment<ContentSubmissionEnd_ContentSubmissionFragment$key>(
		CONTENT_SUBMISSION_FRAGMENT,
		contentSubmissionFragmentRef,
	);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [answersResultQueryRef, loadAnswersResultQuery] =
		useQueryLoader<ContentSubmissionEnd_AnswersResultQuery>(ANSWERS_RESULTS_QUERY);

	useEffect(() => {
		loadAnswersResultQuery({ submissionId: contentSubmission.id });
	}, []);

	const [isUserAnswersResultVisible, setIsUserAnswersResultVisible] = useState(true);

	const rootNodeTagsNames =
		contentSubmission.learnOpportunity?.root?.structureDefinition.tags?.map((t) => t.name) || [];

	const configConsequences =
		contentSubmission.learnOpportunity?.root?.structureDefinition.extension?.configConsequences;

	const nextContentNodeContainedElementsType =
		contentSubmission.learnOpportunity?.nextContentNode?.typeDefinition.containedElementTypes;

	const containedElementTypes =
		contentSubmission.learnOpportunity?.typeDefinition.containedElementTypes;

	const hasContainedElemetTypesMultiplechoiceOrRandomMultiplechoice =
		containedElementTypes?.includes("multipleChoice") ||
		containedElementTypes?.includes("randomMultipleChoice");

	const showAnswerNextNodeIsMultiplechoiceMessage = `Wir hoffen, Sie haben wertvolle Erkenntnisse für Ihren persönlichen Arbeitsalltag mitgenommen und Ihren Wissensschatz erweitert. Wissen wirkt eben am besten, wenn es geteilt wird. \n Wenn Sie möchten, können Sie nun die Fragen zu diesem Modul beantworten. Da die Zertifizierung bereits abgelaufen ist, können Sie sich - fall gewünscht - die richtigen Antworten anzeigen lassen. Klicken Sie dazu auf den entsprechenden Button`;

	const nodeIsNotMultipleChoiceMessage = `Wir hoffen, Sie haben wertvolle Erkenntnisse für Ihren persönlichen Arbeitsalltag mitgenommen und Ihren Wissensschatz erweitert. Wissen wirkt eben am besten, wenn es geteilt wird.`;

	const nextNodeIsMultipleChoiceMessage = `Wir hoffen, Sie haben wertvolle Erkenntnisse für Ihren persönlichen Arbeitsalltag mitgenommen und Ihren Wissensschatz erweitert. Wissen wirkt eben am besten, wenn es geteilt wird.\n
Sie können nun Punkte erwerben, indem Sie die folgende Lernerfolgskontrolle erfolgreich absolvieren. Sie kommen zu den Multiple-Choice-Fragen, wenn Sie unten auf "Zu den Fragen“ klicken - es sei denn, Sie haben das Modul bereits bestanden oder die Fragen sind nach einem Fehlversuch gesperrt.
`;

	const isTemporarilyBlockedUntil = isTemporarilyBlockedUntilUtil(
		contentSubmission.learnOpportunity?.typeDefinition.contentNodeAdvancementResult?.configResults,
	);

	const isPermanentlyBlocked = isPermanentlyBlockedUtil(
		contentSubmission.learnOpportunity?.typeDefinition.contentNodeAdvancementResult?.configResults,
	);

	const rewardingWasPossible = (
		rewardResults: ReadonlyArray<{
			readonly amount?: number;
			readonly certificateName?: string;
			readonly issuedCertificate?: {
				readonly fileRef: {
					readonly url: string | null;
				} | null;
			} | null;
			readonly kind: RewardResultKind;
			readonly pointsPoolName?: string;
			readonly rewardKind: RewardKind;
		}>,
	): boolean => {
		return (
			rewardResults.filter(
				(rewardResult) =>
					rewardResult.kind == "FachberaterWasAwarded" ||
					rewardResult.kind == "PacPointsRewardWasPossibleResult" ||
					rewardResult.kind == "PharmaPointsRewardWasPossibleResult" ||
					rewardResult.kind == "CertificateRewardWasPossibleResult" ||
					rewardResult.kind == "EmailRewardWasPossibleResult",
			).length > 0
		);
	};

	const hasFachberaterWasReward =
		contentSubmission.definition.rewardResults &&
		contentSubmission.definition.rewardResults?.filter((r) =>
			["FachberaterWasAwarded"].includes(r.kind),
		).length > 0;

	const backgroundRewardDefiner = (reward: any) => {
		if (reward.kind === "CertificateRewardWasPossibleResult") {
			return "bg-primary";
		} else return "surface-200";
	};

	const onHideDialog = (): void => {
		setIsUserAnswersResultVisible(false);
	};

	const isBasisseminarModul =
		rootNodeTagsNames.map((tag) => tag.trim().toLowerCase()).indexOf("basisseminar") > -1;

	return (
		<ProjectContentElementContainer
			hideBack
			contentSubmissionFragmentRef={contentSubmission}
			nextButton={
				contentSubmission.learnOpportunity && (
					<div className="flex flex-row">
						<NavigateDirectToQuestionsAdvanceTreeButton
							learnOpportunityFragmentRef={contentSubmission.learnOpportunity}
							showFinished={true}
						/>
						{hasFachberaterWasReward && (
							<PrimaryButton onClick={() => navigate(DASHBOARD_PATH)} label="Zur Startseite" />
						)}
						<FailedContentButton contentSubmissionRef={contentSubmission} />
						{/*	<Button
							onClick={() => {
								loadAnswersResultQuery(
									{
										submissionId: contentSubmission.id,
									},
									{ fetchPolicy: "network-only" },
								);

								setIsUserAnswersResultVisible(true);
							}}
							className="bg-secondary ml-0 md:ml-3"
							label="Antworten ansehen"
						/>*/}
						{answersResultQueryRef! && (
							<ShowUserAnswersResultDialog
								queryRef={answersResultQueryRef!}
								isVisible={isUserAnswersResultVisible}
								onHideDialog={onHideDialog}
							/>
						)}
					</div>
				)
			}
		>
			<div className="flex flex-column justify-content-center align-items-center flex-grow-1 p-5 md:pt-3 lg:pt-8">
				{contentSubmission.definition.relativeProgress &&
					hasContainedElemetTypesMultiplechoiceOrRandomMultiplechoice && (
						<div className="relative flex justify-content-center align-items-center mb-3">
							<Knob
								min={0}
								max={100}
								strokeWidth={10}
								size={window.innerWidth > 1024 ? 200 : 150}
								value={contentSubmission.definition.relativeProgress.percentageCorrect}
								showValue={false}
							/>

							<div className="absolute flex justify-content-center flex-column">
								<div className="text-lg font-bold text-center">
									{contentSubmission.definition.relativeProgress.numCorrect} /{" "}
									{contentSubmission.definition.relativeProgress.numElements}
								</div>
								{hasContainedElemetTypesMultiplechoiceOrRandomMultiplechoice && (
									<div className="text-center">korrekt</div>
								)}
							</div>
						</div>
					)}

				{hasContainedElemetTypesMultiplechoiceOrRandomMultiplechoice &&
					(contentSubmission.definition.status === "passed" ? (
						<div className="mb-2">
							<h1 className="text-center mt-2 lg:mt-4">Herzlichen Glückwunsch</h1>
							<p className="text-lg font-bold text-center">
								Sie haben die Lernerfolgskontrolle „
								{contentSubmission.learnOpportunity?.root?.structureDefinition.title}“ erfolgreich
								bestanden.
							</p>
							{hasFachberaterWasReward && (
								<div>
									<p className="text-lg text-center">
										Ihr persönliches Zertifikat „Fachberater* in Pädiatrie“ wird in den nächsten 2-3
										Wochen postalisch an die von Ihnen hinterlegte Apothekenanschrift gesendet.
									</p>
								</div>
							)}
						</div>
					) : (
						<div>
							<h1 className="text-center">Schade.</h1>
							<p className="text-lg text-center">Leider nicht bestanden.</p>
						</div>
					))}

				{isBasisseminarModul &&
					contentSubmission.definition.status === "passed" &&
					rewardingWasPossible(contentSubmission.definition.rewardResults ?? []) && (
						<PrivateAddressDialog userFragment={user} />
					)}

				{hasContainedElemetTypesMultiplechoiceOrRandomMultiplechoice &&
					contentSubmission.definition.status === "passed" &&
					!rewardingWasPossible(contentSubmission.definition.rewardResults ?? []) && (
						<Message
							className="mb-5"
							severity="warn"
							content="Für dieses Modul wurden keine Punkte / Zertifikate vergeben."
						/>
					)}

				{isTemporarilyBlockedUntil && (
					<Message
						className="mb-5"
						severity="error"
						content={`Durch die Falschbeantwortung wird dieses Modul bis ${formatDateTime(
							isTemporarilyBlockedUntil.blockedUntil as string,
						)} Uhr gesperrt.`}
					/>
				)}

				{isPermanentlyBlocked && (
					<Message
						className="mb-5"
						severity="error"
						content={
							isBasisseminarModul ? (
								<>
									Sie haben keinen weiteren Versuch mehr. Bitte wenden Sie sich an{"  "}
									<a className="underline ml-1 text-primary" href="mailto:kontakt@paedia.de">
										kontakt@paedia.de
									</a>
									.
								</>
							) : (
								"Sie haben keinen weiteren Versuch mehr."
							)
						}
					/>
				)}

				{rewardingWasPossible(contentSubmission.definition.rewardResults ?? []) && (
					<RewardsContainer className="w-12 mt-6">
						{contentSubmission.definition.rewardResults
							?.filter((r) =>
								[
									"PacPointsRewardWasPossibleResult",
									"CertificateRewardWasPossibleResult",
									"PharmaPointsRewardWasPossibleResult",
								].includes(r.kind),
							)
							.map((reward, index) => (
								<div
									key={"reward-" + index}
									className={`border-round border-400 border-1 flex mb-3 p-2 lg:p-4 align-items-center justify-content-center  ${backgroundRewardDefiner(
										reward,
									)}`}
								>
									{reward.kind == "PacPointsRewardWasPossibleResult" && (
										<div className="text-base lg:text-xl font-bold">
											Sie haben {reward.amount} PAC {singularPluralPointsDisplay(reward.amount!)}{" "}
											erhalten!
										</div>
									)}

									{reward.kind === "PharmaPointsRewardWasPossibleResult" && (
										<div className="text-base lg:text-xl font-bold">
											Sie haben {reward.amount} BAK {singularPluralPointsDisplay(reward.amount!)}{" "}
											erhalten!
										</div>
									)}

									{reward.kind === "CertificateRewardWasPossibleResult" && (
										<a
											target="_blank"
											rel="noopener noreferrer"
											href={reward.issuedCertificate?.fileRef?.url || ""}
										>
											<div className="text-base text-white lg:text-xl font-bold text-center">
												Sie haben ein {reward.certificateName} erhalten! Hier klicken zum
												Herunterladen.
											</div>
										</a>
									)}
								</div>
							))}
						{hasFachberaterWasReward &&
							contentSubmission.definition.rewardResults
								?.filter((r) => ["FachberaterWasAwarded"].includes(r.kind))
								.map((reward) => (
									<div key={reward.kind}>
										<div
											className={`border-round border-400 border-1 flex mb-3 p-2 lg:p-4 align-items-center justify-content-center surface-200
								}`}
										>
											{" "}
											<div className="text-base lg:text-xl font-bold ">
												Sie sind jetzt Fachberater
											</div>
										</div>

										<Button
											onClick={() => window.open(reward.issuedCertificate?.fileRef?.url as string)}
											className={
												"flex w-full bg-primary mb-3 p-2 lg:p-4 border-round border-400 border-1"
											}
										>
											<div className="text-base w-full text-white lg:text-xl font-bold text-center">
												Zertifikat downloaden
											</div>
										</Button>
									</div>
								))}
					</RewardsContainer>
				)}

				{configConsequences?.rewardExpired &&
					!configConsequences.showAnswerExpired &&
					!containedElementTypes?.includes("multipleChoice") &&
					!containedElementTypes?.includes("randomMultipleChoice") && (
						<ContentSubmissionFinishedMessageCard message={nodeIsNotMultipleChoiceMessage} />
					)}
				{configConsequences?.rewardExpired &&
					configConsequences.showAnswerExpired &&
					(nextContentNodeContainedElementsType?.includes("multipleChoice") ||
					nextContentNodeContainedElementsType?.includes("randomMultipleChoice") ? (
						<ContentSubmissionFinishedMessageCard
							message={showAnswerNextNodeIsMultiplechoiceMessage}
						/>
					) : !containedElementTypes?.includes("multipleChoice") &&
					  !containedElementTypes?.includes("randomMultipleChoice") ? (
						<ContentSubmissionFinishedMessageCard message={nodeIsNotMultipleChoiceMessage} />
					) : null)}
				{contentSubmission.definition.status === "passed" &&
					!rewardingWasPossible(contentSubmission.definition.rewardResults ?? []) &&
					!configConsequences?.rewardExpired && (
						<>
							{" "}
							{nextContentNodeContainedElementsType?.includes("multipleChoice") ||
							nextContentNodeContainedElementsType?.includes("randomMultipleChoice") ? (
								<ContentSubmissionFinishedMessageCard message={nextNodeIsMultipleChoiceMessage} />
							) : (
								!containedElementTypes?.includes("multipleChoice") &&
								!containedElementTypes?.includes("randomMultipleChoice") && (
									<ContentSubmissionFinishedMessageCard message={nodeIsNotMultipleChoiceMessage} />
								)
							)}
						</>
					)}
				{contentSubmission.definition.status === "passed" &&
					rewardingWasPossible(contentSubmission.definition.rewardResults ?? []) &&
					!configConsequences?.rewardExpired && (
						<>
							{" "}
							{nextContentNodeContainedElementsType?.includes("multipleChoice") ||
							nextContentNodeContainedElementsType?.includes("randomMultipleChoice") ? (
								<ContentSubmissionFinishedMessageCard message={nextNodeIsMultipleChoiceMessage} />
							) : (
								!containedElementTypes?.includes("multipleChoice") &&
								!containedElementTypes?.includes("randomMultipleChoice") && (
									<ContentSubmissionFinishedMessageCard message={nodeIsNotMultipleChoiceMessage} />
								)
							)}
						</>
					)}
			</div>
		</ProjectContentElementContainer>
	);
}
