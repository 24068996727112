import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { getLearnOpportunityPath } from "../../../infecto-lms-webapp-impl/router/routes/auth/nodes.routes";
import { NodeBreadcrumbs_NodeFragment$key } from "@generated/NodeBreadcrumbs_NodeFragment.graphql";
import { LMS_WEBAPP_CONFIG_IMPL } from "../../../infecto-lms-webapp-impl/config";
import { SEARCH_SCREEN_PATH } from "../../../infecto-lms-webapp-impl/router/routes/auth/search.routes";

const LEARN_OPPORTUNITY_FRAGMENT = graphql`
	fragment NodeBreadcrumbs_NodeFragment on LearnOpportunityV2 {
		id
		path {
			id
			structureDefinition {
				title
			}
		}
		structureDefinition {
			title
		}
	}
`;

interface Breadcrumb {
	path?: string;
	label: string;
}

interface OwnProps {
	learnOpportunityFragmentRef: NodeBreadcrumbs_NodeFragment$key;
}

const BreadcrumbList = styled.ol`
	list-style: none;
	display: flex;
	align-content: center;
	padding: 0;
	flex-wrap: wrap;
`;

const BreadcrumbListItem = styled.li`
	margin-right: 10px;
	padding-right: 15px;
	position: relative;

	a {
		color: var(--primary-color);
	}

	&:not(:last-child):after {
		position: absolute;
		right: 0;
		content: ">";
	}
`;

export function NodeBreadcrumbs({ learnOpportunityFragmentRef }: OwnProps) {
	const learnOpportunity = useFragment<NodeBreadcrumbs_NodeFragment$key>(
		LEARN_OPPORTUNITY_FRAGMENT,
		learnOpportunityFragmentRef,
	);

	const breadcrumbs: Breadcrumb[] = [
		{
			path: "/",
			label:
				LMS_WEBAPP_CONFIG_IMPL.brandId ===
				"QnJhbmQ6QnJhbmQ6ZTNiMTNlYzktNzZjMC00YTUyLWJkNDAtZjIxYWViMTU3MzJj"
					? "Alle Inhalte"
					: "Dashboard",
		},
		{ path: SEARCH_SCREEN_PATH, label: "Module" },
	];

	learnOpportunity.path.forEach((p) =>
		breadcrumbs.push({
			path: getLearnOpportunityPath(p.id),
			label: p.structureDefinition.title,
		}),
	);

	breadcrumbs.push({
		label: learnOpportunity.structureDefinition.title,
	});

	return (
		<BreadcrumbList>
			{breadcrumbs.map((bc, index) => (
				<BreadcrumbListItem key={"bc" + index}>
					{bc.path && <NavLink to={bc.path}>{bc.label}</NavLink>}
					{!bc.path && bc.label}
				</BreadcrumbListItem>
			))}
		</BreadcrumbList>
	);
}
