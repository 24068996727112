import ReactPlayer from "react-player";
import styled from "styled-components";
import screenfull from "screenfull";
import { SliderAtom } from "../SliderAtom.component";
import { basic80, white } from "../../../../infecto-lms-webapp-impl/color";

const definedHeightVideoPlayer = "max-height: calc(100vh - 300px)";
export const Wrapper = styled.div<{ fullScreen: boolean; hideMouse: boolean }>`
	display: flex;
    flex-grow-1;
	${({ fullScreen }) => (fullScreen ? "max-height: 100%" : definedHeightVideoPlayer)};
	position: relative;
	inset: 0;
	background-color: rgb(226 232 240);
	${({ hideMouse }) => hideMouse && "cursor: none;"}
	${({ fullScreen }) =>
		fullScreen &&
		!screenfull.isEnabled &&
		"position: fixed; background-color: rgb(226 232 240); inset: 0px;"}
	${({ fullScreen }) => fullScreen && "justify-content: center;" + "align-items: center "}
`;

export const CenterWrapper = styled.div<{ fullScreen: boolean }>`
	position: relative;
	${({ fullScreen }) => (fullScreen ? "max-height: 100%" : definedHeightVideoPlayer)};
	flex-grow: 1;
	border-radius: 10px;
	overflow: hidden;
	max-width: ${({ fullScreen }) => (fullScreen ? "calc( 100dvh * 16/9 )" : "100%")};
	${({ fullScreen }) => fullScreen && "border-radius: none"}
`;

export const StyledReactPlayer = styled(ReactPlayer)`
	max-height: 100%;
	aspect-ratio: 16 / 9;
`;

export const Mask = styled.div<{ showBottomControls?: boolean }>`
	width: 100%;
	height: 100%;
	position: absolute;
	inset: 0px;
	${({ showBottomControls }) => showBottomControls && "background-color: rgba(0, 0, 0, 0.5)"}
`;

export const Overlay = styled.div<{
	hidden?: boolean;
	showBottomControls?: boolean;
	hideControls: boolean;
}>`
	display: flex;
	position: absolute;
	inset: 0px;
	flex-direction: column;
	padding-bottom: 0.75rem;
	padding-left: 0.75rem;
	padding-right: 0.75rem;
	transition-property: opacity;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	transition-duration: 150ms;
	transition-duration: 100ms;
	${({ hidden }) => hidden && "display: none"}
	&.video-controls .bottom-controls {
		${({ showBottomControls }) => !showBottomControls && "display: none"}
	}
	&.bottom-controls {
		${({ showBottomControls }) => !showBottomControls && "display: none"}
		@media screen and  (min-width: 1440px) {
			display: none;
		}
	}
	&.video-controls:hover .bottom-controls {
		@media screen and (min-width: 1440px) {
			${({ hideControls }) => (hideControls ? "display: none" : "display: block")}
		}
	}
`;

export const TopControls = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	min-height: 0px;
	gap: 1.5rem;
	padding-left: 0.75rem;
	padding-right: 0.75rem;
	padding-top: 0.75rem;
	@media screen and (orientation: landscape) {
		padding-left: 1.5rem;
		padding-right: 1.5rem;
		padding-top: 1.5rem;
	}
	@media screen and (max-width: 480px) {
		padding: 0.5rem;
	}
`;

export const TopRightControls = styled.div<{ showControls?: boolean }>`
	display: flex;
	flex-direction: row;
	gap: 1.5rem;
	${({ showControls }) => !showControls && "display: none"};
`;
export const CenterControls = styled.div`
	display: flex;
	justify-content: flex-end;
	flex-grow: 1;
	min-height: 0px;
	padding: 0.75rem;
`;

export const CenterPlayControls = styled.div<{ hidden?: boolean }>`
	display: flex;
	flex-direction: row;
	align-items: center;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	gap: 3rem;
	@media screen and (max-width: 767px) {
		gap: 1.5rem;
	}
	${({ hidden }) => hidden && "display: none"};
	& > * {
		cursor: pointer;
	}
`;

export const CenterPlayBackground = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100px;
	height: 100px;
	@media screen and (max-width: 480px) {
		width: 70px;
		height: 70px;
	}
`;

export const CenterRewindBackground = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 2.75rem;
	height: 2.75rem;
`;

export const BottomControls = styled.div<{ hidden?: boolean }>`
	display: none;
	flex-direction: column;
	${({ hidden }) => hidden && "display: flex"};
	padding-left: 0.75rem;
	padding-right: 0.75rem;
	padding-bottom: 0.75rem;
`;

export const MenuWrapperAnchor = styled.div`
	position: relative;
`;

export const MenuWrapper = styled.div`
	display: flex;
	position: absolute;
	bottom: 0;
	right: 0;
	max-height: 100%;
	z-index: 10;
`;

export const ControlsWrapper = styled.div`
	display: flex;
	flex-direction: row;
	gap: 3rem;
	align-items: center;
	justify-content: flex-end;
`;

export const SmallVideoTime = styled.div`
	padding-bottom: 0.75rem;
`;

export const VolumeWrapper = styled.div`
	position: relative;
`;

export const VolumeSliderWrapper = styled.div`
	position: absolute;
	bottom: 4rem;
	margin-left: -1rem;
	background: rgb(35, 36, 35);
	border-radius: 10px;
	padding: 3rem;
`;

export const VolumeSlider = styled(SliderAtom)`
	height: 7rem;
	.p-slider-range {
		background: rgb(255, 255, 255);
		border-radius: 3px;
	}

	.p-slider-handle {
		background: ${white.rgbValue()};
		border-radius: 50%;
		border-color: ${white.rgbValue()};
		transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
	}

	.p-slider-handle:focus {
		outline: 0 none;
		outline-offset: 0;
		box-shadow: 0 0 0 0.2rem ${white.rgbValue()};
	}

	&.p-slider:not(.p-disabled) .p-slider-handle:hover {
		background: ${basic80.rgbValue()};
		border-color: ${white.rgbValue()};
	}
`;
