import React from "react";
import { PrivateRouteDefinition } from "../../../../infecto-lms-webapp/router/interfaces";
import { AnswersScreen } from "../../../../app/screens/Answers.screen";
import { ContentSubmissionScreen } from "../../../../app/screens/ContentSubmission.screen";

export const LEARN_OPPORTUNITY_ID_URL_PARAM = ":learnOpportunityId";
export const getAnswersPath = (learnOpportunityId: string) => {
	return `/learn/show-answers/${learnOpportunityId}`;
};

export const CONTENT_SUBMISSION_ID_URL_PARAM = ":contentSubmissionId";
export const getContentSubmissionPath = (contentSubmissionId: string) => {
	return `/learn/${contentSubmissionId}`;
};

export const LearnRoutes: PrivateRouteDefinition[] = [
	{
		path: getAnswersPath(LEARN_OPPORTUNITY_ID_URL_PARAM),
		component: <AnswersScreen />,
	},
	{
		path: getContentSubmissionPath(CONTENT_SUBMISSION_ID_URL_PARAM),
		component: <ContentSubmissionScreen />,
	},
];
