/**
 * @generated SignedSource<<8e491438526ea0c803e11bfd4d91f6f4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ContentNodeAdvancementStatus = "BlockedByTree" | "CanBeRestartedAfterFailed" | "CanBeRestartedAfterPassed" | "CanBeStarted" | "CanNotBeRestartedAfterFailed" | "CanNotBeRestartedAfterPassed" | "CanNotBeStarted" | "Continue" | "NotContentNode" | "ViolatesTreeFlow" | "%future added value";
export type ContentSubmissionStatus = "active" | "failed" | "passed" | "%future added value";
export type ElementTypeV2 = "clozeText" | "file" | "multipleChoice" | "order" | "podcast" | "randomMultipleChoice" | "text" | "video" | "%future added value";
export type RestartIfFailedContentConfigType = "RestartIfFailedContent_BlockPermanently" | "RestartIfFailedContent_BlockPermanently_WhenRewarded" | "RestartIfFailedContent_BlockTemporarily" | "RestartIfFailedContent_BlockTemporarily_WhenRewarded" | "RestartIfFailedContent_CanNot" | "%future added value";
export type RewardKind = "Certificate" | "Email" | "MakeFachberater" | "PacPoints" | "PharmaPoints" | "Points" | "%future added value";
export type RewardResultKind = "CertificateRewardAlreadyRewardedResult" | "CertificateRewardWasNotPossibleResult" | "CertificateRewardWasPossibleResult" | "EmailRewardAlreadyRewardedResult" | "EmailRewardWasNotPossibleResult" | "EmailRewardWasPossibleResult" | "FachberaterWasAwarded" | "MakeFachberaterRewardAlreadyRewardedResult" | "MakeFachberaterRewardWasNotPossibleResult" | "PacPointsRewardAlreadyRewardedResult" | "PacPointsRewardWasNotPossibleResult" | "PacPointsRewardWasPossibleResult" | "PharmaPointsRewardAlreadyRewardedResult" | "PharmaPointsRewardWasNotPossibleResult" | "PharmaPointsRewardWasPossibleResult" | "PointsRewardAlreadyRewardedResult" | "PointsRewardWasNotPossibleResult" | "PointsRewardWasPossibleResult" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ContentSubmissionEnd_ContentSubmissionFragment$data = {
  readonly definition: {
    readonly relativeProgress?: {
      readonly numCorrect: number;
      readonly numElements: number;
      readonly percentageCorrect: number;
    };
    readonly rewardResults?: ReadonlyArray<{
      readonly amount?: number;
      readonly certificateName?: string;
      readonly issuedCertificate?: {
        readonly fileRef: {
          readonly url: string | null;
        } | null;
      } | null;
      readonly kind: RewardResultKind;
      readonly rewardKind: RewardKind;
    }>;
    readonly status: ContentSubmissionStatus;
  };
  readonly id: string;
  readonly learnOpportunity: {
    readonly id: string;
    readonly nextContentNode: {
      readonly structureDefinition: {
        readonly title: string;
      };
      readonly typeDefinition: {
        readonly containedElementTypes?: ReadonlyArray<ElementTypeV2>;
      };
    } | null;
    readonly root: {
      readonly structureDefinition: {
        readonly extension?: {
          readonly configConsequences?: {
            readonly rewardExpired: boolean;
            readonly showAnswerExpired: boolean;
          };
        };
        readonly tags?: ReadonlyArray<{
          readonly name: string;
        }>;
        readonly title: string;
      };
    } | null;
    readonly typeDefinition: {
      readonly containedElementTypes?: ReadonlyArray<ElementTypeV2>;
      readonly contentNodeAdvancementResult?: {
        readonly configResults?: ReadonlyArray<{
          readonly blockedUntil?: string;
          readonly canBeRestarted: boolean;
          readonly configType: RestartIfFailedContentConfigType;
        }>;
        readonly status: ContentNodeAdvancementStatus;
      };
    };
    readonly " $fragmentSpreads": FragmentRefs<"NavigateDirectToQuestionsAdvanceContentButton_LearnOpportunityFragment" | "NavigateDirectToQuestionsAdvanceTreeButton_LearnOpportunityV2Fragment">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"ContentElementContainer_ContentSubmissionFragment" | "FailedContentButton_ContentSubmissionFragment" | "ProjectContentElementContainer_ContentSubmissionFragment">;
  readonly " $fragmentType": "ContentSubmissionEnd_ContentSubmissionFragment";
};
export type ContentSubmissionEnd_ContentSubmissionFragment$key = {
  readonly " $data"?: ContentSubmissionEnd_ContentSubmissionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentSubmissionEnd_ContentSubmissionFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "containedElementTypes",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "ELearningContentSubmissionRelativeProgress",
  "kind": "LinkedField",
  "name": "relativeProgress",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numCorrect",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numElements",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "percentageCorrect",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "amount",
    "storageKey": null
  }
],
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "certificateName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "IssuedCertificateV2",
    "kind": "LinkedField",
    "name": "issuedCertificate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "File",
        "kind": "LinkedField",
        "name": "fileRef",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "url",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentSubmissionEnd_ContentSubmissionFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "LearnOpportunityV2",
      "kind": "LinkedField",
      "name": "learnOpportunity",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "LearnOpportunityV2",
          "kind": "LinkedField",
          "name": "nextContentNode",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "structureDefinition",
              "plural": false,
              "selections": [
                (v1/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "typeDefinition",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v2/*: any*/)
                  ],
                  "type": "LearnOpportunityELearningContentTypeDefinition",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "LearnOpportunityV2",
          "kind": "LinkedField",
          "name": "root",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "structureDefinition",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Tag",
                      "kind": "LinkedField",
                      "name": "tags",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "name",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "extension",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "InfectopharmTreeConfigConsequences",
                              "kind": "LinkedField",
                              "name": "configConsequences",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "rewardExpired",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "showAnswerExpired",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "type": "LearnOpportunityRootExtensionImpl",
                          "abstractKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "LearnOpportunityRootStructureDefinition",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "typeDefinition",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "contentNodeAdvancementResult",
                  "plural": false,
                  "selections": [
                    (v3/*: any*/),
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": null,
                          "kind": "LinkedField",
                          "name": "configResults",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "configType",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "canBeRestarted",
                              "storageKey": null
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "blockedUntil",
                                  "storageKey": null
                                }
                              ],
                              "type": "NegativeBlockTemporarilyRestartIfFailedContentConfigResult",
                              "abstractKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "type": "CanNotBeRestartedAfterFailedContentNodeAdvancementResult",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "LearnOpportunityELearningContentTypeDefinition",
              "abstractKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "NavigateDirectToQuestionsAdvanceTreeButton_LearnOpportunityV2Fragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "NavigateDirectToQuestionsAdvanceContentButton_LearnOpportunityFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "definition",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            (v4/*: any*/)
          ],
          "type": "FailedELearningContentSubmissionDefinition",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "rewardResults",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "kind",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "rewardKind",
                  "storageKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": (v5/*: any*/),
                  "type": "PharmaPointsRewardWasPossibleResult",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": (v5/*: any*/),
                  "type": "PacPointsRewardWasPossibleResult",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": (v6/*: any*/),
                  "type": "FachberaterWasAwardedResult",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": (v6/*: any*/),
                  "type": "CertificateRewardWasPossibleResult",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "PassedELearningContentSubmissionDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContentElementContainer_ContentSubmissionFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FailedContentButton_ContentSubmissionFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectContentElementContainer_ContentSubmissionFragment"
    }
  ],
  "type": "ContentSubmission",
  "abstractKey": null
};
})();

(node as any).hash = "df28e84e3002e247285b277d2ea3b731";

export default node;
