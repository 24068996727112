/**
 * @generated SignedSource<<dc3980c03c6e6054c9e6be77cf281f87>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ElementTypeV2 = "clozeText" | "file" | "multipleChoice" | "order" | "podcast" | "randomMultipleChoice" | "text" | "video" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProjectContentElementContainer_ContentSubmissionFragment$data = {
  readonly definition: {
    readonly canGoToPrevious?: boolean;
    readonly progress?: {
      readonly percentage: number;
    };
  };
  readonly id: string;
  readonly learnOpportunity: {
    readonly id: string;
    readonly root: {
      readonly id: string;
    } | null;
    readonly typeDefinition: {
      readonly containedElementTypes?: ReadonlyArray<ElementTypeV2>;
    };
    readonly " $fragmentSpreads": FragmentRefs<"AttachmentDisplay_LearnOpportunityV2Fragment">;
  } | null;
  readonly " $fragmentType": "ProjectContentElementContainer_ContentSubmissionFragment";
};
export type ProjectContentElementContainer_ContentSubmissionFragment$key = {
  readonly " $data"?: ProjectContentElementContainer_ContentSubmissionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectContentElementContainer_ContentSubmissionFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectContentElementContainer_ContentSubmissionFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "definition",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "canGoToPrevious",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "progress",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "percentage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "ActiveELearningContentSubmissionDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LearnOpportunityV2",
      "kind": "LinkedField",
      "name": "learnOpportunity",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "LearnOpportunityV2",
          "kind": "LinkedField",
          "name": "root",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "typeDefinition",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "containedElementTypes",
                  "storageKey": null
                }
              ],
              "type": "LearnOpportunityELearningContentTypeDefinition",
              "abstractKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AttachmentDisplay_LearnOpportunityV2Fragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ContentSubmission",
  "abstractKey": null
};
})();

(node as any).hash = "3d2b96543098454c40ff5d2fdae8dc4f";

export default node;
