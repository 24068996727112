import { NavLink } from "react-router-dom";
import { Menu } from "primereact/menu";
import React from "react";
import { MAIN_MENU_ITEMS } from "../../../infecto-lms-webapp-impl/impl-main-menu-items";
import { SideMenuFooter } from "../../../infecto-lms-webapp-impl/components/SideMenuFooter.component";
import { ProjectLogoComponent } from "../../../infecto-lms-webapp-impl/components/ProjectLogo.component";
import { LMS_WEBAPP_CONFIG_IMPL } from "../../../infecto-lms-webapp-impl/config";
import styled from "styled-components";

interface OwnProps {
	className: string;
	collapsed: boolean;
}

export function MainSidebar({ className, collapsed }: OwnProps) {
	return (
		<DivContainer className={"flex-column " + (className || "")}>
			{LMS_WEBAPP_CONFIG_IMPL.showLogoInSideBar && (
				<div className="p-4 text-center">
					<NavLink to="/">
						{!collapsed && <ProjectLogoComponent width={150} />}
						{collapsed && <ProjectLogoComponent width={50} />}
					</NavLink>
				</div>
			)}

			<div className="flex-grow-1 flex p-4">
				<Menu
					className="flex-grow-1 border-0"
					style={{ width: "100%" }}
					model={MAIN_MENU_ITEMS("SideMenu", collapsed)}
				/>
			</div>

			{!collapsed && <SideMenuFooter />}
		</DivContainer>
	);
}
const DivContainer = styled.div`
	overflow-y: auto;
	overflow-x: hidden;
	flex-shrink: 0;
	max-height: calc(100vh - 10px);
	@media only screen and (max-width: 1024px) {
		max-width: 275px;
	}
`;
