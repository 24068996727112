/**
 * @generated SignedSource<<0767670141d8b725f5b2ea3dd58459ee>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CertificateAchievedCursorSortDirectionInput = "asc" | "desc" | "%future added value";
export type CertificatesScreen_Query$variables = {
  sortDirection?: CertificateAchievedCursorSortDirectionInput | null;
};
export type CertificatesScreen_Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CertificatesScreen_QueryFragment" | "CertificatesScreen_WordpressCertificatesQueryFragment">;
};
export type CertificatesScreen_Query = {
  response: CertificatesScreen_Query$data;
  variables: CertificatesScreen_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sortDirection"
  }
],
v1 = {
  "kind": "Variable",
  "name": "sortDirection",
  "variableName": "sortDirection"
},
v2 = {
  "kind": "Literal",
  "name": "first",
  "value": 10
},
v3 = [
  (v2/*: any*/),
  (v1/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  },
  (v4/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = [
  "sortDirection"
],
v10 = [
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CertificatesScreen_Query",
    "selections": [
      {
        "args": [
          (v1/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "CertificatesScreen_QueryFragment"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "CertificatesScreen_WordpressCertificatesQueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CertificatesScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CertificateAchievedByUserQueries",
        "kind": "LinkedField",
        "name": "CertificateAchievedByUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "IssuedCertificateConnection",
            "kind": "LinkedField",
            "name": "certificates",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "IssuedCertificateEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "IssuedCertificateV2",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "File",
                        "kind": "LinkedField",
                        "name": "fileRef",
                        "plural": false,
                        "selections": (v5/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "issuedAt",
                        "storageKey": null
                      },
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v3/*: any*/),
            "filters": (v9/*: any*/),
            "handle": "connection",
            "key": "CertificatesScreen_certificates",
            "kind": "LinkedHandle",
            "name": "certificates"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthViewerSchema",
            "kind": "LinkedField",
            "name": "Auth",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CurrentUser",
                "kind": "LinkedField",
                "name": "currentUser",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "extension",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "salutation",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "title",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "lastName",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "firstName",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "efnNumber",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "globalUserStatus",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "country",
                                "storageKey": null
                              }
                            ],
                            "type": "InfectopharmUserExtensionImpl",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "WordpressCertificateAchievedByUserViewerSchema",
            "kind": "LinkedField",
            "name": "WordpressCertificateAchievedByUser",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v10/*: any*/),
                "concreteType": "WordpressCertificateAndLearnStateConnection",
                "kind": "LinkedField",
                "name": "wordPressCertificates",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WordpressCertificateAndLearnStateEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "WordpressCertificateAndLearnState",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "contentNodeTitle",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "userName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "File",
                            "kind": "LinkedField",
                            "name": "certificateFile",
                            "plural": false,
                            "selections": (v5/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "finishedAt",
                            "storageKey": null
                          },
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v8/*: any*/)
                ],
                "storageKey": "wordPressCertificates(first:10)"
              },
              {
                "alias": null,
                "args": (v10/*: any*/),
                "filters": (v9/*: any*/),
                "handle": "connection",
                "key": "CertificatesScreen_wordPressCertificates",
                "kind": "LinkedHandle",
                "name": "wordPressCertificates"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d95bb2c4bf6817de1a49ea9adbbd745e",
    "id": null,
    "metadata": {},
    "name": "CertificatesScreen_Query",
    "operationKind": "query",
    "text": "query CertificatesScreen_Query(\n  $sortDirection: CertificateAchievedCursorSortDirectionInput\n) {\n  ...CertificatesScreen_QueryFragment_4esatO\n  ...CertificatesScreen_WordpressCertificatesQueryFragment\n}\n\nfragment CertificatesScreen_QueryFragment_4esatO on Query {\n  CertificateAchievedByUser {\n    certificates(first: 10, sortDirection: $sortDirection) {\n      edges {\n        node {\n          name\n          fileRef {\n            url\n            id\n          }\n          issuedAt\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n  Viewer {\n    Auth {\n      currentUser {\n        user {\n          ...DefaultContainer_UserDataFragment\n          ...UserStatusMessage_UserFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment CertificatesScreen_WordpressCertificatesQueryFragment on Query {\n  Viewer {\n    WordpressCertificateAchievedByUser {\n      wordPressCertificates(first: 10) {\n        edges {\n          node {\n            id\n            contentNodeTitle\n            userName\n            certificateFile {\n              url\n              id\n            }\n            finishedAt\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n  }\n}\n\nfragment DefaultContainer_UserDataFragment on User {\n  ...MainTopBar_UserDataFragment\n}\n\nfragment MainTopBar_UserDataFragment on User {\n  ...ProfileLink_UserDataFragment\n}\n\nfragment ProfileLink_UserDataFragment on User {\n  extension {\n    __typename\n    ... on InfectopharmUserExtensionImpl {\n      salutation\n      title\n      lastName\n      firstName\n      efnNumber\n    }\n  }\n}\n\nfragment UserStatusMessage_UserFragment on User {\n  extension {\n    __typename\n    ... on InfectopharmUserExtensionImpl {\n      globalUserStatus\n      country\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9c87c9dc29f29874d988bc1690f33725";

export default node;
