/**
 * @generated SignedSource<<3eba932779e9249b6113cd8784e2a5d9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NodePointsDisplay_NodeRootStructureDefinitionFragment$data = {
  readonly extension: {
    readonly reducedDataInTree?: {
      readonly pacPoints: number | null;
      readonly pharmaPoints: number | null;
    };
  };
  readonly " $fragmentType": "NodePointsDisplay_NodeRootStructureDefinitionFragment";
};
export type NodePointsDisplay_NodeRootStructureDefinitionFragment$key = {
  readonly " $data"?: NodePointsDisplay_NodeRootStructureDefinitionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NodePointsDisplay_NodeRootStructureDefinitionFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NodePointsDisplay_NodeRootStructureDefinitionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "extension",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ReducedDataInTree",
              "kind": "LinkedField",
              "name": "reducedDataInTree",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "pacPoints",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "pharmaPoints",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "LearnOpportunityRootExtensionImpl",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "LearnOpportunityRootStructureDefinition",
  "abstractKey": null
};

(node as any).hash = "86352045b666347de81a84c867ad5651";

export default node;
