import InfectopharmLogo from "../../../infecto-lms-webapp-impl/assets/infectopharm.png";
import PaediaLogo from "../../../infecto-lms-webapp-impl/assets/LogoPaediaRGB.png";
import PaediaAkademieLogo from "../../assets/LogoPadiaAkademie.png";

export const selectLogoBasedOnTag = (tagId: string) => {
	if (tagId == "VGFnOlRhZzo0MGYyY2VlMy02YTUyLTRhMWMtYTAwMC0zZjk0YWUxOTdmZDY=") {
		return InfectopharmLogo;
	} else if (tagId == "VGFnOlRhZzplNjc2YTc1Yy00YTY5LTRhY2EtYjZjMi03ZThjMzI0ODgwYjg=") {
		return PaediaLogo;
	} else return PaediaAkademieLogo;
};
