import React, { ReactNode } from "react";
import { CurrentUser } from "./CurrentUser.component";
import { useTypedSelector } from "../../redux/redux-store";
import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay";
import { MaintenanceScreen } from "../../../app/screens/MaintenanceScreen";
import { AuthenticatedUser_Query } from "@generated/AuthenticatedUser_Query.graphql";
import { selectAuthSlice } from "../../redux/slices/auth.slice";

const QUERY = graphql`
	query AuthenticatedUser_Query {
		Maintenance {
			MaintenanceUpdate {
				id
				isUpdating
			}
		}
	}
`;
interface OwnProps {
	loginFallback: ReactNode;
	children: any;
}

export function AuthenticatedUser({ children, loginFallback }: OwnProps) {
	const authState = useTypedSelector(selectAuthSlice);

	const data = useLazyLoadQuery<AuthenticatedUser_Query>(
		QUERY,
		{},
		{ fetchPolicy: "network-only" },
	);

	const isUpdating = data.Maintenance.MaintenanceUpdate.isUpdating;

	return (
		<>
			{isUpdating ? (
				<MaintenanceScreen />
			) : authState.isLoggedIn ? (
				<CurrentUser>{children}</CurrentUser>
			) : (
				loginFallback
			)}
		</>
	);
}
