import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import {
	ElementTypeV2,
	SearchLearnOpportunitiesCursorSortDirectionInput,
	SearchLearnOpportunitiesFormatTypeInput,
	SearchLearnOpportunitiesOrderByInput,
	SearchLearnOpportunitiesSearchOptionsInput,
} from "@generated/SearchResults_Query.graphql";
import { useUrlFilterState } from "../../hooks/useUrlFilterState";

export interface SearchParameters {
	query?: string;
	tagIds?: string[];
	elementTypes?: ElementTypeV2[];
	isCertified?: boolean;
	formatTypes?: SearchLearnOpportunitiesFormatTypeInput[];
	searchOptions?: SearchLearnOpportunitiesSearchOptionsInput[];
	orderBy?: SearchLearnOpportunitiesOrderByInput;
	sortDirection?: SearchLearnOpportunitiesCursorSortDirectionInput;
}

interface SearchContextParameters {
	filters: SearchParameters;
	setFilters: (filters: SearchParameters) => void;
	clearFilters: () => void;
}

export const SearchContext = React.createContext<SearchContextParameters>({
	setFilters: () => {},
	clearFilters: () => {},
	filters: {},
});

interface OwnProps {
	children: any;
	formatType?: SearchLearnOpportunitiesFormatTypeInput[];
}

export function SearchContextProvider({ children, formatType }: OwnProps) {
	const [params] = useSearchParams();

	const { state, setState } = useUrlFilterState<SearchParameters>({
		query: params.get("query") || undefined,
		tagIds: [],
		searchOptions: [],
		elementTypes: [],
		formatTypes: formatType || [],
		sortDirection: "asc",
	});

	useEffect(() => {
		const newQuery = params.get("query");
		if (newQuery) {
			setState({
				...state,
				query: newQuery,
			});
		}
		// eslint-disable-next-line
    }, [params])

	return (
		<SearchContext.Provider
			value={{
				filters: state,
				setFilters: (filters) => {
					setState(() => filters);
				},
				clearFilters: () => {
					setState({});
				},
			}}
		>
			{children}
		</SearchContext.Provider>
	);
}
