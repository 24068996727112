/**
 * @generated SignedSource<<5bf4647018d1765cf139f18504d4e267>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InfectopharmCountry = "Deutschland" | "Oesterreich" | "Unknown" | "%future added value";
export type Salutation = "Divers" | "Mr" | "Mrs" | "Unknown" | "%future added value";
export type PageNotFoundScreen_Query$variables = {};
export type PageNotFoundScreen_Query$data = {
  readonly Viewer: {
    readonly Auth: {
      readonly currentUser: {
        readonly user: {
          readonly extension: {
            readonly country?: InfectopharmCountry | null;
            readonly efnNumber?: string | null;
            readonly firstName?: string | null;
            readonly lastName?: string | null;
            readonly salutation?: Salutation | null;
            readonly title?: string | null;
          };
          readonly " $fragmentSpreads": FragmentRefs<"PaediaDefaultContainer_UserDataFragment">;
        };
      } | null;
    };
  };
};
export type PageNotFoundScreen_Query = {
  response: PageNotFoundScreen_Query$data;
  variables: PageNotFoundScreen_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "salutation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "efnNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "country",
      "storageKey": null
    }
  ],
  "type": "InfectopharmUserExtensionImpl",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PageNotFoundScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthViewerSchema",
            "kind": "LinkedField",
            "name": "Auth",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CurrentUser",
                "kind": "LinkedField",
                "name": "currentUser",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "extension",
                        "plural": false,
                        "selections": [
                          (v0/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "PaediaDefaultContainer_UserDataFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PageNotFoundScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthViewerSchema",
            "kind": "LinkedField",
            "name": "Auth",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CurrentUser",
                "kind": "LinkedField",
                "name": "currentUser",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "extension",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          (v0/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e9686cd79d935ca7c7389959e5f2e694",
    "id": null,
    "metadata": {},
    "name": "PageNotFoundScreen_Query",
    "operationKind": "query",
    "text": "query PageNotFoundScreen_Query {\n  Viewer {\n    Auth {\n      currentUser {\n        user {\n          extension {\n            __typename\n            ... on InfectopharmUserExtensionImpl {\n              salutation\n              title\n              lastName\n              firstName\n              efnNumber\n              country\n            }\n          }\n          ...PaediaDefaultContainer_UserDataFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment MainTopBar_UserDataFragment on User {\n  ...ProfileLink_UserDataFragment\n}\n\nfragment PaediaDefaultContainer_UserDataFragment on User {\n  ...MainTopBar_UserDataFragment\n}\n\nfragment ProfileLink_UserDataFragment on User {\n  extension {\n    __typename\n    ... on InfectopharmUserExtensionImpl {\n      salutation\n      title\n      lastName\n      firstName\n      efnNumber\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ceac63564152dacb2150c44e5a473dbd";

export default node;
