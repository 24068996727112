import React from "react";
import styled from "styled-components";
import { PageIndicator } from "./PageIndicator.component";

interface OwnProps {
	numPages: number;
	modalWidth: number;
	activePageIndex: number;
}

export function PageIndicators({ numPages, modalWidth, activePageIndex }: OwnProps) {
	return (
		<Indicators modalWidth={modalWidth}>
			<InnerContainer>
				{[...Array(numPages)].map((_, i) => (
					<PageIndicator
						key={i}
						modalWidth={modalWidth}
						numPages={numPages}
						active={i === activePageIndex}
					/>
				))}
			</InnerContainer>
		</Indicators>
	);
}

const Indicators = styled.div<{ modalWidth: number }>`
	position: absolute;
	top: ${(props) => props.modalWidth * 0.05}px;
	z-index: 1;
	width: ${(props) => props.modalWidth}px;
	padding-left: ${(props) => props.modalWidth * 0.025}px;
	padding-right: ${(props) => props.modalWidth * 0.025}px;
`;

const InnerContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-self: center;
`;
