type TkaFontFamily = "Nunito Sans";
export const createCSS = (
	lineHeightInREM: number,
	fontSizeInREM: number,
	options: {
		italic?: boolean;
		underline?: boolean;
		bold?: boolean;
		fontFamily?: TkaFontFamily;
	} = { fontFamily: "Nunito Sans" },
): string => `
    font-family: '${options.fontFamily || "Nunito Sans"}', sans-serif;
    line-height: ${lineHeightInREM.toString()}rem;
    font-size: ${fontSizeInREM.toString()}rem;
    font-weight: ${options.bold ? "bold" : "normal"};
    ${options.italic && "font-style: italic;"}
    ${options.underline && "text-decoration: underline"}
`;
