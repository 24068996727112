import React, { ReactNode, useState } from "react";
import { Dialog } from "primereact/dialog";
import { TertiaryButton } from "../components/buttons/TertiaryButton";
import { PrimaryButton } from "../components/buttons/PrimaryButton";

interface DialogSettings {
	title: string;
	content: string;
	dialogCallback: DialogCallback;
	affirmativeText?: string;
	negativeText?: string;
	dismissible?: boolean;
}

interface DialogHookProps {
	privacyDialog: ReactNode;
	showPrivacyDialog: (settings: DialogSettings) => void;
	isAcceptingNewPrivacy: (isLoading: boolean) => void;
}

type DialogResult = "Accept" | "Deny";

type DialogCallback = (result: DialogResult) => void;

interface DialogState extends DialogSettings {
	dialogCallback: DialogCallback;
	dialogVisible: boolean;
}

const INITIAL_STATE = {
	dialogCallback: () => {},
	title: "Untitled",
	content: "No content",
	affirmativeText: "Ja",
	negativeText: "Nein",
	dialogVisible: false,
	dismissible: true,
};

export const useDialogLogic = (): DialogHookProps => {
	const [dialogState, setDialogState] = useState<DialogState>(INITIAL_STATE);
	const [loadingState, setLoadingState] = useState<boolean>(false);

	return {
		privacyDialog: (
			<Dialog
				className={"mx-8"}
				header={dialogState.title}
				visible={dialogState.dialogVisible}
				dismissableMask={dialogState.dismissible}
				closable={dialogState.dismissible}
				draggable={false}
				footer={
					<div>
						{dialogState.negativeText && (
							<TertiaryButton
								label={dialogState.negativeText || "Abbrechen"}
								icon="pi pi-times"
								onClick={() => {
									if (dialogState.dismissible) {
										setDialogState(INITIAL_STATE);
									}

									dialogState.dialogCallback("Deny");
								}}
								loading={loadingState}
								className="p-button-text"
							/>
						)}
						<PrimaryButton
							label={dialogState.affirmativeText || "Akzeptieren"}
							icon="pi pi-check"
							onClick={() => {
								dialogState.dialogCallback("Accept");
								setDialogState(INITIAL_STATE);
							}}
							loading={loadingState}
							autoFocus={true}
						/>
					</div>
				}
				onHide={() => {
					if (dialogState.dismissible) {
						dialogState.dialogCallback("Deny");
						setDialogState(INITIAL_STATE);
					}
				}}
			>
				<div dangerouslySetInnerHTML={{ __html: dialogState.content }} />
			</Dialog>
		),

		showPrivacyDialog: (settings: DialogSettings) => {
			setDialogState({
				...settings,
				dialogVisible: true,
			});
		},

		isAcceptingNewPrivacy: (isLoading: boolean) => {
			setLoadingState(isLoading);
		},
	};
};
