import { authMiddleware } from "react-relay-network-modern";
import { ReduxStore } from "../redux/redux-store";
import {
	JwtClaimData,
	logout,
	refreshLogin,
	selectCurrentAccountId,
} from "../redux/slices/auth.slice";
import { decodeToken } from "react-jwt";
import { LMS_WEBAPP_CONFIG_IMPL } from "../../infecto-lms-webapp-impl/config";

export const JwtMiddleware = authMiddleware({
	allowEmptyToken: true,
	token: () => ReduxStore.getState().auth.loginData?.accessToken || "",
	tokenRefreshPromise: () => {
		const state = ReduxStore.getState();
		return fetch(`${process.env.REACT_APP_API_BASE}/api/refresh-token`, {
			method: "POST",
			headers: new Headers({ "content-type": "application/json" }),
			body: JSON.stringify({
				refreshToken: state.auth.loginData?.refreshToken,
				accountId: selectCurrentAccountId(state),
				base64BrandId: LMS_WEBAPP_CONFIG_IMPL.brandId,
			}),
		})
			.then((res) => res.json())
			.then((json) => {
				const token = json.accessToken;

				const jwtTokenData = decodeToken<JwtClaimData>(token);

				if (jwtTokenData && jwtTokenData.isLoggedIn) {
					ReduxStore.dispatch(refreshLogin({ loginData: json }));

					return token;
				} else {
					ReduxStore.dispatch(logout());

					return undefined;
				}
			})
			.catch(() => {
				ReduxStore.dispatch(logout());
			});
	},
});
