/**
 * @generated SignedSource<<3f9092f1c737b6b42d6f812a5bacf3ea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type FachberaterStatus_LegacyPacPointsQuery$variables = {};
export type FachberaterStatus_LegacyPacPointsQuery$data = {
  readonly Viewer: {
    readonly LegacyPacPointsAchievedByUser: {
      readonly legacyPacPoints: ReadonlyArray<{
        readonly node: {
          readonly structureDefinition: {
            readonly title: string;
          };
        };
        readonly pacPointsAmount: number;
        readonly receivedAt: string;
      }>;
    };
  };
};
export type FachberaterStatus_LegacyPacPointsQuery = {
  response: FachberaterStatus_LegacyPacPointsQuery$data;
  variables: FachberaterStatus_LegacyPacPointsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "receivedAt",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pacPointsAmount",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "FachberaterStatus_LegacyPacPointsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LegacyPacPointsAchievedByUserViewerSchema",
            "kind": "LinkedField",
            "name": "LegacyPacPointsAchievedByUser",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LegacyPacPointsLearnState",
                "kind": "LinkedField",
                "name": "legacyPacPoints",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LearnOpportunityV2",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "structureDefinition",
                        "plural": false,
                        "selections": [
                          (v0/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v1/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "FachberaterStatus_LegacyPacPointsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LegacyPacPointsAchievedByUserViewerSchema",
            "kind": "LinkedField",
            "name": "LegacyPacPointsAchievedByUser",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LegacyPacPointsLearnState",
                "kind": "LinkedField",
                "name": "legacyPacPoints",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LearnOpportunityV2",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "structureDefinition",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          (v0/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d51492af407e3a73ed0110057ec614b4",
    "id": null,
    "metadata": {},
    "name": "FachberaterStatus_LegacyPacPointsQuery",
    "operationKind": "query",
    "text": "query FachberaterStatus_LegacyPacPointsQuery {\n  Viewer {\n    LegacyPacPointsAchievedByUser {\n      legacyPacPoints {\n        node {\n          structureDefinition {\n            __typename\n            title\n          }\n          id\n        }\n        receivedAt\n        pacPointsAmount\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0ec350a58042f8230a72ada954bae08d";

export default node;
