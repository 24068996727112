import React, { useContext, useEffect } from "react";
import { useLazyLoadQuery, usePaginationFragment } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { NodeCard } from "../../../infecto-lms-webapp-impl/components/NodeCard.component";
import { SearchContext } from "../../../infecto-lms-webapp/context/search-context/SearchContextProvider";
import { PaediaSearchResults_Query } from "@generated/PaediaSearchResults_Query.graphql";
import { PaediaSearchResults_Refetch } from "@generated/PaediaSearchResults_Refetch.graphql";
import { PaediaSearchResults_LearnOpportunitiesFragment$key } from "@generated/PaediaSearchResults_LearnOpportunitiesFragment.graphql";
import { PrimaryButton } from "../../../infecto-lms-webapp/components/buttons/PrimaryButton";

const QUERY = graphql`
	query PaediaSearchResults_Query(
		$query: String
		$tagIds: [ID!]
		$orderBy: SearchLearnOpportunitiesOrderByInput
		$searchOptions: [SearchLearnOpportunitiesSearchOptionsInput!]
		$sortDirection: SearchLearnOpportunitiesCursorSortDirectionInput
		$elementTypes: [ElementTypeV2!]
		$isCertified: Boolean
		$formatTypes: [SearchLearnOpportunitiesFormatTypeInput!]
	) {
		...PaediaSearchResults_LearnOpportunitiesFragment
			@arguments(
				query: $query
				tagIds: $tagIds
				orderBy: $orderBy
				searchOptions: $searchOptions
				sortDirection: $sortDirection
				elementTypes: $elementTypes
				isCertified: $isCertified
				formatTypes: $formatTypes
			)
	}
`;

const LEARN_OPPORTUNITIES_FRAGMENT = graphql`
	fragment PaediaSearchResults_LearnOpportunitiesFragment on Query
	@refetchable(queryName: "PaediaSearchResults_Refetch")
	@argumentDefinitions(
		first: { type: "Int", defaultValue: 20 }
		after: { type: "String" }
		query: { type: "String" }
		tagIds: { type: "[ID!]" }
		formatTypes: { type: "[SearchLearnOpportunitiesFormatTypeInput!]" }
		orderBy: { type: "SearchLearnOpportunitiesOrderByInput" }
		searchOptions: { type: "[SearchLearnOpportunitiesSearchOptionsInput!]" }
		sortDirection: { type: "SearchLearnOpportunitiesCursorSortDirectionInput" }
		elementTypes: { type: "[ElementTypeV2!]" }
		isCertified: { type: "Boolean" }
	) {
		LearnV2 {
			SearchLearnOpportunities(
				first: $first
				after: $after
				query: $query
				tagIds: $tagIds
				orderBy: $orderBy
				searchOptions: $searchOptions
				sortDirection: $sortDirection
				elementTypes: $elementTypes
				isCertified: $isCertified
				formatTypes: $formatTypes
			) @connection(key: "PaediaSearchResults_SearchLearnOpportunities") {
				pageInfo {
					endCursor
					hasPreviousPage
					hasNextPage
					startCursor
				}
				edges {
					node {
						...NodeCard_NodeFragment
					}
				}
			}
		}
	}
`;

export function PaediaSearchResults() {
	const { filters } = useContext(SearchContext);

	const queryData = useLazyLoadQuery<PaediaSearchResults_Query>(
		QUERY,
		{ query: filters.query },
		{ fetchPolicy: "network-only" },
	);

	const { data, loadNext, refetch, hasNext } = usePaginationFragment<
		PaediaSearchResults_Refetch,
		PaediaSearchResults_LearnOpportunitiesFragment$key
	>(LEARN_OPPORTUNITIES_FRAGMENT, queryData);

	useEffect(() => {
		refetch(
			{
				...filters,
				query: filters.query ? filters.query : undefined,
			},
			{ fetchPolicy: "network-only" },
		);
	}, [filters]);

	const results =
		data.LearnV2.SearchLearnOpportunities.edges?.filter((e) => !!e?.node).map((e) => e!.node!) ||
		[];

	return (
		<div className="pb-5">
			<div className="grid ">
				{results?.map((learnOpportunity, index) => (
					<div className={`lg:col-6 col-12 `} key={index}>
						<NodeCard
							isFeatured={false}
							learnOpportunityFragmentRef={learnOpportunity}
							isLandingPage={false}
						/>
					</div>
				))}
				{results.length === 0 && (
					<div className="text-center col-12">
						Leider keine Ergebnisse - bitte passen Sie die Suchparameter an.
					</div>
				)}
			</div>
			<div className="flex justify-content-center mt-4">
				{hasNext && <PrimaryButton label="Mehr laden..." onClick={() => loadNext(10)} />}
			</div>
		</div>
	);
}
