import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { ConfirmButton_MessageDefinitionConfirmButtonFragment$key } from "@generated/ConfirmButton_MessageDefinitionConfirmButtonFragment.graphql";
import { ButtonWithTitle } from "./ButtonWithTitle.component";

const MESSAGE_DEFINITION_CONFIRM_BUTTON_FRAGMENT = graphql`
	fragment ConfirmButton_MessageDefinitionConfirmButtonFragment on MessageDefinitionConfirmButton {
		title
	}
`;

interface OwnProps {
	onClick: () => void;
	modalWidth: number;
	messageDefinitionConfirmButtonFragmentRef: ConfirmButton_MessageDefinitionConfirmButtonFragment$key;
}

export function ConfirmButton({
	onClick,
	modalWidth,
	messageDefinitionConfirmButtonFragmentRef,
}: OwnProps) {
	const button = useFragment<ConfirmButton_MessageDefinitionConfirmButtonFragment$key>(
		MESSAGE_DEFINITION_CONFIRM_BUTTON_FRAGMENT,
		messageDefinitionConfirmButtonFragmentRef,
	);

	return <ButtonWithTitle title={button.title} onClick={onClick} modalWidth={modalWidth} />;
}
