import React, { useState } from "react";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { MessageModal } from "./MessageModal.component";
import { MessageModal_MessageFragment$key } from "@generated/MessageModal_MessageFragment.graphql";
import { LastMessageModal_Query } from "@generated/LastMessageModal_Query.graphql";

const QUERY = graphql`
	query LastMessageModal_Query {
		Viewer {
			Messaging {
				LastUnseenMessage {
					...MessageModal_MessageFragment
					id
					messageDefinition {
						pages {
							edges {
								node {
									id
									image {
										url
									}
									...MessagePage_MessageDefinitionPageFragment
								}
							}
						}
					}
				}
			}
		}
	}
`;

interface State {
	selectedMessage?: MessageModal_MessageFragment$key;
}

export function LastMessageModal() {
	const query = useLazyLoadQuery<LastMessageModal_Query>(QUERY, {});

	const lastUnseenMessage = query.Viewer.Messaging.LastUnseenMessage;

	const [state, setState] = useState<State>({
		selectedMessage: lastUnseenMessage || undefined,
	});

	return (
		<MessageModal
			hideModal={() => setState((state) => ({ ...state, selectedMessage: undefined }))}
			messageFragment={state.selectedMessage}
		/>
	);
}
