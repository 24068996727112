import React from "react";
import { PrivateRouteDefinition } from "../../../../infecto-lms-webapp/router/interfaces";
import { DashboardScreen } from "../../../../app/screens/Dashboard.screen";
import { ROLE_ADMIN, ROLE_USER } from "../../../../infecto-lms-webapp/router/UserRoles";

export const DASHBOARD_PATH = "/";

export const DashboardRoutes: PrivateRouteDefinition[] = [
	{
		path: DASHBOARD_PATH,
		component: <DashboardScreen />,
		requiredRoles: [ROLE_ADMIN, ROLE_USER],
	},
];
