/**
 * @generated SignedSource<<225dcaf988310598ecf45240b4b8c031>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LearningOutcomeDescriptionComponent_NodeFragment$data = {
  readonly root: {
    readonly id: string;
    readonly structureDefinition: {
      readonly extension?: {
        readonly numOfQuestionsInChildren?: number;
        readonly onPercentagePassContentChildConfig?: {
          readonly requiredPercentage: number;
        } | null;
        readonly reducedDataInTree?: {
          readonly pacPoints: number | null;
          readonly pharmaPoints: number | null;
        };
      };
      readonly title: string;
    };
  } | null;
  readonly " $fragmentType": "LearningOutcomeDescriptionComponent_NodeFragment";
};
export type LearningOutcomeDescriptionComponent_NodeFragment$key = {
  readonly " $data"?: LearningOutcomeDescriptionComponent_NodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"LearningOutcomeDescriptionComponent_NodeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LearningOutcomeDescriptionComponent_NodeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "LearnOpportunityV2",
      "kind": "LinkedField",
      "name": "root",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "structureDefinition",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "extension",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ReducedDataInTree",
                          "kind": "LinkedField",
                          "name": "reducedDataInTree",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "pacPoints",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "pharmaPoints",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "OnPercentageChildrenPassContentConfig",
                          "kind": "LinkedField",
                          "name": "onPercentagePassContentChildConfig",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "requiredPercentage",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "numOfQuestionsInChildren",
                          "storageKey": null
                        }
                      ],
                      "type": "LearnOpportunityRootExtensionImpl",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "LearnOpportunityRootStructureDefinition",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "LearnOpportunityV2",
  "abstractKey": null
};

(node as any).hash = "460a5a403e5611c15b4e7f6fe634eac8";

export default node;
