/**
 * @generated SignedSource<<aa625e90be3c1c4783920c910221b43d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Salutation = "Divers" | "Mr" | "Mrs" | "Unknown" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProfileLink_UserDataFragment$data = {
  readonly extension: {
    readonly efnNumber?: string | null;
    readonly firstName?: string | null;
    readonly lastName?: string | null;
    readonly salutation?: Salutation | null;
    readonly title?: string | null;
  };
  readonly " $fragmentType": "ProfileLink_UserDataFragment";
};
export type ProfileLink_UserDataFragment$key = {
  readonly " $data"?: ProfileLink_UserDataFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProfileLink_UserDataFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProfileLink_UserDataFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "extension",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "salutation",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "firstName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "efnNumber",
              "storageKey": null
            }
          ],
          "type": "InfectopharmUserExtensionImpl",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "bfd7bbf2022dd21ba61346b4385b6136";

export default node;
