/**
 * @generated SignedSource<<dcbd2d2d46c9c7c88384d56e524fc997>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type RewardKind = "Certificate" | "Email" | "MakeFachberater" | "PacPoints" | "PharmaPoints" | "Points" | "%future added value";
export type FachberaterStatus_Query$variables = {};
export type FachberaterStatus_Query$data = {
  readonly Viewer: {
    readonly FachberaterCertificates: {
      readonly currentFachberaterCertificate: {
        readonly certificateFile: {
          readonly name: string;
          readonly url: string | null;
        } | null;
      } | null;
    };
    readonly Rewards: {
      readonly UserRewards: ReadonlyArray<{
        readonly receivedAt: string;
        readonly receivedForRootNodeTitle: string;
        readonly rewardResult: {
          readonly amount?: number;
          readonly rewardKind: RewardKind;
        };
      }>;
    };
  };
};
export type FachberaterStatus_Query = {
  response: FachberaterStatus_Query$data;
  variables: FachberaterStatus_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "receivedAt",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "receivedForRootNodeTitle",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rewardKind",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "amount",
    "storageKey": null
  }
],
v4 = {
  "kind": "InlineFragment",
  "selections": (v3/*: any*/),
  "type": "PharmaPointsRewardWasPossibleResult",
  "abstractKey": null
},
v5 = {
  "kind": "InlineFragment",
  "selections": (v3/*: any*/),
  "type": "PacPointsRewardWasPossibleResult",
  "abstractKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "FachberaterStatus_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RewardsViewerSchema",
            "kind": "LinkedField",
            "name": "Rewards",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserPoints",
                "kind": "LinkedField",
                "name": "UserRewards",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "rewardResult",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "FachberaterCertificatesViewerSchema",
            "kind": "LinkedField",
            "name": "FachberaterCertificates",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "FachberaterCertificate",
                "kind": "LinkedField",
                "name": "currentFachberaterCertificate",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "File",
                    "kind": "LinkedField",
                    "name": "certificateFile",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "FachberaterStatus_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RewardsViewerSchema",
            "kind": "LinkedField",
            "name": "Rewards",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserPoints",
                "kind": "LinkedField",
                "name": "UserRewards",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "rewardResult",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      },
                      (v2/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "FachberaterCertificatesViewerSchema",
            "kind": "LinkedField",
            "name": "FachberaterCertificates",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "FachberaterCertificate",
                "kind": "LinkedField",
                "name": "currentFachberaterCertificate",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "File",
                    "kind": "LinkedField",
                    "name": "certificateFile",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "10ff02baf9effcaac7ecf79ff2b4c28e",
    "id": null,
    "metadata": {},
    "name": "FachberaterStatus_Query",
    "operationKind": "query",
    "text": "query FachberaterStatus_Query {\n  Viewer {\n    Rewards {\n      UserRewards {\n        receivedAt\n        receivedForRootNodeTitle\n        rewardResult {\n          __typename\n          rewardKind\n          ... on PharmaPointsRewardWasPossibleResult {\n            amount\n          }\n          ... on PacPointsRewardWasPossibleResult {\n            amount\n          }\n        }\n      }\n    }\n    FachberaterCertificates {\n      currentFachberaterCertificate {\n        certificateFile {\n          name\n          url\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f1ddbe4b1c00d6fba63c35fb521432fc";

export default node;
