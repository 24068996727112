/**
 * @generated SignedSource<<c841320a5f2861fc9dcf8027f325aaf5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LastMessageModal_Query$variables = {};
export type LastMessageModal_Query$data = {
  readonly Viewer: {
    readonly Messaging: {
      readonly LastUnseenMessage: {
        readonly id: string;
        readonly messageDefinition: {
          readonly pages: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly id: string;
                readonly image: {
                  readonly url: string | null;
                } | null;
                readonly " $fragmentSpreads": FragmentRefs<"MessagePage_MessageDefinitionPageFragment">;
              };
            } | null> | null;
          };
        } | null;
        readonly " $fragmentSpreads": FragmentRefs<"MessageModal_MessageFragment">;
      } | null;
    };
  };
};
export type LastMessageModal_Query = {
  response: LastMessageModal_Query$data;
  variables: LastMessageModal_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "LastMessageModal_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MessagingViewerSchema",
            "kind": "LinkedField",
            "name": "Messaging",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Message",
                "kind": "LinkedField",
                "name": "LastUnseenMessage",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "MessageModal_MessageFragment"
                  },
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MessageDefinition",
                    "kind": "LinkedField",
                    "name": "messageDefinition",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "MessageDefinitionPagesConnection",
                        "kind": "LinkedField",
                        "name": "pages",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "MessageDefinitionPagesEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "MessageDefinitionPage",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v0/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "File",
                                    "kind": "LinkedField",
                                    "name": "image",
                                    "plural": false,
                                    "selections": [
                                      (v1/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "MessagePage_MessageDefinitionPageFragment"
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "LastMessageModal_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MessagingViewerSchema",
            "kind": "LinkedField",
            "name": "Messaging",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Message",
                "kind": "LinkedField",
                "name": "LastUnseenMessage",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MessageDefinition",
                    "kind": "LinkedField",
                    "name": "messageDefinition",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "MessageDefinitionPagesConnection",
                        "kind": "LinkedField",
                        "name": "pages",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "MessageDefinitionPagesEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "MessageDefinitionPage",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v0/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "File",
                                    "kind": "LinkedField",
                                    "name": "image",
                                    "plural": false,
                                    "selections": [
                                      (v1/*: any*/),
                                      (v0/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "internalTitle",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "MessageDefinition",
                                    "kind": "LinkedField",
                                    "name": "messageDefinition",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": null,
                                        "kind": "LinkedField",
                                        "name": "buttonOpt",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "__typename",
                                            "storageKey": null
                                          },
                                          {
                                            "kind": "TypeDiscriminator",
                                            "abstractKey": "__isMessageDefinitionButtonInterface"
                                          },
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "type",
                                            "storageKey": null
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              (v2/*: any*/),
                                              {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "link",
                                                "storageKey": null
                                              }
                                            ],
                                            "type": "MessageDefinitionLinkButton",
                                            "abstractKey": null
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              (v2/*: any*/)
                                            ],
                                            "type": "MessageDefinitionConfirmButton",
                                            "abstractKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      },
                                      (v0/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasSeen",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d4f78cb0cdb1a150dcaf6bd7eabba1ff",
    "id": null,
    "metadata": {},
    "name": "LastMessageModal_Query",
    "operationKind": "query",
    "text": "query LastMessageModal_Query {\n  Viewer {\n    Messaging {\n      LastUnseenMessage {\n        ...MessageModal_MessageFragment\n        id\n        messageDefinition {\n          pages {\n            edges {\n              node {\n                id\n                image {\n                  url\n                  id\n                }\n                ...MessagePage_MessageDefinitionPageFragment\n              }\n            }\n          }\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment ConfirmButton_MessageDefinitionConfirmButtonFragment on MessageDefinitionConfirmButton {\n  title\n}\n\nfragment LinkButton_MessageDefinitionLinkButtonFragment on MessageDefinitionLinkButton {\n  title\n  link\n}\n\nfragment MessageDefinitionButton_MessageDefinitionButtonFragment on MessageDefinitionButtonInterface {\n  __isMessageDefinitionButtonInterface: __typename\n  type\n  ... on MessageDefinitionLinkButton {\n    ...LinkButton_MessageDefinitionLinkButtonFragment\n  }\n  ... on MessageDefinitionConfirmButton {\n    ...ConfirmButton_MessageDefinitionConfirmButtonFragment\n  }\n}\n\nfragment MessageModal_MessageFragment on Message {\n  id\n  messageDefinition {\n    pages {\n      edges {\n        node {\n          id\n          image {\n            url\n            id\n          }\n          ...MessagePage_MessageDefinitionPageFragment\n        }\n      }\n    }\n    id\n  }\n  hasSeen\n}\n\nfragment MessagePage_MessageDefinitionPageFragment on MessageDefinitionPage {\n  image {\n    url\n    id\n  }\n  internalTitle\n  messageDefinition {\n    buttonOpt {\n      __typename\n      ...MessageDefinitionButton_MessageDefinitionButtonFragment\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "93df2dd01adb75fda2aa32d3024c2a93";

export default node;
