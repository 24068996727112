import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { NodeContentElementsList_NodeFragment$key } from "@generated/NodeContentElementsList_NodeFragment.graphql";
import { getLearnOpportunityPath } from "../../../infecto-lms-webapp-impl/router/routes/auth/nodes.routes";
import { ProjectContentButtonCME } from "../../../infecto-lms-webapp-impl/components/relay/ProjectContentButtonCME";

const LEARN_OPPORTUNITY_FRAGMENT = graphql`
	fragment NodeContentElementsList_NodeFragment on LearnOpportunityV2 {
		id
		shortDescription
		structureDefinition {
			title
		}
		...ProjectContentButtonCME_LearnOpportunityFragment
		typeDefinition {
			definitionType
			... on LearnOpportunityELearningContentTypeDefinition {
				containedElementTypes
			}
			... on LearnOpportunityBranchTypeDefinition {
				children {
					...ProjectContentButtonCME_LearnOpportunityFragment
					id
					shortDescription
					structureDefinition {
						title
					}
					typeDefinition {
						definitionType
						... on LearnOpportunityELearningContentTypeDefinition {
							containedElementTypes
							contentNodeAdvancementResult {
								status
								... on CanNotBeStartedContentNodeAdvancementResult {
									reasonConfigTypes
								}
								... on ContinueContentNodeAdvancementResult {
									activeContentSubmissionId
								}
								... on CanNotBeRestartedAfterFailedContentNodeAdvancementResult {
									configResults {
										configType

										... on RestartIfFailedContentConfigResult {
											canBeRestarted
										}

										... on NegativeBlockPermanentlyRestartIfFailedContentConfigResult {
											canBeRestarted
										}

										... on NegativeBlockTemporarilyRestartIfFailedContentConfigResult {
											blockedUntil
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;

interface OwnProps {
	learnOpportunityFragmentRef: NodeContentElementsList_NodeFragment$key;
}

export function NodeContentElementsList({ learnOpportunityFragmentRef }: OwnProps) {
	const navigate = useNavigate();

	const learnOpportunity = useFragment<NodeContentElementsList_NodeFragment$key>(
		LEARN_OPPORTUNITY_FRAGMENT,
		learnOpportunityFragmentRef,
	);

	return (
		<div className="lg:pr-4">
			<ModulesList className="mt-4 mb-4">
				{learnOpportunity.typeDefinition.children ? (
					learnOpportunity.typeDefinition.children.map((c) =>
						ProjectContentButtonCME ? (
							<ProjectContentButtonCME
								className={"text-sm p-2 mb-2 w-full border-noround"}
								learnOpportunityFragmentRef={c}
							/>
						) : (
							<LinkButton
								className="bg-primary text-sm p-2 mb-2"
								key={c.structureDefinition.title}
								onClick={() => navigate(getLearnOpportunityPath(c.id))}
							>
								<span
									dangerouslySetInnerHTML={{
										__html:
											c.shortDescription && c.shortDescription !== "<div><br></div>"
												? c.shortDescription
												: c.structureDefinition.title,
									}}
								/>
							</LinkButton>
						),
					)
				) : ProjectContentButtonCME ? (
					<ProjectContentButtonCME
						className={"text-sm p-2 mb-2 w-full border-noround"}
						learnOpportunityFragmentRef={learnOpportunity}
					/>
				) : (
					<LinkButton
						className="bg-primary text-sm p-2 mb-2"
						key={learnOpportunity.structureDefinition.title}
						onClick={() => navigate(getLearnOpportunityPath(learnOpportunity.id))}
					>
						<span
							dangerouslySetInnerHTML={{
								__html:
									learnOpportunity.shortDescription &&
									learnOpportunity.shortDescription !== "<div><br></div>"
										? learnOpportunity.shortDescription
										: learnOpportunity.structureDefinition.title,
							}}
						/>
					</LinkButton>
				)}
			</ModulesList>
		</div>
	);
}

const ModulesList = styled.div`
	display: flex;
	flex-direction: column;
`;

const LinkButton = styled.button`
	max-width: 400px;
	text-align: center;
	border: none;
	cursor: pointer;
`;
