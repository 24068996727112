import React from "react";
import { PrivateRouteDefinition } from "../../../../infecto-lms-webapp/router/interfaces";
import { ServerErrorScreen } from "../../../../app/screens/ServerErrorScreen";

export const ServerErrorRoutes: PrivateRouteDefinition[] = [
	{
		path: "/unerwartet",
		component: <ServerErrorScreen />,
	},
];
