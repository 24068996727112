import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { Tag } from "primereact/tag";
import styled from "styled-components";
import { NodeTag_TagFragment$key } from "@generated/NodeTag_TagFragment.graphql";
import { LMS_WEBAPP_CONFIG_IMPL } from "../../../infecto-lms-webapp-impl/config";

const TAG_FRAGMENT = graphql`
	fragment NodeTag_TagFragment on Tag {
		id
		name
		colorHex
	}
`;

interface OwnProps {
	tagFragmentRef: NodeTag_TagFragment$key;
	fontSize?: string;
}

interface TagProps {
	fontSize?: string;
	isPaediaBrand: boolean;
}

const StyledTag = styled(Tag)<TagProps>`
	pointer-events: ${(props) => (props.isPaediaBrand ? "none" : "auto !important")};
	${(props) => !props.isPaediaBrand && "cursor: not-allowed !important"}
	.p-tag-value {
		font-size: 0.75rem;
	}
	@media (min-width: 1200px) {
		.p-tag-value {
			font-size: ${(p) => p.fontSize}rem;
		}
	}
`;

export function NodeTag({ tagFragmentRef, fontSize }: OwnProps) {
	const tag = useFragment<NodeTag_TagFragment$key>(TAG_FRAGMENT, tagFragmentRef);
	const isPaedia =
		LMS_WEBAPP_CONFIG_IMPL.brandId ===
		"QnJhbmQ6QnJhbmQ6ZTNiMTNlYzktNzZjMC00YTUyLWJkNDAtZjIxYWViMTU3MzJj";
	return (
		<StyledTag
			onClick={(e) => {
				e.preventDefault();
				e.stopPropagation();
			}}
			isPaediaBrand={isPaedia}
			fontSize={fontSize ? fontSize : "0.75"}
			className="mr-1 pl-3 pr-3 text-xxs mb-2"
			value={tag.name}
			style={{ backgroundColor: tag.colorHex }}
			rounded
		/>
	);
}
