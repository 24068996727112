import React from "react";
import { hexToRgb } from "../functions/helpers/convertHexToRgb";
import { LMS_WEBAPP_CONFIG_IMPL } from "../../infecto-lms-webapp-impl/config";
import { DASHBOARD_PATH } from "../../infecto-lms-webapp-impl/router/routes/auth/dashboard.routes";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from "./buttons/PrimaryButton";
interface OwnProps {
	errorType: string;
	title: string;
	description: string;
}
export const ErrorPageComponent = ({ errorType, title, description }: OwnProps) => {
	const navigate = useNavigate();
	return (
		<div className="flex align-content-center w-full flex-column align-items-center mt-8">
			<div className="flex flex-row align-items-center w-full justify-content-center mb-4">
				<Stripe isOnRight={false} background={hexToRgb(LMS_WEBAPP_CONFIG_IMPL.brandColor)} />{" "}
				<h3 className="text-5xl mr-2 ml-2 text-primary m-0">{errorType}</h3>
				<Stripe isOnRight={true} background={hexToRgb(LMS_WEBAPP_CONFIG_IMPL.brandColor)} />{" "}
			</div>
			<h2 className="text-center mb-4">{title}</h2>
			<p className="text-center text-base">{description}</p>
			<PrimaryButton
				onClick={() => navigate(DASHBOARD_PATH)}
				className="mt-4"
				label="Zurück zur Übersicht"
			/>
		</div>
	);
};
interface StripeProps {
	background: string;
	isOnRight: boolean;
}
const Stripe = styled.div<StripeProps>`
	height: 20px;
	width: 30%;
	background: ${(props) =>
		`linear-gradient(${props.isOnRight ? `to left` : `to right`}, transparent 0%, rgba(${
			props.background
		}, 0.25) 25%, rgba(${props.background}, 0.5) 50%, rgba(${props.background}, 0.75) 75%, rgb(${
			props.background
		}) 100%)`};
`;
