import { Dialog } from "primereact/dialog";
import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { graphql } from "babel-plugin-relay/macro";
import { useMutation } from "react-relay";
import { toast } from "react-toastify";
import { ValidatedField } from "./form/ValidatedField.component";
import { DefaultTextAreaComponent } from "./form/DefaultTextInput.component";
import { FeedbackButtonV2_LeaveFeedbackMutation } from "@generated/FeedbackButtonV2_LeaveFeedbackMutation.graphql";
import { CustomButtonDialog } from "./content-element/multiplechoice/ShowUserAnswersSummaryDialog.component";
import { LMS_WEBAPP_CONFIG_IMPL } from "../../infecto-lms-webapp-impl/config";
import { PrimaryButton } from "./buttons/PrimaryButton";

const LEAVE_FEEDBACK_MUTATION = graphql`
	mutation FeedbackButtonV2_LeaveFeedbackMutation($input: InfectoLeaveFeedbackInput!) {
		FeedbackV2 {
			infectoLeaveFeedback(input: $input) {
				clientMutationId
			}
		}
	}
`;

interface FormState {
	body: string;
}

interface OwnProps {
	nodeId?: string;
	screen?: string;
}

export function FeedbackButtonV2({ nodeId, screen }: OwnProps) {
	const [modalVisible, setVisible] = useState(false);

	const [leaveFeedback, isLeavingFeedback] =
		useMutation<FeedbackButtonV2_LeaveFeedbackMutation>(LEAVE_FEEDBACK_MUTATION);

	const formik = useFormik<FormState>({
		initialValues: {
			body: "",
		},
		validationSchema: Yup.object().shape({
			body: Yup.string().required("Das Feld Text wird benötigt."),
		}),
		onSubmit: (values) => {
			leaveFeedback({
				variables: {
					input: {
						body: values.body,
						screen,
						nodeId: nodeId,
					},
				},
				onCompleted: () => {
					toast("Feedback erfolgreich hinterlassen!", { type: "success" });
					setVisible(false);
					formik.resetForm();
				},
			});
		},
	});

	return (
		<div>
			<PrimaryButton label="Feedback" onClick={() => setVisible(true)} />

			<Dialog
				className="w-9 md:w-6 lg:w-4"
				header={<h1 className="m-0">Feedback hinterlassen</h1>}
				onHide={() => setVisible(false)}
				visible={modalVisible}
			>
				<div>
					<p>
						Hier können Sie unserem Team Feedback hinterlassen. Egal ob Sie einen Fehler melden
						möchten oder einen Verbesserungsvorschlag haben. Füllen Sie einfach das Formular aus und
						wir kümmern uns darum.
					</p>
					<form onSubmit={formik.handleSubmit} className="p-fluid">
						<ValidatedField<FormState, string>
							name="body"
							placeholder="Bitte geben Sie Ihr Feedback ein."
							formikConfig={formik}
							component={DefaultTextAreaComponent}
						/>
						<CustomButtonDialog
							color={LMS_WEBAPP_CONFIG_IMPL.brandColor}
							disabled={isLeavingFeedback}
							type="submit"
							label="Feedback hinterlassen"
							className="p-mt-2"
						/>
					</form>
				</div>
			</Dialog>
		</div>
	);
}
