/**
 * @generated SignedSource<<ba7fe30cb28145985a24b3892a944e52>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AnsweringTypeV2 = "allowMultipleAnswersAllCorrectMustBeGiven" | "allowMultipleAnswersOnlySomeCorrectMustBeGiven" | "allowOnlyOneAnswer" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type MultipleChoiceElementDisplay_MultipleChoiceElementV2Fragment$data = {
  readonly answerOptions: ReadonlyArray<{
    readonly answer: string;
    readonly image: {
      readonly url: string | null;
    } | null;
  }>;
  readonly answeringType: AnsweringTypeV2;
  readonly image: {
    readonly url: string | null;
  } | null;
  readonly question: string;
  readonly " $fragmentType": "MultipleChoiceElementDisplay_MultipleChoiceElementV2Fragment";
};
export type MultipleChoiceElementDisplay_MultipleChoiceElementV2Fragment$key = {
  readonly " $data"?: MultipleChoiceElementDisplay_MultipleChoiceElementV2Fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"MultipleChoiceElementDisplay_MultipleChoiceElementV2Fragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "concreteType": "File",
  "kind": "LinkedField",
  "name": "image",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MultipleChoiceElementDisplay_MultipleChoiceElementV2Fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "question",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "answeringType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AnswerOptionWithoutCorrect",
      "kind": "LinkedField",
      "name": "answerOptions",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "answer",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "MultipleChoiceLearnElement",
  "abstractKey": null
};
})();

(node as any).hash = "f7a3db33a9c706be4d248a6594c79513";

export default node;
