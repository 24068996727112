import React from "react";
import { PrimaryButton } from "../buttons/PrimaryButton";

interface OwnProps {
	label?: string;
	disabled?: boolean;
	onClick: () => void;
}

export function NodeSubmitButton({ label, disabled, onClick }: OwnProps) {
	return (
		<PrimaryButton
			label={label}
			className="pl-5 pr-5 md:pl-6 md:pr-6 pt-2 pb-2"
			disabled={disabled}
			onClick={onClick}
		/>
	);
}
