export type TkaIcon = "check" | "times" | "default";
export const primeIconsMap: Record<TkaIcon, string> = {
	check: "pi pi-check",
	times: "pi pi-times",
	default: "pi pi-times",
};

class IconConverter {
	constructor(private readonly iconsMap: Record<TkaIcon, string>) {}
	for(tkaIcon: TkaIcon): string {
		return this.iconsMap[tkaIcon] || this.iconsMap["default"];
	}
}
const primeIconsIconConverter = new IconConverter(primeIconsMap);
export const iconConverter = primeIconsIconConverter;
