import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { ProjectNodeIcons_NodeFragment$key } from "@generated/ProjectNodeIcons_NodeFragment.graphql";
import { useFragment } from "react-relay";

const LEARN_OPPORTUNITY_FRAGMENT = graphql`
	fragment ProjectNodeIcons_NodeFragment on LearnOpportunityV2 {
		structureDefinition {
			... on LearnOpportunityRootStructureDefinition {
				extension {
					... on LearnOpportunityRootExtensionImpl {
						reducedDataInTree {
							elementTypes
						}
					}
				}
			}
		}
	}
`;
interface OwnProps {
	learnOpportunityFragmentRef: ProjectNodeIcons_NodeFragment$key;
}
export const ProjectNodeIcons = ({ learnOpportunityFragmentRef }: OwnProps) => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const learnOpportunity = useFragment<ProjectNodeIcons_NodeFragment$key>(
		LEARN_OPPORTUNITY_FRAGMENT,
		learnOpportunityFragmentRef,
	);
	return <div></div>;
};
