// @ts-ignore

declare global {
	interface Window {
		dataLayer: any;
	}
}

export const track = (params: {}) => {
	// @ts-ignore
	window.dataLayer = window.dataLayer || [];
	// @ts-ignore
	window.dataLayer.push(params);
};
