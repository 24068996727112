export const footerPixelsLeftFrame = (value: boolean) => {
	return value ? 170 : 0;
};

export const footerPixelsRightFrame = (value: boolean) => {
	return value ? 102 : 20;
};
export const footerPixelsMainContainer = (value: boolean) => {
	return value ? 170 : 20;
};
