/**
 * @generated SignedSource<<7ad7396f02d7e4a4ef765491eb0729f8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CertificatesScreen_RenewedFachberaterCertificatesQuery$variables = {};
export type CertificatesScreen_RenewedFachberaterCertificatesQuery$data = {
  readonly Viewer: {
    readonly FachberaterCertificates: {
      readonly renewedFachberaterCertificates: ReadonlyArray<{
        readonly certificateFile: {
          readonly name: string;
          readonly url: string | null;
        } | null;
        readonly issuedAt: string;
      }>;
    };
  };
};
export type CertificatesScreen_RenewedFachberaterCertificatesQuery = {
  response: CertificatesScreen_RenewedFachberaterCertificatesQuery$data;
  variables: CertificatesScreen_RenewedFachberaterCertificatesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "issuedAt",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CertificatesScreen_RenewedFachberaterCertificatesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FachberaterCertificatesViewerSchema",
            "kind": "LinkedField",
            "name": "FachberaterCertificates",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "FachberaterCertificate",
                "kind": "LinkedField",
                "name": "renewedFachberaterCertificates",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "File",
                    "kind": "LinkedField",
                    "name": "certificateFile",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CertificatesScreen_RenewedFachberaterCertificatesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FachberaterCertificatesViewerSchema",
            "kind": "LinkedField",
            "name": "FachberaterCertificates",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "FachberaterCertificate",
                "kind": "LinkedField",
                "name": "renewedFachberaterCertificates",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "File",
                    "kind": "LinkedField",
                    "name": "certificateFile",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e7357941858a1242e2c3187e7dac7f03",
    "id": null,
    "metadata": {},
    "name": "CertificatesScreen_RenewedFachberaterCertificatesQuery",
    "operationKind": "query",
    "text": "query CertificatesScreen_RenewedFachberaterCertificatesQuery {\n  Viewer {\n    FachberaterCertificates {\n      renewedFachberaterCertificates {\n        certificateFile {\n          name\n          url\n          id\n        }\n        issuedAt\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ff104ea33bccb4b89ff2a8c1bd80caee";

export default node;
