/**
 * @generated SignedSource<<4a87a1744580f4fdf0796c535bdb1f04>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NodeDescription_NodeFragment$data = {
  readonly description: string | null;
  readonly " $fragmentType": "NodeDescription_NodeFragment";
};
export type NodeDescription_NodeFragment$key = {
  readonly " $data"?: NodeDescription_NodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NodeDescription_NodeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NodeDescription_NodeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    }
  ],
  "type": "LearnOpportunityV2",
  "abstractKey": null
};

(node as any).hash = "f723f843f9f4015acd75eb55e49ab949";

export default node;
