import tinycolor from "tinycolor2";
import { LMS_WEBAPP_CONFIG_IMPL } from "../../../infecto-lms-webapp-impl/config";

export const calculateButtonStateColor = () => {
	const baseColor = LMS_WEBAPP_CONFIG_IMPL.brandColor;

	const hoverColor = tinycolor(baseColor).darken(3.5).toString();
	const pressColor = tinycolor(baseColor).darken(15).toString();

	return { hoverColor, pressColor, baseColor };
};
