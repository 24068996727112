import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/browser";
import { decodeToken } from "react-jwt";
import { ReduxState } from "../redux-store";
import { Permission } from "@generated/CurrentUser_Query.graphql";
import { encode as base64_encode } from "base-64";

export type JwtClaimData = {
	userId: string;
	accountId: string;
	permissionsInAccount: Permission[];
	accountsWithAccess: string[];
	isLoggedIn: boolean;
};

export type JwtTokenData = {
	accessToken: string;
	refreshToken: string;
};

export type AuthState = {
	isLoggedIn: boolean;
	shouldLogout: boolean;
	loginData?: JwtTokenData;
};

let parsedLoginData: JwtTokenData | undefined;

export const LOCAL_STORAGE_LOGIN_DATA_KEY = "tkt-login-data";

try {
	const storedData = localStorage.getItem(LOCAL_STORAGE_LOGIN_DATA_KEY);

	parsedLoginData = storedData ? JSON.parse(storedData) : undefined;
} catch {}

const INITIAL_STATE: AuthState = {
	isLoggedIn: !!parsedLoginData,
	shouldLogout: false,
	loginData: parsedLoginData,
};

const authSlice = createSlice({
	name: "auth",
	initialState: INITIAL_STATE,
	reducers: {
		setLoggedIn: (state, action: PayloadAction<{ tokenData: JwtTokenData; redirect?: string }>) => {
			state.isLoggedIn = true;
			state.shouldLogout = false;
			state.loginData = action.payload.tokenData;

			const decodedToken = decodeToken<JwtClaimData>(state.loginData.accessToken);

			Sentry.setUser({ id: decodedToken?.userId, accountId: decodedToken?.accountId });

			localStorage.setItem(LOCAL_STORAGE_LOGIN_DATA_KEY, JSON.stringify(action.payload.tokenData));

			if (action.payload.redirect) {
				window.location.replace("/");
			} else {
				window.location.reload();
			}
		},
		refreshLogin: (state, action: PayloadAction<{ loginData: JwtTokenData }>) => {
			state.isLoggedIn = true;
			state.loginData = {
				accessToken: action.payload.loginData.accessToken,
				refreshToken: action.payload.loginData.refreshToken,
			};

			const decodedToken = decodeToken<JwtClaimData>(state.loginData.accessToken);

			Sentry.setUser({ id: decodedToken?.userId, accountId: decodedToken?.accountId });

			localStorage.setItem(LOCAL_STORAGE_LOGIN_DATA_KEY, JSON.stringify(action.payload.loginData));
		},
		logout: (state) => {
			Sentry.setUser(null);
			state.isLoggedIn = false;
			state.loginData = undefined;
			state.shouldLogout = true;
			localStorage.removeItem(LOCAL_STORAGE_LOGIN_DATA_KEY);
			window.location.reload();
		},
	},
});

export const { setLoggedIn, refreshLogin, logout } = authSlice.actions;
export const AuthSliceReducer = authSlice.reducer;

export const selectAuthSlice = (state: ReduxState) => state.auth;

export const selectJwtClaimData = createSelector(selectAuthSlice, (state) => {
	if (state.loginData) {
		return decodeToken<JwtClaimData>(state.loginData.accessToken);
	} else {
		return null;
	}
});

export const selectLoginData = createSelector(selectAuthSlice, (state) => {
	return state.loginData;
});

export const selectCurrentAccountId = createSelector(selectJwtClaimData, (jwtClaimData) => {
	return jwtClaimData?.accountId;
});

export const selectDecodedAccountId = createSelector(selectJwtClaimData, (jwtClaimData) => {
	return base64_encode(`Account:${jwtClaimData?.accountId}`);
});
