/**
 * @generated SignedSource<<a8ee0856503a7995aac7e10d87ad1745>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ContentSubmissionStatus = "active" | "failed" | "passed" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ContentSubmissionScreen_ContentSubmissionFragment$data = {
  readonly definition: {
    readonly status: ContentSubmissionStatus;
  };
  readonly id: string;
  readonly learnOpportunity: {
    readonly " $fragmentSpreads": FragmentRefs<"NodeContainer_LearnOpportunityV2Fragment">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"ContentElement_ContentSubmissionFragment" | "ContentSubmissionEnd_ContentSubmissionFragment">;
  readonly " $fragmentType": "ContentSubmissionScreen_ContentSubmissionFragment";
};
export type ContentSubmissionScreen_ContentSubmissionFragment$key = {
  readonly " $data"?: ContentSubmissionScreen_ContentSubmissionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentSubmissionScreen_ContentSubmissionFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentSubmissionScreen_ContentSubmissionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LearnOpportunityV2",
      "kind": "LinkedField",
      "name": "learnOpportunity",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "NodeContainer_LearnOpportunityV2Fragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "definition",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContentSubmissionEnd_ContentSubmissionFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContentElement_ContentSubmissionFragment"
    }
  ],
  "type": "ContentSubmission",
  "abstractKey": null
};

(node as any).hash = "301a77f6c8c5cdd96044086ff38f0312";

export default node;
