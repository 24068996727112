import React from "react";
import { Button } from "primereact/button";
import styled from "styled-components";
import { calculateButtonStateColor } from "../../functions/helpers/calculateButtonStateColor";
import { LMS_WEBAPP_CONFIG_IMPL } from "../../../infecto-lms-webapp-impl/config";
import { sharedLabelStyles } from "./Label";

export interface ButtonProps {
	colorHover: string;
	colorPress: string;
	color: string;
	fontFamily: string;
}
const ButtonPrimaryContainer = styled(Button)<ButtonProps>`
	display: inline-flex;
	padding: 10px 20px;
	justify-content: center;
	align-items: center;
	border-radius: 4px !important;
	background-color: ${(props) => props.color} !important;
	border-color: ${(props) => props.color} !important;
	.p-button-label {
		${sharedLabelStyles}
	}
	&:hover {
		background-color: ${(props) => props.colorHover} !important;
		border-color: ${(props) => props.colorHover} !important;
	}
	&:active {
		background-color: ${(props) => props.colorPress} !important;
		border-color: ${(props) => props.colorPress} !important;
	}
	&:disabled {
		opacity: 0.6;
	}
`;
export interface ButtonOwnProps {
	className?: any;
	label?: string;
	onClick?: (e?: any) => void;
	children?: any;
	disabled?: boolean;
	tooltipOptions?: any;
	tooltip?: string;
	type?: "button" | "reset" | "submit";
	icon?: any;
	loading?: boolean;
	autoFocus?: boolean;
}

export const PrimaryButton = ({
	className,
	label,
	onClick,
	children,
	disabled,
	tooltipOptions,
	tooltip,
	type,
	icon,
	loading,
	autoFocus,
}: ButtonOwnProps) => {
	const { hoverColor, pressColor } = calculateButtonStateColor();

	return (
		<ButtonPrimaryContainer
			fontFamily={LMS_WEBAPP_CONFIG_IMPL.fontFamily}
			color={LMS_WEBAPP_CONFIG_IMPL.brandColor}
			tooltipOptions={tooltipOptions}
			disabled={disabled}
			colorHover={hoverColor}
			colorPress={pressColor}
			className={`p-button-primary bg-primary border-primary ${className}`}
			onClick={onClick}
			label={label}
			tooltip={tooltip}
			type={type}
			icon={icon}
			loading={loading}
			autoFocus={autoFocus}
		>
			{children}
		</ButtonPrimaryContainer>
	);
};
