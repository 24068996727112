/**
 * @generated SignedSource<<5db14c36bff536623ff50f79e26f8c5c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type MessageModal_AcknowledgeMessagesMutation$variables = {
  messageId: string;
};
export type MessageModal_AcknowledgeMessagesMutation$data = {
  readonly Viewer: {
    readonly Messaging: {
      readonly acknowledgeMessages: {
        readonly messageInformation: {
          readonly id: string;
          readonly numUnseenMessages: number;
        };
        readonly messages: ReadonlyArray<{
          readonly hasSeen: boolean;
          readonly id: string;
        }>;
      } | null;
    };
  };
};
export type MessageModal_AcknowledgeMessagesMutation = {
  response: MessageModal_AcknowledgeMessagesMutation$data;
  variables: MessageModal_AcknowledgeMessagesMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "messageId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ViewerMutationType",
    "kind": "LinkedField",
    "name": "Viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MessagingViewerMutationSchema",
        "kind": "LinkedField",
        "name": "Messaging",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "fields": [
                  {
                    "items": [
                      {
                        "kind": "Variable",
                        "name": "messageIds.0",
                        "variableName": "messageId"
                      }
                    ],
                    "kind": "ListValue",
                    "name": "messageIds"
                  }
                ],
                "kind": "ObjectValue",
                "name": "input"
              }
            ],
            "concreteType": "AcknowledgeMessagesPayload",
            "kind": "LinkedField",
            "name": "acknowledgeMessages",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Message",
                "kind": "LinkedField",
                "name": "messages",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasSeen",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "MessageInformation",
                "kind": "LinkedField",
                "name": "messageInformation",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "numUnseenMessages",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MessageModal_AcknowledgeMessagesMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MessageModal_AcknowledgeMessagesMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "5247917106427d5dba2b974385523d8b",
    "id": null,
    "metadata": {},
    "name": "MessageModal_AcknowledgeMessagesMutation",
    "operationKind": "mutation",
    "text": "mutation MessageModal_AcknowledgeMessagesMutation(\n  $messageId: ID!\n) {\n  Viewer {\n    Messaging {\n      acknowledgeMessages(input: {messageIds: [$messageId]}) {\n        messages {\n          id\n          hasSeen\n        }\n        messageInformation {\n          id\n          numUnseenMessages\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "eb68e01396c5cc21264d92b22dbbb817";

export default node;
