/**
 * @generated SignedSource<<14b776e31148f4fcf9740e29ebcfc44b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AcceptPrivacyInput = {
  acceptanceText: string;
  clientMutationId?: string | null;
  privacyId: string;
};
export type CurrentUser_AcceptPrivacyMutation$variables = {
  input: AcceptPrivacyInput;
};
export type CurrentUser_AcceptPrivacyMutation$data = {
  readonly Viewer: {
    readonly Privacy: {
      readonly acceptPrivacy: {
        readonly clientMutationId: string | null;
      } | null;
    };
  };
};
export type CurrentUser_AcceptPrivacyMutation = {
  response: CurrentUser_AcceptPrivacyMutation$data;
  variables: CurrentUser_AcceptPrivacyMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ViewerMutationType",
    "kind": "LinkedField",
    "name": "Viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PrivacyViewerMutationSchema",
        "kind": "LinkedField",
        "name": "Privacy",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "input",
                "variableName": "input"
              }
            ],
            "concreteType": "AcceptPrivacyPayload",
            "kind": "LinkedField",
            "name": "acceptPrivacy",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "clientMutationId",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CurrentUser_AcceptPrivacyMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CurrentUser_AcceptPrivacyMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "38e7f5fbb6d7dacee560007cedcc2341",
    "id": null,
    "metadata": {},
    "name": "CurrentUser_AcceptPrivacyMutation",
    "operationKind": "mutation",
    "text": "mutation CurrentUser_AcceptPrivacyMutation(\n  $input: AcceptPrivacyInput!\n) {\n  Viewer {\n    Privacy {\n      acceptPrivacy(input: $input) {\n        clientMutationId\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b42652dc01036f41b021aa719c7ca020";

export default node;
