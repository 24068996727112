import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import styled from "styled-components";
import { Button } from "primereact/button";
import { MessagePage_MessageDefinitionPageFragment$key } from "@generated/MessagePage_MessageDefinitionPageFragment.graphql";
import { MessageDefinitionButton } from "./MessageDefinitionButton.component";

const MESSAGE_DEFINITION_PAGE_FRAGMENT = graphql`
	fragment MessagePage_MessageDefinitionPageFragment on MessageDefinitionPage {
		image {
			url
		}
		internalTitle
		messageDefinition {
			buttonOpt {
				...MessageDefinitionButton_MessageDefinitionButtonFragment
			}
		}
	}
`;

interface OwnProps {
	modalWidth: number;
	onClose?: () => void;
	messageDefinitionPageFragmentRef?: MessagePage_MessageDefinitionPageFragment$key;
	hideModal: () => void;
}

export function MessagePage({
	modalWidth,
	onClose,
	messageDefinitionPageFragmentRef,
	hideModal,
}: OwnProps) {
	const page = useFragment<MessagePage_MessageDefinitionPageFragment$key>(
		MESSAGE_DEFINITION_PAGE_FRAGMENT,
		messageDefinitionPageFragmentRef || null,
	);

	if (!page || !page.messageDefinition) return null;

	return (
		<>
			<MessageContainer url={page.image!.url!} modalWidth={modalWidth}>
				<ContentContainer modalWidth={modalWidth}>
					<HeaderModal>
						<Button
							style={{ width: 20, height: 20 }}
							className="p-button-outlined p-button-white text-white "
							icon="pi pi-times"
							onClick={hideModal}
						/>
					</HeaderModal>
					<Title modalWidth={modalWidth}>{page.internalTitle}</Title>
					<ButtonContainer>
						{onClose && (
							<MessageDefinitionButton
								onClose={onClose}
								modalWidth={modalWidth}
								messageDefinitionButtonFragmentRef={page.messageDefinition.buttonOpt}
							/>
						)}
					</ButtonContainer>
				</ContentContainer>
			</MessageContainer>
		</>
	);
}

const MessageContainer = styled.div<{ modalWidth: number; url: string }>`
	width: 350px;
	height: 458px;
	background-image: url(${(props) => props.url});
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	cursor: pointer;
	@media (max-width: 500px) {
		width: 308px;
		height: 408px;
	}
`;
const HeaderModal = styled.div`
	display: flex;
	justify-content: flex-end;
	padding: 20px 20px;
	width: 100%;
`;
const Title = styled.p<{ modalWidth: number }>`
	text-align: center;
	font-size: 15px;
	color: white;
	padding: 10px;
	width: 90%;
	height: 80%;
	overflow: auto;
	@media (max-width: 900px) {
		font-size: 13px;
	} ;
`;
const ContentContainer = styled.div<{ modalWidth: number }>`
	display: flex;
	width: 100%;
	height: 100%;
	flex-direction: column;
	align-items: center;
	background: rgba(0, 0, 0, 0.5);
	border-radius: 10px;
`;
const ButtonContainer = styled.div`
	margin: 20px;
`;
