import React, { useEffect } from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay";
import styled from "styled-components";
import { NavLink, useMatch, useNavigate } from "react-router-dom";
import Wissenwirkt from "../../infecto-lms-webapp-impl/assets/logo.png";
import { LandingPageScreen_Query } from "@generated/LandingPageScreen_Query.graphql";
import { LANDING_PAGE_PATH } from "../../infecto-lms-webapp-impl/router/routes/non-auth/landing-pages.routes";
import { DASHBOARD_PATH } from "../../infecto-lms-webapp-impl/router/routes/auth/dashboard.routes";
import { NodeCard } from "../../infecto-lms-webapp-impl/components/NodeCard.component";

const QUERY = graphql`
	query LandingPageScreen_Query($first: Int, $after: String, $filterByUrlParam: String!) {
		SingleLandingPageV2 {
			singleLandingPage(first: $first, after: $after, filterByUrlParam: $filterByUrlParam) {
				pageInfo {
					endCursor
					hasPreviousPage
					hasNextPage
					startCursor
				}
				edges {
					node {
						description
						image {
							id
							url
						}
						rootNodes {
							... on LearnOpportunitiesV2Connection {
								edges {
									node {
										...NodeCard_LandingPageNodeFragment
									}
								}
							}
						}
						title
						urlParam
					}
				}
			}
		}
	}
`;

export function LandingPageScreen() {
	const {
		params: { urlParam },
	} = useMatch(LANDING_PAGE_PATH)!;
	const navigate = useNavigate();
	const query = useLazyLoadQuery<LandingPageScreen_Query>(QUERY, {
		filterByUrlParam: urlParam!,
	});
	const result = query.SingleLandingPageV2.singleLandingPage.edges!.map((e) => e!.node!);
	useEffect(() => {
		const destinationPath = window.location.pathname;
		if (
			document.referrer === window.location.href ||
			document.referrer.indexOf(window.location.host) === -1
		) {
			navigate("/", { replace: true });
			navigate(destinationPath);
		}
	}, []);
	return (
		<div style={{ width: "100%" }}>
			{result.length > 0 ? (
				<PageContainer key={result[0].urlParam}>
					<Header>
						<NavLink to={DASHBOARD_PATH}>
							<LogoWrapper>
								<Image imageurl={Wissenwirkt} />
							</LogoWrapper>
						</NavLink>
					</Header>

					<BodyContainer className="surface-100">
						<ContentCard className="shadow-1">
							<TitleTag>{result[0].title}</TitleTag>
							<WrapperContainer>
								{result[0].description && (
									<DescrContainer dangerouslySetInnerHTML={{ __html: result[0].description }} />
								)}
								{result[0].image !== null && (
									<ImageWrapper>
										<Image imageurl={result[0].image!.url} />
									</ImageWrapper>
								)}
							</WrapperContainer>
						</ContentCard>
						<div className="grid mt-2 mb-4">
							{result[0].rootNodes?.edges?.map((rootNode: any) => (
								<div key={rootNode.node.id + 1} className="lg:col-6 col-12">
									<NodeCard landingPageFragmentRef={rootNode.node} isLandingPage={true} />
								</div>
							))}
						</div>
					</BodyContainer>
				</PageContainer>
			) : null}
		</div>
	);
}

interface ImageProps {
	imageurl?: string | null;
}

const PageContainer = styled.div`
	width: 100%;
	height: 100%;
`;

const Header = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	padding: 20px 15%;
	border-bottom-width: 1px !important;
	border-bottom-style: solid;
	border-color: #e0e0e0;
	align-items: center;
	@media (max-width: 699px) {
		padding: 15px 10%;
	}
	@media (max-width: 550px) {
		padding: 10px 5%;
	}
`;

const LogoWrapper = styled.div`
	width: 80px;
	height: 80px;
	display: flex;
	align-items: center;

	@media (max-width: 699px) {
		width: 100px;
		height: 100px;
	}
	@media (max-width: 550px) {
		width: 60px;
		height: 60px;
	}
`;
const Image = styled.div<ImageProps>`
	width: 100%;
	height: 100%;
	background-image: url(${(p) => p.imageurl});
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
`;
const WrapperContainer = styled.div`
	display: flex;
	justify-content: space-between;
	@media (max-width: 1100px) {
		flex-direction: column-reverse;
		justify-content: center;
		align-items: center;
	}
`;
const ContentCard = styled.div`
	border-radius: 10px;
	background: white;
	padding: 60px;
	@media (max-width: 699px) {
		padding: 30px;
	}
`;
const DescrContainer = styled.div`
	margin-right: 10px;
	width: 55%;
	padding: 10px 20px 10px 0px;
	font-size: 18px;
	line-height: 1.5;
	@media (max-width: 1100px) and (min-width: 699px) {
		padding: 10px;
		width: 90%;
		margin-top: 20px;
	}
	@media (max-width: 699px) {
		padding: 10px 0px;
		width: 100%;
		font-size: 15px;
		margin-top: 20px;
	}
	@media (max-width: 550px) {
		font-size: 13px;
	}
`;
const ImageWrapper = styled.div`
	width: 350px;
	height: 350px;
	padding-top: 20px;
	@media (max-width: 1100px) and (min-width: 699px) {
		width: 80%;
	}
	@media (max-width: 699px) {
		width: 250px;
		height: 250px;
		margin: 0 auto;
	}
`;
const TitleTag = styled.div`
	font-weight: bold;
	font-size: 30px;
	color: #495057;
	margin-bottom: 20px;
	@media (max-width: 1100px) {
		text-align: center;
		padding-left: 0px;
	}
	@media (max-width: 699px) {
		width: 100%;
		font-size: 25px;
		margin-bottom: 0px;
	}
	@media (max-width: 550px) {
		font-size: 20px;
		margin-bottom: 0px;
	}
`;
const BodyContainer = styled.div`
	padding: 30px 15%;
	width: 100%;
	height: 100%;
	@media (max-width: 699px) {
		padding: 30px 10%;
	}
	@media (max-width: 550px) {
		padding: 30px 5%;
	}
`;
