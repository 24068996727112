import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { NodeTag } from "./NodeTag.component";
import styled from "styled-components";
import { NodeTagList_LearnOpportunityRootStructureDefinitionFragment$key } from "@generated/NodeTagList_LearnOpportunityRootStructureDefinitionFragment.graphql";
import { LMS_WEBAPP_CONFIG_IMPL } from "../../../infecto-lms-webapp-impl/config";

const LEARN_OPPORTUNITY_FRAGMENT = graphql`
	fragment NodeTagList_LearnOpportunityRootStructureDefinitionFragment on LearnOpportunityRootStructureDefinition {
		tags {
			id
			...NodeTag_TagFragment
		}
	}
`;

interface OwnProps {
	className?: string;
	learnOpportunityRootStructureDefinitionFragmentRef: NodeTagList_LearnOpportunityRootStructureDefinitionFragment$key;
	fontSize?: string;
}

export function NodeTagList({
	className,
	learnOpportunityRootStructureDefinitionFragmentRef,
	fontSize,
}: OwnProps) {
	const isPaedia =
		LMS_WEBAPP_CONFIG_IMPL.brandId ===
		"QnJhbmQ6QnJhbmQ6ZTNiMTNlYzktNzZjMC00YTUyLWJkNDAtZjIxYWViMTU3MzJj";

	const learnOpportunity =
		useFragment<NodeTagList_LearnOpportunityRootStructureDefinitionFragment$key>(
			LEARN_OPPORTUNITY_FRAGMENT,
			learnOpportunityRootStructureDefinitionFragmentRef,
		);

	return learnOpportunity.tags.length > 0 ? (
		<TagsContainer isPaediaBrand={isPaedia} className={`flex flex-wrap ${className}`}>
			{learnOpportunity.tags.map((tag) => (
				<NodeTag fontSize={fontSize} key={tag.id} tagFragmentRef={tag} />
			))}
		</TagsContainer>
	) : null;
}
interface BrandProps {
	isPaediaBrand: boolean;
}
const TagsContainer = styled.div<BrandProps>`
	pointer-events: ${(props) => (props.isPaediaBrand ? "none" : "auto !important")};
	${(props) => !props.isPaediaBrand && "cursor: not-allowed !important"}
`;
