import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay";
import { NodeInstructors_Query } from "@generated/NodeInstructors_Query.graphql";
import { NodeInstructorCard } from "./NodeInstructorCard.component";

const QUERY = graphql`
	query NodeInstructors_Query($selectedInstructors: [ID!]!) {
		Instructors {
			Instructors(ids: $selectedInstructors) {
				id
				name
				title
				description
				portraitFile {
					id
					url
				}
				...NodeInstructorCard_InstructorFragment
			}
		}
	}
`;

interface OwnProps {
	ids: readonly string[];
}
export const NodeInstructors = ({ ids }: OwnProps) => {
	const query = useLazyLoadQuery<NodeInstructors_Query>(
		QUERY,
		{
			selectedInstructors: ids,
		},
		{ fetchPolicy: "network-only" },
	);

	const instructors = query.Instructors.Instructors.map((b) => b);

	return (
		<div>
			<h3>Expert:innen</h3>
			<div className="grid w-12 justify-content-center md:justify-content-start">
				{instructors &&
					instructors
						.sort((a, b) =>
							a.name
								.split(" ")
								.reverse()
								.join(" ")
								.localeCompare(b.name.split(" ").reverse().join("")),
						)
						.map((instructor) => (
							<NodeInstructorCard key={instructor.id} instructorRef={instructor} />
						))}
			</div>
		</div>
	);
};
