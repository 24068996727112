import React from "react";
import styled from "styled-components";
import { Carousel, CarouselResponsiveOptions } from "primereact/carousel";
import { CarousalNode_NodeFragment$key } from "@generated/CarousalNode_NodeFragment.graphql";
import { CarousalNode } from "./CarousalNode";

const NodesCarouselContainer = styled.div`
	background-color: white;
	padding: 1rem 0.5rem;
	@media screen and (min-width: 481px) and (max-width: 786px) {
		width: 90vw;
	}
	@media screen and (max-width: 480px) {
		width: 90vw;
	}
	border: 1px solid #d5d5d5;
	border-radius: 5px;
`;

const StyledCarousal = styled(Carousel)`
	.p-carousel-indicators {
		display: none;
	}
`;

interface OwnProps {
	nodes: readonly CarousalNode_NodeFragment$key[];
	categoryLogo: string;
	categoryTitle: string;
	tagId: string;
}

export const NodesCarousal = ({ nodes, categoryLogo, categoryTitle, tagId }: OwnProps) => {
	const isThemenHeft = tagId == "VGFnOnBhZWRpYS10YWctMQ==";

	const isPaediaSchoolProduct =
		tagId === "VGFnOlRhZzplNjc2YTc1Yy00YTY5LTRhY2EtYjZjMi03ZThjMzI0ODgwYjg=";

	const responsiveOptions: CarouselResponsiveOptions[] = [
		{
			breakpoint: "1920px",
			numVisible: isThemenHeft ? 4 : 3,
			numScroll: 1,
		},
		{
			breakpoint: "1536px",
			numVisible: isThemenHeft ? 3 : 2,
			numScroll: 1,
		},
		{
			breakpoint: "1366px",
			numVisible: isThemenHeft ? 2 : 2,
			numScroll: 1,
		},
		{
			breakpoint: "1024px",
			numVisible: isThemenHeft ? 1 : 1,
			numScroll: 1,
		},
		{
			breakpoint: "768px",
			numVisible: isThemenHeft ? 1 : 1,
			numScroll: 1,
		},
		{
			breakpoint: "414px",
			numVisible: isThemenHeft ? 1 : 1,
			numScroll: 1,
		},
	];
	const responsiveCardWidth = () => {
		if (window.innerWidth < 480) {
			return isThemenHeft ? 190 : 200;
		} else if (window.innerWidth > 480 && window.innerWidth < 769) {
			return isThemenHeft ? 220 : 250;
		} else return isThemenHeft ? 230 : 320;
	};
	const responsiveCardHeight = () => {
		if (window.innerWidth < 480) {
			return isThemenHeft ? 250 : 150;
		} else if (window.innerWidth > 480 && window.innerWidth < 769) {
			return isThemenHeft ? 280 : 190;
		} else return isThemenHeft ? 300 : 250;
	};
	return (
		<>
			<div className="flex flex-row align-items-center align-self-start">
				<ImageContainer>
					<Image isPaediaLogo={isPaediaSchoolProduct ? 100 : 155} imageurl={categoryLogo} />
				</ImageContainer>
				<h3 className="ml-2">{categoryTitle}</h3>
			</div>

			<NodesCarouselContainer className="mt-4 mb-4">
				<StyledCarousal
					value={nodes as []}
					responsiveOptions={responsiveOptions}
					numVisible={isThemenHeft ? 6 : 4}
					numScroll={1}
					itemTemplate={(node) => (
						<CarousalNode
							height={responsiveCardHeight()}
							width={responsiveCardWidth()}
							nodeRef={node}
						/>
					)}
				/>
			</NodesCarouselContainer>
		</>
	);
};

interface ImageProps {
	imageurl?: string | null;
	isPaediaLogo: number;
}

const ImageContainer = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	width: 155px;
	height: 60px;
	margin-bottom: 2px;
`;
const Image = styled.div<ImageProps>`
	width: ${(p) => p.isPaediaLogo}px;
	height: 100%;
	background-image: url(${(p) => p.imageurl});
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
`;
