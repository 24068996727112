import { Dialog } from "primereact/dialog";
import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay";
import { toast } from "react-toastify";
import { PrivateAddressDialog_SupplyPrivateAddressMutation } from "@generated/PrivateAddressDialog_SupplyPrivateAddressMutation.graphql";
import { ValidatedField } from "../form/ValidatedField.component";
import { DefaultTextFieldComponent } from "../form/DefaultTextInput.component";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import {
	InfectopharmCountry,
	PrivateAddressDialog_UserFragment$key,
} from "@generated/PrivateAddressDialog_UserFragment.graphql";
import { CheckboxChangeParams } from "primereact/checkbox";
import { LMS_WEBAPP_CONFIG_IMPL } from "../../../infecto-lms-webapp-impl/config";
import styled from "styled-components";
import { PrimaryButton } from "../buttons/PrimaryButton";
import { StyledCheckbox } from "../../../infecto-lms-webapp-impl/components/relay/ProjectSearchFilters";
import { calculateButtonStateColor } from "../../functions/helpers/calculateButtonStateColor";

const USER_FRAGMENT = graphql`
	fragment PrivateAddressDialog_UserFragment on User {
		id
		extension {
			... on InfectopharmUserExtensionImpl {
				privateAddress {
					street
					postalCode
					city
					country
				}
			}
		}
	}
`;

const SUPPLY_PRIVATE_ADDRESS_MUTATION = graphql`
	mutation PrivateAddressDialog_SupplyPrivateAddressMutation($input: SupplyPrivateAddressInput!) {
		Viewer {
			RPC {
				supplyPrivateAddress(input: $input) {
					clientMutationId
				}
			}
		}
	}
`;

interface FormState {
	street: string;
	postalCode: string;
	city: string;
	country: InfectopharmCountry;
	legal: boolean;
	legalTwo: boolean;
}

interface OwnProps {
	userFragment: PrivateAddressDialog_UserFragment$key | null;
}

export function PrivateAddressDialog({ userFragment }: OwnProps) {
	const [modalVisible, setVisible] = useState(false);

	const user = useFragment<PrivateAddressDialog_UserFragment$key>(USER_FRAGMENT, userFragment);

	const [isPrivateAddressChecked, setPrivateAddressChecked] = useState(
		user?.extension.privateAddress && user?.extension.privateAddress.country !== "Unknown",
	);

	const [supplyPrivateAddress, isSupplyingPrivateAddress] =
		useMutation<PrivateAddressDialog_SupplyPrivateAddressMutation>(SUPPLY_PRIVATE_ADDRESS_MUTATION);

	const formik = useFormik<FormState>({
		initialValues: {
			street: user?.extension.privateAddress?.street || "",
			postalCode: user?.extension.privateAddress?.postalCode || "",
			city: user?.extension.privateAddress?.city || "",
			country: user?.extension.privateAddress?.country || "Deutschland",
			legal: false,
			legalTwo: false,
		},
		validationSchema: Yup.object().shape({
			street: Yup.string().required("Straße wird benötigt"),
			postalCode: Yup.string().when("country", (country) => {
				return Yup.string()
					.length(country === "Deutschland" ? 5 : 4, `Bitte geben Sie eine gültige PLZ ein.`)
					.matches(/^\d+$/, `Bitte geben Sie eine gültige PLZ ein.`)
					.required("PLZ wird benötigt.");
			}),
			city: Yup.string().required("Stadt wird benötigt"),
			legal: Yup.boolean()
				.oneOf([true], "Das Feld ist benötigt.")
				.required("Das Feld ist benötigt."),
			legalTwo: Yup.boolean()
				.oneOf([true], "Das Feld ist benötigt.")
				.required("Das Feld ist benötigt."),
		}),
		onSubmit: (values) => {
			supplyPrivateAddress({
				variables: {
					input: {
						street: values.street,
						postalCode: values.postalCode,
						city: values.city,
						country: values.country,
					},
				},
				onCompleted: () => {
					toast("Adresse erfolgreich hinterlassen!", { type: "success" });
					setVisible(false);
				},
			});
		},
	});

	const handleChange = (val: CheckboxChangeParams) => {
		setPrivateAddressChecked(!isPrivateAddressChecked);

		if (val.checked) {
			setVisible(!isPrivateAddressChecked);
		} else {
			supplyPrivateAddress({
				variables: {
					input: {
						street: "",
						postalCode: "",
						city: "",
						country: "Unknown",
					},
				},
			});
		}
	};

	return (
		<div className={"justify-content-center flex flex-column"}>
			<p className="text-lg text-center">
				Alternativ können wir Ihnen das Zertifikat auch an Ihre Privatadresse senden.
			</p>
			<div className="field-checkbox justify-content-center">
				<StyledCheckbox
					colorPress={calculateButtonStateColor().pressColor}
					color={LMS_WEBAPP_CONFIG_IMPL.brandColor}
					onChange={handleChange}
					checked={isPrivateAddressChecked}
				/>
				<label className={"text-lg"} htmlFor="city4">
					Ja, ich möchte das Zertifikat gerne an meine Privatadresse zugesendet bekommen.
				</label>
			</div>

			<p className={"text-lg my-0 text-center"}>
				Sie können Ihre Adresse jederzeit in Ihrem Benutzerkonto unter dem Menüpunkt "Einstellungen"
				ändern oder löschen.
			</p>

			<Dialog
				className="w-9 md:w-6 lg:w-4"
				header={<h1 className="m-0">Private Anschrift</h1>}
				onHide={() => setVisible(false)}
				visible={modalVisible}
			>
				<div>
					<p className={"text-lg"}>
						Sie können Ihre Daten jederzeit in Ihrem Benutzerkonto unter dem Menüpunkt
						"Einstellungen" bearbeiten.
					</p>

					<form onSubmit={formik.handleSubmit} className="p-fluid">
						<ValidatedField<FormState, string>
							name={"street"}
							label={"Straße und Hausnummer"}
							iconClass={"pi-street"}
							formikConfig={formik}
							required={true}
							component={DefaultTextFieldComponent}
						/>
						<ValidatedField<FormState, string>
							name={"postalCode"}
							label={"PLZ"}
							iconClass={"pi-street"}
							formikConfig={formik}
							required={true}
							component={DefaultTextFieldComponent}
						/>
						<ValidatedField<FormState, string>
							name={"city"}
							label={"Stadt"}
							iconClass={"pi-street"}
							formikConfig={formik}
							required={true}
							component={DefaultTextFieldComponent}
						/>
						<ValidatedField<FormState, string>
							name={"country"}
							label={"Land"}
							iconClass={"pi-street"}
							formikConfig={formik}
							component={({ fieldValue, updateField, fieldName, isValid }) => {
								return (
									<div>
										<Dropdown
											name={fieldName}
											value={fieldValue}
											onChange={(e) => updateField(e.value)}
											options={[
												{ value: "Deutschland", label: "Deutschland" },
												{
													value: "Oesterreich",
													label: "Österreich",
												},
											]}
											className={classNames({ "p-invalid": !isValid })}
										/>
									</div>
								);
							}}
						/>
						<ValidatedField<FormState, boolean>
							name={"legal"}
							required={true}
							formikConfig={formik}
							component={({ fieldValue, updateField, fieldName }) => {
								return (
									<div className="p-col-12">
										<StyledCheckbox
											colorPress={calculateButtonStateColor().pressColor}
											color={LMS_WEBAPP_CONFIG_IMPL.brandColor}
											className="mr-2"
											inputId={fieldName}
											onChange={(e) => updateField(e.checked)}
											checked={fieldValue}
										/>
										<label htmlFor={fieldName} className="p-checkbox-label">
											Ich habe die{" "}
											<Link
												target="_blank"
												rel="noopener noreferrer"
												href={LMS_WEBAPP_CONFIG_IMPL.privacyLink}
											>
												Datenschutzbestimmungen
											</Link>{" "}
											gelesen.*
										</label>
									</div>
								);
							}}
						/>
						<ValidatedField<FormState, boolean>
							name={"legalTwo"}
							required={true}
							formikConfig={formik}
							component={({ fieldValue, updateField, fieldName }) => {
								return (
									<div className="flex p-col-12">
										<StyledCheckbox
											colorPress={calculateButtonStateColor().pressColor}
											color={LMS_WEBAPP_CONFIG_IMPL.brandColor}
											className="mr-2"
											inputId={fieldName}
											onChange={(e) => updateField(e.checked)}
											checked={fieldValue}
										/>
										<label htmlFor={fieldName} className="p-checkbox-label">
											Hiermit stimme ich der folgenden Verarbeitung meiner personenbezogenen Daten
											durch die Pädia GmbH zu. Hierzu zählt die Datenerhebung, Datenspeicherung
											jedoch keine Weitergabe an Dritte. Die Daten werden ausschließlich zum Versand
											des Fachberaterzertifikates verwendet.*
										</label>
									</div>
								);
							}}
						/>

						<p>Pflichtfeld*</p>

						<PrimaryButton
							disabled={isSupplyingPrivateAddress || !formik.isValid}
							type="submit"
							label="Speichern"
							className="p-mt-2"
						/>
					</form>
				</div>
			</Dialog>
		</div>
	);
}

const Link = styled.a`
	text-decoration: underline;
	color: var(--primary-color);
`;
