/**
 * @generated SignedSource<<51545b9a0339fc11082955449d850941>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TextElementDisplayWithAnswers_TextElementV2Fragment$data = {
  readonly text: string;
  readonly title: string;
  readonly " $fragmentType": "TextElementDisplayWithAnswers_TextElementV2Fragment";
};
export type TextElementDisplayWithAnswers_TextElementV2Fragment$key = {
  readonly " $data"?: TextElementDisplayWithAnswers_TextElementV2Fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"TextElementDisplayWithAnswers_TextElementV2Fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TextElementDisplayWithAnswers_TextElementV2Fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "text",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    }
  ],
  "type": "TextElementV2",
  "abstractKey": null
};

(node as any).hash = "2e2781bf5d5999bd1ac0f4d1da17b794";

export default node;
