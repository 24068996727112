import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { PodcastElementPart_ContentSubmissionFragment$key } from "@generated/PodcastElementPart_ContentSubmissionFragment.graphql";
import { PodcastElementDisplay } from "./PodcastElementDisplay.component";
import { SubmitDisplayElementButton } from "../SubmitDisplayElementButton.component";
import { ProjectContentElementContainer } from "../../../../infecto-lms-webapp-impl/containers/ProjectContentElement.container";

const CONTENT_SUBMISSION_FRAGMENT = graphql`
	fragment PodcastElementPart_ContentSubmissionFragment on ContentSubmission {
		id
		definition {
			... on ActiveELearningContentSubmissionDefinition {
				currentElementState {
					element {
						...PodcastElementDisplay_PodcastElementV2Fragment
					}
				}
			}
		}
		...ContentElementContainer_ContentSubmissionFragment
		...SubmitDisplayElementButton_ContentSubmissionFragment
		...ProjectContentElementContainer_ContentSubmissionFragment
	}
`;

interface OwnProps {
	contentSubmissionFragmentRef: PodcastElementPart_ContentSubmissionFragment$key;
}

export function PodcastElementPart({ contentSubmissionFragmentRef }: OwnProps) {
	const contentSubmission = useFragment<PodcastElementPart_ContentSubmissionFragment$key>(
		CONTENT_SUBMISSION_FRAGMENT,
		contentSubmissionFragmentRef,
	);

	if (!contentSubmission.definition.currentElementState) return null;

	return (
		<ProjectContentElementContainer
			contentSubmissionFragmentRef={contentSubmission}
			nextButton={<SubmitDisplayElementButton contentSubmissionFragmentRef={contentSubmission} />}
		>
			<PodcastElementDisplay
				podcastElementFragmentRef={contentSubmission.definition.currentElementState.element}
			/>
		</ProjectContentElementContainer>
	);
}
