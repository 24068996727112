import React from "react";
import { useCallback, useMemo, useState } from "react";
import { DynamicSlideMenuContext } from "./dynamic-slide-menu-provider.context";
import { DynamicSlideMenuProps } from "./dynamic-slide-menu-provider.interface";

export const DynamicSlideMenuProvider = ({ children }: DynamicSlideMenuProps) => {
	const [path, setPath] = useState<string[]>([]);
	const navigate = useCallback((navigatePath?: string, replace = false) => {
		if (replace) {
			setPath(navigatePath ? [navigatePath] : []);
		} else if (navigatePath) {
			setPath((currentPath) => {
				const pathCopy = currentPath.slice();
				pathCopy.push(navigatePath);
				return pathCopy;
			});
		}
	}, []);

	const goBack = useCallback(() => {
		setPath((currentPath) => {
			if (currentPath.length === 0) return currentPath;
			const pathCopy = currentPath.slice();
			pathCopy.pop();
			return pathCopy;
		});
	}, []);

	const contextValue = useMemo(
		() => ({
			path,
			depth: 1,
			navigate,
			goBack,
		}),
		[path, navigate, goBack],
	);

	return (
		<DynamicSlideMenuContext.Provider value={contextValue}>
			{children}
		</DynamicSlideMenuContext.Provider>
	);
};
