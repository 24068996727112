/**
 * @generated SignedSource<<cb7651427eb960d126011f0424ef69d2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NodeInstructors_Query$variables = {
  selectedInstructors: ReadonlyArray<string>;
};
export type NodeInstructors_Query$data = {
  readonly Instructors: {
    readonly Instructors: ReadonlyArray<{
      readonly description: string | null;
      readonly id: string;
      readonly name: string;
      readonly portraitFile: {
        readonly id: string;
        readonly url: string | null;
      } | null;
      readonly title: string | null;
      readonly " $fragmentSpreads": FragmentRefs<"NodeInstructorCard_InstructorFragment">;
    }>;
  };
};
export type NodeInstructors_Query = {
  response: NodeInstructors_Query$data;
  variables: NodeInstructors_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "selectedInstructors"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "ids",
    "variableName": "selectedInstructors"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "File",
  "kind": "LinkedField",
  "name": "portraitFile",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NodeInstructors_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "InstructorsQueries",
        "kind": "LinkedField",
        "name": "Instructors",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "InstructorImpl",
            "kind": "LinkedField",
            "name": "Instructors",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "NodeInstructorCard_InstructorFragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NodeInstructors_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "InstructorsQueries",
        "kind": "LinkedField",
        "name": "Instructors",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "InstructorImpl",
            "kind": "LinkedField",
            "name": "Instructors",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1e924c6dcea03b69f9304becb1c4e9da",
    "id": null,
    "metadata": {},
    "name": "NodeInstructors_Query",
    "operationKind": "query",
    "text": "query NodeInstructors_Query(\n  $selectedInstructors: [ID!]!\n) {\n  Instructors {\n    Instructors(ids: $selectedInstructors) {\n      id\n      name\n      title\n      description\n      portraitFile {\n        id\n        url\n      }\n      ...NodeInstructorCard_InstructorFragment\n    }\n  }\n}\n\nfragment NodeInstructorCard_InstructorFragment on InstructorImpl {\n  name\n  title\n  description\n  portraitFile {\n    id\n    url\n  }\n}\n"
  }
};
})();

(node as any).hash = "612c1a61233b511e6b38aebd54d22076";

export default node;
