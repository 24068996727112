/**
 * @generated SignedSource<<05adbe70c9682ce6d615b19aceacaf35>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AllMessagesScreen_MessagesPaginationFragment$data = {
  readonly Viewer: {
    readonly Messaging: {
      readonly Messages: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly messageDefinition: {
              readonly pages: {
                readonly edges: ReadonlyArray<{
                  readonly node: {
                    readonly image: {
                      readonly url: string | null;
                    } | null;
                  };
                } | null> | null;
              };
            } | null;
            readonly " $fragmentSpreads": FragmentRefs<"MessageModal_MessageFragment" | "MessagePreview_MessageFragment">;
          };
        } | null> | null;
      };
    };
  };
  readonly " $fragmentType": "AllMessagesScreen_MessagesPaginationFragment";
};
export type AllMessagesScreen_MessagesPaginationFragment$key = {
  readonly " $data"?: AllMessagesScreen_MessagesPaginationFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AllMessagesScreen_MessagesPaginationFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "Viewer",
  "Messaging",
  "Messages"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./AllMessagesScreen_MessagesPaginationFragment_RefetchQuery.graphql')
    }
  },
  "name": "AllMessagesScreen_MessagesPaginationFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ViewerQueryType",
      "kind": "LinkedField",
      "name": "Viewer",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "MessagingViewerSchema",
          "kind": "LinkedField",
          "name": "Messaging",
          "plural": false,
          "selections": [
            {
              "alias": "Messages",
              "args": null,
              "concreteType": "MessagesConnection",
              "kind": "LinkedField",
              "name": "__AllmessagesScreen_Messages_connection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "MessagesEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Message",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "id",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "MessageDefinition",
                          "kind": "LinkedField",
                          "name": "messageDefinition",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "MessageDefinitionPagesConnection",
                              "kind": "LinkedField",
                              "name": "pages",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "MessageDefinitionPagesEdge",
                                  "kind": "LinkedField",
                                  "name": "edges",
                                  "plural": true,
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "MessageDefinitionPage",
                                      "kind": "LinkedField",
                                      "name": "node",
                                      "plural": false,
                                      "selections": [
                                        {
                                          "alias": null,
                                          "args": null,
                                          "concreteType": "File",
                                          "kind": "LinkedField",
                                          "name": "image",
                                          "plural": false,
                                          "selections": [
                                            {
                                              "alias": null,
                                              "args": null,
                                              "kind": "ScalarField",
                                              "name": "url",
                                              "storageKey": null
                                            }
                                          ],
                                          "storageKey": null
                                        }
                                      ],
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "MessagePreview_MessageFragment"
                        },
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "MessageModal_MessageFragment"
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "__typename",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "cursor",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PageInfo",
                  "kind": "LinkedField",
                  "name": "pageInfo",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "endCursor",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasNextPage",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "43baa427ef3b5c8ca50ef503779df549";

export default node;
