import { useEffect } from "react";
import { LMS_WEBAPP_CONFIG_IMPL } from "../../infecto-lms-webapp-impl/config";

export const usePageTitle = (title: string) => {
	useEffect(() => {
		document.title = `${LMS_WEBAPP_CONFIG_IMPL.browserTabTitle} ${title}`;

		return () => {
			document.title = `${LMS_WEBAPP_CONFIG_IMPL.browserTabTitle} ${LMS_WEBAPP_CONFIG_IMPL.browserTabDescription}`;
		};
	}, [title]);
};
