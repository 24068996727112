import React from "react";
import { ProjectContentButtonCME_LearnOpportunityFragment$key } from "@generated/ProjectContentButtonCME_LearnOpportunityFragment.graphql";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { NavigateDirectToQuestionsAdvanceTreeButton } from "../../../app/components/NavigateDirectToQuestionsAdvanceTreeButton.component";
import { NavigateDirectToQuestionsAdvanceContentButton } from "../../../app/components/NavigateDirectToQuestionsAdvanceContentButton";

const TREE_ADVANCEMENT_RESULT_FRAGMENT = graphql`
	fragment ProjectContentButtonCME_LearnOpportunityFragment on LearnOpportunityV2 {
		id
		typeDefinition {
			definitionType
			... on LearnOpportunityELearningContentTypeDefinition {
				contentNodeAdvancementResult {
					status
				}
			}
		}
		...NavigateDirectToQuestionsAdvanceTreeButton_LearnOpportunityV2Fragment
	}
`;

interface OwnProps {
	learnOpportunityFragmentRef: ProjectContentButtonCME_LearnOpportunityFragment$key;
	className?: string;
}
export const ProjectContentButtonCME = ({ learnOpportunityFragmentRef, className }: OwnProps) => {
	const learnOpportunity = useFragment<ProjectContentButtonCME_LearnOpportunityFragment$key>(
		TREE_ADVANCEMENT_RESULT_FRAGMENT,
		learnOpportunityFragmentRef,
	);

	return (
		<div>
			{learnOpportunity.typeDefinition.contentNodeAdvancementResult?.status == "BlockedByTree" ? (
				<NavigateDirectToQuestionsAdvanceTreeButton
					learnOpportunityFragmentRef={learnOpportunity}
					showFinished={false}
					className={className}
				/>
			) : (
				<NavigateDirectToQuestionsAdvanceContentButton
					learnOpportunityId={learnOpportunity.id}
					className={className}
				/>
			)}
		</div>
	);
};
