/**
 * @generated SignedSource<<b386d833309e7693486d35ecf302007d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NodeTag_TagFragment$data = {
  readonly colorHex: string;
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "NodeTag_TagFragment";
};
export type NodeTag_TagFragment$key = {
  readonly " $data"?: NodeTag_TagFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NodeTag_TagFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NodeTag_TagFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "colorHex",
      "storageKey": null
    }
  ],
  "type": "Tag",
  "abstractKey": null
};

(node as any).hash = "c5df3c79b2f058cd98694dd074a27291";

export default node;
