import React from "react";
import styled from "styled-components";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { AttachmentHeftDisplay_LearnOpportunityV2Fragment$key } from "@generated/AttachmentHeftDisplay_LearnOpportunityV2Fragment.graphql";
import { useTypedSelector } from "../../redux/redux-store";
import { selectJwtClaimData, selectLoginData } from "../../redux/slices/auth.slice";
import { AttachmentButtonsDisplay } from "./AttachmentButtonsDisplay.component";
import { LMS_WEBAPP_CONFIG_IMPL } from "../../../infecto-lms-webapp-impl/config";
import { useSelector } from "react-redux";
import { RefreshToken } from "../../redux/slices/RefreshToken";
import { PrimaryButton } from "../buttons/PrimaryButton";
import { Label } from "../buttons/Label";

const LEARN_OPPORTUNITY_FRAGMENT = graphql`
	fragment AttachmentHeftDisplay_LearnOpportunityV2Fragment on LearnOpportunityV2 {
		id
		structureDefinition {
			... on LearnOpportunityRootStructureDefinition {
				extension {
					... on LearnOpportunityRootExtensionImpl {
						firstLearnOpportunityIdWithMultipleChoiceElement
					}
				}
			}
		}
		root {
			id
			structureDefinition {
				... on LearnOpportunityRootStructureDefinition {
					extension {
						... on LearnOpportunityRootExtensionImpl {
							firstLearnOpportunityIdWithMultipleChoiceElement
						}
					}
				}
			}
			attachmentsV2 {
				... on InfectopharmFileAttachment {
					file {
						url
					}
				}
			}
			typeDefinition {
				... on LearnOpportunityBranchTypeDefinition {
					children {
						id
						attachmentsV2 {
							... on InfectopharmFileAttachment {
								file {
									url
								}
							}
						}
					}
				}
			}
		}
		...AttachmentButtonsDisplayFragment_LearnOpportunityV2Fragment
	}
`;

interface OwnProps {
	learnOpportunityFragmentRef: AttachmentHeftDisplay_LearnOpportunityV2Fragment$key;
	marginTopValue: string;
}

export function AttachmentHeftDisplay({ learnOpportunityFragmentRef, marginTopValue }: OwnProps) {
	const loginData = useTypedSelector(selectLoginData);
	const jwtClaimData = useSelector(selectJwtClaimData);
	const learnOpportunity = useFragment<AttachmentHeftDisplay_LearnOpportunityV2Fragment$key>(
		LEARN_OPPORTUNITY_FRAGMENT,
		learnOpportunityFragmentRef,
	);
	const nodeId = learnOpportunity.root?.id
		? learnOpportunity?.root?.structureDefinition.extension
				?.firstLearnOpportunityIdWithMultipleChoiceElement
		: learnOpportunity?.structureDefinition.extension
				?.firstLearnOpportunityIdWithMultipleChoiceElement;

	return (
		<WrapperContainer marginTop={marginTopValue}>
			{learnOpportunity.structureDefinition.extension
				?.firstLearnOpportunityIdWithMultipleChoiceElement ||
			learnOpportunity.root?.structureDefinition.extension
				?.firstLearnOpportunityIdWithMultipleChoiceElement ||
			learnOpportunity?.root?.attachmentsV2.length ? (
				<div className="mt-4">
					<h3 className="text-base">Download</h3>
					<List>
						<HeftContainer className="lg:pr-4">
							<AttachmentButtonsDisplay learnOpportunityFragmentRef={learnOpportunity} />
							{(learnOpportunity.structureDefinition.extension
								?.firstLearnOpportunityIdWithMultipleChoiceElement ||
								learnOpportunity.root?.structureDefinition.extension
									?.firstLearnOpportunityIdWithMultipleChoiceElement) && (
								<AttachmentButton
									onClick={() => {
										RefreshToken(
											loginData?.refreshToken as string,
											jwtClaimData?.accountId as string,
										).then((currentAccessToken) => {
											window.open(
												`${process.env.REACT_APP_API_BASE}/api/download-cme-questions?token=${currentAccessToken}&accountId=${jwtClaimData?.accountId}&nodeId=${nodeId}`,
											);
										});
									}}
									className="mb-2"
								>
									<PrimaryButton className="p-2 flex flex-column w-full">
										<Label>{LMS_WEBAPP_CONFIG_IMPL.multipleChoiceDownloadButtonText}</Label>
									</PrimaryButton>
								</AttachmentButton>
							)}
						</HeftContainer>
					</List>
				</div>
			) : null}
		</WrapperContainer>
	);
}

const List = styled.div`
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
`;

interface MarginProps {
	marginTop: string;
}

const WrapperContainer = styled.div<MarginProps>`
	margin-top: ${(props) => props.marginTop};
`;

const HeftContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

const AttachmentButton = styled.div`
	max-width: 400px;
	text-align: center;
	border: none;
	cursor: pointer;
`;
