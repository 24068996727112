import React from "react";
import { Button } from "primereact/button";
import styled from "styled-components";
import { calculateButtonStateColor } from "../../functions/helpers/calculateButtonStateColor";
import { LMS_WEBAPP_CONFIG_IMPL } from "../../../infecto-lms-webapp-impl/config";
import { ButtonOwnProps, ButtonProps } from "./PrimaryButton";
import { sharedLabelStyles } from "./Label";

const TertiaryButtonContainer = styled(Button)<ButtonProps>`
	display: inline-flex;
	justify-content: center;
	align-items: center;
	border-radius: 4px !important;
	color: ${(props) => props.color} !important;
	.p-button-label {
		${sharedLabelStyles}
	}
	&:hover {
		color: ${(props) => props.colorHover} !important;
		backdrop-filter: blur(20px);
	}
	&:active {
		color: ${(props) => props.colorHover} !important;
		backdrop-filter: blur(20px);
	}
	&:disabled {
		opacity: 0.6;
	}
`;

export const TertiaryButton = ({
	className,
	label,
	onClick,
	children,
	disabled,
	tooltipOptions,
	tooltip,
	type,
	icon,
	loading,
	autoFocus,
}: ButtonOwnProps) => {
	const { hoverColor, pressColor } = calculateButtonStateColor();

	return (
		<TertiaryButtonContainer
			fontFamily={LMS_WEBAPP_CONFIG_IMPL.fontFamily}
			color={LMS_WEBAPP_CONFIG_IMPL.brandColor}
			tooltipOptions={tooltipOptions}
			disabled={disabled}
			colorHover={hoverColor}
			colorPress={pressColor}
			className={`text-primary bg-white ${className}`}
			onClick={onClick}
			label={label}
			tooltip={tooltip}
			type={type}
			icon={icon}
			loading={loading}
			autoFocus={autoFocus}
		>
			<div>{children}</div>
		</TertiaryButtonContainer>
	);
};
