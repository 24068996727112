/**
 * @generated SignedSource<<867744db3eab72c85aae130732d4c616>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NodeCertificateDisplay_NodeRootStructureDefinitionFragment$data = {
  readonly extension: {
    readonly reducedDataInTree?: {
      readonly certificateTemplateNames: ReadonlyArray<string>;
    };
  };
  readonly " $fragmentType": "NodeCertificateDisplay_NodeRootStructureDefinitionFragment";
};
export type NodeCertificateDisplay_NodeRootStructureDefinitionFragment$key = {
  readonly " $data"?: NodeCertificateDisplay_NodeRootStructureDefinitionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NodeCertificateDisplay_NodeRootStructureDefinitionFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NodeCertificateDisplay_NodeRootStructureDefinitionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "extension",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ReducedDataInTree",
              "kind": "LinkedField",
              "name": "reducedDataInTree",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "certificateTemplateNames",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "LearnOpportunityRootExtensionImpl",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "LearnOpportunityRootStructureDefinition",
  "abstractKey": null
};

(node as any).hash = "4c970b34d192a160f5f39ecc93ac363b";

export default node;
