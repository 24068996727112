/**
 * @generated SignedSource<<208704a9a5e9544bdadac140cdb6042b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DefaultContainer_UserDataFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"MainTopBar_UserDataFragment">;
  readonly " $fragmentType": "DefaultContainer_UserDataFragment";
};
export type DefaultContainer_UserDataFragment$key = {
  readonly " $data"?: DefaultContainer_UserDataFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"DefaultContainer_UserDataFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DefaultContainer_UserDataFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MainTopBar_UserDataFragment"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "6d9e51ac8f81c3a3f6408c0df83c9aa0";

export default node;
