import React from "react";
import { PrivateRouteDefinition } from "../../../../infecto-lms-webapp/router/interfaces";
import { NodeScreen } from "../../../../app/screens/Node.screen";

export const LEARN_OPPORTUNITY_ID_URL_PARAM = ":learnOpportunityId";
export const getLearnOpportunityPath = (learnOpportunityId: string) => {
	return `/modul/${learnOpportunityId}`;
};

export const NodesRoutes: PrivateRouteDefinition[] = [
	{
		path: getLearnOpportunityPath(LEARN_OPPORTUNITY_ID_URL_PARAM),
		component: <NodeScreen />,
	},
];
