export type VideoPlayerProps = {
	url: string;
};

export type VideoPlayeWithProvidersProps = {
	onProgress: (progress: number) => void;
} & VideoPlayerProps;

export interface HlsPlayer {
	levels: {
		id: number;
		width: number;
		height: number;
	}[];
	currentLevel: number;
	nextLevel: number;
	loadLevel: number;
	autoLevelEnabled: boolean;
}

export enum OverlayInitiator {
	Transcripts,
	Settings,
	Volume,
	OverlayButton,
	OutsideFocus,
}
