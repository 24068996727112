import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import styled from "styled-components";
import { NodePointsDisplay_NodeRootStructureDefinitionFragment$key } from "@generated/NodePointsDisplay_NodeRootStructureDefinitionFragment.graphql";
import { singularPluralPointsDisplay } from "../../../infecto-lms-webapp/functions/helpers/singularPluralPointsDisplay";

const LEARN_OPPORTUNITY_ROOT_STRUCTURE_DEFINITION_FRAGMENT = graphql`
	fragment NodePointsDisplay_NodeRootStructureDefinitionFragment on LearnOpportunityRootStructureDefinition {
		extension {
			... on LearnOpportunityRootExtensionImpl {
				reducedDataInTree {
					pacPoints
					pharmaPoints
				}
			}
		}
	}
`;

interface OwnProps {
	learnOpportunityRootStructureDefinitionFragmentRef: NodePointsDisplay_NodeRootStructureDefinitionFragment$key;
}

const StyledDiv = styled.div`
	margin-left: 10px;
`;

export function NodePointsDisplay({
	learnOpportunityRootStructureDefinitionFragmentRef,
}: OwnProps) {
	const learnOpportunityRootStructureDefinition =
		useFragment<NodePointsDisplay_NodeRootStructureDefinitionFragment$key>(
			LEARN_OPPORTUNITY_ROOT_STRUCTURE_DEFINITION_FRAGMENT,
			learnOpportunityRootStructureDefinitionFragmentRef,
		);

	return (
		<div className="flex align-items-center">
			{(learnOpportunityRootStructureDefinition.extension.reducedDataInTree?.pacPoints || 0) >
				0 && (
				<StyledDiv>
					<span>
						{`${
							learnOpportunityRootStructureDefinition.extension.reducedDataInTree?.pacPoints
						} PAC-${singularPluralPointsDisplay(
							learnOpportunityRootStructureDefinition.extension.reducedDataInTree?.pacPoints!,
						)}`}
					</span>
				</StyledDiv>
			)}

			{(learnOpportunityRootStructureDefinition.extension.reducedDataInTree?.pharmaPoints || 0) >
				0 && (
				<StyledDiv>
					<span>
						{`${
							learnOpportunityRootStructureDefinition.extension.reducedDataInTree?.pharmaPoints
						} BAK-${singularPluralPointsDisplay(
							learnOpportunityRootStructureDefinition.extension.reducedDataInTree?.pharmaPoints!,
						)}`}
					</span>
				</StyledDiv>
			)}
		</div>
	);
}
