import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import styled from "styled-components";
import { MessageDefinitionButton_MessageDefinitionButtonFragment$key } from "@generated/MessageDefinitionButton_MessageDefinitionButtonFragment.graphql";
import { LinkButton } from "./LinkButton.component";
import { ConfirmButton } from "./ConfirmButton.component";

const MESSAGE_DEFINITION_BUTTON_FRAGMENT = graphql`
	fragment MessageDefinitionButton_MessageDefinitionButtonFragment on MessageDefinitionButtonInterface {
		type
		... on MessageDefinitionLinkButton {
			...LinkButton_MessageDefinitionLinkButtonFragment
		}
		... on MessageDefinitionConfirmButton {
			...ConfirmButton_MessageDefinitionConfirmButtonFragment
		}
	}
`;

interface OwnProps {
	onClose: () => void;
	modalWidth: number;
	messageDefinitionButtonFragmentRef?: MessageDefinitionButton_MessageDefinitionButtonFragment$key | null;
}

export function MessageDefinitionButton({
	onClose,
	modalWidth,
	messageDefinitionButtonFragmentRef,
}: OwnProps) {
	const button = useFragment<MessageDefinitionButton_MessageDefinitionButtonFragment$key>(
		MESSAGE_DEFINITION_BUTTON_FRAGMENT,
		messageDefinitionButtonFragmentRef || null,
	);

	const chooseButton = () => {
		switch (button?.type) {
			case "link":
				return (
					<LinkButton
						onClose={onClose}
						modalWidth={modalWidth}
						messageDefinitionLinkButtonFragmentRef={button}
					/>
				);
			case "confirm":
				return (
					<ConfirmButton
						onClick={onClose}
						modalWidth={modalWidth}
						messageDefinitionConfirmButtonFragmentRef={button}
					/>
				);
			default:
				return null;
		}
	};

	return <ButtonContainer modalWidth={modalWidth}>{chooseButton()}</ButtonContainer>;
}

const ButtonContainer = styled.div<{ modalWidth: number }>`
	display: flex;
	width: ${(props) => props.modalWidth}px;
`;
