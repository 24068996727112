/**
 * @generated SignedSource<<5a5035f1d53703536a92e101051d99a5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ElementStateKind = "Input" | "InputAndIsCorrect" | "ShowAnswer" | "Untouched" | "UntouchedAndPreviouslyIncorrect" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type MultipleChoiceElementPart_ContentSubmissionFragment$data = {
  readonly definition: {
    readonly currentElementState?: {
      readonly checkedAnswerIndices?: ReadonlyArray<number>;
      readonly element?: {
        readonly answerOptions?: ReadonlyArray<{
          readonly answer: string;
        }>;
        readonly id: string;
        readonly question?: string;
        readonly " $fragmentSpreads": FragmentRefs<"MultipleChoiceElementDisplay_MultipleChoiceElementV2Fragment">;
      };
      readonly kind: ElementStateKind;
    } | null;
    readonly progress?: {
      readonly currentElementIndex?: number;
      readonly isAtLastElement?: boolean;
      readonly numElements?: number;
      readonly percentage: number;
    };
  };
  readonly id: string;
  readonly learnOpportunity: {
    readonly id: string;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectContentElementContainer_ContentSubmissionFragment">;
  readonly " $fragmentType": "MultipleChoiceElementPart_ContentSubmissionFragment";
};
export type MultipleChoiceElementPart_ContentSubmissionFragment$key = {
  readonly " $data"?: MultipleChoiceElementPart_ContentSubmissionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"MultipleChoiceElementPart_ContentSubmissionFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "AnswerOptionWithoutCorrect",
  "kind": "LinkedField",
  "name": "answerOptions",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "answer",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v2 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "MultipleChoiceElementDisplay_MultipleChoiceElementV2Fragment"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MultipleChoiceElementPart_ContentSubmissionFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "LearnOpportunityV2",
      "kind": "LinkedField",
      "name": "learnOpportunity",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "definition",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "progress",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "percentage",
                  "storageKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "isAtLastElement",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "numElements",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "currentElementIndex",
                      "storageKey": null
                    }
                  ],
                  "type": "ActiveELearningContentSubmissionProgress",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "currentElementState",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "kind",
                  "storageKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "element",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "question",
                              "storageKey": null
                            },
                            (v1/*: any*/),
                            (v2/*: any*/)
                          ],
                          "type": "MultipleChoiceLearnElement",
                          "abstractKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "UntouchedElementState",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "element",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            (v1/*: any*/),
                            (v2/*: any*/)
                          ],
                          "type": "MultipleChoiceLearnElement",
                          "abstractKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "checkedAnswerIndices",
                      "storageKey": null
                    }
                  ],
                  "type": "MultipleChoiceInputElementState",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "ActiveELearningContentSubmissionDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectContentElementContainer_ContentSubmissionFragment"
    }
  ],
  "type": "ContentSubmission",
  "abstractKey": null
};
})();

(node as any).hash = "9b0c548e3128969fe4916144d60e75f2";

export default node;
