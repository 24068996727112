import { EXTEND_ERROR_MESSAGE_EFN_OAK } from "./EXTEND_ERROR_MESSAGE_EFN_OAK";
export const ERROR_MESSAGES: { [key: string]: string } = {
	"auth-unspecified-auth-exception":
		"Login fehlgeschlagen. Bitte überprüfen Sie Benutzername und Passwort.",
	auth_cant_login_initial_validation:
		"Der Freischaltungs-Prozess ist noch nicht abgeschlossen, deshalb können Sie sich noch nicht anmelden.",
	auth_authenticate_unspecified_auth:
		"Login fehlgeschlagen. Bitte überprüfen Sie Benutzername und Passwort.",
	auth_login_with_email_and_password_unspecified_auth:
		"Login fehlgeschlagen. Bitte überprüfen Sie Benutzername und Passwort.",
	...EXTEND_ERROR_MESSAGE_EFN_OAK,
};
