import React from "react";
import styled from "styled-components";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { CarousalNode_NodeFragment$key } from "@generated/CarousalNode_NodeFragment.graphql";
import { getLearnOpportunityPath } from "../../../infecto-lms-webapp-impl/router/routes/auth/nodes.routes";
import { NavLink } from "react-router-dom";

const NODE_FRAGMENT = graphql`
	fragment CarousalNode_NodeFragment on LearnOpportunityV2 {
		id
		image {
			url
		}
		structureDefinition {
			title
			... on LearnOpportunityRootStructureDefinition {
				icon {
					url
				}
			}
		}
	}
`;

interface OwnProps {
	nodeRef: CarousalNode_NodeFragment$key;
	height: number;
	width: number;
}
export const CarousalNode = ({ nodeRef, height, width }: OwnProps) => {
	const node = useFragment<CarousalNode_NodeFragment$key>(NODE_FRAGMENT, nodeRef!);

	return (
		<Wrapper width={width} className="m-2 text-center">
			<NavLink to={getLearnOpportunityPath(node.id)}>
				<CardContainer height={height}>
					<Image imageurl={node.structureDefinition.icon?.url} />
				</CardContainer>
			</NavLink>
		</Wrapper>
	);
};

interface ImageProps {
	imageurl?: string | null;
}

interface ContainerProps {
	height: number;
}

interface WrapperProps {
	width: number;
}

const Wrapper = styled.div<WrapperProps>`
	width: ${(p) => p.width}px;
`;

const CardContainer = styled.div<ContainerProps>`
	height: ${(p) => p.height}px;
	margin: 0.5rem;
	transition: box-shadow 0.2s ease-in-out;
	cursor: pointer;
	&:hover {
		box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2), 0 0 10px 0 rgba(0, 0, 0, 0.19);
	}
`;

const Image = styled.div<ImageProps>`
	width: 100%;
	height: 100%;
	background-image: url(${(p) => p.imageurl});
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
`;
