import React from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { MenuItem } from "primereact/menuitem";
import styled from "styled-components";
import { classNames } from "primereact/utils";
import { MenuType } from "./navigation.interface";

interface OwnProps {
	item: MenuItem;
	collapsed: boolean;
	icon: any;
	onClick: () => void;
	menuType: MenuType;
}

const StyledItem = styled.div`
	:hover {
		cursor: pointer;
		background: var(--surface-100);
	}
`;

export function RouterMenuItem({ item, collapsed, icon, onClick, menuType }: OwnProps) {
	const match = useMatch({ path: item.url!, end: true });
	const navigate = useNavigate();
	return (
		<StyledItem
			onClick={() => {
				onClick();
				navigate(item.url!);
			}}
			className={
				"p-3 border-round font-bold flex align-items-center " +
				(match ? "surface-100 text-primary " : "") +
				(menuType === "SideMenu" ? "mb-4" : "mb-1")
			}
		>
			{item.icon && (
				<span className={`flex align-items-center ${classNames({ "mr-3": !collapsed })}`}>
					{icon}
				</span>
			)}
			{!collapsed && <span className="text-sm">{item?.label}</span>}
		</StyledItem>
	);
}
