// import React, { useEffect, useState } from "react";
// import styled from "styled-components";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { VideoElementDisplayWithAnswers_VideoElementV2Fragment$key } from "@generated/VideoElementDisplayWithAnswers_VideoElementV2Fragment.graphql";
// import { VideoPlayer } from "../../video/VideoPlayer.component";

const VIDEO_ELEMENT_FRAGMENT = graphql`
	fragment VideoElementDisplayWithAnswers_VideoElementV2Fragment on VideoElement {
		title
	}
`;

interface OwnProps {
	videoElementFragmentRef: VideoElementDisplayWithAnswers_VideoElementV2Fragment$key;
}

/*const VideoPlayerWithMaxWidth = styled(VideoPlayer)`
	max-width: 1240px;
`;*/

export function VideoElementDisplayWithAnswers({ videoElementFragmentRef }: OwnProps) {
	const videoElement = useFragment<VideoElementDisplayWithAnswers_VideoElementV2Fragment$key>(
		VIDEO_ELEMENT_FRAGMENT,
		videoElementFragmentRef,
	);

	// const [timestamp, setTimestamp] = useState<number>(0);

	/*useEffect(() => {
		setTimestamp(0);
	}, [videoElement]);*/

	return videoElement
		? null
		: /*<div className="flex p-5 flex-column-reverse md:flex-row">
			{videoElement.releasedSubtitlesOpt?.subtitles?.subtitles.length && (
				<div className="pt-8 pl-4 pr-4 overflow-scroll" style={{ maxHeight: "80vh" }}>
					<SubtitleDisplay
						subtitles={videoElement.releasedSubtitlesOpt?.subtitles?.subtitles}
						timestamp={timestamp}
					/>
				</div>
			)}

			<div>
				<h1 dangerouslySetInnerHTML={{ __html: videoElement.title }} />
				{videoElement.releasedFileOpt?.file?.url ? (
					<VideoPlayerWithMaxWidth
						timestamp={timestamp}
						onCurrentTimeChange={(seconds) => {
							setTimestamp(() => seconds);
						}}
						videoFile={videoElement.releasedFileOpt?.file?.url}
					/>
				) : (
					<div>Keine Videodatei </div>
				)}
			</div>
		</div>*/
		  null;
}
