import React, { Suspense } from "react";
import * as Sentry from "@sentry/react";
/*import { isTablet } from "react-device-detect";*/
import { ToastContainer } from "react-toastify";
import { AuthenticatedUser } from "../infecto-lms-webapp/components/auth/AuthenticatedUser.component";
import { RelayEnvironmentProvider } from "react-relay";
import { LastMessageModal } from "../infecto-lms-webapp/components/message/LastMessageModal.component";
import { Loader } from "../infecto-lms-webapp/components/loading.component";
import { TrackingOptIn } from "../infecto-lms-webapp/components/TrackingOptIn.component";
import { NonAuthRoutes } from "../infecto-lms-webapp/router/routes/non-auth/non-auth.routes";
import { AuthRoutes } from "../infecto-lms-webapp/router/routes/auth/auth.routes";
import { RelayEnvironment } from "../infecto-lms-webapp/relay/RelayEnvironment";
import ClearCache from "react-clear-cache";

const isProduction = process.env.REACT_APP_APP_ENVIRONMENT === "production";

if (isProduction) {
	Sentry.init({
		dsn: process.env.REACT_APP_SENTRY_DSN,
	});
}

function App() {
	return (
		<>
			<ToastContainer autoClose={5000} newestOnTop />
			<Sentry.ErrorBoundary onError={(e) => console.error(e)}>
				<ClearCache auto duration={60000}>
					{() => (
						<Suspense fallback={<Loader />}>
							<RelayEnvironmentProvider environment={RelayEnvironment}>
								<AuthenticatedUser loginFallback={<NonAuthRoutes />}>
									<>
										<AuthRoutes />
										<LastMessageModal />
									</>
								</AuthenticatedUser>
							</RelayEnvironmentProvider>
						</Suspense>
					)}
				</ClearCache>
			</Sentry.ErrorBoundary>

			{/*{!isTablet && <AppAd />}*/}
			<TrackingOptIn />
		</>
	);
}

export default App;
