import React, { ReactNode } from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay";
import { useNavigate } from "react-router-dom";
import { ProjectContentElementContainer_ContentSubmissionFragment$key } from "@generated/ProjectContentElementContainer_ContentSubmissionFragment.graphql";
import { ProjectContentElementContainer_GoBackElementWiseMutation } from "@generated/ProjectContentElementContainer_GoBackElementWiseMutation.graphql";
import { ProgressBar } from "primereact/progressbar";
import { getLearnOpportunityPath } from "../router/routes/auth/nodes.routes";
import { WrapperContainerWithScrollbar } from "../../infecto-lms-webapp/containers/WrapperContainerWithScrollbar.container";
import styled from "styled-components";
import { footerPixelsRightFrame } from "../../infecto-lms-webapp/hooks/scrollbarComponentHelpers";
import { LMS_WEBAPP_CONFIG_IMPL } from "../config";
import { SecondaryButton } from "../../infecto-lms-webapp/components/buttons/SecondaryButton";

const CONTENT_SUBMISSION_FRAGMENT = graphql`
	fragment ProjectContentElementContainer_ContentSubmissionFragment on ContentSubmission {
		id
		definition {
			... on ActiveELearningContentSubmissionDefinition {
				canGoToPrevious
				progress {
					percentage
				}
			}
		}
		learnOpportunity {
			id
			root {
				id
			}
			typeDefinition {
				... on LearnOpportunityELearningContentTypeDefinition {
					containedElementTypes
				}
			}
			...AttachmentDisplay_LearnOpportunityV2Fragment
		}
	}
`;

const GO_BACK_ELEMENT_WISE_MUTATION = graphql`
	mutation ProjectContentElementContainer_GoBackElementWiseMutation(
		$input: GoToPreviousElementInput!
	) {
		ELearning {
			goToPreviousElement(input: $input) {
				contentSubmission {
					...ContentSubmissionScreen_ContentSubmissionFragment
				}
			}
		}
	}
`;

interface OwnProps {
	hideBack?: boolean;
	contentSubmissionFragmentRef: ProjectContentElementContainer_ContentSubmissionFragment$key;
	children: any;
	nextButton: ReactNode;
}

export function ProjectContentElementContainer({
	hideBack,
	contentSubmissionFragmentRef,
	children,
	nextButton,
}: OwnProps) {
	const contentSubmission =
		useFragment<ProjectContentElementContainer_ContentSubmissionFragment$key>(
			CONTENT_SUBMISSION_FRAGMENT,
			contentSubmissionFragmentRef,
		);

	const [goBack, isGoingBack] =
		useMutation<ProjectContentElementContainer_GoBackElementWiseMutation>(
			GO_BACK_ELEMENT_WISE_MUTATION,
		);

	const navigate = useNavigate();

	const hasMultipleChoice =
		contentSubmission.learnOpportunity?.typeDefinition.containedElementTypes?.includes(
			"multipleChoice",
		) ||
		contentSubmission.learnOpportunity?.typeDefinition.containedElementTypes?.includes(
			"randomMultipleChoice",
		);

	return (
		<DivContainer
			footerPixels={footerPixelsRightFrame(hasMultipleChoice || false)}
			className="flex flex-grow-1 flex-column "
		>
			<WrapperContainerWithScrollbar
				width={98}
				footerPixels={footerPixelsRightFrame(hasMultipleChoice || false)}
				marginBottom={10}
			>
				<div className="flex-grow-1">
					{children}

					{/*	{contentSubmission.learnOpportunity && (
 					<AttachmentDisplay
 						positionAbsolute={true}
 						openInitially={false}
 						learnOpportunityFragmentRef={contentSubmission.learnOpportunity}
 					/>
 				)}*/}
				</div>
				<div className="flex flex-column absolute bottom-0 bg-white w-12 z-2 right-0">
					{hasMultipleChoice && (
						<div>
							<ProgressBar
								color={LMS_WEBAPP_CONFIG_IMPL.brandColor}
								style={{ height: "6px" }}
								showValue={false}
								value={contentSubmission.definition.progress?.percentage}
							/>
						</div>
					)}
					{hasMultipleChoice && (
						<div className="p-3 md:p-5 flex">
							<div className="ml-auto flex text-center">
								{!hideBack && contentSubmission.learnOpportunity && (
									<SecondaryButton
										label="Zurück"
										className="mr-2 md:mr-4 pl-6 pr-6 "
										disabled={isGoingBack}
										onClick={() => {
											if (contentSubmission.definition.canGoToPrevious) {
												goBack({
													variables: { input: { contentSubmissionId: contentSubmission.id } },
												});
											} else {
												navigate(
													getLearnOpportunityPath(contentSubmission.learnOpportunity!.root!.id),
												);
											}
										}}
									/>
								)}
								{nextButton}
							</div>
						</div>
					)}
				</div>
			</WrapperContainerWithScrollbar>
		</DivContainer>
	);
}
interface DivProps {
	footerPixels: number;
}
const DivContainer = styled.div<DivProps>`
	max-height: calc(100vh - ${(props) => props.footerPixels}px);
`;
