export class PaletteColor {
	private readonly r: number;
	private readonly g: number;
	private readonly b: number;

	constructor(r: number, g: number, b: number) {
		this.r = r;
		this.g = g;
		this.b = b;
	}

	rgbValue(): string {
		return `rgb(${this.r.toString()}, ${this.g.toString()}, ${this.b.toString()})`;
	}

	rgbaValue(a: number): string {
		return `rgba(${this.r.toString()}, ${this.g.toString()}, ${this.b.toString()}, ${a.toString()})`;
	}
}

export const white: PaletteColor = new PaletteColor(255, 255, 255);
export const lightGrey: PaletteColor = new PaletteColor(80, 84, 81);

export const secondary100: PaletteColor = new PaletteColor(74, 206, 127);
export const brandColor: PaletteColor = new PaletteColor(42, 146, 205);
export const activeIcon: PaletteColor = new PaletteColor(204, 235, 216);

export const disabledButton: PaletteColor = new PaletteColor(243, 245, 248);

export const basic80: PaletteColor = new PaletteColor(210, 227, 208);

export const error20: PaletteColor = new PaletteColor(251, 221, 210);

export const error100: PaletteColor = new PaletteColor(234, 86, 28);
