import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import { MainTopBar } from "../../infecto-lms-webapp/components/navigation/MainTopBar.component";
import { PaediaDefaultContainer_UserDataFragment$key } from "@generated/PaediaDefaultContainer_UserDataFragment.graphql";
import { NewsTickerComponent } from "../components/NewsTicker.component";
import { LMS_WEBAPP_CONFIG_IMPL } from "../../infecto-lms-webapp-impl/config";
import { NavLink } from "react-router-dom";
import { ProjectLogoComponent } from "../../infecto-lms-webapp-impl/components/ProjectLogo.component";
import { Menu } from "primereact/menu";
import { MAIN_MENU_ITEMS } from "../../infecto-lms-webapp-impl/impl-main-menu-items";
import { SideMenuFooter } from "../../infecto-lms-webapp-impl/components/SideMenuFooter.component";
import {
	RightContentDefaultWrapperContainer,
	RightSlotContentDefaultContainer,
} from "../../infecto-lms-webapp/containers/Default.container";

const USER_DATA = graphql`
	fragment PaediaDefaultContainer_UserDataFragment on User {
		...MainTopBar_UserDataFragment
	}
`;

interface OwnProps {
	userDataFragmentRef?: PaediaDefaultContainer_UserDataFragment$key | null;
	children: any;
	rightSideSlot?: ReactNode;
	containerId?: string;
	screenName: string;
	collapsed: boolean;
}

export function PaediaDefaultContainer({
	userDataFragmentRef,
	children,
	rightSideSlot,
	containerId,
	screenName,
	collapsed,
}: OwnProps) {
	const userData = useFragment<PaediaDefaultContainer_UserDataFragment$key>(
		USER_DATA,
		userDataFragmentRef || null,
	);

	const mainSideBarRef = useRef(null);
	const newsTickerRef = useRef(null);
	const topBarRef = useRef(null);
	const [mainSideBarWidth, setMainSideBarWidth] = useState(0);
	const [newsTickerHeight, setNewsTickerHeight] = useState(0);
	const [topBarHeight, setTopBarHeight] = useState(0);

	useEffect(() => {
		// @ts-ignore
		setMainSideBarWidth(mainSideBarRef.current.offsetWidth);
		// @ts-ignore
		setTopBarHeight(topBarRef.current.offsetHeight);

		// @ts-ignore
		setNewsTickerHeight(newsTickerRef.current.offsetHeight);
	}, []);

	return (
		<div className="flex flex-grow-1">
			<div ref={mainSideBarRef} className={"flex-column border-right-1 border-300 hidden md:flex"}>
				{LMS_WEBAPP_CONFIG_IMPL.showLogoInSideBar && (
					<div className="p-4 text-center">
						<NavLink to="/">
							{!collapsed && <ProjectLogoComponent width={150} />}
							{collapsed && <ProjectLogoComponent width={50} />}
						</NavLink>
					</div>
				)}

				<div className="flex-grow-1 flex p-4">
					<Menu
						className="flex-grow-1 border-0"
						style={{ width: "100%" }}
						model={MAIN_MENU_ITEMS("SideMenu", collapsed)}
					/>
				</div>

				{!collapsed && <SideMenuFooter />}
			</div>

			<RightContentDefaultWrapperContainer
				sideBarWidth={mainSideBarWidth}
				className="flex flex-grow-1 flex-column"
			>
				<div ref={newsTickerRef}>
					<NewsTickerComponent sideMenuWidth={mainSideBarWidth} />
				</div>
				<div ref={topBarRef}>
					<MainTopBar
						containerId={containerId}
						screen={screenName}
						className="border-bottom-1 z-1"
						userDataFragmentRef={userData}
					/>
				</div>
				<RightSlotContentDefaultContainer
					topBarsHeight={topBarHeight + newsTickerHeight}
					className="flex flex-grow-1 surface-100"
				>
					<div className=" flex-grow-1  justify-content-center flex">{children}</div>
					{rightSideSlot}
				</RightSlotContentDefaultContainer>
			</RightContentDefaultWrapperContainer>
		</div>
	);
}
