/**
 * @generated SignedSource<<18282a1b98c83e50eadae84dd35ffdc3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ContentSubmissionEnd_AnswersResultQuery$variables = {
  submissionId: string;
};
export type ContentSubmissionEnd_AnswersResultQuery$data = {
  readonly LearnV2: {
    readonly GetAnswersResult: ReadonlyArray<{
      readonly answers: ReadonlyArray<string>;
      readonly correctAnswerIndices: ReadonlyArray<number>;
      readonly question: string;
      readonly selectedAnswersIndices: ReadonlyArray<number>;
    }>;
  };
};
export type ContentSubmissionEnd_AnswersResultQuery = {
  response: ContentSubmissionEnd_AnswersResultQuery$data;
  variables: ContentSubmissionEnd_AnswersResultQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "submissionId"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "LearnV2Queries",
    "kind": "LinkedField",
    "name": "LearnV2",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "submissionId",
            "variableName": "submissionId"
          }
        ],
        "concreteType": "MultipleChoiceResult",
        "kind": "LinkedField",
        "name": "GetAnswersResult",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "question",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "answers",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "correctAnswerIndices",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "selectedAnswersIndices",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ContentSubmissionEnd_AnswersResultQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ContentSubmissionEnd_AnswersResultQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f462f12d20fe5593858028ea6df6eb7d",
    "id": null,
    "metadata": {},
    "name": "ContentSubmissionEnd_AnswersResultQuery",
    "operationKind": "query",
    "text": "query ContentSubmissionEnd_AnswersResultQuery(\n  $submissionId: ID!\n) {\n  LearnV2 {\n    GetAnswersResult(submissionId: $submissionId) {\n      question\n      answers\n      correctAnswerIndices\n      selectedAnswersIndices\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a3e2a874ee0689120fa90961c7a322b8";

export default node;
