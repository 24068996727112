import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReduxState } from "../redux-store";
import { InfectopharmCountry } from "@generated/CurrentUser_Query.graphql";

export interface CurrentUserData {
	readonly country?: InfectopharmCountry | null;
}

export interface CurrentUserState {
	currentUser?: CurrentUserData;
}

const INITIAL_STATE: CurrentUserState = {};

const currentUserSlice = createSlice({
	name: "current-user",
	initialState: INITIAL_STATE,
	reducers: {
		setCurrentUser: (state, action: PayloadAction<CurrentUserData>) => {
			state.currentUser = action.payload;
		},
	},
});

export const { setCurrentUser } = currentUserSlice.actions;
export const CurrentUserSliceReducer = currentUserSlice.reducer;

const selectCurrentUserSlice = (state: ReduxState) => state.currentUser;

export const selectCurrentUser = createSelector(selectCurrentUserSlice, (e) => e.currentUser);
