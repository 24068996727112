import { ReactComponent as ArrowLeft2 } from "../../assets/icons/arrow-left-2.svg";
import { ReactComponent as ArrowRight2 } from "../../assets/icons/arrow-right-2.svg";
import { ReactComponent as Rewind_10 } from "../../assets/icons/rewind-10.svg";
import { ReactComponent as Forward10 } from "../../assets/icons/forward-10.svg";
import { ReactComponent as Fullscreen } from "../../assets/icons/fullscreen.svg";
import { ReactComponent as CloseFullscreen } from "../../assets/icons/close-fullscreen.svg";
import { ReactComponent as PlayArrow } from "../../assets/icons/play-arrow.svg";
import { ReactComponent as PauseArrow } from "../../assets/icons/pause-arrow.svg";
import { ReactComponent as Settings } from "../../assets/icons/settings.svg";
import { ReactComponent as Transcripts } from "../../assets/icons/transcripts.svg";
import { ReactComponent as Volume } from "../../assets/icons/volume.svg";
import { ReactComponent as VideoPlay } from "../../assets/icons/video-play.svg";

import React, { SVGAttributes } from "react";

export type TkaIconName =
	| "arrowLeft2"
	| "arrowRight2"
	| "rewind10"
	| "forward10"
	| "fullscreen"
	| "closeFullscreen"
	| "playArrow"
	| "pauseArrow"
	| "settings"
	| "transcripts"
	| "volume"
	| "videoPlay";

export type AdjustableStyles = "stroke" | "fill" | "fill-also";

export const IconMap: Record<
	TkaIconName,
	{
		icon: React.FunctionComponent<SVGAttributes<SVGElement>>;
		styles: [AdjustableStyles] | [AdjustableStyles, AdjustableStyles];
	}
> = {
	arrowLeft2: { icon: ArrowLeft2, styles: ["stroke"] },
	arrowRight2: { icon: ArrowRight2, styles: ["stroke"] },
	rewind10: { icon: Rewind_10, styles: ["fill"] },
	forward10: { icon: Forward10, styles: ["fill"] },
	fullscreen: { icon: Fullscreen, styles: ["stroke"] },
	closeFullscreen: { icon: CloseFullscreen, styles: ["stroke"] },
	playArrow: { icon: PlayArrow, styles: ["fill"] },
	pauseArrow: { icon: PauseArrow, styles: ["fill"] },
	settings: { icon: Settings, styles: ["fill"] },
	transcripts: { icon: Transcripts, styles: ["fill"] },
	volume: { icon: Volume, styles: ["fill"] },
	videoPlay: { icon: VideoPlay, styles: ["stroke", "fill-also"] },
};
