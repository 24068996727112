import React, { useEffect, useState } from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery, usePaginationFragment } from "react-relay";
import { Carousel } from "primereact/carousel";
import { FeaturedNodesCarousel_QueryFragment$key } from "@generated/FeaturedNodesCarousel_QueryFragment.graphql";
import { CarousalFeaturedNode } from "./CarousalFeaturedNode";
import { FeaturedNodesCarousel_Query } from "@generated/FeaturedNodesCarousel_Query.graphql";
import { FeaturedNodesCarousel_RefetchQuery } from "@generated/FeaturedNodesCarousel_RefetchQuery.graphql";
import styled from "styled-components";

const QUERY = graphql`
	query FeaturedNodesCarousel_Query {
		...FeaturedNodesCarousel_QueryFragment
	}
`;
const FEATURED_CAROUSAL_NODES_FRAGMENT = graphql`
	fragment FeaturedNodesCarousel_QueryFragment on Query
	@refetchable(queryName: "FeaturedNodesCarousel_RefetchQuery")
	@argumentDefinitions(first: { type: "Int" }, after: { type: "String" }) {
		LearnV2 {
			SearchLearnOpportunities(
				first: $first
				after: $after
				searchOptions: [hideFinished, onlyShowBasisSeminar]
				orderBy: date
				sortDirection: desc
			) @connection(key: "Nodes_SearchLearnOpportunities") {
				pageInfo {
					endCursor
					hasPreviousPage
					hasNextPage
					startCursor
				}
				edges {
					node {
						id
						...CarousalFeaturedNode_NodeFragment
						nextContentNode {
							typeDefinition {
								... on LearnOpportunityELearningContentTypeDefinition {
									containedElementTypes
								}
							}
						}
						structureDefinition {
							title
							... on LearnOpportunityRootStructureDefinition {
								tags {
									name
								}
							}
						}
						image {
							url
						}
					}
				}
			}
		}
	}
`;

export function FeaturedNodesCarousel() {
	const query = useLazyLoadQuery<FeaturedNodesCarousel_Query>(
		QUERY,
		{},
		{ fetchPolicy: "network-only" },
	);

	const { data } = usePaginationFragment<
		FeaturedNodesCarousel_RefetchQuery,
		FeaturedNodesCarousel_QueryFragment$key
	>(FEATURED_CAROUSAL_NODES_FRAGMENT, query);
	const nodes = data.LearnV2.SearchLearnOpportunities.edges?.map((e) => e!.node);

	if (!nodes || nodes.length == 0) return null;

	const [slideIndex, setSlideIndex] = useState(0);
	const [userTouch, setUserTouch] = useState(false);

	useEffect(() => {
		if (!userTouch) {
			const interval = setInterval(() => {
				if (slideIndex < nodes.length) {
					setSlideIndex((prevCount) => prevCount + 1);
				} else {
					setSlideIndex(0);
				}
			}, 5000);
			return () => clearInterval(interval);
		} else {
			setSlideIndex(slideIndex);
		}
	}, [slideIndex, nodes, userTouch]);

	return (
		<div>
			<NodesCarouselContainer className="mb-6">
				<StyledCarousal
					page={slideIndex}
					onClick={() => setUserTouch(true)}
					value={nodes}
					numVisible={1}
					numScroll={1}
					itemTemplate={(node) => <CarousalFeaturedNode nodeRef={node} />}
				/>
			</NodesCarouselContainer>
		</div>
	);
}

const NodesCarouselContainer = styled.div`
	background-color: white;
	padding: 1rem 0.5rem;
	@media screen and (min-width: 481px) and (max-width: 786px) {
		width: 100%;
	}
	@media screen and (max-width: 480px) {
		width: 100%;
	}
	border: 1px solid #d5d5d5;
	border-radius: 5px;
`;

const StyledCarousal = styled(Carousel)`
	.p-carousel-indicators {
		display: none;
	}
`;
