/**
 * @generated SignedSource<<8c1422cf9ca7eb8cc96830458065841d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ContentNodeAdvancementStatus = "BlockedByTree" | "CanBeRestartedAfterFailed" | "CanBeRestartedAfterPassed" | "CanBeStarted" | "CanNotBeRestartedAfterFailed" | "CanNotBeRestartedAfterPassed" | "CanNotBeStarted" | "Continue" | "NotContentNode" | "ViolatesTreeFlow" | "%future added value";
export type TypeDefinitionType = "branch" | "content" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProjectContentButtonCME_LearnOpportunityFragment$data = {
  readonly id: string;
  readonly typeDefinition: {
    readonly contentNodeAdvancementResult?: {
      readonly status: ContentNodeAdvancementStatus;
    };
    readonly definitionType: TypeDefinitionType;
  };
  readonly " $fragmentSpreads": FragmentRefs<"NavigateDirectToQuestionsAdvanceTreeButton_LearnOpportunityV2Fragment">;
  readonly " $fragmentType": "ProjectContentButtonCME_LearnOpportunityFragment";
};
export type ProjectContentButtonCME_LearnOpportunityFragment$key = {
  readonly " $data"?: ProjectContentButtonCME_LearnOpportunityFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectContentButtonCME_LearnOpportunityFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectContentButtonCME_LearnOpportunityFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "typeDefinition",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "definitionType",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "contentNodeAdvancementResult",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "status",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "LearnOpportunityELearningContentTypeDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NavigateDirectToQuestionsAdvanceTreeButton_LearnOpportunityV2Fragment"
    }
  ],
  "type": "LearnOpportunityV2",
  "abstractKey": null
};

(node as any).hash = "08e76db65b07dc6fa1d07a6bb6464533";

export default node;
