import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { AuthSliceReducer } from "./slices/auth.slice";
import { CurrentUserSliceReducer } from "./slices/current-user.slice";

export const ReduxStore = configureStore({
	reducer: {
		auth: AuthSliceReducer,
		currentUser: CurrentUserSliceReducer,
	},
});

export type ReduxState = ReturnType<typeof ReduxStore.getState>;
export type TypedDispatch = typeof ReduxStore.dispatch;

export const useTypedDispatch: () => TypedDispatch = useDispatch;
export const useTypedSelector: TypedUseSelectorHook<ReduxState> = useSelector;
