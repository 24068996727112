import { PrivateRouteDefinition } from "../../../../infecto-lms-webapp/router/interfaces";
import { DashboardRoutes } from "./dashboard.routes";
import { LearnRoutes } from "./learn.routes";
import { NodesRoutes } from "./nodes.routes";
import { SearchRoutes } from "./search.routes";
import { CommunicationSettingsRoutes } from "./communication-settings.routes";
import { DebugRoutes } from "./debug.routes";
import { LearnStateRoutes } from "./learn-state.routes";
import { LegalRoutes } from "./legal.routes";
import { MessagingRoutes } from "./messaging.routes";
import { LandingPagesRoutes } from "../non-auth/landing-pages.routes";
import { CertificatesRoutes } from "./certificates.routes";
import { PageNotFoundRoutes } from "./page-not-found.routes";
import { ServerErrorRoutes } from "./server-error.routes";

export const ImplAuthRoutes: PrivateRouteDefinition[] = [
	...DashboardRoutes,
	...LearnRoutes,
	...NodesRoutes,
	...SearchRoutes,
	...CommunicationSettingsRoutes,
	...DebugRoutes,
	...LearnStateRoutes,
	...LegalRoutes,
	...MessagingRoutes,
	...LandingPagesRoutes,
	...CertificatesRoutes,
	...PageNotFoundRoutes,
	...ServerErrorRoutes,
];
