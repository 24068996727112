import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { UserStatusMessage_UserFragment$key } from "@generated/UserStatusMessage_UserFragment.graphql";
import { ProjectUserStatusMessageComponent } from "../../infecto-lms-webapp-impl/components/ProjectUserStatusMessage.component";

const USER_FRAGMENT = graphql`
	fragment UserStatusMessage_UserFragment on User {
		extension {
			... on InfectopharmUserExtensionImpl {
				globalUserStatus
				country
			}
		}
	}
`;

interface OwnProps {
	userFragment: UserStatusMessage_UserFragment$key;
}

export function UserStatusMessage({ userFragment }: OwnProps) {
	const user = useFragment<UserStatusMessage_UserFragment$key>(USER_FRAGMENT, userFragment);

	return <ProjectUserStatusMessageComponent user={user.extension} />;
}
