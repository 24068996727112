import React from "react";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SEARCH_SCREEN_PATH } from "../../infecto-lms-webapp-impl/router/routes/auth/search.routes";

interface SearchFormState {
	query?: string;
}

interface OwnProps {
	className?: string;
}

export function ExternalSearchBar({ className }: OwnProps) {
	const [params] = useSearchParams();
	const navigate = useNavigate();

	const formik = useFormik<SearchFormState>({
		enableReinitialize: true,
		initialValues: {
			query: params.get("query") || "",
		},
		onSubmit: (values, { setSubmitting }) => {
			navigate(`${SEARCH_SCREEN_PATH}?query=${values.query}`, { replace: true });
			setSubmitting(false);
		},
	});

	return (
		<div className={className}>
			<span className="p-input-icon-right">
				<i className="pi pi-search" />
				<InputText
					placeholder="Module suchen..."
					value={formik.values.query}
					onChange={(e) => formik.setFieldValue("query", e.target.value)}
					onKeyUp={(e) => {
						if (e.key === "Enter") {
							formik.handleSubmit();
						}
					}}
				/>
			</span>
		</div>
	);
}
