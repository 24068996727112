/**
 * @generated SignedSource<<e7e62384415816fae6797d65f426925f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PaediaDefaultContainer_UserDataFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"MainTopBar_UserDataFragment">;
  readonly " $fragmentType": "PaediaDefaultContainer_UserDataFragment";
};
export type PaediaDefaultContainer_UserDataFragment$key = {
  readonly " $data"?: PaediaDefaultContainer_UserDataFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PaediaDefaultContainer_UserDataFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PaediaDefaultContainer_UserDataFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MainTopBar_UserDataFragment"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "b5513224f0f30760eb498ced547fd627";

export default node;
