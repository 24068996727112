import styled from "styled-components";
import React from "react";
import { formatDuration } from "./VideoPlayer.component";

export type PodcastSubtitles = ReadonlyArray<{
	readonly startTime: any;
	readonly endTime: any;
	readonly text: string;
}>;

interface OwnProps {
	timestamp: number;
	subtitles?: PodcastSubtitles;
}

const SubtitleEntry = styled.div<{ active: boolean; hide: boolean }>`
	display: flex;

	${(props) => (props.active ? "opacity: 100%;" : "opacity: 50%;")}
	${(props) => props.hide && "display: none!important;"}
  .timestamp {
		width: 70px !important;
		min-width: 70px !important;
	}
`;

export function PodcastSubtitleDisplay({ timestamp, subtitles }: OwnProps) {
	if (!subtitles) {
		return null;
	}

	const refs = subtitles
		? subtitles.reduce((acc: any, value, index) => {
				acc[index] = React.createRef();
				return acc;
		  }, {})
		: [];

	const activeIndex = subtitles
		? subtitles.findIndex((subtitle) =>
				timestamp
					? (subtitle.startTime as number) <= timestamp && (subtitle.endTime as number) >= timestamp
					: false,
		  )
		: -1;

	const handleScrolling = () => {
		for (const key in refs) {
			if (refs.hasOwnProperty(key) && refs[key].current.classList.contains("tk-hide"))
				refs[key].current.classList.remove("tk-hide");
		}
	};

	const selectBoldElement = (index: number) => index === activeIndex;

	const hideAllElementsAfterNth = (index: number, nthElementToFocus: number): boolean =>
		index < activeIndex - nthElementToFocus;

	return (
		<div onScroll={handleScrolling}>
			{subtitles.map((subtitle, index) => (
				<SubtitleEntry
					ref={refs[index]}
					key={subtitle.startTime + "-" + index}
					active={selectBoldElement(index)}
					hide={hideAllElementsAfterNth(index, 5)}
					className="mb-3"
				>
					<div className="timestamp">{formatDuration(subtitle.startTime)}</div>
					<div className="text ">{subtitle.text}</div>
				</SubtitleEntry>
			))}
		</div>
	);
}
