import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Loader } from "../../../components/loading.component";
import { LoginPromptComponent } from "../../../components/auth/LoginPrompt.component";
import { ImplNonAuthRoutes } from "../../../../infecto-lms-webapp-impl/router/routes/non-auth/impl-non-auth.routes";

export function NonAuthRoutes() {
	return (
		<BrowserRouter>
			<Suspense fallback={<Loader />}>
				<Routes>
					{[...ImplNonAuthRoutes].map((prd) => {
						return <Route key={prd.path} path={prd.path} element={prd.component} />;
					})}
					<Route path="*" element={<LoginPromptComponent />} />
				</Routes>
			</Suspense>
		</BrowserRouter>
	);
}
