import React from "react";
import { NodeBreadcrumbs } from "../../../infecto-lms-webapp/components/node/NodeBreadcrumbs.component";
import { NodeTagList } from "../../../infecto-lms-webapp/components/node/NodeTagList.component";
import { DurationDisplay } from "../../../infecto-lms-webapp/functions/helpers/DurationDisplay";
import { NodeContentElementsList } from "../../../infecto-lms-webapp/components/node/NodeContentElementsList.component";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment, useLazyLoadQuery } from "react-relay";
import { NodeLeftFrame_NodeFragment$key } from "@generated/NodeLeftFrame_NodeFragment.graphql";
import styled from "styled-components";
import { AttachmentHeftDisplay } from "../../../infecto-lms-webapp/components/attachment/AttachmentHeftDisplay.component";
import { NodeLeftFrame_Query } from "@generated/NodeLeftFrame_Query.graphql";
import { selectLogoBasedOnTag } from "./SelectLogoBasedOnTag";
import { formatDateTime } from "../../../infecto-lms-webapp/components/DateTimeDisplay.component";
import { singularPluralPointsDisplay } from "../../../infecto-lms-webapp/functions/helpers/singularPluralPointsDisplay";
import { WrapperContainerWithScrollbar } from "../../../infecto-lms-webapp/containers/WrapperContainerWithScrollbar.container";

const QUERY = graphql`
	query NodeLeftFrame_Query($learnOpportunityId: ID!) {
		node(id: $learnOpportunityId) {
			id
			... on LearnOpportunityV2 {
				id
				...NodeLeftFrame_NodeFragment
			}
		}
		Viewer {
			Auth {
				currentUser {
					user {
						...DefaultContainer_UserDataFragment
						...UserStatusMessage_UserFragment
					}
				}
			}
		}
	}
`;
const LEARN_OPPORTUNITY_FRAGMENT = graphql`
	fragment NodeLeftFrame_NodeFragment on LearnOpportunityV2 {
		id
		image {
			url
		}
		root {
			id
			structureDefinition {
				title
				... on LearnOpportunityRootStructureDefinition {
					tags {
						id
						name
					}
					icon {
						url
					}
					extension {
						... on LearnOpportunityRootExtensionImpl {
							configConsequences {
								restartTriesCount
								isRewardedTo
								rewardExpired
								showAnswerExpired
							}
							totalUserTries
							lengthInSecondsOverride
							reducedDataInTree {
								pacPoints
								pharmaPoints
							}
						}
					}
					...NodeTagList_LearnOpportunityRootStructureDefinitionFragment
				}
			}
		}
		shortDescription
		structureDefinition {
			title
			definitionType
		}
		typeDefinition {
			definitionType
			... on LearnOpportunityELearningContentTypeDefinition {
				extension {
					... on LearnContentExtensionImpl {
						pacPoints
						lengthInSecondsOverride
					}
				}
			}
		}
		...ProjectContentButtonCME_LearnOpportunityFragment
		...AttachmentHeftDisplay_LearnOpportunityV2Fragment
		...NodeNavigationButtons_NodeFragment
		...NodeBreadcrumbs_NodeFragment
		...NodeConfigurationsDisplay_NodeFragment
		...NodeContentElementsList_NodeFragment
		...AttachmentDisplay_LearnOpportunityV2Fragment
		...NodeDescription_NodeFragment
		...ShowAnswersButton_LearnOpportunityFragment
	}
`;
interface OwnProps {
	rootId: string;
	footerPixels: number;
}
const Icon = styled.img`
	max-width: 100px;
`;
export const NodeLeftFrame = ({ rootId, footerPixels }: OwnProps) => {
	const query = useLazyLoadQuery<NodeLeftFrame_Query>(
		QUERY,
		{
			learnOpportunityId: rootId!,
		},
		{ fetchPolicy: "network-only" },
	);

	const learnOpportunity = useFragment<NodeLeftFrame_NodeFragment$key>(
		LEARN_OPPORTUNITY_FRAGMENT,
		query.node,
	)!;

	const title = learnOpportunity.root?.structureDefinition.extension?.configConsequences
		?.isRewardedTo
		? `zertifiziert bis ${formatDateTime(
				learnOpportunity.root?.structureDefinition.extension?.configConsequences?.isRewardedTo,
				false,
		  )}`
		: learnOpportunity.structureDefinition.title;

	const isRoot = learnOpportunity.structureDefinition.definitionType === "root";
	const lengthInSeconds = isRoot
		? learnOpportunity.root?.structureDefinition.extension?.lengthInSecondsOverride || 0
		: learnOpportunity.typeDefinition.extension?.lengthInSecondsOverride || 0;

	const hasPoints = () => {
		return (
			(learnOpportunity.root?.structureDefinition.extension?.reducedDataInTree?.pacPoints || -1) >
				0 ||
			(learnOpportunity.root?.structureDefinition.extension?.reducedDataInTree?.pharmaPoints ||
				-1) > 0
		);
	};

	const restartTriesCount =
		learnOpportunity.root?.structureDefinition.extension?.configConsequences?.restartTriesCount ||
		0;
	const totalUserTries = learnOpportunity.root?.structureDefinition.extension?.totalUserTries || 0;

	interface PointsProps {
		points: number | null | undefined;
		type: string;
	}
	const AvailablePoints = ({ points, type }: PointsProps) => {
		return (
			<div className="text-base" style={{ textAlign: "right" }}>
				{(points || -1) > 0 && (
					<div>
						{type}
						{singularPluralPointsDisplay(points!)}
						<BoldText className="ml-2 text-base font-bold">{points}</BoldText>
					</div>
				)}
			</div>
		);
	};
	return (
		<div className="col-12 xl:col-3 pl-4 pb-3 md:bg-transparent tk-bg-white lg:h-full flex flex-column align-content-start">
			<WrapperContainerWithScrollbar marginBottom={0} footerPixels={footerPixels} width={100}>
				<div className="pr-4 block md:hidden">
					<NodeBreadcrumbs learnOpportunityFragmentRef={learnOpportunity} />
				</div>
				<div className="flex pt-5 pr-4 align-items-center">
					{learnOpportunity.root?.structureDefinition.tags &&
						learnOpportunity.root?.structureDefinition.tags.map((tag) => (
							<Icon
								key={tag.name}
								className="mr-2 mb-2"
								alt={tag.name}
								src={selectLogoBasedOnTag(tag.id)}
							/>
						))}

					{learnOpportunity.root && (
						<NodeTagList
							fontSize={"0.5"}
							className="justify-content-end align-items-center flex-grow-1"
							learnOpportunityRootStructureDefinitionFragmentRef={
								learnOpportunity.root?.structureDefinition
							}
						/>
					)}
				</div>
				<div className="flex align-items-center pr-4 pt-4 pb-2 flex-wrap">
					<div className="w-full">
						<table className="w-full">
							<tbody>
								<tr>
									<td>
										{" "}
										{lengthInSeconds !== 0 && (
											<div className="text-base">
												Dauer: <DurationDisplay duration={lengthInSeconds} />
											</div>
										)}
									</td>
									{learnOpportunity.root?.structureDefinition.extension?.configConsequences
										?.rewardExpired || false ? (
										<td className="text-base" style={{ textAlign: "right" }}>
											Übungsmodul
										</td>
									) : (
										<td>
											{hasPoints() ? (
												<AvailablePoints
													type={"PAC-"}
													points={
														learnOpportunity.root?.structureDefinition.extension?.reducedDataInTree
															?.pacPoints
													}
												/>
											) : null}
										</td>
									)}
								</tr>
								<tr>
									{learnOpportunity.root?.structureDefinition.extension?.configConsequences
										?.rewardExpired ||
									false ||
									!hasPoints() ? (
										<td></td>
									) : (
										<td>
											{" "}
											{learnOpportunity.root?.structureDefinition.extension?.configConsequences
												?.restartTriesCount && (
												<span className="text-base">{`Versuche: ${
													restartTriesCount - totalUserTries
												} von ${restartTriesCount}`}</span>
											)}
										</td>
									)}
									{learnOpportunity.root?.structureDefinition.extension?.configConsequences
										?.rewardExpired || false ? (
										<td></td>
									) : (
										<td>
											{hasPoints() ? (
												<AvailablePoints
													type={"BAK-"}
													points={
														learnOpportunity.root?.structureDefinition.extension?.reducedDataInTree
															?.pharmaPoints
													}
												/>
											) : null}
										</td>
									)}
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<hr className="w-12" />
				<BoldText className="mr-4 mt-2 mb-2  word-wrap text-base">{title}</BoldText>

				<hr className="w-12" />
				{learnOpportunity.shortDescription && (
					<div
						className="mr-4 text-base"
						dangerouslySetInnerHTML={{ __html: learnOpportunity.shortDescription }}
					/>
				)}
				<NodeContentElementsList learnOpportunityFragmentRef={learnOpportunity} />
				<AttachmentHeftDisplay
					learnOpportunityFragmentRef={learnOpportunity}
					marginTopValue={"auto"}
				/>
			</WrapperContainerWithScrollbar>
		</div>
	);
};

const BoldText = styled.span`
	font-family: "Open Sans", sans-serif;
	font-weight: bold;
`;
