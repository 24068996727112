/**
 * @generated SignedSource<<8fa89e8fd4197a688c1e6c0ee8fe4e4c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ElementTypeV2 = "clozeText" | "file" | "multipleChoice" | "order" | "podcast" | "randomMultipleChoice" | "text" | "video" | "%future added value";
export type TypeDefinitionType = "branch" | "content" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type NodeConfigurationsDisplay_NodeFragment$data = {
  readonly root: {
    readonly structureDefinition: {
      readonly extension?: {
        readonly configConsequences?: {
          readonly rewardExpired: boolean;
        };
      };
    };
  } | null;
  readonly typeDefinition: {
    readonly containedElementTypes?: ReadonlyArray<ElementTypeV2>;
    readonly definitionType: TypeDefinitionType;
    readonly extension?: {
      readonly amountOfMultipleChoiceElementsInContentSubmission?: number;
      readonly configConsequences?: {
        readonly isBlockedPermanentlyAfterNumFailedTries: number | null;
        readonly isBlockedTemporarilyForNumDaysAfterOneFailedTry: number | null;
        readonly percentageRequiredToPass: number | null;
      };
    };
  };
  readonly " $fragmentType": "NodeConfigurationsDisplay_NodeFragment";
};
export type NodeConfigurationsDisplay_NodeFragment$key = {
  readonly " $data"?: NodeConfigurationsDisplay_NodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NodeConfigurationsDisplay_NodeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NodeConfigurationsDisplay_NodeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "LearnOpportunityV2",
      "kind": "LinkedField",
      "name": "root",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "structureDefinition",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "extension",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "InfectopharmTreeConfigConsequences",
                          "kind": "LinkedField",
                          "name": "configConsequences",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "rewardExpired",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "type": "LearnOpportunityRootExtensionImpl",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "LearnOpportunityRootStructureDefinition",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "typeDefinition",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "definitionType",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "extension",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "amountOfMultipleChoiceElementsInContentSubmission",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ContentConfigConsequences",
                      "kind": "LinkedField",
                      "name": "configConsequences",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "percentageRequiredToPass",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "isBlockedPermanentlyAfterNumFailedTries",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "isBlockedTemporarilyForNumDaysAfterOneFailedTry",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "LearnContentExtensionImpl",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "containedElementTypes",
              "storageKey": null
            }
          ],
          "type": "LearnOpportunityELearningContentTypeDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "LearnOpportunityV2",
  "abstractKey": null
};

(node as any).hash = "b3bfbab20fdb318957c660f79f1ae991";

export default node;
