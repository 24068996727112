import React from "react";
import MaintenanceBg from "../../infecto-lms-webapp/assets/bg-maintenance.jpg";
import styled from "styled-components";
import { ProjectLogoComponent } from "../../infecto-lms-webapp-impl/components/ProjectLogo.component";

export const MaintenanceScreen = () => {
	return (
		<PageContainer imageurl={MaintenanceBg}>
			<CardContainer>
				<CardMaintenance>
					<ProjectLogoComponent width={window.innerWidth >= 1024 ? 250 : 150} />
					<DescriptionContainer>
						<h3 className="mt-4 mb-0 text-center">
							Die Lernplattform PädiaAkademie ist zur Zeit wegen Wartungsarbeiten geschlossen.
						</h3>
					</DescriptionContainer>
				</CardMaintenance>
			</CardContainer>
		</PageContainer>
	);
};

interface ImageProps {
	imageurl?: string | null;
}

const PageContainer = styled.div<ImageProps>`
	width: 100%;
	height: 100%;
	background-image: url(${(p) => p.imageurl});
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
`;
const CardContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	margin-top: 40px;
`;
const CardMaintenance = styled.div`
	background-color: rgba(84, 94, 96, 0.4);
	width: 500px;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 30px;
	margin: 10px;
`;

const DescriptionContainer = styled.div`
	color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;
