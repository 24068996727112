import React from "react";
import { PrivateRouteDefinition } from "../../../../infecto-lms-webapp/router/interfaces";
import { LandingPageScreen } from "../../../../app/screens/LandingPageScreen";

export const LANDING_PAGE_PATH = "/pages/:urlParam";

export const LandingPagesRoutes: PrivateRouteDefinition[] = [
	{
		path: LANDING_PAGE_PATH,
		component: <LandingPageScreen />,
	},
];
