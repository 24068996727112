import ReactPlayer from "react-player";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import screenfull from "screenfull";
import styled from "styled-components";
import { findDOMNode } from "react-dom";
import FullScreenButton from "../../assets/fullscreen.png";
import PlayButton from "../../assets/play-button.png";
import "./video-player.scss";
import { track } from "../../functions/gtm";

const LowerControlsContainer = styled.div`
	background-color: rgba(0, 0, 0, 0.4);
	padding: 10px;
	border-radius: 4px;
	@media (max-width: 600px) {
		padding: 5px;
	}
`;
interface OwnProps {
	className?: string;
	timestamp?: number;
	videoFile: string;
	onCurrentTimeChange?: (seconds: number) => void;
}

export const formatInt = (int: number): string => {
	if (int < 10) {
		return `0${int}`;
	}
	return `${int}`;
};

export const formatDuration = (time: number): string => {
	const seconds = moment.duration(time, "seconds").seconds();
	const minutes = moment.duration(time, "seconds").minutes();
	const hours = moment.duration(time, "seconds").hours();
	if (hours > 0) {
		return `${formatInt(hours)}:${formatInt(minutes)}:${formatInt(seconds)}`;
	}
	if (minutes > 0) {
		return `${formatInt(minutes)}:${formatInt(seconds)}`;
	}
	return `00:${formatInt(seconds)}`;
};

export function VideoPlayer({ className, timestamp, videoFile, onCurrentTimeChange }: OwnProps) {
	const [state, setState] = useState({
		playing: false,
		rate: 1,
		timestamp: timestamp || 0,
		duration: 0,
		isFullscreen: false,
	});
	const [displayLowerControls, setDisplayLowerControls] = useState({ display: "none" });
	const seekWrapper = useRef<HTMLDivElement | null>(null);
	const playerWrapper = useRef<HTMLDivElement | null>(null);
	const videoPlayer = useRef<ReactPlayer | null>(null);

	useEffect(() => {
		if (screenfull && screenfull.onchange) {
			screenfull.onchange(() => {
				setState((state) => ({ ...state, isFullscreen: screenfull.isFullscreen }));
			});
		}
		// eslint-disable-next-line
	}, []);

	const playedRatio = state.timestamp / (state.duration + 1);
	const remainingRatio = 1 - state.timestamp / (state.duration + 1);

	return (
		<div
			className={`video-player flex-grow-1 ${state.isFullscreen ? "fullscreen" : ""} ${
				className || ""
			}`}
			onMouseEnter={() => {
				setDisplayLowerControls({ display: "block" });
			}}
			onMouseLeave={() => {
				setDisplayLowerControls({ display: "none" });
			}}
			ref={playerWrapper}
		>
			<div
				className="player-wrapper w-9"
				onMouseEnter={() => {
					setDisplayLowerControls({ display: "block" });
				}}
				onMouseLeave={() => {
					setDisplayLowerControls({ display: "none" });
				}}
			>
				<ReactPlayer
					playbackRate={state.rate}
					playing={state.playing}
					onDuration={(duration) => {
						setState({ ...state, duration });
						if (timestamp) {
							videoPlayer.current?.seekTo(timestamp);
						}
						track({ event: "video_loaded", videoFile });
					}}
					onPause={() => {
						setState({ ...state, playing: false });
						track({ event: "video_paused", videoFile });
					}}
					onEnded={() => {
						setState({ ...state, playing: false });
						track({ event: "video_ended", videoFile });
					}}
					ref={videoPlayer}
					onProgress={(progress) => {
						setState(() => ({ ...state, timestamp: progress.playedSeconds }));
						if (onCurrentTimeChange) {
							onCurrentTimeChange(progress.playedSeconds);
						}
					}}
					onSeek={(seconds) => {
						setState(() => ({ ...state, timestamp: seconds }));
						if (onCurrentTimeChange) {
							onCurrentTimeChange(seconds);
						}
					}}
					width="100%"
					height="100%"
					url={videoFile}
				/>
				<div className={`video-overlay ${state.playing ? "playing" : "stopped"}`}>
					<div
						className="play-button"
						onClick={() => setState({ ...state, playing: !state.playing })}
					>
						{!state.playing ? <img alt="Video starten" src={PlayButton} /> : null}
					</div>
					<LowerControlsContainer style={displayLowerControls}>
						<div className="lower-controls justify-content-between align-items-center">
							<div className="timestamp mr-3">
								{formatDuration(videoPlayer.current?.getCurrentTime() || 0)} /{" "}
								{formatDuration(state.duration)}
							</div>

							<div
								ref={seekWrapper}
								className="seek"
								onClick={(event) => {
									const wrapperElement = seekWrapper.current as HTMLDivElement;
									const relativeClickX = event.clientX - wrapperElement.getBoundingClientRect().x;
									const clickRatio = relativeClickX / wrapperElement.getBoundingClientRect().width;
									const durationToGoTo = clickRatio * state.duration;
									videoPlayer.current!.seekTo(durationToGoTo, "seconds");
								}}
							>
								<div className="bar">
									<div className="bar-fill" style={{ width: `${100 * playedRatio}%` }} />
									<div className="bubble" />
									<div className="bar-remain" style={{ width: `${100 * remainingRatio}%` }} />
								</div>
							</div>

							<div className="flex ml-3">
								<div className="mr-2">
									<select
										className="rate"
										value={state.rate}
										onChange={(event) => {
											setState({ ...state, rate: parseFloat(event.target.value) });
										}}
									>
										<option value={0.5}>0.5x</option>
										<option value={0.75}>0.75x</option>
										<option value={1}>1x</option>
										<option value={1.25}>1.25x</option>
										<option value={1.5}>1.5x</option>
										<option value={2}>2x</option>
									</select>
								</div>
								<div
									className="fullscreen"
									onClick={() => {
										if (screenfull?.isFullscreen) {
											screenfull?.exit();
											setState({ ...state, isFullscreen: false });
										} else {
											screenfull?.request(findDOMNode(playerWrapper.current as any) as any);
											setState({ ...state, isFullscreen: true });
										}
									}}
								>
									<img alt="Vollbild" src={FullScreenButton} />
								</div>
							</div>
						</div>
					</LowerControlsContainer>
				</div>
			</div>
		</div>
	);
}
