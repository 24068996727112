import React, { useEffect } from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay";
import { DashboardScreen_Query } from "@generated/DashboardScreen_Query.graphql";
import { usePageTitle } from "../../infecto-lms-webapp/hooks/usePageTitle";
import { MainContainer } from "../containers/MainContainer";
import { NodesCarousal } from "../components/node/NodesCarousal";
import { extractName } from "../../infecto-lms-webapp/components/navigation/ProfileLink.component";
import { UserStatusMessage } from "../../infecto-lms-webapp/components/UserStatusMessage.component";
import { selectLogoBasedOnTag } from "../components/node/SelectLogoBasedOnTag";
import { FeaturedNodesCarousel } from "../components/FeaturedNodesCarousel";
import styled from "styled-components";
import { PaediaDefaultContainer } from "../containers/PaediaDefault.container";
import { LMS_WEBAPP_CONFIG_IMPL } from "../../infecto-lms-webapp-impl/config";

const DASHBOARD_QUERY = graphql`
	query DashboardScreen_Query {
		Viewer {
			Auth {
				currentUser {
					user {
						extension {
							... on InfectopharmUserExtensionImpl {
								salutation
								title
								firstName
								lastName
								efnNumber
								fachberaterStatus {
									statusType
								}
							}
						}
						...PaediaDefaultContainer_UserDataFragment
						...UserStatusMessage_UserFragment
					}
				}
			}
		}
		Learn {
			NodesSortedToTags {
				tag {
					id
					name
				}
				nodes {
					id
					...CarousalNode_NodeFragment
				}
			}
		}
	}
`;

export function DashboardScreen() {
	usePageTitle("Dashboard");

	const query = useLazyLoadQuery<DashboardScreen_Query>(
		DASHBOARD_QUERY,
		{
			brandId: LMS_WEBAPP_CONFIG_IMPL.brandId,
		},
		{ fetchPolicy: "network-only" },
	);

	useEffect(() => {
		window.onpopstate = () => {};
	}, []);

	const sortList = [
		"VGFnOnBhZWRpYS10YWctMQ==",
		"VGFnOlRhZzphZTk4OWRjNC05MmZiLTQ5ODQtOWRjMy0yOTY0NTZkYjU2ZjY=",
		"VGFnOlRhZzplNjc2YTc1Yy00YTY5LTRhY2EtYjZjMi03ZThjMzI0ODgwYjg=",
		"VGFnOlRhZzo0MGYyY2VlMy02YTUyLTRhMWMtYTAwMC0zZjk0YWUxOTdmZDY=",
	];

	const FIXED_TAG_NAME: { [key: string]: string } = {
		"VGFnOnBhZWRpYS10YWctMQ==": "Themenhefte (BAK-zertifiziert)",
		"VGFnOlRhZzphZTk4OWRjNC05MmZiLTQ5ODQtOWRjMy0yOTY0NTZkYjU2ZjY=":
			"Onlineschulungen (BAK-zertifiziert)",
		"VGFnOlRhZzplNjc2YTc1Yy00YTY5LTRhY2EtYjZjMi03ZThjMzI0ODgwYjg=": "Pädia Produktschulungen",
		"VGFnOlRhZzo0MGYyY2VlMy02YTUyLTRhMWMtYTAwMC0zZjk0YWUxOTdmZDY=":
			"Infectopharm Produktschulungen",
	};

	return (
		<PaediaDefaultContainer
			collapsed={false}
			screenName="dashboard"
			userDataFragmentRef={query.Viewer.Auth.currentUser?.user!}
		>
			<MainContainer>
				<h1>Hallo {extractName(query.Viewer.Auth.currentUser?.user.extension)}!</h1>
				<UserStatusMessage userFragment={query.Viewer.Auth.currentUser?.user!} />
				<p className="text-base mb-7">
					Willkommen auf der neuen Lernplattform der Pädia<strong>Akademie</strong>. Hier finden Sie
					alle aktuellen Onlineschulungen und Themenhefte. Ihre Zertifikate können Sie jederzeit mit
					Klick auf den Menüpunkt "Meine Zertifikate" aufrufen. Wir wünschen Ihnen viel Spaß beim
					Entdecken und Fortbilden!
				</p>
				<CardCarouselsContainer>
					<div>
						<FeaturedNodesCarousel />
					</div>

					<div style={{ width: "100%" }} className="flex flex-column justify-content-center">
						{query.Learn.NodesSortedToTags.filter((tagNodes) => tagNodes.nodes.length > 0)
							.sort((a, b) => sortList.indexOf(a.tag.id) - sortList.indexOf(b.tag.id))
							.map((tagNodes) => (
								<NodesCarousal
									key={tagNodes.tag.id}
									nodes={tagNodes.nodes}
									categoryLogo={selectLogoBasedOnTag(tagNodes.tag.id)}
									categoryTitle={FIXED_TAG_NAME[tagNodes.tag.id]}
									tagId={tagNodes.tag.id}
								/>
							))}
					</div>
				</CardCarouselsContainer>
			</MainContainer>
		</PaediaDefaultContainer>
	);
}

const CardCarouselsContainer = styled.div`
	@media screen and (min-width: 768px) {
		width: 60vw;
	}
	@media screen and (max-width: 768px) {
		width: 90vw;
	}
`;
