/**
 * @generated SignedSource<<42f5a7cd134b32a8d6531516ef18a0bf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PodcastElementPart_ContentSubmissionFragment$data = {
  readonly definition: {
    readonly currentElementState?: {
      readonly element: {
        readonly " $fragmentSpreads": FragmentRefs<"PodcastElementDisplay_PodcastElementV2Fragment">;
      };
    } | null;
  };
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"ContentElementContainer_ContentSubmissionFragment" | "ProjectContentElementContainer_ContentSubmissionFragment" | "SubmitDisplayElementButton_ContentSubmissionFragment">;
  readonly " $fragmentType": "PodcastElementPart_ContentSubmissionFragment";
};
export type PodcastElementPart_ContentSubmissionFragment$key = {
  readonly " $data"?: PodcastElementPart_ContentSubmissionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PodcastElementPart_ContentSubmissionFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PodcastElementPart_ContentSubmissionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "definition",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "currentElementState",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "element",
                  "plural": false,
                  "selections": [
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "PodcastElementDisplay_PodcastElementV2Fragment"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "ActiveELearningContentSubmissionDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContentElementContainer_ContentSubmissionFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SubmitDisplayElementButton_ContentSubmissionFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectContentElementContainer_ContentSubmissionFragment"
    }
  ],
  "type": "ContentSubmission",
  "abstractKey": null
};

(node as any).hash = "8452f4de858c549f94572caedae7ab06";

export default node;
