import React from "react";
// @ts-ignore
import { classNames } from "primereact/utils";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { RenderConfig } from "./ValidatedField.component";

export function DefaultTextFieldComponent({
	fieldName,
	fieldValue,
	updateField,
	isValid,
	disabled,
	placeholder,
}: RenderConfig<string>) {
	return (
		<InputText
			id={fieldName}
			name={fieldName}
			value={fieldValue}
			onChange={(e) => updateField(e.target.value)}
			disabled={disabled}
			placeholder={placeholder}
			className={classNames({ "p-invalid": !isValid })}
		/>
	);
}

export function DefaultTextAreaComponent({
	fieldName,
	fieldValue,
	updateField,
	isValid,
	disabled,
	placeholder,
}: RenderConfig<string>) {
	return (
		<InputTextarea
			id={fieldName}
			name={fieldName}
			value={fieldValue}
			onChange={(e) => updateField(e.target.value)}
			disabled={disabled}
			placeholder={placeholder}
			className={classNames({ "p-invalid": !isValid })}
		/>
	);
}
