/**
 * @generated SignedSource<<fa6b56b148d07b9aadfa3bfb9d7faf69>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ElementTypeV2 = "clozeText" | "file" | "multipleChoice" | "order" | "podcast" | "randomMultipleChoice" | "text" | "video" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ElementAnswers_ElementFragment$data = {
  readonly answerOptions?: ReadonlyArray<{
    readonly isCorrect: boolean;
  }>;
  readonly elementType: ElementTypeV2;
  readonly " $fragmentSpreads": FragmentRefs<"FileElementDisplayWithAnswers_FileElementV2Fragment" | "MultipleChoiceElementDisplayWithAnswers_MultipleChoiceElementV2Fragment" | "PodcastElementDisplayWithAnswers_PodcastElementV2Fragment" | "TextElementDisplayWithAnswers_TextElementV2Fragment" | "VideoElementDisplayWithAnswers_VideoElementV2Fragment">;
  readonly " $fragmentType": "ElementAnswers_ElementFragment";
};
export type ElementAnswers_ElementFragment$key = {
  readonly " $data"?: ElementAnswers_ElementFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ElementAnswers_ElementFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ElementAnswers_ElementFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "elementType",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TextElementDisplayWithAnswers_TextElementV2Fragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MultipleChoiceElementDisplayWithAnswers_MultipleChoiceElementV2Fragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PodcastElementDisplayWithAnswers_PodcastElementV2Fragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "VideoElementDisplayWithAnswers_VideoElementV2Fragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FileElementDisplayWithAnswers_FileElementV2Fragment"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AnswerOptionV2",
          "kind": "LinkedField",
          "name": "answerOptions",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isCorrect",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "MultipleChoiceElementV2",
      "abstractKey": null
    }
  ],
  "type": "ElementV2",
  "abstractKey": "__isElementV2"
};

(node as any).hash = "463ac1b709c3bd4f28c379ce6556c39d";

export default node;
