import React, { useContext } from "react";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import { SearchContext } from "../../context/search-context/SearchContextProvider";
import { PrimaryButton } from "../buttons/PrimaryButton";

interface SearchFormState {
	query?: string;
}

export function SearchBar() {
	const { filters, setFilters } = useContext(SearchContext);

	const formik = useFormik<SearchFormState>({
		enableReinitialize: true,
		initialValues: {
			query: filters.query,
		},
		onSubmit: (values, { setSubmitting }) => {
			setFilters({
				...filters,
				query: values.query,
			});
			setSubmitting(false);
		},
	});

	return (
		<form
			onSubmit={formik.handleSubmit}
			className="flex flex-column md:flex-row justify-content-center pl-0 pr-0 mt-4 mb-4 md:grid col-12 flex-nowrap"
		>
			<InputText
				className="flex-grow-1 md:mr-4 mb-4 md:mb-0"
				value={formik.values.query}
				placeholder="Bitte geben Sie einen Suchbegriff ein..."
				onChange={(e) => formik.setFieldValue("query", e.target.value)}
			/>
			<PrimaryButton className="pl-8 pr-8 pt-3 pb-3" type="submit" label="Suche" />
		</form>
	);
}
