import { graphql } from "babel-plugin-relay/macro";
import { useNavigate } from "react-router-dom";
import { useFragment, useLazyLoadQuery, useMutation } from "react-relay";
import { getContentSubmissionPath } from "../../infecto-lms-webapp-impl/router/routes/auth/learn.routes";
import { formatDateTime } from "../../infecto-lms-webapp/components/DateTimeDisplay.component";
import React from "react";
import styled from "styled-components";
import { NavigateDirectToQuestionsAdvanceContentButton_Query } from "@generated/NavigateDirectToQuestionsAdvanceContentButton_Query.graphql";
import { NavigateDirectToQuestionsAdvanceContentButton_LearnOpportunityFragment$key } from "@generated/NavigateDirectToQuestionsAdvanceContentButton_LearnOpportunityFragment.graphql";
import { NavigateDirectToQuestionsAdvanceContentButton_StartContentNodeMutation } from "@generated/NavigateDirectToQuestionsAdvanceContentButton_StartContentNodeMutation.graphql";
import { NavigateDirectToQuestionsAdvanceContentButton_RestartContentNodeAfterPassedMutation } from "@generated/NavigateDirectToQuestionsAdvanceContentButton_RestartContentNodeAfterPassedMutation.graphql";
import { NavigateDirectToQuestionsAdvanceContentButton_RestartContentNodeAfterNotYetPassedMutation } from "@generated/NavigateDirectToQuestionsAdvanceContentButton_RestartContentNodeAfterNotYetPassedMutation.graphql";
import { isPermanentlyBlockedUtil } from "../utils/advancement-result/is-permanently-blocked";
import { isTemporarilyBlockedUntilUtil } from "../../infecto-lms-webapp/utils/advancement-result/is-temporarily-blocked-until";
import { PrimaryButton } from "../../infecto-lms-webapp/components/buttons/PrimaryButton";
import { Label } from "../../infecto-lms-webapp/components/buttons/Label";

const QUERY = graphql`
	query NavigateDirectToQuestionsAdvanceContentButton_Query($learnOpportunityId: ID!) {
		node(id: $learnOpportunityId) {
			id
			... on LearnOpportunityV2 {
				id
				...NavigateDirectToQuestionsAdvanceContentButton_LearnOpportunityFragment
			}
		}
	}
`;

const LEARN_OPPORTUNITY_FRAGMENT = graphql`
	fragment NavigateDirectToQuestionsAdvanceContentButton_LearnOpportunityFragment on LearnOpportunityV2 {
		id
		root {
			structureDefinition {
				... on LearnOpportunityRootStructureDefinition {
					extension {
						... on LearnOpportunityRootExtensionImpl {
							wordpressCertificates {
								id
							}
						}
					}
				}
			}
		}
		shortDescription
		structureDefinition {
			title
		}
		typeDefinition {
			... on LearnOpportunityELearningContentTypeDefinition {
				definitionType
				contentNodeAdvancementResult {
					status
					... on CanNotBeStartedContentNodeAdvancementResult {
						reasonConfigTypes
					}
					... on ContinueContentNodeAdvancementResult {
						activeContentSubmissionId
					}
					... on CanNotBeRestartedAfterFailedContentNodeAdvancementResult {
						status
						configResults {
							configType

							... on RestartIfFailedContentConfigResult {
								canBeRestarted
							}

							... on NegativeBlockPermanentlyRestartIfFailedContentConfigResult {
								canBeRestarted
							}

							... on NegativeBlockTemporarilyRestartIfFailedContentConfigResult {
								blockedUntil
							}
						}
					}
				}
				containedElementTypes
			}
		}
	}
`;

const START_CONTENT_NODE_MUTATION = graphql`
	mutation NavigateDirectToQuestionsAdvanceContentButton_StartContentNodeMutation(
		$input: StartContentNodeInput!
	) {
		LearnV2 {
			startContentNode(input: $input) {
				contentSubmission {
					id
				}
			}
		}
	}
`;

const RESTART_CONTENT_NODE_AFTER_PASSED_MUTATION = graphql`
	mutation NavigateDirectToQuestionsAdvanceContentButton_RestartContentNodeAfterPassedMutation(
		$input: RestartContentNodeAfterPassedInput!
	) {
		LearnV2 {
			restartContentNodeAfterPassed(input: $input) {
				contentSubmission {
					id
				}
			}
		}
	}
`;

const RESTART_CONTENT_NODE_AFTER_NOT_YET_PASSED_MUTATION = graphql`
	mutation NavigateDirectToQuestionsAdvanceContentButton_RestartContentNodeAfterNotYetPassedMutation(
		$input: RestartContentNodeAfterFailedInput!
	) {
		LearnV2 {
			restartContentNodeAfterFailed(input: $input) {
				contentSubmission {
					id
				}
			}
		}
	}
`;

interface OwnProps {
	learnOpportunityId?: string;
	className?: string;
	elementTypeLabel?: string;
}

export function NavigateDirectToQuestionsAdvanceContentButton({
	learnOpportunityId,
	className,
	elementTypeLabel,
}: OwnProps) {
	const navigate = useNavigate();

	const query = useLazyLoadQuery<NavigateDirectToQuestionsAdvanceContentButton_Query>(
		QUERY,
		{
			learnOpportunityId: learnOpportunityId!,
		},
		{ fetchPolicy: "network-only" },
	);
	const learnOpportunity =
		useFragment<NavigateDirectToQuestionsAdvanceContentButton_LearnOpportunityFragment$key>(
			LEARN_OPPORTUNITY_FRAGMENT,
			query.node!,
		)!;
	const [startContentNode, isStartingContentNode] =
		useMutation<NavigateDirectToQuestionsAdvanceContentButton_StartContentNodeMutation>(
			START_CONTENT_NODE_MUTATION,
		);

	const [restartContentNodeAfterPassed, isRestartingContentNodeAfterPassed] =
		useMutation<NavigateDirectToQuestionsAdvanceContentButton_RestartContentNodeAfterPassedMutation>(
			RESTART_CONTENT_NODE_AFTER_PASSED_MUTATION,
		);

	const [restartContentNodeAfterNotYetPassed, isRestartingContentNodeAfterNotYetPassed] =
		useMutation<NavigateDirectToQuestionsAdvanceContentButton_RestartContentNodeAfterNotYetPassedMutation>(
			RESTART_CONTENT_NODE_AFTER_NOT_YET_PASSED_MUTATION,
		);

	const { contentNodeAdvancementResult } = learnOpportunity.typeDefinition;

	const numWordpressCertificates =
		(learnOpportunity.root?.structureDefinition.extension?.wordpressCertificates! &&
			learnOpportunity.root?.structureDefinition.extension.wordpressCertificates.length) ||
		0;

	const hasContainedElementTypesMultipleChoiceOrRandomMultipleChoice =
		learnOpportunity.typeDefinition.containedElementTypes?.includes("multipleChoice") ||
		learnOpportunity.typeDefinition.containedElementTypes?.includes("randomMultipleChoice");

	const hasLekAndWordPressCertificate =
		hasContainedElementTypesMultipleChoiceOrRandomMultipleChoice && numWordpressCertificates > 0;

	const isTemporarilyBlockedUntil = isTemporarilyBlockedUntilUtil(
		learnOpportunity?.typeDefinition.contentNodeAdvancementResult?.configResults,
	);

	const isPermanentlyBlocked = isPermanentlyBlockedUtil(
		learnOpportunity?.typeDefinition.contentNodeAdvancementResult?.configResults,
	);

	return (
		<DivContainer>
			{(() => {
				switch (contentNodeAdvancementResult?.status) {
					case "CanBeStarted":
						return (
							<LinkButton
								disabled={isStartingContentNode || hasLekAndWordPressCertificate}
								onClick={() => {
									localStorage.setItem("previousRouteStarted", location.pathname);
									startContentNode({
										variables: { input: { contentNodeId: learnOpportunity.id } },
										onCompleted: (response) => {
											navigate(
												getContentSubmissionPath(
													response.LearnV2.startContentNode?.contentSubmission.id || "",
												),
											);
										},
									});
								}}
								className={`bg-primary ${className}`}
							>
								{" "}
								{elementTypeLabel ? (
									elementTypeLabel
								) : (
									<Label
										dangerouslySetInnerHTML={{
											__html:
												learnOpportunity.shortDescription &&
												learnOpportunity.shortDescription !== "<div><br></div>"
													? learnOpportunity.shortDescription
													: learnOpportunity.structureDefinition.title,
										}}
									/>
								)}
							</LinkButton>
						);
					case "CanNotBeStarted":
						return (
							<LinkButton
								disabled
								className={`bg-primary ${className}`}
								tooltipOptions={{
									showOnDisabled: true,
									position: "bottom",
									mouseTrack: true,
									mouseTrackTop: 0,
									mouseTrackLeft: 0,
								}}
								tooltip={
									learnOpportunity.typeDefinition.contentNodeAdvancementResult?.reasonConfigTypes?.find(
										(ct) => ct === "StartContent_SpecificUserStatusesCan",
									)
										? "Ihr Konto ist nicht vollständig freigeschalten. Bitte beachten Sie die gelben Warnmeldungen auf dieser Seite."
										: "Nicht freigeschaltet"
								}
							>
								{" "}
								{elementTypeLabel ? (
									"Nicht freigeschaltet"
								) : (
									<Label
										dangerouslySetInnerHTML={{
											__html:
												learnOpportunity.shortDescription &&
												learnOpportunity.shortDescription !== "<div><br></div>"
													? learnOpportunity.shortDescription
													: learnOpportunity.structureDefinition.title,
										}}
									/>
								)}
							</LinkButton>
						);
					case "Continue":
						return (
							<LinkButton
								disabled={hasLekAndWordPressCertificate}
								onClick={() => {
									localStorage.setItem("previousRouteContinue", location.pathname);
									navigate(
										getContentSubmissionPath(
											learnOpportunity.typeDefinition.contentNodeAdvancementResult
												?.activeContentSubmissionId || "",
										),
									);
								}}
								className={`bg-primary ${className}`}
							>
								{" "}
								{elementTypeLabel ? (
									elementTypeLabel
								) : (
									<Label
										dangerouslySetInnerHTML={{
											__html:
												learnOpportunity.shortDescription &&
												learnOpportunity.shortDescription !== "<div><br></div>"
													? learnOpportunity.shortDescription
													: learnOpportunity.structureDefinition.title,
										}}
									/>
								)}
							</LinkButton>
						);
					case "CanBeRestartedAfterPassed":
						return (
							<LinkButton
								disabled={isRestartingContentNodeAfterPassed || hasLekAndWordPressCertificate}
								onClick={() => {
									localStorage.setItem("previousRouteAfterPassed", location.pathname);
									restartContentNodeAfterPassed({
										variables: { input: { contentNodeId: learnOpportunity.id } },
										onCompleted: (response) => {
											navigate(
												getContentSubmissionPath(
													response.LearnV2.restartContentNodeAfterPassed?.contentSubmission.id ||
														"",
												),
											);
										},
									});
								}}
								className={`bg-primary ${className}`}
							>
								{" "}
								{elementTypeLabel ? (
									elementTypeLabel
								) : (
									<Label
										dangerouslySetInnerHTML={{
											__html:
												learnOpportunity.shortDescription &&
												learnOpportunity.shortDescription !== "<div><br></div>"
													? learnOpportunity.shortDescription
													: learnOpportunity.structureDefinition.title,
										}}
									/>
								)}
							</LinkButton>
						);
					case "CanNotBeRestartedAfterPassed":
						return (
							<LinkButton
								disabled
								className={`bg-primary ${className}`}
								tooltipOptions={{
									showOnDisabled: true,
									position: "bottom",
									mouseTrack: true,
									mouseTrackTop: 0,
									mouseTrackLeft: 0,
								}}
								tooltip={"Modul bereits abgeschlossen"}
							>
								{elementTypeLabel ? (
									elementTypeLabel
								) : (
									<Label
										dangerouslySetInnerHTML={{
											__html:
												learnOpportunity.shortDescription &&
												learnOpportunity.shortDescription !== "<div><br></div>"
													? learnOpportunity.shortDescription
													: learnOpportunity.structureDefinition.title,
										}}
									/>
								)}
							</LinkButton>
						);
					case "CanBeRestartedAfterFailed":
						return (
							<LinkButton
								disabled={isRestartingContentNodeAfterNotYetPassed || hasLekAndWordPressCertificate}
								onClick={() => {
									localStorage.setItem("previousRouteAfterFailed", location.pathname);
									restartContentNodeAfterNotYetPassed({
										variables: { input: { contentNodeId: learnOpportunity.id } },
										onCompleted: (response) => {
											navigate(
												getContentSubmissionPath(
													response.LearnV2.restartContentNodeAfterFailed?.contentSubmission.id ||
														"",
												),
											);
										},
									});
								}}
								className={`bg-primary ${className}`}
							>
								{" "}
								{elementTypeLabel ? (
									elementTypeLabel
								) : (
									<Label
										dangerouslySetInnerHTML={{
											__html:
												learnOpportunity.shortDescription &&
												learnOpportunity.shortDescription !== "<div><br></div>"
													? learnOpportunity.shortDescription
													: learnOpportunity.structureDefinition.title,
										}}
									/>
								)}
							</LinkButton>
						);
					case "CanNotBeRestartedAfterFailed":
						const tooltip = (() => {
							if (isTemporarilyBlockedUntil) {
								return `Dieses Modul ist nach Falschbeantwortung bis ${formatDateTime(
									isTemporarilyBlockedUntil.blockedUntil as string,
								)} für Sie gesperrt. Sie erhalten eine E-Mail sobald es wieder freigeschalten wurde.`;
							}
							if (isPermanentlyBlocked) {
								return "Sie haben keinen weiteren Versuch mehr.";
							}
							return "Sie haben keinen weiteren Versuch mehr.";
						})();
						return (
							<LinkButton
								disabled
								className={`bg-primary ${className}`}
								tooltipOptions={{
									showOnDisabled: true,
									position: "bottom",
									mouseTrack: true,
									mouseTrackTop: 0,
									mouseTrackLeft: 0,
								}}
								tooltip={tooltip}
							>
								{elementTypeLabel ? (
									isPermanentlyBlocked ? (
										"Permanent gesperrt"
									) : (
										elementTypeLabel
									)
								) : (
									<Label
										dangerouslySetInnerHTML={{
											__html:
												learnOpportunity.shortDescription &&
												learnOpportunity.shortDescription !== "<div><br></div>"
													? learnOpportunity.shortDescription
													: learnOpportunity.structureDefinition.title,
										}}
									/>
								)}
							</LinkButton>
						);
					default:
						// BlockedByTree, NotContentNode, ViolatesTreeFlow
						return null;
				}
			})()}
		</DivContainer>
	);
}

const LinkButton = styled(PrimaryButton)`
	display: flex;
	justify-content: center;
	text-align: center;
	border: none;
	cursor: pointer;
	flex: 1 1 auto;
	max-width: 400px;
`;

const DivContainer = styled.div`
	max-width: 400px;
`;
