import { ReactStyledComponentTypographyFactory } from "./factories";
import styled from "styled-components";
import { TkaTypography } from "./typography";
import { createCSS } from "./typography.util";

export const tkaH2Typography = new TkaTypography(createCSS(1.25, 1, { bold: true }));

export const TkaH2Span = new ReactStyledComponentTypographyFactory(
	tkaH2Typography,
	styled.span,
).create();

// copy
export const tkaCopyTypography = new TkaTypography(createCSS(1.25, 0.857));

export const TkaCopySpan = new ReactStyledComponentTypographyFactory(
	tkaCopyTypography,
	styled.span,
).create();

// copy small
export const tkaCopySmallTypography = new TkaTypography(createCSS(1, 0.75));
export const TkaCopySmallSpan = new ReactStyledComponentTypographyFactory(
	tkaCopySmallTypography,
	styled.span,
)
	.with(`margin:0;`)
	.create();
