/**
 * @generated SignedSource<<5ccd22f774d57b7933319962a8af8b83>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FeaturedNodesCarousel_Query$variables = {};
export type FeaturedNodesCarousel_Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"FeaturedNodesCarousel_QueryFragment">;
};
export type FeaturedNodesCarousel_Query = {
  response: FeaturedNodesCarousel_Query$data;
  variables: FeaturedNodesCarousel_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": "date"
  },
  {
    "kind": "Literal",
    "name": "searchOptions",
    "value": [
      "hideFinished",
      "onlyShowBasisSeminar"
    ]
  },
  {
    "kind": "Literal",
    "name": "sortDirection",
    "value": "desc"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "FeaturedNodesCarousel_Query",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "FeaturedNodesCarousel_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "FeaturedNodesCarousel_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LearnV2Queries",
        "kind": "LinkedField",
        "name": "LearnV2",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v0/*: any*/),
            "concreteType": "LearnOpportunitiesV2Connection",
            "kind": "LinkedField",
            "name": "SearchLearnOpportunities",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasPreviousPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startCursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "LearnOpportunitiesV2Edge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LearnOpportunityV2",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "LearnOpportunityV2",
                        "kind": "LinkedField",
                        "name": "nextContentNode",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "typeDefinition",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "containedElementTypes",
                                    "storageKey": null
                                  }
                                ],
                                "type": "LearnOpportunityELearningContentTypeDefinition",
                                "abstractKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v1/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "structureDefinition",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Tag",
                                "kind": "LinkedField",
                                "name": "tags",
                                "plural": true,
                                "selections": [
                                  (v1/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "name",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "LearnOpportunityRootStructureDefinition",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "File",
                        "kind": "LinkedField",
                        "name": "image",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "url",
                            "storageKey": null
                          },
                          (v1/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "SearchLearnOpportunities(orderBy:\"date\",searchOptions:[\"hideFinished\",\"onlyShowBasisSeminar\"],sortDirection:\"desc\")"
          },
          {
            "alias": null,
            "args": (v0/*: any*/),
            "filters": [
              "searchOptions",
              "orderBy",
              "sortDirection"
            ],
            "handle": "connection",
            "key": "Nodes_SearchLearnOpportunities",
            "kind": "LinkedHandle",
            "name": "SearchLearnOpportunities"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "38d554b2008830b79b694ef74690b7b1",
    "id": null,
    "metadata": {},
    "name": "FeaturedNodesCarousel_Query",
    "operationKind": "query",
    "text": "query FeaturedNodesCarousel_Query {\n  ...FeaturedNodesCarousel_QueryFragment\n}\n\nfragment CarousalFeaturedNode_NodeFragment on LearnOpportunityV2 {\n  id\n  nextContentNode {\n    typeDefinition {\n      __typename\n      ... on LearnOpportunityELearningContentTypeDefinition {\n        containedElementTypes\n      }\n    }\n    id\n  }\n  structureDefinition {\n    __typename\n    title\n    ... on LearnOpportunityRootStructureDefinition {\n      tags {\n        id\n        name\n      }\n    }\n  }\n  image {\n    url\n    id\n  }\n}\n\nfragment FeaturedNodesCarousel_QueryFragment on Query {\n  LearnV2 {\n    SearchLearnOpportunities(searchOptions: [hideFinished, onlyShowBasisSeminar], orderBy: date, sortDirection: desc) {\n      pageInfo {\n        endCursor\n        hasPreviousPage\n        hasNextPage\n        startCursor\n      }\n      edges {\n        node {\n          id\n          ...CarousalFeaturedNode_NodeFragment\n          nextContentNode {\n            typeDefinition {\n              __typename\n              ... on LearnOpportunityELearningContentTypeDefinition {\n                containedElementTypes\n              }\n            }\n            id\n          }\n          structureDefinition {\n            __typename\n            title\n            ... on LearnOpportunityRootStructureDefinition {\n              tags {\n                name\n                id\n              }\n            }\n          }\n          image {\n            url\n            id\n          }\n          __typename\n        }\n        cursor\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7ec92ca4e7456c7f1ed5b0d073682003";

export default node;
