import React from "react";
import { DebugScreen } from "../../../../infecto-lms-webapp/screens/DebugScreen";
import { PrivateRouteDefinition } from "../../../../infecto-lms-webapp/router/interfaces";

export const DebugRoutes: PrivateRouteDefinition[] = [
	{
		path: "/debug",
		component: <DebugScreen />,
	},
];
