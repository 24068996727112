import React from "react";
import { useContext } from "react";
import { TkaH2Span } from "../../../utils";
import { white } from "../../../../infecto-lms-webapp-impl/color";
import { DynamicSlideMenuContext } from "../dynamic-slide-menu-provider";
import { Icon } from "../../icon";
import { DynamicSlideMenuParentProps } from "./dynamic-slide-menu-parent.interface";
import styled from "styled-components";

export const DynamicSlideMenuParent = ({
	title,
	width,
	path,
	children,
}: DynamicSlideMenuParentProps) => {
	const context = useContext(DynamicSlideMenuContext);
	const { path: currentPath, goBack, depth } = context;

	const canGoBack = currentPath.length > 1;

	if (!currentPath.includes(path)) return null;

	return currentPath.includes(path) && depth === currentPath.length ? (
		<ParentWrapper className="flex flex-column" width={width} onClick={(e) => e.stopPropagation()}>
			{(canGoBack || title) && (
				<div className="relative flex  justify-content-center">
					{canGoBack && (
						<div className="absolute left-0 h-full flex flex-row align-items-center">
							<Icon icon={"arrowLeft2"} paletteColor={white} sizeInRem={0.75} onClick={goBack} />
						</div>
					)}
					{title && <NavigateTitle paletteColor={white}>{title}</NavigateTitle>}
				</div>
			)}
			{children}
		</ParentWrapper>
	) : (
		<>{children}</>
	);
};
export const ParentWrapper = styled.div<{ width?: number }>`
	border-radius: 10px;
	gap: 1.5rem;
	padding: 1.5rem;
	@media screen and (max-width: 768px) and (min-width: 480px) {
		padding: 2rem;
		gap: 1rem;
	}
	@media screen and (max-width: 480px) {
		padding: 1rem;
		gap: 1rem;
	}
	overflow-y: scroll;
	background: rgb(35, 36, 35);
	width: ${({ width }) => (width !== undefined ? `${width}rem` : "initial")};
`;
export const NavigateTitle = styled(TkaH2Span)`
	@media screen and (max-width: 480px) {
		font-size: 0.75rem;
	}
`;
