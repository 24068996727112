import React, { useContext } from "react";
import { Dropdown } from "primereact/dropdown";
import { SearchContext } from "../../context/search-context/SearchContextProvider";

type Sort = "byAlphabetAsc" | "byAlphabetDesc" | "byDateAsc" | "byDateDesc" | "byRelevancy";

export function SearchOrderV2() {
	const { filters, setFilters } = useContext(SearchContext);

	let sort: Sort;
	switch (filters.orderBy) {
		case "alphabetical":
			if (filters.sortDirection === "asc") {
				sort = "byAlphabetAsc";
			} else {
				sort = "byAlphabetDesc";
			}
			break;
		case "date":
			if (filters.sortDirection === "asc") {
				sort = "byDateAsc";
			} else {
				sort = "byDateDesc";
			}
			break;
		case "relevancy":
			sort = "byRelevancy";
			break;
		default:
			sort = "byDateDesc";
	}

	return (
		<div className="flex align-items-center">
			<Dropdown
				className="ml-auto"
				options={[
					{ value: "byRelevancy", label: "Relevanz" },
					{ value: "byAlphabetAsc", label: "A-Z" },
					{ value: "byAlphabetDesc", label: "Z-A" },
					{ value: "byDateAsc", label: "Datum aufsteigend" },
					{ value: "byDateDesc", label: "Datum absteigend" },
				]}
				value={sort}
				onChange={(e) => {
					switch (e.value) {
						case "byAlphabetAsc":
							setFilters({
								...filters,
								orderBy: "alphabetical",
								sortDirection: "asc",
							});
							break;
						case "byAlphabetDesc":
							setFilters({
								...filters,
								orderBy: "alphabetical",
								sortDirection: "desc",
							});
							break;
						case "byDateAsc":
							setFilters({
								...filters,
								orderBy: "date",
								sortDirection: "asc",
							});
							break;
						case "byDateDesc":
							setFilters({
								...filters,
								orderBy: "date",
								sortDirection: "desc",
							});
							break;
						case "byRelevancy":
							setFilters({
								...filters,
								orderBy: "relevancy",
								sortDirection: undefined,
							});
							break;
						default:
							setFilters({
								...filters,
								orderBy: undefined,
								sortDirection: undefined,
							});
					}
				}}
			/>
		</div>
	);
}
