/**
 * @generated SignedSource<<da0698ae6854c5bcb2f2703d50ccd713>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type FileContentTypeV2 = "jpg" | "pdf" | "png" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type FileElementDisplayWithAnswers_FileElementV2Fragment$data = {
  readonly file: {
    readonly url: string | null;
  } | null;
  readonly fileContentType: FileContentTypeV2;
  readonly title: string;
  readonly " $fragmentType": "FileElementDisplayWithAnswers_FileElementV2Fragment";
};
export type FileElementDisplayWithAnswers_FileElementV2Fragment$key = {
  readonly " $data"?: FileElementDisplayWithAnswers_FileElementV2Fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"FileElementDisplayWithAnswers_FileElementV2Fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FileElementDisplayWithAnswers_FileElementV2Fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "file",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fileContentType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    }
  ],
  "type": "FileElementV2",
  "abstractKey": null
};

(node as any).hash = "7cf93ee3fd940e6db4a05adc2fba08c6";

export default node;
