import styled from "styled-components";

interface OwnProps {
	footerPixels: number;
}
export const MainContainerWithTopAndBottomBar = styled.div<OwnProps>`
	max-height: calc(100vh - ${(props) => props.footerPixels}px);
	max-width: 100%;
	overflow: auto;
	@media only screen and (min-width: 1024px) {
		overflow: hidden;
	}
`;
