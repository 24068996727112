import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { ElementAnswers_ElementFragment$key } from "@generated/ElementAnswers_ElementFragment.graphql";
import { MultipleChoiceElementDisplayWithAnswers } from "./multiplechoice/MultipleChoiceElementDisplayWithAnswers.component";
import { TextElementDisplayWithAnswers } from "./text/TextElementDisplayWithAnswers.component";
import { FileElementDisplayWithAnswersWithAnswers } from "./file/FileElementDisplayWithAnswers.component";
import { VideoElementDisplayWithAnswers } from "./video/VideoElementDisplayWithAnswers.component";
import { PodcastElementDisplayWithAnswers } from "./podcast/PodcastElementDisplayWithAnswers.component";

const ELEMENT_FRAGMENT = graphql`
	fragment ElementAnswers_ElementFragment on ElementV2 {
		elementType
		...TextElementDisplayWithAnswers_TextElementV2Fragment
		...MultipleChoiceElementDisplayWithAnswers_MultipleChoiceElementV2Fragment
		...PodcastElementDisplayWithAnswers_PodcastElementV2Fragment
		...VideoElementDisplayWithAnswers_VideoElementV2Fragment
		...FileElementDisplayWithAnswers_FileElementV2Fragment

		... on MultipleChoiceElementV2 {
			answerOptions {
				isCorrect
			}
		}
	}
`;

interface OwnProps {
	elementFragmentRef: ElementAnswers_ElementFragment$key;
}

export function ElementAnswers({ elementFragmentRef }: OwnProps) {
	const element = useFragment<ElementAnswers_ElementFragment$key>(
		ELEMENT_FRAGMENT,
		elementFragmentRef,
	);

	switch (element.elementType) {
		case "text":
			return <TextElementDisplayWithAnswers textElementFragmentRef={element} />;
		case "file":
			return <FileElementDisplayWithAnswersWithAnswers fileElementFragmentRef={element} />;
		case "video":
			return <VideoElementDisplayWithAnswers videoElementFragmentRef={element} />;
		case "multipleChoice":
			return (
				<MultipleChoiceElementDisplayWithAnswers
					setCheckedAnswerIndices={() => {}}
					checkedAnswerIndices={element
						.answerOptions!.map((ao, index) => (ao.isCorrect ? index : -1))
						.filter((i) => i !== -1)}
					multipleChoiceElementFragmentRef={element}
				/>
			);
		case "podcast":
			return <PodcastElementDisplayWithAnswers podcastElementFragmentRef={element} />;
		default:
			return <div>Unbekannter Elementtyp: {element?.elementType}</div>;
	}
}
