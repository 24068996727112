import React from "react";
import { MainContainerWithTopBar } from "../../infecto-lms-webapp/containers/MainContainerWithTopBar.container";
import { graphql } from "babel-plugin-relay/macro";
import { usePageTitle } from "../../infecto-lms-webapp/hooks/usePageTitle";
import { useLazyLoadQuery } from "react-relay";
import { PaediaDefaultContainer } from "../containers/PaediaDefault.container";
import { ErrorPageComponent } from "../../infecto-lms-webapp/components/ErrorPage.component";
import { ServerErrorScreen_Query } from "@generated/ServerErrorScreen_Query.graphql";

const SERVER_ERROR_QUERY = graphql`
	query ServerErrorScreen_Query {
		Viewer {
			Auth {
				currentUser {
					user {
						extension {
							... on InfectopharmUserExtensionImpl {
								salutation
								title
								lastName
								firstName
								efnNumber
								country
							}
						}
						...PaediaDefaultContainer_UserDataFragment
					}
				}
			}
		}
	}
`;

export const ServerErrorScreen = () => {
	usePageTitle("PageNotFound");
	const query = useLazyLoadQuery<ServerErrorScreen_Query>(SERVER_ERROR_QUERY, {});

	return (
		<PaediaDefaultContainer
			collapsed={false}
			screenName="dashboard"
			userDataFragmentRef={query.Viewer.Auth.currentUser?.user}
		>
			<MainContainerWithTopBar>
				<ErrorPageComponent
					errorType={"Oops!"}
					title={"Etwas ist schiefgelaufen..."}
					description={
						"Es tut uns leid, aber auf dem Server ist ein unerwarteter Fehler aufgetreten."
					}
				/>
			</MainContainerWithTopBar>
		</PaediaDefaultContainer>
	);
};
