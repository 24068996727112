import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { ContentElement_ContentSubmissionFragment$key } from "@generated/ContentElement_ContentSubmissionFragment.graphql";
import { TextElementPart } from "./text/TextElementPart.component";
import { FileElementPart } from "./file/FileElementPart.component";
import { VideoElementPart } from "./video/VideoElementPart.component";
import { MultipleChoiceElementPart } from "./multiplechoice/MultipleChoiceElementPart.component";
import { PodcastElementPart } from "./podcast/PodcastElementPart.component";

const CONTENT_SUBMISSION_FRAGMENT = graphql`
	fragment ContentElement_ContentSubmissionFragment on ContentSubmission {
		definition {
			... on ActiveELearningContentSubmissionDefinition {
				currentElementState {
					element {
						elementType
					}
				}
			}
		}
		...FileElementPart_ContentSubmissionFragment
		...MultipleChoiceElementPart_ContentSubmissionFragment
		...PodcastElementPart_ContentSubmissionFragment
		...TextElementPart_ContentSubmissionFragment
		...VideoElementPart_ContentSubmissionFragment
	}
`;

interface OwnProps {
	contentSubmissionFragmentRef: ContentElement_ContentSubmissionFragment$key;
}

export function ContentElement({ contentSubmissionFragmentRef }: OwnProps) {
	const contentSubmission = useFragment<ContentElement_ContentSubmissionFragment$key>(
		CONTENT_SUBMISSION_FRAGMENT,
		contentSubmissionFragmentRef,
	);

	switch (contentSubmission.definition.currentElementState?.element.elementType) {
		case "file":
			return <FileElementPart contentSubmissionFragmentRef={contentSubmission} />;
		case "multipleChoice":
			return <MultipleChoiceElementPart contentSubmissionFragmentRef={contentSubmission} />;
		case "podcast":
			return <PodcastElementPart contentSubmissionFragmentRef={contentSubmission} />;
		case "text":
			return <TextElementPart contentSubmissionFragmentRef={contentSubmission} />;
		case "video":
			return <VideoElementPart contentSubmissionFragmentRef={contentSubmission} />;
		default:
			return (
				<div>
					Unbekannter Elementtyp:{" "}
					{contentSubmission.definition.currentElementState?.element.elementType}
				</div>
			);
	}
}
