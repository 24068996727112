/**
 * @generated SignedSource<<3003270459153bba38df238349d89af8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ElementTypeV2 = "clozeText" | "file" | "multipleChoice" | "order" | "podcast" | "randomMultipleChoice" | "text" | "video" | "%future added value";
export type TypeDefinitionType = "branch" | "content" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type NodeScreen_NodeFragment$data = {
  readonly description: string | null;
  readonly id: string;
  readonly image: {
    readonly url: string | null;
  } | null;
  readonly instructors: ReadonlyArray<{
    readonly superId: string;
  }>;
  readonly root: {
    readonly id: string;
    readonly structureDefinition: {
      readonly extension?: {
        readonly configConsequences?: {
          readonly rewardExpired: boolean;
          readonly showAnswerExpired: boolean;
        };
        readonly dateTimeOnlyAdminsVisibility?: string | null;
        readonly reducedDataInTree?: {
          readonly elementTypes: ReadonlyArray<ElementTypeV2>;
          readonly pharmaPoints: number | null;
        };
      };
      readonly icon?: {
        readonly url: string | null;
      } | null;
      readonly title: string;
      readonly " $fragmentSpreads": FragmentRefs<"NodeTagList_LearnOpportunityRootStructureDefinitionFragment">;
    };
  } | null;
  readonly shortDescription: string | null;
  readonly structureDefinition: {
    readonly title: string;
  };
  readonly typeDefinition: {
    readonly children?: ReadonlyArray<{
      readonly id: string;
      readonly typeDefinition: {
        readonly containedElementTypes?: ReadonlyArray<ElementTypeV2>;
      };
      readonly " $fragmentSpreads": FragmentRefs<"ShowAnswersButton_LearnOpportunityFragment">;
    }>;
    readonly definitionType: TypeDefinitionType;
  };
  readonly " $fragmentSpreads": FragmentRefs<"AttachmentDisplay_LearnOpportunityV2Fragment" | "AttachmentHeftDisplay_LearnOpportunityV2Fragment" | "LearningOutcomeDescriptionComponent_NodeFragment" | "NodeBreadcrumbs_NodeFragment" | "NodeConfigurationsDisplay_NodeFragment" | "NodeContentElementsList_NodeFragment" | "NodeDescription_NodeFragment" | "NodeNavigationButtons_NodeFragment" | "PaediaTreeAdvancementMessage_NodeFragment" | "ProjectContentButtonCME_LearnOpportunityFragment" | "ShowAnswersButton_LearnOpportunityFragment">;
  readonly " $fragmentType": "NodeScreen_NodeFragment";
};
export type NodeScreen_NodeFragment$key = {
  readonly " $data"?: NodeScreen_NodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NodeScreen_NodeFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v3 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "ShowAnswersButton_LearnOpportunityFragment"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NodeScreen_NodeFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "image",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LearnOpportunityV2",
      "kind": "LinkedField",
      "name": "root",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "structureDefinition",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "extension",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "dateTimeOnlyAdminsVisibility",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ReducedDataInTree",
                          "kind": "LinkedField",
                          "name": "reducedDataInTree",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "elementTypes",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "pharmaPoints",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "InfectopharmTreeConfigConsequences",
                          "kind": "LinkedField",
                          "name": "configConsequences",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "rewardExpired",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "showAnswerExpired",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "type": "LearnOpportunityRootExtensionImpl",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "File",
                  "kind": "LinkedField",
                  "name": "icon",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "NodeTagList_LearnOpportunityRootStructureDefinitionFragment"
                }
              ],
              "type": "LearnOpportunityRootStructureDefinition",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shortDescription",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "instructors",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "superId",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "structureDefinition",
      "plural": false,
      "selections": [
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "typeDefinition",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "LearnOpportunityV2",
              "kind": "LinkedField",
              "name": "children",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "typeDefinition",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "containedElementTypes",
                          "storageKey": null
                        }
                      ],
                      "type": "LearnOpportunityELearningContentTypeDefinition",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                },
                (v3/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "type": "LearnOpportunityBranchTypeDefinition",
          "abstractKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "definitionType",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectContentButtonCME_LearnOpportunityFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AttachmentHeftDisplay_LearnOpportunityV2Fragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NodeNavigationButtons_NodeFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NodeBreadcrumbs_NodeFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NodeConfigurationsDisplay_NodeFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NodeContentElementsList_NodeFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AttachmentDisplay_LearnOpportunityV2Fragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NodeDescription_NodeFragment"
    },
    (v3/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PaediaTreeAdvancementMessage_NodeFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LearningOutcomeDescriptionComponent_NodeFragment"
    }
  ],
  "type": "LearnOpportunityV2",
  "abstractKey": null
};
})();

(node as any).hash = "ce40eba2adde996d92b866dbeb7bc17b";

export default node;
