/**
 * @generated SignedSource<<6bebd5999912c092b299866ef7418560>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AuthenticatedUser_Query$variables = {};
export type AuthenticatedUser_Query$data = {
  readonly Maintenance: {
    readonly MaintenanceUpdate: {
      readonly id: string;
      readonly isUpdating: boolean;
    };
  };
};
export type AuthenticatedUser_Query = {
  response: AuthenticatedUser_Query$data;
  variables: AuthenticatedUser_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "MaintenanceQueries",
    "kind": "LinkedField",
    "name": "Maintenance",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MaintenanceUpdate",
        "kind": "LinkedField",
        "name": "MaintenanceUpdate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isUpdating",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AuthenticatedUser_Query",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AuthenticatedUser_Query",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "b59754135ae6cf655800ffedeeabf159",
    "id": null,
    "metadata": {},
    "name": "AuthenticatedUser_Query",
    "operationKind": "query",
    "text": "query AuthenticatedUser_Query {\n  Maintenance {\n    MaintenanceUpdate {\n      id\n      isUpdating\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1aa0506bdc64a97cfbdcc44c8f9b26a2";

export default node;
