import React from "react";
import { Card } from "primereact/card";
import styled from "styled-components";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { NodeInstructorCard_InstructorFragment$key } from "@generated/NodeInstructorCard_InstructorFragment.graphql";

const FRAGMENT = graphql`
	fragment NodeInstructorCard_InstructorFragment on InstructorImpl {
		name
		title
		description
		portraitFile {
			id
			url
		}
	}
`;
interface OwnProps {
	instructorRef: NodeInstructorCard_InstructorFragment$key;
}
export const NodeInstructorCard = ({ instructorRef }: OwnProps) => {
	const instructor = useFragment<NodeInstructorCard_InstructorFragment$key>(
		FRAGMENT,
		instructorRef,
	)!;

	return (
		<CardContainer className="lg:col-5 col-12 mt-2 lg:mr-4 mb-2 p-1">
			<div className="flex p-0">
				{instructor?.portraitFile?.url && <Image imageurl={instructor.portraitFile.url} />}

				<div className="flex-column">
					<h4 className="m-0">{instructor.name}</h4>
					<h5 className="m-0">{instructor.title}</h5>
				</div>
			</div>
			{instructor.description && (
				<DescriptionParag dangerouslySetInnerHTML={{ __html: instructor.description }} />
			)}
		</CardContainer>
	);
};

interface ImageProps {
	imageurl?: string | null;
}

const Image = styled.div<ImageProps>`
	height: 100px;
	width: 100px;
	margin-right: 20px;
	background-image: url(${(p) => p.imageurl});
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
`;

const CardContainer = styled(Card)`
	.p-card-content {
		padding-top: 0;
		padding-bottom: 0;
	}
`;

const DescriptionParag = styled.p`
	margin-bottom: 0px;
`;
