import { MenuItem } from "primereact/menuitem";
import { RouterMenuItem } from "../infecto-lms-webapp/components/navigation/RouterMenuItem";
import React from "react";
import { DASHBOARD_PATH } from "./router/routes/auth/dashboard.routes";
import { MenuType } from "../infecto-lms-webapp/components/navigation/navigation.interface";

export const PROFILE_LINK_MENU_ITEMS: (
	onclick: () => void,
	menuType: MenuType,
	collapsed: boolean,
) => MenuItem[] = (onclick: () => void, menuType: MenuType, collapsed: boolean) => [
	{
		label: "Logout",
		url: DASHBOARD_PATH,
		icon: "pi pi-sign-out",
		template: (item) => (
			<RouterMenuItem
				icon={<i className={"text-lg " + item.icon} />}
				menuType={menuType}
				onClick={onclick}
				item={item}
				collapsed={collapsed}
			/>
		),
	},
];
