import { createContext, SetStateAction } from "react";

type VideoPlayerContext = {
	progress: number;
	setProgress: React.Dispatch<SetStateAction<number>>;
};

export const VideoPlayerContext = createContext<VideoPlayerContext>({
	progress: 0,
	setProgress: () => {},
});
