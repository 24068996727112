/**
 * @generated SignedSource<<c07cced5f9fdef72fd259caf1d45513d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AttachmentHeftDisplay_LearnOpportunityV2Fragment$data = {
  readonly id: string;
  readonly root: {
    readonly attachmentsV2: ReadonlyArray<{
      readonly file?: {
        readonly url: string | null;
      } | null;
    }>;
    readonly id: string;
    readonly structureDefinition: {
      readonly extension?: {
        readonly firstLearnOpportunityIdWithMultipleChoiceElement?: string | null;
      };
    };
    readonly typeDefinition: {
      readonly children?: ReadonlyArray<{
        readonly attachmentsV2: ReadonlyArray<{
          readonly file?: {
            readonly url: string | null;
          } | null;
        }>;
        readonly id: string;
      }>;
    };
  } | null;
  readonly structureDefinition: {
    readonly extension?: {
      readonly firstLearnOpportunityIdWithMultipleChoiceElement?: string | null;
    };
  };
  readonly " $fragmentSpreads": FragmentRefs<"AttachmentButtonsDisplayFragment_LearnOpportunityV2Fragment">;
  readonly " $fragmentType": "AttachmentHeftDisplay_LearnOpportunityV2Fragment";
};
export type AttachmentHeftDisplay_LearnOpportunityV2Fragment$key = {
  readonly " $data"?: AttachmentHeftDisplay_LearnOpportunityV2Fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AttachmentHeftDisplay_LearnOpportunityV2Fragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "structureDefinition",
  "plural": false,
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "extension",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "firstLearnOpportunityIdWithMultipleChoiceElement",
                  "storageKey": null
                }
              ],
              "type": "LearnOpportunityRootExtensionImpl",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "LearnOpportunityRootStructureDefinition",
      "abstractKey": null
    }
  ],
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "attachmentsV2",
  "plural": true,
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "File",
          "kind": "LinkedField",
          "name": "file",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "url",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "InfectopharmFileAttachment",
      "abstractKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AttachmentHeftDisplay_LearnOpportunityV2Fragment",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "LearnOpportunityV2",
      "kind": "LinkedField",
      "name": "root",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "typeDefinition",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "LearnOpportunityV2",
                  "kind": "LinkedField",
                  "name": "children",
                  "plural": true,
                  "selections": [
                    (v0/*: any*/),
                    (v2/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "type": "LearnOpportunityBranchTypeDefinition",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AttachmentButtonsDisplayFragment_LearnOpportunityV2Fragment"
    }
  ],
  "type": "LearnOpportunityV2",
  "abstractKey": null
};
})();

(node as any).hash = "bdf5e1eccdb3a17b0146931d30468eac";

export default node;
