import React from "react";
import styled from "styled-components";
import { Dialog } from "primereact/dialog";
import { PreloadedQuery, usePreloadedQuery } from "react-relay";
import { MultipleChoiceElementPart_SubmittedAnswersQuery } from "@generated/MultipleChoiceElementPart_SubmittedAnswersQuery.graphql";
import { SUBMITTED_ANSWERS_QUERY } from "./MultipleChoiceElementPart.component";
import { StripHtml } from "../../../functions/helpers/StripHtml";
import { LMS_WEBAPP_CONFIG_IMPL } from "../../../../infecto-lms-webapp-impl/config";
import { PrimaryButton } from "../../buttons/PrimaryButton";
import { SecondaryButton } from "../../buttons/SecondaryButton";

interface OwnProps {
	isVisible: boolean;
	onHideDialog(): void;
	queryRef: PreloadedQuery<
		MultipleChoiceElementPart_SubmittedAnswersQuery,
		Record<string, unknown>
	>;
	submitLearnElement(): void;
	totalElementsCount: number;
	currentElement: any;
	checkedAnswerIndices: number[];
}

export function ShowUserAnswersSummaryDialog({
	isVisible,
	onHideDialog,
	queryRef,
	submitLearnElement,
	totalElementsCount,
	currentElement,
	checkedAnswerIndices,
}: OwnProps) {
	const data = usePreloadedQuery<MultipleChoiceElementPart_SubmittedAnswersQuery>(
		SUBMITTED_ANSWERS_QUERY,
		queryRef,
	);

	const answersRenderer = (answers: readonly string[]): JSX.Element[] => {
		return answers?.map((answer, index) => <p key={index}>{StripHtml(answer)}</p>);
	};

	const itemRenderer = (index: number, question: string, answers: JSX.Element[]): JSX.Element => {
		return (
			<div key={index}>
				<span className="font-bold text-sm md:text-base">
					<span>{index + 1}. Frage:</span> {StripHtml(question)}
				</span>
				<br />
				<br />
				<div className="flex flex-row">
					<p className="text-sm md:text-base">Ihre Antwort:</p>
					<div className="text-sm md:text-base ml-4 mb-2">{answers}</div>
				</div>
				<br />
			</div>
		);
	};

	const contentRenderer = data.LearnV2.GetSubmittedAnswers.map((decision, index) =>
		itemRenderer(index, decision.question, answersRenderer(decision.answers)),
	);

	if (data.LearnV2.GetSubmittedAnswers.length < totalElementsCount) {
		contentRenderer.push(
			itemRenderer(
				totalElementsCount - 1,
				currentElement?.question,
				answersRenderer(
					currentElement?.answerOptions
						.filter((e: any, index: number) => checkedAnswerIndices.includes(index))
						.map((e: any) => e.answer),
				),
			),
		);
	}

	const onSubmitLearnElementAndHideDialog = () => {
		submitLearnElement();
		onHideDialog();
	};

	const goBackButton = () => {
		onHideDialog();
		contentRenderer.pop();
	};
	const footer = () => {
		return (
			<div>
				<SecondaryButton label="Zurück" onClick={goBackButton} />
				<CustomButtonDialog
					color={LMS_WEBAPP_CONFIG_IMPL.brandColor}
					label="Absenden"
					onClick={onSubmitLearnElementAndHideDialog}
				/>
			</div>
		);
	};

	return (
		<StyledDialog
			header="Zusammenfassung der Anworten"
			visible={isVisible}
			footer={footer}
			onHide={onHideDialog}
		>
			{contentRenderer}
		</StyledDialog>
	);
}

const StyledDialog = styled(Dialog)`
	width: 70%;
	@media (max-width: 768px) {
		width: 90%;
	}
`;
export interface ButtonDialogProps {
	color: string;
}

export const CustomButtonDialog = styled(PrimaryButton)<ButtonDialogProps>`
	background-color: ${(props) => props.color} !important;
	border-color: ${(props) => props.color} !important;
	&.p-button:hover {
		background-color: ${(props) => props.color};
		border-color: ${(props) => props.color};
	}
`;
