import { NavLink } from "react-router-dom";
import React from "react";
import * as Icon from "react-feather";
import { DATA_PRIVACY_SCREEN_PATH, IMPRESS_SCREEN_PATH } from "../router/routes/auth/legal.routes";

export const SideMenuFooter = () => {
	return (
		<div className="border-top-1 border-300 p-4 ml-2 w-full">
			<div className="text-xl flex align-items-center">
				<a className="flex align-items-center no-underline" href="tel:+4962529128700">
					<i className="pi pi-phone mr-3" />
					<span className="text-primary">06252 9128700</span>
				</a>
			</div>
			<div className="flex align-items-center">
				<span className="font-bold text-lg mr-3 ml-1">§</span>
				<NavLink className="mr-2" to={DATA_PRIVACY_SCREEN_PATH}>
					Datenschutz
				</NavLink>
				<span>|</span>
				<div className="flex align-items-center">
					<Icon.Info size={15} className="mr-3 ml-2" />
					<NavLink className="mr-2" to={IMPRESS_SCREEN_PATH}>
						Impressum
					</NavLink>
				</div>
			</div>
		</div>
	);
};
