import React from "react";
import { Button } from "primereact/button";
import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery, useMutation } from "react-relay";
import { toast } from "react-toastify";
import { DebugScreen_SendTestEmailMutation } from "@generated/DebugScreen_SendTestEmailMutation.graphql";
import { DefaultContainer } from "../containers/Default.container";
import { MainContainerWithTopBar } from "../containers/MainContainerWithTopBar.container";
import { DebugScreen_Query } from "@generated/DebugScreen_Query.graphql";

const QUERY = graphql`
	query DebugScreen_Query {
		Viewer {
			Auth {
				currentUser {
					user {
						...DefaultContainer_UserDataFragment
					}
				}
			}
		}
	}
`;
const SEND_TEST_EMAIL_MUTATION = graphql`
	mutation DebugScreen_SendTestEmailMutation {
		Auth {
			testEmail(input: {}) {
				clientMutationId
			}
		}
	}
`;

export function DebugScreen() {
	const query = useLazyLoadQuery<DebugScreen_Query>(QUERY, {});

	const [sendTestEmail, isSending] =
		useMutation<DebugScreen_SendTestEmailMutation>(SEND_TEST_EMAIL_MUTATION);
	return (
		<DefaultContainer
			userDataFragmentRef={query.Viewer.Auth.currentUser?.user!}
			collapsed={false}
			screenName="debug"
		>
			<MainContainerWithTopBar>
				<Button
					disabled={isSending}
					onClick={() =>
						sendTestEmail({
							variables: {},
							onCompleted: () => {
								toast.success("Test E-Mail versendet");
							},
						})
					}
					label="Sende Test-Mail"
				/>
			</MainContainerWithTopBar>
		</DefaultContainer>
	);
}
