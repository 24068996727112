import styled from "styled-components";

export const MainContainer = styled.div`
	padding: 2rem;
	width: 85%;
	@media screen and (max-width: 768px) {
		width: 95%;
	}
	max-height: calc(100vh - 70px);
	@media screen and (max-width: 1024px) {
		width: 95%;
		padding: 1rem;
	}
	overflow: auto;
`;
