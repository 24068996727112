/**
 * @generated SignedSource<<9acdc35de72d55dec455f37fa87dcea0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WordpressCertificatesAchievedCursorSortDirectionInput = "asc" | "desc" | "%future added value";
export type WordpressCertificates_Refetch$variables = {
  after?: string | null;
  first?: number | null;
  sortDirection?: WordpressCertificatesAchievedCursorSortDirectionInput | null;
};
export type WordpressCertificates_Refetch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CertificatesScreen_WordpressCertificatesQueryFragment">;
};
export type WordpressCertificates_Refetch = {
  response: WordpressCertificates_Refetch$data;
  variables: WordpressCertificates_Refetch$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": 10,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sortDirection"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "sortDirection",
    "variableName": "sortDirection"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WordpressCertificates_Refetch",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "CertificatesScreen_WordpressCertificatesQueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WordpressCertificates_Refetch",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "WordpressCertificateAchievedByUserViewerSchema",
            "kind": "LinkedField",
            "name": "WordpressCertificateAchievedByUser",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "WordpressCertificateAndLearnStateConnection",
                "kind": "LinkedField",
                "name": "wordPressCertificates",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WordpressCertificateAndLearnStateEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "WordpressCertificateAndLearnState",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "contentNodeTitle",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "userName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "File",
                            "kind": "LinkedField",
                            "name": "certificateFile",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "url",
                                "storageKey": null
                              },
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "finishedAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v1/*: any*/),
                "filters": [
                  "sortDirection"
                ],
                "handle": "connection",
                "key": "CertificatesScreen_wordPressCertificates",
                "kind": "LinkedHandle",
                "name": "wordPressCertificates"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "56f3edcb20b7d59eaebda3f373afe507",
    "id": null,
    "metadata": {},
    "name": "WordpressCertificates_Refetch",
    "operationKind": "query",
    "text": "query WordpressCertificates_Refetch(\n  $after: String\n  $first: Int = 10\n  $sortDirection: WordpressCertificatesAchievedCursorSortDirectionInput\n) {\n  ...CertificatesScreen_WordpressCertificatesQueryFragment_2luGbS\n}\n\nfragment CertificatesScreen_WordpressCertificatesQueryFragment_2luGbS on Query {\n  Viewer {\n    WordpressCertificateAchievedByUser {\n      wordPressCertificates(first: $first, after: $after, sortDirection: $sortDirection) {\n        edges {\n          node {\n            id\n            contentNodeTitle\n            userName\n            certificateFile {\n              url\n              id\n            }\n            finishedAt\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8718f34716085a6c8906fddf35f8ea47";

export default node;
