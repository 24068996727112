import React, { useState } from "react";
import styled from "styled-components";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { PodcastElementDisplay_PodcastElementV2Fragment$key } from "@generated/PodcastElementDisplay_PodcastElementV2Fragment.graphql";
import { VideoPlayer } from "../../video/VideoPlayer.component";
import { PodigeePlayer } from "../../video/PodigeePlayer.component";
import { PodcastSubtitleDisplay } from "../../video/PdocastSubtitleDisplay.component";

const PODCAST_ELEMENT_FRAGMENT = graphql`
	fragment PodcastElementDisplay_PodcastElementV2Fragment on PodcastLearnElement {
		podcastSource {
			sourceType
			... on PodigeePodcastSourceV2 {
				podigeeUrl
			}
		}
		releasedFileOpt {
			file {
				url
			}
		}
		releasedSubtitlesOpt {
			subtitles {
				subtitles {
					startTime
					endTime
					text
				}
			}
		}
		title
	}
`;

interface OwnProps {
	podcastElementFragmentRef: PodcastElementDisplay_PodcastElementV2Fragment$key;
}

const PodcastPlayerWithMaxWidth = styled(VideoPlayer)`
	max-width: 1240px;
`;

export function PodcastElementDisplay({ podcastElementFragmentRef }: OwnProps) {
	const podcastElement = useFragment<PodcastElementDisplay_PodcastElementV2Fragment$key>(
		PODCAST_ELEMENT_FRAGMENT,
		podcastElementFragmentRef,
	);

	const [timestamp, setTimestamp] = useState<number>(0);

	return podcastElement ? (
		<div className="flex p-5">
			{podcastElement.podcastSource.sourceType === "podigee" ? (
				<div className="flex-grow-1">
					<h1 dangerouslySetInnerHTML={{ __html: podcastElement.title }} />
					<PodigeePlayer url={podcastElement.podcastSource.podigeeUrl} />
				</div>
			) : (
				<>
					<div className="pt-8 pl-4 pr-4">
						<PodcastSubtitleDisplay
							subtitles={podcastElement.releasedSubtitlesOpt?.subtitles?.subtitles}
							timestamp={timestamp}
						/>
					</div>

					<div>
						<h1 dangerouslySetInnerHTML={{ __html: podcastElement.title }} />
						{podcastElement.releasedFileOpt?.file?.url ? (
							<PodcastPlayerWithMaxWidth
								timestamp={timestamp}
								onCurrentTimeChange={(seconds) => {
									setTimestamp(() => seconds);
								}}
								videoFile={podcastElement.releasedFileOpt?.file?.url}
							/>
						) : (
							<div>Keine Podcastdatei </div>
						)}
					</div>
				</>
			)}
		</div>
	) : null;
}
