/**
 * @generated SignedSource<<207709b9a0f6bb952aa3fc5e8d403879>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CertificatesScreen_QueryFragment$data = {
  readonly CertificateAchievedByUser: {
    readonly certificates: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly fileRef: {
            readonly url: string | null;
          } | null;
          readonly issuedAt: string;
          readonly name: string;
        };
      } | null> | null;
    };
  };
  readonly Viewer: {
    readonly Auth: {
      readonly currentUser: {
        readonly user: {
          readonly " $fragmentSpreads": FragmentRefs<"DefaultContainer_UserDataFragment" | "UserStatusMessage_UserFragment">;
        };
      } | null;
    };
  };
  readonly " $fragmentType": "CertificatesScreen_QueryFragment";
};
export type CertificatesScreen_QueryFragment$key = {
  readonly " $data"?: CertificatesScreen_QueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CertificatesScreen_QueryFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "CertificateAchievedByUser",
  "certificates"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": 10,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sortDirection"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./CertificatesScreen_Refetch.graphql')
    }
  },
  "name": "CertificatesScreen_QueryFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CertificateAchievedByUserQueries",
      "kind": "LinkedField",
      "name": "CertificateAchievedByUser",
      "plural": false,
      "selections": [
        {
          "alias": "certificates",
          "args": [
            {
              "kind": "Variable",
              "name": "sortDirection",
              "variableName": "sortDirection"
            }
          ],
          "concreteType": "IssuedCertificateConnection",
          "kind": "LinkedField",
          "name": "__CertificatesScreen_certificates_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "IssuedCertificateEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "IssuedCertificateV2",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "File",
                      "kind": "LinkedField",
                      "name": "fileRef",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "url",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "issuedAt",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ViewerQueryType",
      "kind": "LinkedField",
      "name": "Viewer",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AuthViewerSchema",
          "kind": "LinkedField",
          "name": "Auth",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CurrentUser",
              "kind": "LinkedField",
              "name": "currentUser",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "User",
                  "kind": "LinkedField",
                  "name": "user",
                  "plural": false,
                  "selections": [
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "DefaultContainer_UserDataFragment"
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "UserStatusMessage_UserFragment"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "7ef2aa646982e6f5450da0c99c9c57ac";

export default node;
