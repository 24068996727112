import React from "react";
import styled from "styled-components";
import {
	Slider,
	SliderChangeParams,
	SliderProps,
	SliderSlideEndParams,
	SliderValueType,
} from "primereact/slider";
import { basic80, brandColor } from "../../../infecto-lms-webapp-impl/color";

interface Props extends SliderProps {
	value: SliderValueType;
	onChange?: (e: SliderChangeParams) => void;
	onSlideEnd?: (e: SliderSlideEndParams) => void;
	min?: number;
	max?: number;
	step?: number;
	showHandle?: boolean;
}

export const SliderAtom = ({ step = 1, ...props }: Props) => {
	return <StyledSlider step={step} {...props} />;
};

const StyledSlider = styled(Slider)<{ showHandle?: boolean }>`
	&.p-slider {
		background: ${basic80.rgbValue()};
		border: 0 none;
		border-radius: 3px;
	}

	&.p-slider:hover {
		cursor: pointer;
	}

	.p-slider-range {
		background: ${brandColor.rgbValue()};
		border-radius: 3px;
	}

	.p-slider-handle {
		visibility: ${({ showHandle }) => (showHandle ? "visible" : "hidden")};
		background: ${brandColor.rgbValue()};
		border-radius: 50%;
		border-color: ${brandColor.rgbValue()};
		transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
	}

	.p-slider-handle:focus {
		outline: 0 none;
		outline-offset: 0;
		box-shadow: 0 0 0 0.2rem ${brandColor.rgbValue()};
	}

	&.p-slider:not(.p-disabled) .p-slider-handle:hover {
		background: ${brandColor.rgbValue()};
		border-color: ${brandColor.rgbValue()};
	}

	.p-slider.p-slider-horizontal {
		height: 0.375rem;
	}
`;
