import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { SubtitleDisplay } from "../../video/SubtitleDisplay.component";
import { VideoPlayer } from "../../video-player-container/video-player";
import { VideoElementDisplay_VideoLearnElementFragment$key } from "@generated/VideoElementDisplay_VideoLearnElementFragment.graphql";

const VIDEO_ELEMENT_FRAGMENT = graphql`
	fragment VideoElementDisplay_VideoLearnElementFragment on VideoLearnElement {
		videoFile {
			url
		}
		subtitles {
			subtitles {
				startTimeInSec
				endTimeInSec
				text
			}
		}
		title
	}
`;

interface OwnProps {
	videoElementFragmentRef: VideoElementDisplay_VideoLearnElementFragment$key;
}

const VideoPlayerWithMaxWidth = styled(VideoPlayer)`
	max-width: 1240px;
`;

export function VideoElementDisplay({ videoElementFragmentRef }: OwnProps) {
	const videoElement = useFragment<VideoElementDisplay_VideoLearnElementFragment$key>(
		VIDEO_ELEMENT_FRAGMENT,
		videoElementFragmentRef,
	);

	const [timestamp, setTimestamp] = useState<number>(0);

	useEffect(() => {
		setTimestamp(0);
	}, [videoElement]);

	return videoElement ? (
		<div className="flex p-5 flex-column-reverse md:flex-row">
			{videoElement.subtitles?.length > 0 && (
				<div className="pt-8 pl-4 pr-4 overflow-scroll" style={{ maxHeight: "80vh" }}>
					<SubtitleDisplay subtitles={videoElement?.subtitles[0].subtitles} timestamp={timestamp} />
				</div>
			)}
			<div>
				<h1 dangerouslySetInnerHTML={{ __html: videoElement.title }} />
				{videoElement.videoFile?.url ? (
					<VideoPlayerWithMaxWidth url={videoElement.videoFile?.url} />
				) : (
					<div>Keine Videodatei </div>
				)}
			</div>
		</div>
	) : null;
}
