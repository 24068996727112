import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { FileElementDisplayWithAnswers_FileElementV2Fragment$key } from "@generated/FileElementDisplayWithAnswers_FileElementV2Fragment.graphql";
import { PdfViewer } from "../../PdfViewer.component";

const FILE_ELEMENT_FRAGMENT = graphql`
	fragment FileElementDisplayWithAnswers_FileElementV2Fragment on FileElementV2 {
		file {
			url
		}
		fileContentType
		title
	}
`;

interface OwnProps {
	fileElementFragmentRef: FileElementDisplayWithAnswers_FileElementV2Fragment$key;
}

export function FileElementDisplayWithAnswersWithAnswers({ fileElementFragmentRef }: OwnProps) {
	const fileElement = useFragment<FileElementDisplayWithAnswers_FileElementV2Fragment$key>(
		FILE_ELEMENT_FRAGMENT,
		fileElementFragmentRef,
	);

	if (!fileElement) return null;

	return (
		<div className="p-2 md:p-6">
			<h1 className="m-2" dangerouslySetInnerHTML={{ __html: fileElement.title }} />
			{fileElement.fileContentType === "pdf" && fileElement.file?.url && (
				<div className="flex-1 flex">
					<PdfViewer url={fileElement.file.url} />
				</div>
			)}

			{["png", "jpg"].includes(fileElement.fileContentType || "") && fileElement.file?.url && (
				<div>
					<img
						style={{ maxWidth: "100%", maxHeight: 500 }}
						src={fileElement.file?.url}
						alt={fileElement.title}
						width="auto"
					/>
				</div>
			)}

			{!fileElement.file?.url && <div>DIESE DATEI KONNTE NICHT ANGEZEIGT WERDEN</div>}
		</div>
	);
}
