import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { NodeConfigurationsDisplay_NodeFragment$key } from "@generated/NodeConfigurationsDisplay_NodeFragment.graphql";

const LEARN_OPPORTUNITY_FRAGMENT = graphql`
	fragment NodeConfigurationsDisplay_NodeFragment on LearnOpportunityV2 {
		root {
			structureDefinition {
				... on LearnOpportunityRootStructureDefinition {
					extension {
						... on LearnOpportunityRootExtensionImpl {
							configConsequences {
								rewardExpired
							}
						}
					}
				}
			}
		}
		typeDefinition {
			definitionType
			... on LearnOpportunityELearningContentTypeDefinition {
				extension {
					... on LearnContentExtensionImpl {
						amountOfMultipleChoiceElementsInContentSubmission
						configConsequences {
							percentageRequiredToPass
							isBlockedPermanentlyAfterNumFailedTries
							isBlockedTemporarilyForNumDaysAfterOneFailedTry
						}
					}
				}
				containedElementTypes
			}
		}
	}
`;

interface OwnProps {
	learnOpportunityFragmentRef: NodeConfigurationsDisplay_NodeFragment$key;
	className?: string;
}

export function NodeConfigurationsDisplay({ learnOpportunityFragmentRef, className }: OwnProps) {
	const learnOpportunity = useFragment<NodeConfigurationsDisplay_NodeFragment$key>(
		LEARN_OPPORTUNITY_FRAGMENT,
		learnOpportunityFragmentRef,
	);

	if (
		!learnOpportunity.root?.structureDefinition.extension?.configConsequences ||
		learnOpportunity.typeDefinition.definitionType !== "content"
	)
		return null;

	const numMultipleChoiceElements =
		learnOpportunity.typeDefinition.extension?.amountOfMultipleChoiceElementsInContentSubmission ||
		0;
	return (
		<div className="mt-2 lg:mt-5">
			{!learnOpportunity.root.structureDefinition.extension?.configConsequences.rewardExpired ? (
				<div>
					{numMultipleChoiceElements > 0 && (
						<div>
							<h2 className={`mb-2 lg:mb-3 ${className}`}>Details zur Lernerfolgskontrolle</h2>
							<div className={`${className}`}>Anzahl an Fragen: {numMultipleChoiceElements}</div>
						</div>
					)}
					{learnOpportunity.typeDefinition.extension?.configConsequences
						?.percentageRequiredToPass && (
						<div className={`${className}`}>
							Benötigter Anteil korrekter Antworten:{" "}
							{
								learnOpportunity.typeDefinition.extension?.configConsequences
									.percentageRequiredToPass
							}
							%
						</div>
					)}
					{learnOpportunity.typeDefinition.extension?.configConsequences
						?.isBlockedTemporarilyForNumDaysAfterOneFailedTry && (
						<div className={`${className}`}>
							Wird nach Fehlversuch gesperrt für:{" "}
							{
								learnOpportunity.typeDefinition.extension?.configConsequences
									.isBlockedTemporarilyForNumDaysAfterOneFailedTry
							}{" "}
							Tage
						</div>
					)}
					{learnOpportunity.typeDefinition.extension?.configConsequences
						?.isBlockedPermanentlyAfterNumFailedTries && (
						<div className={`${className}`}>
							Wird nach{" "}
							{
								learnOpportunity.typeDefinition.extension?.configConsequences
									.isBlockedPermanentlyAfterNumFailedTries
							}{" "}
							Fehlversuchen gesperrt.
						</div>
					)}
				</div>
			) : (
				<div>
					<h2 className={`${className}`}>Details</h2>
					<div className={`${className}`}>Dieses Modul ist abgelaufen.</div>
				</div>
			)}
		</div>
	);
}
