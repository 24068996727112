/**
 * @generated SignedSource<<9fe0347129cda09cacecfb54c1571c45>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MessagePage_MessageDefinitionPageFragment$data = {
  readonly image: {
    readonly url: string | null;
  } | null;
  readonly internalTitle: string;
  readonly messageDefinition: {
    readonly buttonOpt: {
      readonly " $fragmentSpreads": FragmentRefs<"MessageDefinitionButton_MessageDefinitionButtonFragment">;
    } | null;
  } | null;
  readonly " $fragmentType": "MessagePage_MessageDefinitionPageFragment";
};
export type MessagePage_MessageDefinitionPageFragment$key = {
  readonly " $data"?: MessagePage_MessageDefinitionPageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"MessagePage_MessageDefinitionPageFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MessagePage_MessageDefinitionPageFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "image",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "internalTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MessageDefinition",
      "kind": "LinkedField",
      "name": "messageDefinition",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "buttonOpt",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "MessageDefinitionButton_MessageDefinitionButtonFragment"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "MessageDefinitionPage",
  "abstractKey": null
};

(node as any).hash = "79a1d147671d9cdc26f6b110ff61d379";

export default node;
